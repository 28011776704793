import * as React from 'react'
import styled from '@emotion/styled'

import { ISelectRowsFunction } from './../../../../../Interfaces'
import RowText from './RowText'
import RowCheckbox from './RowCheckbox'
import { PADDING } from '../../../../../Constants'

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    padding: ${PADDING}px;

    :hover {
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
    }
`

const Hr = styled.div`
    border-color: ${({ theme }) => theme.colors.neutralsGrey60};
    border-bottom: 1px solid;
    height: 100%;
    position: absolute;

    width: 95%;
    margin: auto;
`

interface IRowProps<T> {
    checkboxSelectable: boolean
    selectAction: ISelectRowsFunction<T | boolean | any>
    virtualizedListRowStyle: React.CSSProperties
    rowSelected: boolean
    renderHr: boolean
    disabled: boolean
    rowItemComponentInstance: JSX.Element
}

export default class Row<T> extends React.Component<IRowProps<T>> {
    static readonly defaultProps = {
        renderHr: false,
        disabled: false,
    }

    render(): React.ReactNode {
        const {
            virtualizedListRowStyle,
            checkboxSelectable,
            rowSelected,
            selectAction,
            renderHr,
            disabled,
            rowItemComponentInstance,
        } = this.props

        return (
            <Container className="ctr-dropdown-row" onClick={selectAction} style={virtualizedListRowStyle}>
                {checkboxSelectable && (
                    <RowCheckbox disabled={disabled} handleClick={selectAction} value={rowSelected} />
                )}
                <RowText checkboxSelectable={checkboxSelectable} rowItemComponentInstance={rowItemComponentInstance} />
                {renderHr && <Hr />}
            </Container>
        )
    }
}
