import MockAdapter from 'axios-mock-adapter/types'

import { IDataSourceItem } from '@planier/data-source-types'
import { TDataSourceId } from '../../Types/IDataSource'
import { VIEW_ENGINE_URL } from '../../Constants/DataSourceConstants'
import IDataSourceConfiguration from '../../Types/IDataSourceConfiguration'
import { getTestDataSourceConfiguration } from '../../Test/DataSourceTestData'

export const fetchDataSourceMetadataMock = (
    axiosMock: MockAdapter,
    dataSourceId: TDataSourceId,
    partialTestData?: Partial<IDataSourceConfiguration>
): void => {
    const dataSourceMetaData = { ...getTestDataSourceConfiguration(), ...partialTestData }

    axiosMock.onGet(`${VIEW_ENGINE_URL}/DataSource?id=${dataSourceId}`).reply(200, dataSourceMetaData)
}

export const fetchDataSourceDataMock = <TItem extends IDataSourceItem>(
    axiosMock: MockAdapter,
    url: string,
    successfulResponse: boolean,
    mockData?: TItem[]
): void => {
    if (!successfulResponse) {
        axiosMock.onPost(`/${url}`).reply(404, {})
        return
    }

    axiosMock.onPost(`/${url}`).reply(200, { ListData: mockData, AccumulatedData: [] })
}
