import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { getTestId } from '@planier/test'

import { Button } from '@planier/generic-components'

export interface IListActionButtonProps {
    buttonText?: string
    callbackProps: unknown
    disabled: boolean
    onClick: (callbackProps: unknown) => void
    testId?: string
}

export class ListActionButton extends React.Component<IListActionButtonProps> {
    private handleActionButtonClick = () => {
        const { callbackProps, onClick } = this.props

        onClick(callbackProps)
    }

    render(): React.ReactNode {
        const { buttonText, disabled, testId } = this.props

        return (
            <Grid item>
                <Button
                    disabled={disabled}
                    onClick={this.handleActionButtonClick}
                    testId={getTestId(['LIST_ACTION', 'BTN'], testId)}
                >
                    {buttonText}
                </Button>
            </Grid>
        )
    }
}

export default ListActionButton
