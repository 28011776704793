import { RootState } from 'typesafe-actions'
import { createSelector } from 'reselect'

import INavigationSubsection from '../Types/INavigationSubsection'
import INavigationSection from '../Types/INavigationSection'
import INavigationSectionWithSubsections from '../Types/INavigationSectionWithSubsections'
import INavigationItem from '../Types/INavigationItem'
import { NAVIGATION_ITEMS_ASYNC_OPERATION } from '../Constants/AsyncOperationConstants'
import { selectIsAsyncOperationIsInProgress } from '@planier/async-operation'
import { getLogger } from '@planier/log'

const Log = getLogger('navigation.NavigationSelectors')

export const selectNavigationSections = (state: RootState): INavigationSection[] => {
    return state.navigationItems.navigationSections
}

export const selectNavigationSubsections = (state: RootState): INavigationSubsection[] => {
    return state.navigationItems.navigationSubsections
}

export const selectAreNavigationItemsSet = (state: RootState): boolean => {
    return selectNavigationSections(state).length > 0 && selectNavigationSubsections(state).length > 0
}

type TSubsectionsWithinObject = {
    [id: number]: INavigationSubsection
}

export const selectSectionsWithSubsections = createSelector(
    selectNavigationSections,
    selectNavigationSubsections,
    (sections, subsections): INavigationSectionWithSubsections[] => {
        const subsectionsWithinObject = subsections.reduce((subsectionsObject, subsection) => {
            subsectionsObject[subsection.Id] = subsection
            return subsectionsObject
        }, {} as TSubsectionsWithinObject)

        const sectionsWithSubsections: INavigationSectionWithSubsections[] = sections.map((section) => {
            const subsectionObjects = section.Subsections.filter((subsectionId) => {
                if (!subsectionsWithinObject[subsectionId]) {
                    Log.error('No subsection object found for given subsectionId of section')
                    return false
                }

                return true
            }).map((subsectionId) => subsectionsWithinObject[subsectionId])

            return {
                ...section,
                subsectionObjects,
            }
        })

        return sectionsWithSubsections
    }
)

export const selectCurrentPage = (state: RootState): string => {
    return state.navigationItems.currentPage
}

export const selectCurrentPagePath = (): string => {
    const pagePath = window.location.href.split(window.location.origin + '/')[1]

    return pagePath
}

/**
 * Returns the current sub section.
 */
export const selectCurrentSubsection = createSelector(
    selectNavigationSubsections,
    selectCurrentPagePath,
    (subsections, currentPagePath): INavigationSubsection | null => {
        const matchingSubsection = subsections.find((subsection) => subsection.Url.includes(currentPagePath))

        return matchingSubsection ?? null
    }
)

/**
 * Returns the section for the current page.
 */
export const selectCurrentSectionWithSubsections = createSelector(
    selectSectionsWithSubsections,
    selectCurrentSubsection,
    (sectionsWithSubsections, currentSubsection): INavigationSectionWithSubsections | null => {
        const matchingSection = sectionsWithSubsections.find(({ subsectionObjects }) => {
            const subsectionIsCurrentPage = subsectionObjects.some(
                (subsection) => subsection.Id === currentSubsection?.Id
            )
            return subsectionIsCurrentPage
        })

        return matchingSection ?? null
    }
)

/**
 * If the current section has multiple subsections, returns only those.
 * Otherwise, if there's only one subsection for the current section,
 * return all the sections but basically adds their first subsection's
 * URL to them so that they can be used directly as navigation items.
 */
export const selectCurrentPageNavigationItems = createSelector(
    selectCurrentSectionWithSubsections,
    selectSectionsWithSubsections,
    (currentSectionWithSubsections, sections): INavigationItem[] => {
        if (currentSectionWithSubsections && currentSectionWithSubsections.subsectionObjects.length > 1) {
            return currentSectionWithSubsections.subsectionObjects.map(({ Id, Url, Translation }) => ({
                id: `subsection-${Id}`,
                url: Url,
                label: Translation,
            }))
        }

        return sections
            .filter(({ Id, subsectionObjects }) => {
                if (subsectionObjects.length === 0) {
                    Log.error('found navigation section $0 without any subsections', Id)
                    return false
                }
                return true
            })
            .map(({ Id, subsectionObjects, Translation }) => {
                const firstSubsection = subsectionObjects[0]

                return {
                    id: `section-${Id}`,
                    url: firstSubsection.Url,
                    label: Translation,
                }
            })
    }
)

export const selectNavigationSectionsAreLoading = (state: RootState): boolean => {
    return selectIsAsyncOperationIsInProgress(state, NAVIGATION_ITEMS_ASYNC_OPERATION)
}
