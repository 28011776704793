import * as React from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {
    dataSourceSetFetchFiltersParametersAction,
    selectIsDataSourceDataFetched,
    selectIsDataSourceInitialized,
} from '@planier/data-source'
import { Translate } from '@planier/localization'
import { fetchListDataThunk, selectDataSourceId } from '@planier/configurable-list'
import { BoundThunk } from '@planier/generic-state'
import styled from '@emotion/styled'

// eslint-disable-next-line no-shadow
enum ETabValues {
    Siirtamattomat = 'Siirtamattomat',
    SiirtoonHyvaksytyt = 'SiirtoonHyvaksytyt',
    Siirretty = 'Siirretty',
    Kaikki = 'Kaikki',
}

// eslint-disable-next-line no-shadow
enum EToteumaSiirtoStatusValues {
    SiirtoaOdottava = 1,
    SiirtoonValittu = 2,
    Siirretty = 3,
}

const getToteumaSiirtoStatusValueForTab = (newSelectedTab: ETabValues) => {
    switch (newSelectedTab) {
        case ETabValues.Siirtamattomat:
            return [EToteumaSiirtoStatusValues.SiirtoaOdottava]
        case ETabValues.SiirtoonHyvaksytyt:
            return [EToteumaSiirtoStatusValues.SiirtoonValittu]
        case ETabValues.Siirretty:
            return [EToteumaSiirtoStatusValues.Siirretty]
        case ETabValues.Kaikki:
        default:
            return [
                EToteumaSiirtoStatusValues.SiirtoaOdottava,
                EToteumaSiirtoStatusValues.SiirtoonValittu,
                EToteumaSiirtoStatusValues.Siirretty,
            ]
    }
}

interface IOwnProps {
    listId: string
}

interface IStateProps {
    isDataSourceInitialised: boolean
    dataSourceId: string
    isDataFetched: boolean
}

interface IDispatchProps {
    dataSourceSetFetchParameters: typeof dataSourceSetFetchFiltersParametersAction
    fetchListData: BoundThunk<typeof fetchListDataThunk>
}

interface IToteumaSiirtoStatusTabsProps extends IOwnProps, IStateProps, IDispatchProps {}

const StyledPaper = styled(Paper)`
    margin-bottom: 8px;
`

const ToteumaSiirtoStatusTabsUnconnected: React.FunctionComponent<IToteumaSiirtoStatusTabsProps> = ({
    dataSourceId,
    isDataSourceInitialised,
    dataSourceSetFetchParameters,
    isDataFetched,
    fetchListData,
    listId,
}) => {
    const [selectedTab, setSelectedTab] = useState(ETabValues.Siirtamattomat)
    const [fetchDataOnTabChange, setFetchDataOnTabChange] = useState(false)

    useEffect(() => {
        if (!isDataSourceInitialised) {
            return
        }

        const toteumaSiirtoStatukset = getToteumaSiirtoStatusValueForTab(selectedTab)
        dataSourceSetFetchParameters(dataSourceId, { Toteumastatukset: toteumaSiirtoStatukset })

        if (isDataFetched && fetchDataOnTabChange) {
            fetchListData(listId, { resetSelected: true, resetOffset: true })
        }
    }, [
        dataSourceSetFetchParameters,
        dataSourceId,
        isDataSourceInitialised,
        selectedTab,
        isDataFetched,
        fetchListData,
        listId,
        fetchDataOnTabChange,
    ])

    const handleChange = (event: React.ChangeEvent<unknown>, newSelectedTab: ETabValues) => {
        setSelectedTab(newSelectedTab)

        if (isDataFetched) {
            setFetchDataOnTabChange(true)
        }
    }

    return (
        <StyledPaper>
            <Translate
                translate={{
                    siirtamattomat: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirtamattomat',
                    siirtoonHyvaksytyt: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirtoonHyvaksytyt',
                    siirretty: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.siirretty',
                    kaikki: 'palkkahallinto.ToteumaPage.ToteumaSiirtoStatusTabs.Label.kaikki',
                }}
            >
                {({ siirtamattomat, siirtoonHyvaksytyt, siirretty, kaikki }) => (
                    <Tabs indicatorColor="primary" onChange={handleChange} textColor="primary" value={selectedTab}>
                        <Tab label={siirtamattomat} value={ETabValues.Siirtamattomat} />
                        <Tab label={siirtoonHyvaksytyt} value={ETabValues.SiirtoonHyvaksytyt} />
                        <Tab label={siirretty} value={ETabValues.Siirretty} />
                        <Tab label={kaikki} value={ETabValues.Kaikki} />
                    </Tabs>
                )}
            </Translate>
        </StyledPaper>
    )
}

const mapStateToProps = (state: RootState, { listId }: IOwnProps) => {
    const dataSourceId = selectDataSourceId(state, listId)

    return {
        dataSourceId,
        isDataSourceInitialised: selectIsDataSourceInitialized(state, dataSourceId),
        isDataFetched: selectIsDataSourceDataFetched(state, dataSourceId),
    }
}

const mapDispatchToProps = {
    dataSourceSetFetchParameters: dataSourceSetFetchFiltersParametersAction,
    fetchListData: fetchListDataThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ToteumaSiirtoStatusTabsUnconnected)
