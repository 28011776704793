import { useEffect } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { BoundThunk } from '@planier/generic-state'
import { fetchValuePickerConfigurationsThunk } from '../../Thunks/ValuePickerThunks'
import ValuePicker from '../ValuePicker'
import { selectValuePickerConfiguration } from '../../State/ValuePickerSelectors'
import { TValuePickerConfiguration } from '../../types/TValuePickerConfiguration'

interface IOwnProps {
    valuePickerId: string
    label?: string
    contextId?: string
    context?: 'form' | 'list' | 'unknown'
    onChange?: (newValue: unknown) => void
}

interface IStateProps {
    valuePickerConfiguration: TValuePickerConfiguration | null
}

interface IDispatchProps {
    fetchValuePickerConfigurations: BoundThunk<typeof fetchValuePickerConfigurationsThunk>
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps { }

/**
 * Renders a single value picker and takes care of its initialisation.
 */
const ValuePickerStandaloneUnconnected: React.FunctionComponent<IProps> = ({
    valuePickerId,
    fetchValuePickerConfigurations,
    valuePickerConfiguration,
    label,
    contextId,
    context,
    onChange,
}) => {
    useEffect(() => {
        fetchValuePickerConfigurations([valuePickerId])
    }, [fetchValuePickerConfigurations, valuePickerId])

    if (!valuePickerConfiguration) {
        return null
    }

    return <ValuePicker {...valuePickerConfiguration} onChange={onChange} valuePickerId={valuePickerId} label={label} contextId={contextId} context={context} />
}

const mapStateToProps = (state: RootState, { valuePickerId }: IOwnProps) => ({
    valuePickerConfiguration: selectValuePickerConfiguration(state, valuePickerId),
})

const mapDispatchToProps = {
    fetchValuePickerConfigurations: fetchValuePickerConfigurationsThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuePickerStandaloneUnconnected)
