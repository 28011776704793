import { createAction } from 'typesafe-actions'

import IValuePicker from '../types/IValuePicker'
import TValuePickerId from '../types/TValuePickerId'

export const initializeValuePickersAction = createAction('value-picker.InitializeValuePickers')<{
    page: string
    valuePickers: IValuePicker[]
}>()

export const setValuePickerValueAction = createAction(
    'value-picker.SetValuePickerValue',
    (
        value: unknown,
        id: TValuePickerId,
        contextId?: string,
        sideEffects?: { key: string; value: unknown }[],
        notes?: string
    ) => ({
        value: value,
        id: id,
        contextId: contextId,
        sideEffects: sideEffects,
        notes: notes,
    })
)()

export const setValuePickersValuesAction = createAction(
    'value-picker.setValuePickersValuesAction',
    (value: ReadonlyMap<TValuePickerId, unknown>, contextId?: string, notes?: string) => ({
        value: value,
        contextId: contextId,
        notes: notes,
    })
)()

type TValuePickersWithValue = ReadonlyMap<TValuePickerId, Partial<IValuePicker>>
export const resetValuePickerValuesAction = createAction(
    'value-picker.ResetValues',
    (value: TValuePickersWithValue, contextId?: string) => ({
        value: value,
        contextId: contextId,
    })
)()

export const removeValuePickerAction = createAction('value-picker_removeValuePicker')<TValuePickerId>()

export const loadDefaultValuePickersValues = createAction('value-picker_loadDefaultValuePickerValues')<{
    page: string
    valuePickerIds: TValuePickerId[]
}>()
