import * as React from 'react'
import { Suspense, useCallback, useContext } from 'react'

import EValuePickerType from '../../types/EValuePickerType'
import ValuePickerComponentRegistry from '../../ValuePickerComponentRegistry'
import { getLogger } from '@planier/log'
import { setValuePickerValueAction } from '../../State/ValuePickerActions'
import { useDispatch } from 'react-redux'
import { Moment } from 'moment'
import { IFile, ITimerangeValue } from '@planier/generic-components'
import { TValuePickerConfiguration } from '../../types/TValuePickerConfiguration'
import ITogglePickerConfiguration from 'packages/value-picker/types/ITogglePickerConfiguration'
import { ISkill } from 'packages/value-picker/types/ISkillPickerConfiguration'
import { ISingleDayPickerConfiguration } from 'packages/value-picker/types/ISingleDayPickerConfiguration'
import { SubPriceValue } from 'packages/value-picker-components/components/SubPricePicker'
import { SideEffect } from '@planier/types'

const Log = getLogger('value-picker.ValuePicker')

export interface IValuePickerProps {
    ValuePickerId: string
    ValuePickerType: string
    onChange?: (value: any) => void
    useCachedValuesForOptions?: boolean
    dropdownWidth?: number | null
    dropdownMenuWidth?: number | null
    isRequired: boolean
    [key: string]: any
    label?: string
    contextId?: string
    context?: 'form' | 'list' | 'unknown'
    valueFromStore: any
    notes?: string
    isInitialValueSet?: boolean
    error?: string
    valuePickerComponentProperties: any
    configuration: TValuePickerConfiguration
}

interface IProps extends IValuePickerProps {}

const ValuePickerStateless: React.FunctionComponent<IProps> = ({
    useCachedValuesForOptions = false,
    label,
    isRequired,
    ...props
}) => {
    const {
        ValuePickerType: valuePickerType,
        ValuePickerId,
        contextId,
        configuration,
        valueFromStore,
        onChange,
        ObjectType,
    } = props

    const dispatch = useDispatch()

    const { components } = useContext(ValuePickerComponentRegistry)

    const onValuePickerValueChange = useCallback(
        (value: any, sideEffects?: SideEffect[]) => {
            onChange && onChange(ValuePickerId)

            const newValue = value === undefined ? null : value

            dispatch(setValuePickerValueAction(newValue, ValuePickerId, contextId, sideEffects))
        },
        [onChange, ValuePickerId, dispatch, contextId]
    )

    const onBooleanValuePickerValueChange = useCallback(() => {
        const currentValue = valueFromStore as boolean
        const toggleConfigs = configuration as ITogglePickerConfiguration

        onChange && onChange(ValuePickerId)

        const sideEffects = !currentValue ? toggleConfigs.TrueValueSideEffects : toggleConfigs.FalseValueSideEffects

        dispatch(setValuePickerValueAction(!currentValue, ValuePickerId, contextId, sideEffects))
    }, [valueFromStore, configuration, onChange, ValuePickerId, dispatch, contextId])

    if (!components) {
        Log.warn('Value picker component registry not initialised')
        return null
    }

    switch (valuePickerType) {
        case EValuePickerType.Hidden: {
            return null
        }

        case EValuePickerType.RadioButtonListValuePicker: {
            const { RadioButtonListValuePicker } = components

            return (
                <RadioButtonListValuePicker
                    {...props.valuePickerComponentProperties}
                    onChange={onValuePickerValueChange}
                    value={props.valueFromStore}
                    label={label}
                    OptionsEndpointUrl={props.OptionsEndpointUrl}
                />
            )
        }

        case EValuePickerType.ColorPicker: {
            const { ColorPicker } = components

            return <ColorPicker value={props.valueFromStore} label={label} onChange={onValuePickerValueChange} />
        }

        case EValuePickerType.AdditionalServicesPicker: {
            const { AdditionalServicesPicker } = components

            return (
                <Suspense fallback={null}>
                    <AdditionalServicesPicker
                        {...props.valuePickerComponentProperties}
                        valueFromStore={props.valueFromStore}
                        label={label}
                        onChange={onValuePickerValueChange}
                        valuePickerId={ValuePickerId}
                    />
                </Suspense>
            )
        }

        case EValuePickerType.TransactionPricePicker: {
            const { TransactionPricePicker } = components

            return (
                <TransactionPricePicker
                    isInitialValueSet={props.isInitialValueSet}
                    value={props.valueFromStore}
                    {...props.valuePickerComponentProperties}
                    onChange={onValuePickerValueChange}
                />
            )
        }

        case EValuePickerType.RepetitiveEvent: {
            const { RepetitiveEventPicker } = components

            return (
                <RepetitiveEventPicker
                    value={props.valueFromStore}
                    error={props.error}
                    onChange={onValuePickerValueChange}
                />
            )
        }
        case EValuePickerType.DynamicData: {
            const { DynamicDataPicker } = components

            return (
                <DynamicDataPicker
                    value={props.valueFromStore}
                    onChange={onValuePickerValueChange}
                    ObjectType={ObjectType}
                />
            )
        }

        case EValuePickerType.EventReservationRestrictionRulePicker: {
            const { EventReservationRestrictionRulePicker } = components

            return (
                <Suspense fallback={null}>
                    <EventReservationRestrictionRulePicker
                        value={props.valueFromStore}
                        onChange={onValuePickerValueChange}
                    />
                </Suspense>
            )
        }

        case EValuePickerType.TransactionSalaryPicker: {
            const { TransactionSalaryPicker } = components

            return (
                <TransactionSalaryPicker
                    isInitialValueSet={props.isInitialValueSet}
                    value={props.valueFromStore}
                    {...props.valuePickerComponentProperties}
                    onChange={onValuePickerValueChange}
                />
            )
        }

        case EValuePickerType.NumberPicker: {
            const { NumberPicker } = components

            const numberValue = props.valueFromStore as number

            return (
                <NumberPicker
                    {...props.valuePickerComponentProperties}
                    errors={props.error}
                    label={label}
                    value={numberValue}
                    required={isRequired}
                    onChange={onValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.TagList:
        case EValuePickerType.DropdownV2:
        case EValuePickerType.Dropdown: {
            const {
                EnableTextSearch: enableTextSearch,
                Text: text,
                TextSearchProps: textSearchProps,
                multiselect,
                ItemOptionLabelFields,
                ItemValueLabelFields,
                disabled,
                UseDynamicApiSearch: useDynamicApiSearch,
                ItemIdField: itemIdField,
                MainColorField,
                SupportColorField,
                ItemLabelFields,
                dropdownMenuWidth,
                SingleValueLink,
                valueFieldType,
                spaceReservedForChipSelectionCount,
                notes,
            } = props

            const commonProps = {
                enableTextSearch,
                ValuePickerId,
                onChange,
                textSearchProps,
                multiselect,
                disabled,
                useDynamicApiSearch,
                itemIdField,
                onValuePickerValueChange,
                text: text,
                notes: notes,
                spaceReservedForChipSelectionCount,
            }

            const { ValuePickerWithOptionsWithoutStore } = components

            const { ValuePickerWithOptions } = components

            const value = props.valueFromStore

            switch (valuePickerType) {
                case EValuePickerType.DropdownV2:
                case EValuePickerType.Dropdown: {
                    return (
                        <ValuePickerWithOptionsWithoutStore
                            {...commonProps}
                            {...props.valuePickerComponentProperties}
                            errors={props.error}
                            valuePickerId={ValuePickerId}
                            label={label}
                            dropdownMenuWidth={dropdownMenuWidth}
                            itemOptionLabelFields={ItemOptionLabelFields}
                            itemValueLabelFields={ItemValueLabelFields}
                            required={isRequired}
                            contextId={props.contextId}
                            selected={value}
                            singleValueLinkStart={SingleValueLink?.LinkStart ?? null}
                            singleValueLinkTooltip={SingleValueLink?.Tooltip ?? null}
                            useCachedValuesForOptions={useCachedValuesForOptions}
                            valueFieldType={props.context === 'form' ? 'form' : valueFieldType}
                            valuePickerType={valuePickerType}
                        />
                    )
                }
                case EValuePickerType.TagList: {
                    return (
                        <ValuePickerWithOptions
                            valuePickerId={ValuePickerId}
                            {...commonProps}
                            itemLabelFields={ItemLabelFields}
                            mainColorField={MainColorField}
                            required={isRequired}
                            supportColorField={SupportColorField}
                            useCachedValuesForOptions={useCachedValuesForOptions}
                            valuePickerType={valuePickerType}
                        />
                    )
                }
                default:
                    return null
            }
        }

        case EValuePickerType.CheckboxPicker: {
            const { CheckboxPicker } = components

            const booleanValue = props.valueFromStore as boolean

            return (
                <CheckboxPicker
                    {...props.valuePickerComponentProperties}
                    label={label}
                    onChange={onBooleanValuePickerValueChange}
                    value={booleanValue}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.FilePicker: {
            const { FilePicker } = components

            const fieldValue = props.valueFromStore as IFile

            return (
                <FilePicker
                    displayType={props.DisplayType}
                    {...props.valuePickerComponentProperties}
                    label={label}
                    onChange={onValuePickerValueChange}
                    value={fieldValue}
                />
            )
        }

        case EValuePickerType.SkillPicker: {
            const { SkillPicker } = components

            const fieldValue = props.valueFromStore as ISkill[]

            return (
                <Suspense fallback={null}>
                    <SkillPicker
                        {...props.valuePickerComponentProperties}
                        label={label}
                        onChange={onValuePickerValueChange}
                        value={fieldValue}
                    />
                </Suspense>
            )
        }

        case EValuePickerType.ExternalIdentifierValuePicker: {
            const { ExternalIdentifierValuePicker } = components

            return (
                <Suspense fallback={null}>
                    <ExternalIdentifierValuePicker
                        {...props.valuePickerComponentProperties}
                        onChange={onValuePickerValueChange}
                        value={props.valueFromStore}
                    />
                </Suspense>
            )
        }

        case EValuePickerType.TimeRangePicker: {
            const { TimeRangePicker } = components

            const timerangeValue = props.valueFromStore as ITimerangeValue

            return (
                <TimeRangePicker
                    {...props.valuePickerComponentProperties}
                    value={timerangeValue}
                    errors={props.error}
                    required={isRequired}
                    onChange={onValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.TimePicker: {
            const { TimePicker } = components

            const timeValue = props.valueFromStore as Moment

            return (
                <TimePicker
                    {...props.valuePickerComponentProperties}
                    value={timeValue}
                    errors={props.error}
                    label={label}
                    required={isRequired}
                    onChange={onValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        //TODO
        case EValuePickerType.ConfigurableList: {
            const { ListId: listId, UsePageTemplate: usePageTemplate } = props

            const { ConfigurableListValuePicker } = components

            return (
                <ConfigurableListValuePicker
                    listId={listId}
                    usePageTemplate={usePageTemplate}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.TextSearch: {
            const { TextSearchPicker } = components

            const stringValue = props.valueFromStore as string

            return (
                <TextSearchPicker
                    {...props.valuePickerComponentProperties}
                    value={stringValue}
                    onChange={onValuePickerValueChange}
                    label={label}
                    required={isRequired}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.TextPicker: {
            const { TextPicker } = components

            const stringValue = props.valueFromStore as string

            return (
                <TextPicker
                    {...props.valuePickerComponentProperties}
                    value={stringValue}
                    errors={props.error}
                    label={label}
                    required={isRequired}
                    multiline={props.Multiline}
                    onChange={onValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.Toggle: {
            const { TogglePicker } = components

            const toggleConfigs = configuration as ITogglePickerConfiguration

            const booleanValue = valueFromStore as boolean

            return (
                <TogglePicker
                    value={booleanValue}
                    {...props.valuePickerComponentProperties}
                    label={label}
                    onChange={onBooleanValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                    icon={toggleConfigs.Icon}
                />
            )
        }

        case EValuePickerType.SingleDayPicker: {
            const momentValue = props.valueFromStore as Moment

            const { SingleDayPicker } = components

            const handleOnChange = (value: string) => {
                const config = configuration as ISingleDayPickerConfiguration

                const sideEffects = value ? config.TrueValueSideEffects : config.FalseValueSideEffects

                onValuePickerValueChange(value, sideEffects)
            }

            return (
                <SingleDayPicker
                    {...props.valuePickerComponentProperties}
                    value={momentValue}
                    errors={props.error}
                    label={label}
                    required={isRequired}
                    onChange={handleOnChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.MultiDayPicker: {
            const momentValue = props.valueFromStore as Moment[]

            const { MultiDayPicker } = components

            return (
                <MultiDayPicker
                    {...props.valuePickerComponentProperties}
                    value={momentValue}
                    errors={props.error}
                    label={label}
                    required={isRequired}
                    onChange={onValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.DayPickerWithRangeAndWeekdays: {
            const momentValue = props.valueFromStore
                ? props.valueFromStore instanceof Set
                    ? [...props.valueFromStore]
                    : (props.valueFromStore as Moment[])
                : []

            const { DayPickerWithRangeAndWeekdays } = components

            return (
                <DayPickerWithRangeAndWeekdays
                    {...props.valuePickerComponentProperties}
                    errors={props.error}
                    value={momentValue}
                    label={label}
                    required={isRequired}
                    onChange={onValuePickerValueChange}
                    valuePickerId={ValuePickerId}
                />
            )
        }

        case EValuePickerType.SubPricePicker: {
            const { SubPricePicker } = components

            type SavedSubPriceValue = {
                DayCategory: number
                DayCategoryIdentifier: { Name: string; Id: number }
                OverridingPayTypeIdentifier: { Name: string; Id: number }
                StartTimeText: { Time: string; DayOffset: boolean }
                EndTimeText: { Time: string; DayOffset: boolean }
                DisplayText: string
            }

            const isValidValue = (v: SubPriceValue | SavedSubPriceValue): v is SubPriceValue => {
                return v.hasOwnProperty('OverridingPayType') && !v.hasOwnProperty('OverridingPayTypeIdentifier')
            }

            /*
             * Server sends the saved values in a different format than it wants them to be saved as.
             * Because of this, we need to map the saved values into a "saveable" format before rendering them.
             */
            const value = [...(props.valueFromStore || [])].map((v: SubPriceValue | SavedSubPriceValue) => {
                if (isValidValue(v)) {
                    return v
                }

                return {
                    DayCategory: v.DayCategoryIdentifier,
                    StartTime: v.StartTimeText,
                    EndTime: v.EndTimeText,
                    OverridingPayType: v.OverridingPayTypeIdentifier,
                    DisplayText: v.DisplayText,
                }
            }) as SubPriceValue[]

            return (
                <Suspense fallback={null}>
                    <SubPricePicker
                        {...props.valuePickerComponentProperties}
                        value={value}
                        label={label}
                        valuePickerId={ValuePickerId}
                        onChange={onValuePickerValueChange}
                    />
                </Suspense>
            )
        }

        case EValuePickerType.DynamicValuePicker: {
            // In future we might wanna do something like being able to attach custom
            // components in the fly for dynamic value pickers, but for now the dynamic
            // value picker exists only for handling value picker related state without
            // a component.
            return null
        }

        default:
            return null
    }
}

export default ValuePickerStateless
