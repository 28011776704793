import * as React from 'react'
import { Moment } from 'moment'

import { formatForDataUsage, toDateObject } from '@planier/dates'
import { DayPickerWithRangeAndWeekDaysV2 } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from 'packages/value-picker'

const DayPickerWithRangeAndWeekDays: React.FC<IValuePickerCommonOwnProps<Moment[]>> = ({
    required,
    value,
    onChange,
    errors,
    valuePickerId,
}) => {
    const values = value ?? []

    const formattedValues = values.map((x) => formatForDataUsage(x))

    const _onChange = (newValues: string[]) => {
        const newValueObjects = newValues.map((x) => toDateObject(x))

        onChange(newValueObjects)
    }

    return (
        <DayPickerWithRangeAndWeekDaysV2
            defaultPickerMode={'days'}
            labelRangeEnd={'Loppupäivä'}
            labelRangeStart={'Alkupäivä'}
            value={formattedValues}
            onChange={_onChange}
            required={required}
            errors={errors}
            valuePickerId={valuePickerId}
        />
    )
}

export default DayPickerWithRangeAndWeekDays
