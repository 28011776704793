import { errorOnPromiseFailed } from 'action-creators/ErrorActions'
import { fetchFormView } from '../WebApi/FormViewWebApi'
import {
    finishLoadingData,
    formViewInitializationFailed,
    resetLoadingData,
    setFormViewAction,
} from '../State/FormViewActions'
import {
    executeDataItemActionThunk,
    executeSingleDataItemActionThunk,
    fetchDataSourceDataThunk,
    initializeDataSourceThunk,
    replaceDataSourceItemThunk,
    selectDataSourceItemsByIds,
    TDataSourceId,
} from '@planier/data-source'
import { TDataSourceItemId } from '@planier/data-source-types'
import { IFormViewModalProps } from '@planier/form-view-types'
import { IThunkBaseAction } from '@planier/generic-state'
import {
    selectFormViewConfiguration,
    selectFormViewDataSourceId,
    selectFormViewSubmitActionId,
    selectFormViewSubsequentFormViewId,
    selectFormViewValuePickerIds,
} from '../State/FormViewSelectors'
import {
    EValuePickerType,
    fetchValuePickerConfigurationsThunk,
    getValuePickerInitialState,
    isValuePickerWithOptions,
    IValuePicker,
    resetValuePickerValuesAction,
    selectValuePickerConfiguration,
    selectValuePickerValue,
    setValuePickerValueAction,
    TValuePickerConfiguration,
    TValuePickerId,
} from '@planier/value-picker'
import { openModalAction } from '@planier/modal'
import { displayErrorToaster } from '@planier/notifications'
import { getValueForField } from '../Utils/FormViewUtils'
import { storedOptionsInitializeAction, storedOptionsResetOptionsForComponentsAction } from '@planier/stored-options'
import { asyncOperationStartedAction, asyncOperationSucceededAction } from '@planier/async-operation'
import { asyncOperationFailedAction } from './../../async-operation/State/AsyncOperationActions'
import { getFormViewIsInitializingAsyncOperationId } from '../Utils/AsyncOperationUtils'
import IFormViewFieldConfigurationV2 from '../Types/V2/IFormViewFieldConfigurationV2'

import { fetchValuePickerWithOptionsDataThunk } from '@planier/value-picker-with-options'
import { flatMap, get, set } from 'lodash-es'
import { chain } from 'lodash'

const getFormViewThunk =
    (formId: string, dataSourceIdOverride?: string): IThunkBaseAction =>
    async (dispatch, getState): Promise<void> => {
        const state = getState()

        const formViewFromStore = selectFormViewConfiguration(state, formId)
        if (formViewFromStore) {
            return
        }

        try {
            const formViewFromApi = await fetchFormView(formId)

            if (formViewFromApi) {
                dispatch(
                    setFormViewAction({
                        ...formViewFromApi,
                        DataSourceId: dataSourceIdOverride || formViewFromApi.DataSourceId,
                    })
                )
            }
        } catch (error) {
            dispatch(errorOnPromiseFailed(error))
        }
    }

const formatInitialValue = (value: unknown) => {
    if (Array.isArray(value)) {
        return new Set(value)
    }

    return value
}

const getIsMultipleDifferentValuesSelected = (items: any[], fieldConfig: IFormViewFieldConfigurationV2): boolean => {
    if (fieldConfig.DataSourcePropertyMapping.length > 1) {
        fieldConfig.DataSourcePropertyMapping.forEach((prop) => {
            const propertyName = prop.DataSourceProperty

            if (propertyName) {
                const distinctValues = chain(items)
                    .map((item) => get(item, propertyName))
                    .uniq()
                    .value()

                if (distinctValues.length > 1) {
                    return true
                }
            }
        })
    } else {
        const prop = fieldConfig.DataSourcePropertyMapping[0]

        const propertyName = prop.DataSourceProperty

        if (propertyName) {
            const distinctValues = chain(items)
                .map((item) => get(item, propertyName))
                .uniq()
                .value()

            if (distinctValues.length > 1) {
                return true
            }
        }
    }

    return false
}

const getValueFromInitialValues = (
    overridingValues: Record<string, unknown>,
    fieldConfig: IFormViewFieldConfigurationV2
): any => {
    if (!fieldConfig.DataSourcePropertyMapping) {
        return
    }

    if (fieldConfig.DataSourcePropertyMapping.length > 1) {
        const value = {}

        let didSetAnyValue = false

        fieldConfig.DataSourcePropertyMapping.forEach((prop) => {
            const propertyName = prop.DataSourceProperty

            if (propertyName) {
                const initialValue = getValueForField([], propertyName, fieldConfig, overridingValues)

                if (initialValue !== null && initialValue !== undefined) {
                    didSetAnyValue = true
                }

                set(value, prop.ComponentParameterName ?? '', initialValue)
            }
        })

        return didSetAnyValue ? value : null
    } else {
        const prop = fieldConfig.DataSourcePropertyMapping[0]

        const propertyName = prop.DataSourceProperty

        if (propertyName) {
            const initialValue = getValueForField([], propertyName, fieldConfig, overridingValues)

            return initialValue
        }

        return null
    }
}

const getInitialValue = (
    items: any[],
    overridingValues: Record<string, unknown>,
    fieldConfig: IFormViewFieldConfigurationV2
): any => {
    if (!fieldConfig.DataSourcePropertyMapping || fieldConfig.DataSourcePropertyMapping.length === 0) {
        return items?.length === 1 ? items[0] : items
    }

    if (fieldConfig.DataSourcePropertyMapping.length > 1) {
        const value = {}

        fieldConfig.DataSourcePropertyMapping.forEach((prop) => {
            const propertyName = prop.DataSourceProperty

            const initialValue = getValueForField(items, propertyName, fieldConfig, overridingValues)

            set(value, prop.ComponentParameterName ?? '', initialValue)
        })

        return value
    } else {
        const prop = fieldConfig.DataSourcePropertyMapping[0]

        const propertyName = prop.DataSourceProperty

        const initialValue = getValueForField(items, propertyName, fieldConfig, overridingValues)

        return initialValue
    }
}

const initializeFormViewValuePickersThunk =
    (
        formId: string,
        itemIds: TDataSourceItemId[],
        ignoreInitialValueFields: string[],
        initialFormValuesOverride: Record<string, unknown>
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const formViewConfg = selectFormViewConfiguration(state, formId)

        if (!formViewConfg) {
            return
        }

        const dataSourceId = formViewConfg.DataSourceId

        const items = selectDataSourceItemsByIds(getState(), dataSourceId, itemIds)

        const initialFieldConfig = flatMap(formViewConfg.Sections, 'Fields') as IFormViewFieldConfigurationV2[]

        const valuePickerWithOptionsIds: string[] = []

        initialFieldConfig.forEach((field) => {
            const valuePickerId = field.ValuePickerId

            const valuePickerConfig = selectValuePickerConfiguration(state, valuePickerId)

            //Temporary Hack to avoid rewriting of list valuepickers (yet)
            const overridingType =
                valuePickerConfig?.ValuePickerType === EValuePickerType.Dropdown
                    ? EValuePickerType.DropdownV2
                    : valuePickerConfig?.ValuePickerType

            if (overridingType === EValuePickerType.DropdownV2) {
                valuePickerWithOptionsIds.push(valuePickerId)
            }

            if (!valuePickerConfig || ignoreInitialValueFields.find((x) => x === field.Id)) {
                return
            }

            const config: TValuePickerConfiguration = { ...valuePickerConfig, ValuePickerType: overridingType }

            const initialValueFromConfig = field.InitialValueSelector?.ValuePickerId
                ? selectValuePickerValue(
                      state,
                      field.InitialValueSelector?.ValuePickerId,
                      field.InitialValueSelector?.ContextId
                  )
                : undefined

            const initialValueFromOverridingValue = getValueFromInitialValues(initialFormValuesOverride, field)

            const valuePickerInitialValue = getValuePickerInitialState(config)

            const initialValueFromItems =
                items === null || items.length === 0
                    ? undefined
                    : getInitialValue(items, initialFormValuesOverride, field)

            const isMultipleValuesSelectedOnNonMultiselectField =
                getIsMultipleDifferentValuesSelected(items, field) && !valuePickerConfig.multiselect

            const initialValue = initialValueFromConfig
                ? initialValueFromConfig
                : initialValueFromOverridingValue
                ? initialValueFromOverridingValue
                : isMultipleValuesSelectedOnNonMultiselectField
                ? null
                : initialValueFromItems
                ? initialValueFromItems
                : valuePickerInitialValue

            //Types are bleeding due to bad type definitions on valuepicker configs
            const sideEffects =
                valuePickerConfig.TrueValueSideEffects && initialValue
                    ? valuePickerConfig.TrueValueSideEffects
                    : valuePickerConfig.FalseValueSideEffects && !initialValue
                    ? valuePickerConfig.FalseValueSideEffects
                    : undefined

            // DynamicData values should not be formatted with same principal than other components..
            let formattedValue
            if (overridingType === EValuePickerType.DynamicData) {
                formattedValue = initialValue
            } else {
                formattedValue = formatInitialValue(initialValue)
            }

            const contextId = field.Id

            const note = isMultipleValuesSelectedOnNonMultiselectField ? 'Useita arvoja' : undefined

            dispatch(storedOptionsInitializeAction(valuePickerId))

            dispatch(setValuePickerValueAction(formattedValue, valuePickerId, contextId, sideEffects, note))
        })

        await Promise.all(
            valuePickerWithOptionsIds.map((valuePickerId) =>
                dispatch(fetchValuePickerWithOptionsDataThunk(valuePickerId, { useCachedValues: false }))
            )
        )

        dispatch(finishLoadingData(formId))
    }

export const initializeFormViewThunk =
    (
        formId: string,
        itemIds: (string | number)[],
        ignoreInitialValueFields: string[],
        initialFormValuesOverride: Record<string, unknown>,
        dataSourceIdOverride?: string
    ): IThunkBaseAction =>
    async (dispatch, getState): Promise<void> => {
        dispatch(asyncOperationStartedAction(getFormViewIsInitializingAsyncOperationId(formId)))

        try {
            await dispatch(getFormViewThunk(formId, dataSourceIdOverride))

            dispatch(resetLoadingData(formId))

            const state = getState()
            const dataSourceId = selectFormViewDataSourceId(state, formId)
            const valuePickerIds = selectFormViewValuePickerIds(state, formId)

            if (!dataSourceId) {
                throw Error(`DataSourceId has not been defined for form ${formId}`)
            }

            await Promise.all([
                dispatch(initializeDataSourceThunk(dataSourceId, { fetchData: false })),
                dispatch(fetchValuePickerConfigurationsThunk(valuePickerIds, undefined, true)),
            ])

            await dispatch(
                initializeFormViewValuePickersThunk(
                    formId,
                    itemIds,
                    ignoreInitialValueFields,
                    initialFormValuesOverride
                )
            )

            dispatch(asyncOperationSucceededAction(getFormViewIsInitializingAsyncOperationId(formId)))
        } catch (e) {
            dispatch(formViewInitializationFailed(formId))
            dispatch(errorOnPromiseFailed(e))
            dispatch(asyncOperationFailedAction(e, getFormViewIsInitializingAsyncOperationId(formId)))
        }
    }

export const executeFormViewSubmitActionThunk =
    (
        formId: string,
        itemIds: TDataSourceItemId[],
        changedValues: Record<string, unknown>,
        additionalRequestProps?: Record<string, unknown>,
        continueAfterSubmit = false,
        skipRefetchAll = false
    ): IThunkBaseAction =>
    async (dispatch, getState): Promise<void> => {
        try {
            const state = getState()

            const dataSourceId = selectFormViewDataSourceId(state, formId)
            if (!dataSourceId) {
                throw new Error(`No data source defined for form '${formId}'`)
            }
            const submitActionId = selectFormViewSubmitActionId(state, formId)
            if (!submitActionId) {
                throw new Error(`No submit action defined for form '${formId}'`)
            }

            if (itemIds.length === 1) {
                await dispatch(
                    executeSingleDataItemActionThunk(
                        dataSourceId,
                        itemIds[0],
                        submitActionId,
                        {
                            actionParameters: changedValues,
                            additionalRequestProps,
                        },
                        skipRefetchAll
                    )
                )
            } else {
                await dispatch(
                    executeDataItemActionThunk(dataSourceId, itemIds, submitActionId, {
                        actionParameters: changedValues,
                        additionalRequestProps,
                    })
                )
            }

            const nextFormViewId = selectFormViewSubsequentFormViewId(state, formId)

            if (nextFormViewId) {
                dispatch(openFormViewConfigurableModalThunk(nextFormViewId, dataSourceId, itemIds))
            } else if (!continueAfterSubmit) {
                dispatch(resetFormViewValuePickerValuesThunk(formId))
            }
        } catch (error) {
            dispatch(errorOnPromiseFailed(error))

            // Throw the error here, otherwise the form will close.
            throw error
        }
    }

export const openFormViewConfigurableModalThunk =
    (
        formId: string,
        dataSourceId: TDataSourceId | null,
        itemIds: TDataSourceItemId[],
        additionalRequestProps?: Record<string, unknown>,
        ignoreInitialValueFields?: string[],
        initialFormValuesOverride: Record<string, unknown> = {},
        onSubmitCallback?: () => void,
        overrideDataSourceId?: boolean
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        if (dataSourceId === null) {
            return
        }

        let items = selectDataSourceItemsByIds(getState(), dataSourceId, itemIds)
        const missingUpdateData = items.some(({ MetaData }) => MetaData?.HasUpdateData === false)

        // TODO: could probably fetch all the items not in the itemIds list
        if ((itemIds.length > 0 && items.length === 0) || missingUpdateData) {
            const newItems = await dispatch(
                fetchDataSourceDataThunk(dataSourceId, {
                    dynamicRequestParameters: {
                        parameters: {
                            Filters: { Ids: itemIds },
                            GetUpdateData: true,
                            Offset: 0,
                            ExtraRows: 'Include',
                        },
                        overrideEverything: true,
                    },
                    saveDataToStore: !missingUpdateData,
                })
            )

            if (missingUpdateData && newItems?.ListData) {
                await Promise.all(
                    newItems.ListData.map((item) => {
                        dispatch(replaceDataSourceItemThunk(dataSourceId, item, item.Id))
                    })
                )
            }

            items = selectDataSourceItemsByIds(getState(), dataSourceId, itemIds)
        }

        const formViewModalProps: IFormViewModalProps = {
            formId,
            items,
            additionalRequestProps,
            ignoreInitialValueFields,
            initialFormValuesOverride,
            onSubmitCallback,
            dataSourceId,
            overrideDataSourceId,
        }

        dispatch(openModalAction(formId, formViewModalProps))
    }

export const openFormViewCustomModalThunk =
    (formId: string, dataSourceId: string, itemIds: TDataSourceItemId[]): IThunkBaseAction =>
    async (dispatch, getState) => {
        try {
            const items = selectDataSourceItemsByIds(getState(), dataSourceId, itemIds)
            const formViewModalProps: IFormViewModalProps = {
                formId,
                items,
                formSubmitButtonAdditionalFunctionality: {},
                initialFormValuesOverride: {},
            }

            dispatch(openModalAction(formId, formViewModalProps))
        } catch (error) {
            dispatch(displayErrorToaster(error))
        }
    }

export const resetFormViewValuePickerValuesThunk =
    (formId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const formViewValuePickerIds = selectFormViewValuePickerIds(state, formId)

        const valuePickersWithValues = new Map<string, IValuePicker>(
            formViewValuePickerIds
                .map((valuePickerId) => {
                    const configuration = selectValuePickerConfiguration(state, valuePickerId)

                    const value = configuration && getValuePickerInitialState(configuration)

                    return configuration ? [valuePickerId, { value, configuration }] : undefined
                })
                .filter(
                    (
                        valuePicker
                    ): valuePicker is [
                        TValuePickerId,
                        {
                            value: ReturnType<typeof getValuePickerInitialState>
                            configuration: TValuePickerConfiguration
                        }
                    ] => typeof valuePicker !== 'undefined'
                )
        )

        dispatch(resetValuePickerValuesAction(valuePickersWithValues))
    }

export const resetFormViewValuePickerOptionsThunk =
    (formId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()
        const formViewValuePickerIds = selectFormViewValuePickerIds(state, formId)

        const valuePickersWithOptionsIds = formViewValuePickerIds.filter((valuePickerId) => {
            const configuration = selectValuePickerConfiguration(state, valuePickerId)
            return configuration ? isValuePickerWithOptions(configuration.ValuePickerType) : false
        })

        dispatch(storedOptionsResetOptionsForComponentsAction(valuePickersWithOptionsIds))
    }
