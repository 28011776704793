import { useEffect, useState } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { ConfirmationDialog, selectIsModalOpen } from '@planier/modal'
import { BoundThunk } from '@planier/generic-state'
import { TDataSourceItemId, IDataSourceGroupData } from '@planier/data-source-types'
import { fetchDataSourceDataThunk, addAggregatedDataItemToGroupData } from '@planier/data-source'
import { TARVENAKYMA_DATA_SOURCE_ID } from '../../Constants/WorkforceSchedulingConstants'
import PublishDialogContent from './PublishDialogContent'
import { closeModalAction } from '@planier/modal'
import { displayWarningToaster as displayWarningToasterAction } from '@planier/notifications'
import { Translation } from '@planier/localization'
import {
    selectPlanningPeriodAsQueryParameter,
    selectPlanningPeriodDatesAsQueryParameter,
} from '../../State/Selectors/WorkforceSchedulingSelectors'

interface IDispatchProps {
    fetchDataSourceData: BoundThunk<typeof fetchDataSourceDataThunk>
    closeModal: typeof closeModalAction
    displayWarningToaster: typeof displayWarningToasterAction
}

interface IStateProps {
    isOpen: boolean
    planningPeriodQueryParameter: ReturnType<typeof selectPlanningPeriodAsQueryParameter>
    planningPeriodDatesQueryParameter: unknown
}

interface IOwnProps {
    selectedEvents?: ReadonlySet<TDataSourceItemId>
    onSubmit: () => void
    modalId: string
    title?: string
    publishNotification?: string
    includePlanningPeriodInDataQuery?: boolean
}

interface IPublishConfirmationDialogBaseProps extends IStateProps, IDispatchProps, IOwnProps {}

type TFetchParametersToGetSummaryForEvents = Exclude<
    Parameters<typeof fetchDataSourceDataThunk>[1],
    undefined
>['dynamicRequestParameters']

const fetchParametersToGetSummaryForEvents: TFetchParametersToGetSummaryForEvents = {
    parameters: {
        GroupBy: [
            {
                GroupByProperties: [],
                OmitListData: false,
                CustomFunctions: [
                    `MinMaxDate(${Translation.translateKey(
                        'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.TimePeriod'
                    )},Alkamisaika,Loppumisaika)`,
                    `SingleOrCount(${Translation.translateKey(
                        'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.EventTypes'
                    )},Tapahtumatyyppi.Id,Tapahtumatyyppi.Name)`,
                    `SingleOrCount(${Translation.translateKey(
                        'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.WorkUnits'
                    )},Asiakas.Id,Asiakas.Name)`,
                    // Commented out at least for now. If any event doesn't have an employee, the backend throws error
                    // because the employee object is null.
                    /* `SingleOrCount(${Translation.translateKey(
                        'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.Employees
                    )},Tyontekija.Id,Tyontekija.Name)`, */
                ],
                WindowFunctions: [
                    {
                        Function: 'Count',
                        TargetProperty: 'Id',
                        Label: Translation.translateKey(
                            'workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.EventsToPublish'
                        ),
                    },
                ],
            },
        ],
        Filters: {
            Kayttooikeudet: ['PUBLISH'],
        },
    },
}

const getTotalEventsDataItem = (selectedEventsCount: number) => {
    return {
        Value: selectedEventsCount,
        Label: Translation.translateKey('workforce-scheduling.Publish.Confirmation.AggregatedData.Labels.TotalEvents'),
        Unit: Translation.translateKey('workforce-scheduling.Publish.Confirmation.AggregatedData.Units.pieces'),
    }
}

const indexToInsertTextAboutTotalEventsCount = 1

const PublishConfirmationDialogBaseUnconnected: React.FC<IPublishConfirmationDialogBaseProps> = ({
    isOpen,
    fetchDataSourceData,
    selectedEvents,
    onSubmit,
    modalId,
    title = 'workforce-scheduling.PublishEvents.ConfirmationDialog.title',
    publishNotification = 'workforce-scheduling.PublishEvents.ConfirmationDialog.publishNotification',
    closeModal,
    displayWarningToaster,
    planningPeriodQueryParameter,
    includePlanningPeriodInDataQuery = false,
    planningPeriodDatesQueryParameter,
}) => {
    const [groupData, setGroupData] = useState<IDataSourceGroupData[] | null>(null)

    useEffect(() => {
        if (!isOpen) {
            return
        }

        ;(async () => {
            // Doesn't matter for which calendar we fetch the data here, so we just use the demand calendar.
            const data = await fetchDataSourceData(TARVENAKYMA_DATA_SOURCE_ID, {
                saveDataToStore: false,
                datafetchURl: '/v2/Tapahtuma/Search',
                dynamicRequestParameters: {
                    overrideEverything: true,
                    parameters: {
                        ...fetchParametersToGetSummaryForEvents.parameters,
                        Filters: {
                            ...fetchParametersToGetSummaryForEvents.parameters.Filters,
                            ...(selectedEvents && { Ids: [...selectedEvents] }),
                            ...(includePlanningPeriodInDataQuery && planningPeriodQueryParameter),
                            ...(includePlanningPeriodInDataQuery && (planningPeriodDatesQueryParameter as any)),
                        },
                    },
                },
            })

            if (!data || !data.ListData || data.ListData.length === 0) {
                displayWarningToaster('workforce-scheduling.PublishPlanning.ConfirmationDialog.noPublishableEvents')
                closeModal(modalId)
                return
            }

            const { GroupData } = data

            if (!GroupData) {
                displayWarningToaster('Common.SomethingWrong')
                closeModal(modalId)
                return
            }

            const groupDataToUse = selectedEvents
                ? addAggregatedDataItemToGroupData(
                      GroupData,
                      getTotalEventsDataItem(selectedEvents.size),
                      indexToInsertTextAboutTotalEventsCount
                  )
                : GroupData

            setGroupData(groupDataToUse)
        })()
    }, [
        isOpen,
        fetchDataSourceData,
        selectedEvents,
        displayWarningToaster,
        closeModal,
        modalId,
        includePlanningPeriodInDataQuery,
        planningPeriodQueryParameter,
        planningPeriodDatesQueryParameter,
    ])

    if (!isOpen || !groupData) {
        return null
    }

    const handleSubmit = () => {
        closeModal(modalId)
        setGroupData(null)
        onSubmit()
    }

    const extraContent = <PublishDialogContent groupData={groupData} publishNotification={publishNotification} />

    return (
        <ConfirmationDialog
            confirmButtonText="workforce-scheduling.PublishEvents"
            extraContent={extraContent}
            modalId={modalId}
            modalOpen
            onSubmit={handleSubmit}
            title={title}
        />
    )
}

const mapStateToProps = (state: RootState, { modalId }: IOwnProps): IStateProps => ({
    isOpen: selectIsModalOpen(state, modalId),
    planningPeriodQueryParameter: selectPlanningPeriodAsQueryParameter(state),
    planningPeriodDatesQueryParameter: selectPlanningPeriodDatesAsQueryParameter(state),
})

const mapDispatchToProps = {
    fetchDataSourceData: fetchDataSourceDataThunk,
    closeModal: closeModalAction,
    displayWarningToaster: displayWarningToasterAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishConfirmationDialogBaseUnconnected)
