import Asetukset from 'constants/Asetukset'
import DateUtility from 'common/DateUtility'
import IAsetus from 'interfaces/IAsetus'
import ILoginKayttaja from 'interfaces/ILoginKayttaja'
import ISessio from 'interfaces/ISessio'
import ITyyli from 'interfaces/ITyyli'
import Kieli from 'constants/Kieli'
import { Translation } from '@planier/localization'
import { GLOBAL_SETTING_KEYS, setGlobalSettings } from '@planier/global-settings'
import { Userpilot } from 'userpilot'

export const getAsetukset = (palvelimeltaTulleetAsetukset: IAsetus[]): Map<string, string | number | boolean> => {
    const asetusMap = new Map<string, string | number | boolean>()

    palvelimeltaTulleetAsetukset.forEach((asetus) => {
        const { Nimi, Arvo } = asetus
        asetusMap.set(Nimi, Arvo)
    })

    return asetusMap
}

export const getLoggedInUser = (sessio: ISessio): ILoginKayttaja => {
    const publicInfo: ILoginKayttaja = sessio.publicInfo

    const kayttaja: ILoginKayttaja = {
        ...publicInfo,
        Kieli: (Kieli as any)[publicInfo.Kieli],
        Kayttooikeudet: sessio.credential,
    }

    return kayttaja
}

export const asetaLocale = (localeId: number): void => {
    const locale = (Kieli as any)[localeId]
    DateUtility.setLocale(locale)
    Translation.setLocale(locale)
}

export const getTyylit = (palvelimeltaTulleetTyylit: ITyyli[]): Map<string, string> => {
    const tyyliMap = new Map<string, string>()

    palvelimeltaTulleetTyylit.forEach((tyyli) => {
        const { Polku, Arvo } = tyyli
        tyyliMap.set(Polku, Arvo)
    })

    return tyyliMap
}

export const asetaAsiakaskohtainenSanasto = (asetukset: Map<string, string | number | boolean>): void => {
    if (!asetukset) {
        return
    }

    const asiakasAsetusArvo: string | number | boolean | undefined = asetukset.get(Asetukset.Asiakas)

    if (asiakasAsetusArvo) {
        Translation.loadCustomerSpecificTranslations(asiakasAsetusArvo.toString())
    }
}

export const setAnyGlobalSettings = (settingsFromServer: IAsetus[]): void => {
    const globalSettingsMap = settingsFromServer
        .filter((setting) => GLOBAL_SETTING_KEYS.has(setting.Nimi))
        .reduce((settingsMap, { Nimi, Arvo }) => {
            settingsMap.set(Nimi, Arvo)
            return settingsMap
        }, new Map<string, string | boolean | number>())

    setGlobalSettings(globalSettingsMap)
}

const createHashFromUser = (userId: string): string => {
    let hash = 0

    for (let i = 0; i < userId.length; i++) {
        hash = userId.charCodeAt(i) + ((hash << 5) - hash)
    }

    return hash.toString()
}

export const initializeUserpilot = (user: ILoginKayttaja): void => {
    Userpilot.initialize('NX-45eab2dc')

    const { Id, Tyyppi, Kieli: userLanguage, Osio } = user

    const userHash = createHashFromUser(Id.toString())

    Userpilot.identify(userHash, { userType: Tyyppi, language: userLanguage, section: Osio })
}
