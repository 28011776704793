import * as React from 'react'
import { useRef } from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import { BoundThunk } from '@planier/generic-state'
import { TDataSourceItemId } from '@planier/data-source-types'
import { selectIsCalendarNodeSelected } from '../../State/ConfigurableCalendarSelectors'
import { selectCalendarNodeThunk } from '../../Thunks/ConfigurableCalendarThunks'
import SelectedNodeFrame from './SelectedNodeFrame'
import NodeInfo, { nodeIdPrefix } from './NodeInfo'
import { ContextMenu } from '@planier/generic-components'
import useMenuItemsForNode from './Hooks/useMenuItemsForNode'
import useDoubleClickActionForNode from './Hooks/useDoubleClickActionForNode'
import useDoubleClick from 'use-double-click'
import { Moment } from 'moment'

type TMenuData = Exclude<React.ComponentProps<typeof ContextMenu>['menuItemsData'], undefined>

interface IStateProps {
    selected: boolean
}

interface IDispatchProps {
    selectNode: BoundThunk<typeof selectCalendarNodeThunk>
}

interface IOwnProps {
    calendarId: string
    children: React.ReactNode
    indent: number
    infoText: string | null
    length: number
    nodeId: TDataSourceItemId
    isDisabled: boolean
    date: Moment
    contextMenuStaticItems: TMenuData
    getContextMenuDynamicItems: () => TMenuData
}

interface ICalendarNodeContainerProps extends IOwnProps, IStateProps, IDispatchProps {}

// Have the 'left' and 'width' as react's inline styles. At least Styled Components otherwise warned
// about it if there are a lot (200+) of nodes present, because it has to create a class of
// its own for each node (in order to be able to customize the styles for each node). Now that
// we use emotion we'll keep it like this to be sure.
const getLeftAndWidthStyle = (indent: number, length: number, showLeftBorder: boolean) => ({
    left: `calc(${indent}%)`,
    width: `calc(${length}%)`,
    borderLeft: showLeftBorder ? '2px solid #CBD1DC' : '',
})

const NodeContainer = styled.div<{ nodeIdWithPrefix: string; isDisabled: boolean }>`
    height: 100%;
    display: flex;
    cursor: pointer;
    border-radius: 0;
    border-bottom: ${({ theme }) => `thin solid ${theme.colors.neutralsGrey30}`};
    ${({ isDisabled }) =>
        isDisabled &&
        `
        opacity: 50%;
        cursor: initial;
        *:last-child {
            border: unset;
        }
    `}

    &:hover {
        #${({ nodeIdWithPrefix }) => nodeIdWithPrefix} {
            visibility: visible;
        }
    }
`

const StyledContextMenu = styled(ContextMenu)`
    display: flex;
`

const BorderContainer = styled.div`
    border-right-color: ${({ theme }) => theme.colors.neutralsGrey30};
    border-right-style: solid;
    border-right-width: thin;
    position: absolute;
    height: 100%;
`

const CalendarNodeContainer: React.FunctionComponent<ICalendarNodeContainerProps> = ({
    calendarId,
    children,
    indent,
    length,
    nodeId,
    selected,
    selectNode,
    infoText,
    isDisabled,
    date,
    contextMenuStaticItems,
    getContextMenuDynamicItems,
}) => {
    const { menuItems, addDynamicMenuItems } = useMenuItemsForNode(contextMenuStaticItems, getContextMenuDynamicItems)

    const handleNodeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const multiselect = event.ctrlKey || event.metaKey // ctrlKey doesn't work in Mac

        return selectNode(calendarId, nodeId, multiselect)
    }

    const handleNodeRightClick = () => {
        const multiselect = false
        const deSelectOnClick = false

        selectNode(calendarId, nodeId, multiselect, deSelectOnClick)

        addDynamicMenuItems()
    }

    const nodeIdWithPrefix = nodeIdPrefix + nodeId
    const nodeRef = useRef()

    const onDoubleClick = useDoubleClickActionForNode(calendarId, nodeId as string)

    useDoubleClick({
        onSingleClick: isDisabled ? undefined : handleNodeClick,
        onDoubleClick: () => onDoubleClick(),
        ref: nodeRef,
        latency: 250,
    })

    return (
        <StyledContextMenu isDisabled={isDisabled} menuItemsData={menuItems} onOpen={handleNodeRightClick}>
            <BorderContainer style={getLeftAndWidthStyle(indent, length, date.isoWeekday() === 1)}>
                <NodeContainer isDisabled={isDisabled} nodeIdWithPrefix={nodeIdWithPrefix} ref={nodeRef}>
                    <SelectedNodeFrame selected={selected} />
                    <NodeInfo infoText={infoText} nodeIdWithPrefix={nodeIdWithPrefix} nodeSelected={selected} />
                    {children}
                </NodeContainer>
            </BorderContainer>
        </StyledContextMenu>
    )
}

const mapStateToProps = (state: RootState, { calendarId, nodeId }: IOwnProps): IStateProps => ({
    selected: selectIsCalendarNodeSelected(state, calendarId, nodeId),
})

const mapDispatchToProps = {
    selectNode: selectCalendarNodeThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarNodeContainer)
