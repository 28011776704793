import { ITimerangeValue, ITimeRangeValidationError, isTimeRangeValue } from '@planier/generic-components'
import { Translation } from '@planier/localization'
const requiredFieldStr = Translation.has('Pakollinen valinta')
    ? Translation.translateKey('Pakollinen valinta')
    : 'Pakollinen valinta'

export const isRepetitiveEvent = (value: unknown): boolean => {
    if ((value as any).EveryNth) {
        return true
    }

    return false
}

export const validateIfValuePickerValueIsSet = (value: unknown): unknown => {
    if (value === undefined || value === null) {
        return requiredFieldStr
    }

    if (isRepetitiveEvent(value)) {
        if (!(value as any).StartDate) {
            return requiredFieldStr
        } else {
            return undefined
        }
    }

    if (isTimeRangeValue(value)) {
        const timeRangeValue = value as ITimerangeValue

        const timeRangeError: ITimeRangeValidationError = {
            startTimeError: !timeRangeValue.startTime ? requiredFieldStr : undefined,
            endTimeError: !timeRangeValue.endTime ? requiredFieldStr : undefined,
        }

        if (!timeRangeError.startTimeError && !timeRangeError.endTimeError) {
            return undefined
        }

        return timeRangeError
    }

    if (value instanceof Set) {
        return value.size === 0 ? requiredFieldStr : undefined
    }

    if (Array.isArray(value)) {
        return value.length === 0 ? requiredFieldStr : undefined
    }

    if (value === false) {
        return undefined
    }

    if (value === 0) {
        return undefined
    }

    return value ? undefined : requiredFieldStr
}
