import * as React from 'react'
import { useCallback } from 'react'
import styled from '@emotion/styled'

import CalendarSegment from './CalendarSegment'
import CalendarHeaders from './CalendarHeaders'
import IMainAxisHeader from '../../Types/IMainAxisHeader'
import CalendarContent from './CalendarContent'
import ICalendarMainData from '../../Types/ICalendarMainData'
import CalendarFilters from '../CalendarFilters'
import GroupNodeContainer from './GroupNodeContainer'
import CalendarNodeContainer from './CalendarNodeContainer'
import { GROUP_NODE_WIDTH } from '../../Constants/CalendarConstants'
import GroupNodesContainer from './CalendarContent/GroupNodesContainer'
import { useScrollbarWidth, useSyncedScrolling } from '@planier/generic-utilities'
import ICalendarQuickActions from '../../Types/ICalendarQuickActions'
import { getAllCalendarScrollGroups } from '../../Constants/CalendarScrollGroups'
import useContextMenuItemsForNodes from './Hooks/useContextMenuItemsForNodes'

export interface ICalendarBaseProps {
    calendarId: string
    columnAmount: number
    headers: IMainAxisHeader[]
    hiddenValuePickerIds?: string[]
    mainAxisData: ICalendarMainData[]
    quickActions: ICalendarQuickActions | null
    horizontalScrollbarHeight?: number
    setRefForElementForHorizontalScrollbar?: (element: HTMLDivElement) => void
    areCalendarFiltersToBeDisplayed: boolean
}

const OuterContainer = styled.div<{ columnAmount: number; scrollBarWidth: number }>`
    max-width: calc(100% - ${GROUP_NODE_WIDTH.value}px - ${({ scrollBarWidth }) => scrollBarWidth}px);
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
`

const OuterScrollContainer = styled.div<{ scrollBarWidth: number }>`
    overflow-y: auto;
    height: 100%;
    width: calc(100% + ${({ scrollBarWidth }) => scrollBarWidth}px);
`

const InnerScrollContainer = styled.div`
    overflow: scroll;
    width: 100%;
    height: 100%;
`

const GroupContainerCrossAxis = styled.div<{ horizontalScrollbarHeight: number }>`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding-bottom: ${({ horizontalScrollbarHeight: verticalScrollbarHeight }) => verticalScrollbarHeight}px;
    position: relative;
`

export const CalendarBase: React.FunctionComponent<ICalendarBaseProps> = ({
    calendarId,
    columnAmount,
    headers,
    hiddenValuePickerIds,
    mainAxisData,
    quickActions,
    horizontalScrollbarHeight: horizontalScrollbarHeightProp,
    setRefForElementForHorizontalScrollbar: setRefForElementForHorizontalScrollbarProp,
    areCalendarFiltersToBeDisplayed,
}) => {
    const { contextMenuStaticItems, getContextMenuDynamicItems } = useContextMenuItemsForNodes(calendarId)
    const scrollContainerRef = useSyncedScrolling(getAllCalendarScrollGroups(calendarId))

    const { scrollbarWidth: verticalScrollbarWidth, setRefForElement: setRefForElementForVerticalScrollbar } =
        useScrollbarWidth()

    const horizontalScrollbarWidthObject = useScrollbarWidth('horizontal')
    const horizontalScrollbarHeight = horizontalScrollbarHeightProp ?? horizontalScrollbarWidthObject.scrollbarWidth
    const setRefForElementForHorizontalScrollbar =
        setRefForElementForHorizontalScrollbarProp ?? horizontalScrollbarWidthObject.setRefForElement

    const onRefSetForScrollContainer = useCallback(
        (element: HTMLDivElement) => {
            if (!element) {
                return
            }

            setRefForElementForVerticalScrollbar(element)
            scrollContainerRef(element)
            setRefForElementForHorizontalScrollbar(element)
        },
        [setRefForElementForVerticalScrollbar, scrollContainerRef, setRefForElementForHorizontalScrollbar]
    )

    if (headers.length === 0) {
        return null
    }

    const groupNodes: React.ReactNodeArray = mainAxisData.map(({ groupData }) => {
        const GroupNodeComponent = groupData.component
        const { backgroundShading, groupedByData } = groupData.props

        return (
            <GroupNodeContainer
                backgroundShading={backgroundShading}
                groupColor={groupedByData?.groupColor ?? null}
                key={groupData.id}
            >
                <GroupNodeComponent {...groupData.props} />
            </GroupNodeContainer>
        )
    })

    const mainContent = mainAxisData.map(({ nodes, groupData }) => {
        const rowNodes = nodes.map(({ date, component, id, indent, length, colorId, infoText, props, isDisabled }) => {
            const NodeComponent = component

            return (
                <CalendarNodeContainer
                    calendarId={calendarId}
                    contextMenuStaticItems={contextMenuStaticItems}
                    getContextMenuDynamicItems={getContextMenuDynamicItems}
                    indent={indent}
                    infoText={infoText}
                    isDisabled={isDisabled}
                    key={id}
                    date={date}
                    length={length}
                    nodeId={id}
                >
                    <NodeComponent colorId={colorId} {...props} />
                </CalendarNodeContainer>
            )
        })

        return (
            <CalendarSegment backgroundShading={groupData.props.backgroundShading} key={groupData.id}>
                {rowNodes}
            </CalendarSegment>
        )
    })

    return (
        <React.Fragment>
            <GroupContainerCrossAxis horizontalScrollbarHeight={horizontalScrollbarHeight}>
                <CalendarFilters
                    areCalendarFiltersToBeDisplayed={areCalendarFiltersToBeDisplayed}
                    calendarId={calendarId}
                    hiddenValuePickerIds={hiddenValuePickerIds}
                    quickActions={quickActions}
                />

                <GroupNodesContainer calendarId={calendarId}>{groupNodes}</GroupNodesContainer>
            </GroupContainerCrossAxis>
            <OuterContainer columnAmount={columnAmount} scrollBarWidth={verticalScrollbarWidth}>
                <CalendarHeaders headers={headers} />
                <OuterScrollContainer scrollBarWidth={verticalScrollbarWidth}>
                    <InnerScrollContainer ref={onRefSetForScrollContainer}>
                        <CalendarContent columnAmount={columnAmount} mainAxisContent={mainContent} />
                    </InnerScrollContainer>
                </OuterScrollContainer>
            </OuterContainer>
        </React.Fragment>
    )
}

export default CalendarBase
