import { CancelToken } from 'axios'

import makeRequest, { ERequestMethod } from '@planier/rest-api'
import { IListViewDataContainer } from '@planier/data-source-types'
import IStoredOptionsOptionIdentifierObject from '../Types/IStoredOptionsOptionIdentifierObject'
import IStoredOptionsRequestParameters from '../Types/IStoredOptionsRequestParameters'

export const queryOptions = async <TOption extends IStoredOptionsOptionIdentifierObject>(
    urlPath: string,
    params: IStoredOptionsRequestParameters,
    cancelToken: undefined | CancelToken
): Promise<IListViewDataContainer<TOption>> =>
    makeRequest<IListViewDataContainer<TOption>>({
        method: ERequestMethod.POST,
        url: urlPath,
        data: params,
        cancelToken,
    })

export default queryOptions
