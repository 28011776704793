import { ProcessEventToTransactionForm } from '@planier/payroll-management'
import { MessagingForm, CommentingForm } from '@planier/messaging'
import { EmployeeCalendarForm } from '@planier/employee-calendar'
import {
    WorkforceSchedulingDeletionConfirmationDialog,
    PublishEventsConfirmationDialog,
    PublishEvents,
    DeleteEvents,
} from '@planier/workforce-scheduling'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getFormFieldComponents = () => ({})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCustomModalComponents = () => ({
    ProcessEventToTransactionForm,
    WorkforceSchedulingDeletionConfirmationDialog,
    PublishEventsConfirmationDialog,
    PublishEvents,
    DeleteEvents,
    MessagingForm,
    CommentingForm,
    EmployeeCalendarForm,
})
