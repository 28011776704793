import { ColorPicker } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from '@planier/value-picker'

interface IColorValuePickerProps extends IValuePickerCommonOwnProps<string> { }

const ColorValuePicker: React.FC<IColorValuePickerProps> = ({ label, value, onChange }) => {
    return (
        <ColorPicker value={value} label={label ?? ''} onChange={onChange} />
    )
}

export default ColorValuePicker
