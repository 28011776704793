import * as React from 'react'
import { FieldProps } from 'formik'

import DayRangePicker from './DayRangePicker'
import ErrorableField from '../../Form/ErrorableField'
import { getErrorsForFormField } from '@planier/generic-utilities'

interface IIFormikDayPickerOwnProps extends FieldProps {}

type TDayRangePickerProps = React.ComponentProps<typeof DayRangePicker>

interface IFormikDayPickerProps
    extends IIFormikDayPickerOwnProps,
        Pick<TDayRangePickerProps, 'startLabel' | 'endLabel' | 'required' | 'shouldDateBeDisabled'> {}

const FormikDayRangePicker: React.FC<IFormikDayPickerProps> = ({
    form,
    field,
    startLabel,
    endLabel,
    required,
    shouldDateBeDisabled,
}) => {
    const handleChange: TDayRangePickerProps['onChange'] = (startAndEndDays) => {
        form.setFieldValue(field.name, startAndEndDays)
    }

    const handleBlur = () => {
        const isTouched = true
        form.setFieldTouched(field.name, isTouched, form.validateOnBlur)
    }

    const { isFieldErrored, error } = getErrorsForFormField<string>(
        field.name,
        form.errors as Record<string, string>,
        form.touched
    )

    return (
        <ErrorableField field={field} form={form}>
            <DayRangePicker
                endDay={field.value?.end ?? null}
                endLabel={endLabel}
                isFieldErrored={isFieldErrored}
                onBlur={handleBlur}
                onChange={handleChange}
                required={required}
                shouldDateBeDisabled={shouldDateBeDisabled}
                startDay={field.value?.start ?? null}
                startLabel={startLabel}
                validationError={error}
            />
        </ErrorableField>
    )
}

export default FormikDayRangePicker
