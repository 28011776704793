import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'
import { Translation } from '@planier/localization'
import Typography from '@material-ui/core/Typography'

interface ITextIconProps {
    children: string
    doNotTranslate?: boolean
}

const TextStyledAsIcon = styled(Typography)`
    background-color: ${Styles.supplementaryColor.blueGrey};
    border-radius: 4px;
    color: ${Styles.mainColor.white};
    padding: 4px;
    text-align: center;
    text-transform: uppercase;
`

const TextIcon: React.FC<ITextIconProps> = ({ children, doNotTranslate = false }) => {
    const text = doNotTranslate ? children : Translation.translateKey(children)
    return <TextStyledAsIcon>{text}</TextStyledAsIcon>
}

export default TextIcon
