import * as React from 'react'
import styled from '@emotion/styled'

import { useSyncedScrolling } from '@planier/generic-utilities'
import { getCalendarVerticalContentScrollGroups } from '../../../Constants/CalendarScrollGroups'

interface IGroupNodesContainerProps {
    children: React.ReactNodeArray
    calendarId: string
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-right: ${({ theme }) => `thin solid ${theme.legacyCalendarTheme.innerBorder}`};
    height: calc(100% - 49px);
`

/**
 * The user can't scroll the group nodes separately, but it scrolls automatically
 * with the content when scrolling it horizontally.
 */
const ScrollContainer = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    display: inherit;
    height: inherit;
    overflow-y: hidden;
    background-color: white;
    box-sizing: initial;
    width: 100%;
    flex-direction: column;
    height: 100%;
`

const GroupNodesContainer: React.FunctionComponent<IGroupNodesContainerProps> = ({ calendarId, children }) => {
    const scrollContainerRef = useSyncedScrolling(getCalendarVerticalContentScrollGroups(calendarId))

    return (
        <Container>
            <ScrollContainer ref={scrollContainerRef}>{children}</ScrollContainer>
        </Container>
    )
}

export default GroupNodesContainer
