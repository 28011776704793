import * as Yup from 'yup'
import { Schema } from 'yup'
import moment, { Moment, MomentInput } from 'moment'

import { IAsetusArvo, IOsionAsetus } from './reducers/OsiokohtaisetAsetuksetReducer'
import { Translation } from '@planier/localization'
import { find } from 'lodash-es'

type TFormatoija = (arvo: any, asetus: IOsionAsetus) => string

type TVertailija = (vanha: any, uusi: any, asetus: IOsionAsetus) => boolean

type TParser = (arvo: any) => any

interface ITietotyyppiMaarittely {
    /**
     * Missä propertyssä arvo on (muut ovat `null`).
     */
    arvoProperty: keyof IAsetusArvo
    /**
     * Tietotyypin yup-validaattori
     */
    validaattori: Schema<any>
    /**
     * Funktio, joka formatoi arvon stringiksi käyttäjälle näyttämistä varten.
     */
    formatoija: TFormatoija
    /**
     * Funktio, joka vertaa vanhaa ja uutta arvoa. Palauttaa `true`, jos arvot ovat muuttuneet.
     */
    vertailija: TVertailija
    /**
     * Funktio, joka parsii arvon Formikille menevään muotoon (esim. string timestampit Moment-objekteiksi).
     */
    parser: TParser
}

// eslint-disable-next-line no-shadow
export enum AsetusTietotyyppi {
    int = 'int',
    double = 'double',
    bool = 'bool',
    Date = 'Date',
    string = 'string',
    ListInt = 'List<int>',
}

type TTietotyyppiMaaritykset = { [key in AsetusTietotyyppi]: Partial<ITietotyyppiMaarittely> }

export const tietotyyppiMaaritykset: TTietotyyppiMaaritykset = {
    [AsetusTietotyyppi.int]: {
        arvoProperty: 'ArvoInt',
        validaattori: Yup.number().nullable(true).integer('input-kokonaisluku-virheellinen-arvo'),
    },
    [AsetusTietotyyppi.double]: {
        arvoProperty: 'ArvoFloat',
        validaattori: Yup.number().nullable(true),
    },
    [AsetusTietotyyppi.bool]: {
        arvoProperty: 'ArvoBool',
        validaattori: Yup.boolean().nullable(true),
        formatoija: (arvo: boolean): string => Translation.translateKey(arvo ? 'toggle-paalla' : 'toggle-pois-paalta'),
    },
    [AsetusTietotyyppi.Date]: {
        arvoProperty: 'ArvoDateTime',
        validaattori: Yup.date().nullable(true).typeError('input-paivamaara-virheellinen-arvo'),
        formatoija: (arvo: Moment): string => arvo.format('D.M.YYYY'),
        vertailija: (vanha: Moment, uusi: Moment): boolean => !vanha.isSame(uusi, 'day'),
        parser: (arvo: MomentInput): Moment => moment(arvo),
    },
    [AsetusTietotyyppi.string]: {
        arvoProperty: 'ArvoString',
        validaattori: Yup.string().nullable(true),
    },
    [AsetusTietotyyppi.ListInt]: {
        validaattori: Yup.string()
            .nullable(true)
            .matches(/^(?:\d+(?:,\d+)*)?$/, 'input-kokonaisluku-lista-virheellinen-arvo'),
        arvoProperty: 'ArvoString',
    },
}

export const OLETUS_TIETOTYYPPI_MAARITYKSET: ITietotyyppiMaarittely = {
    arvoProperty: 'ArvoInt',
    validaattori: Yup.number().nullable(true),
    formatoija: (arvo) => arvo,
    vertailija: (vanha, uusi) => vanha !== uusi,
    parser: (arvo) => arvo,
}

/**
 * Formatoija enum-tietotyyppiä oleville asetuksille.
 * Palauttaa arvoa vastaavan kuvauksen/selitteen.
 *
 * @param arvo
 * @param asetus
 */
export function enumFormatoija(arvo: number, asetus: IOsionAsetus): string {
    const mahdollinenAsetus = find(asetus.MahdollisetArvot, { Arvo: arvo })

    if (!mahdollinenAsetus) {
        return String(arvo)
    }

    return mahdollinenAsetus.Selite
}

/**
 * Haetaan määritykset tietotyypille `tietotyyppiMaaritykset` objektista.
 *
 * @param tietotyyppi Tietotyypin nimi
 */
export function haeTietotyyppiMaarittely(tietotyyppi: string): ITietotyyppiMaarittely {
    const tietotyyppiMaarittelyPartial: Partial<ITietotyyppiMaarittely> =
        (tietotyyppiMaaritykset as any)[tietotyyppi] || {}

    const tietotyyppiMaarittely: ITietotyyppiMaarittely = {
        ...OLETUS_TIETOTYYPPI_MAARITYKSET,
        ...tietotyyppiMaarittelyPartial,
    }

    // Jos tietotyyppi ei ole mikään `tietotyyppiMaaritykset` objektissa määritellyistä,
    // oletaan sen olevan enum-tietotyyppiä (eli arvo on `ArvoInt` propertyssä).
    const onEnumTyyppia = typeof (tietotyyppiMaaritykset as any)[tietotyyppi] === 'undefined'

    if (onEnumTyyppia) {
        tietotyyppiMaarittely.formatoija = enumFormatoija
    }

    return tietotyyppiMaarittely
}
