import styled from '@emotion/styled'
import { JSX } from 'react'
import EventDot from './EventDot'
import { IIdentifier } from '@planier/event/Types/IEvent'
import { ReadOnlyText } from '@planier/generic-components'
import { dotProps } from './DayLogic'
import { useAppTheme } from '@planier/theme'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 0;
    gap: 8px;
`

const EventTypeCategoryLegend = ({ eventTypeCategory }: { eventTypeCategory: IIdentifier }): JSX.Element => {
    const theme = useAppTheme()

    return (
        <Container data-testid="event-type-category-legend">
            <EventDot size={12} {...dotProps(eventTypeCategory.Id as number, theme)} />
            <ReadOnlyText usage="bodyS">{eventTypeCategory.Name}</ReadOnlyText>
        </Container>
    )
}

export default EventTypeCategoryLegend
