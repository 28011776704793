import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'typesafe-actions'

import {
    selectDataSourceId,
    selectFilterItselfDataRequestParametersName,
    selectSelectedItemIds,
} from '../State/ConfigurableListSelectors'
import {
    registerDynamicValuePickerThunk,
    setValuePickerValueAction,
    removeValuePickerAction,
    selectIsValuePickerInitialized,
} from '@planier/value-picker'
import { dataSourceSetValuePickerIdsAction, selectIsDataSourceInitialized } from '@planier/data-source'
import { TDataSourceItemId } from '@planier/data-source-types'
import { LIST_FILTER_TO_ITSELF_PREFIX } from '../Constants/ListConstants'

const defaultEmptySelected: Set<TDataSourceItemId> = new Set()

const useFilterItselfWhenSetToDoSo = (listId: string): void => {
    const filterItselfDataRequestParameterName = useSelector((state: RootState) =>
        selectFilterItselfDataRequestParametersName(state, listId)
    )

    const valuePickerId = `${LIST_FILTER_TO_ITSELF_PREFIX}${listId}`

    const filterToItselfValuePickerInitialized = useSelector((state: RootState) =>
        selectIsValuePickerInitialized(state, valuePickerId)
    )
    const dataSourceId = useSelector((state: RootState) => selectDataSourceId(state, listId))
    const dataSourceInitialized = useSelector((state: RootState) => selectIsDataSourceInitialized(state, dataSourceId))

    const dispatch = useDispatch()

    const selectedItems = useSelector((state: RootState) =>
        filterItselfDataRequestParameterName ? selectSelectedItemIds(state, listId) : defaultEmptySelected
    )

    useEffect(() => {
        if (
            (!filterItselfDataRequestParameterName || !dataSourceId || !dataSourceInitialized) &&
            !filterToItselfValuePickerInitialized
        ) {
            return
        }

        dispatch(
            registerDynamicValuePickerThunk(
                {
                    ValuePickerId: valuePickerId,
                    DataRequestParameterName: filterItselfDataRequestParameterName as string,
                },
                new Set()
            )
        )
        dispatch(dataSourceSetValuePickerIdsAction(dataSourceId as string, [valuePickerId]))

        return () => {
            // To be sure, we completely remove the value picker when unmounting
            // We'll leave the value picker registered to data source though - that shouldn't matter anywhere.
            dispatch(removeValuePickerAction(valuePickerId))
        }
    }, [
        filterItselfDataRequestParameterName,
        dispatch,
        dataSourceId,
        valuePickerId,
        dataSourceInitialized,
        filterToItselfValuePickerInitialized,
    ])

    // Listen to the selected items change
    useEffect(() => {
        if (filterToItselfValuePickerInitialized && dataSourceInitialized) {
            dispatch(setValuePickerValueAction(selectedItems, valuePickerId))
        }
    }, [valuePickerId, selectedItems, dispatch, filterToItselfValuePickerInitialized, dataSourceInitialized])
}

export default useFilterItselfWhenSetToDoSo
