import IDataPropertyParameter from '../Types/IDataPropertyParameter'
import { IDataSourceItem } from '@planier/data-source-types'
import { get } from 'lodash-es'

const createDataItemActionRequestBody = (
    dataItems: IDataSourceItem[],
    dataPropertyParameters: IDataPropertyParameter[] | null
): Record<string, unknown> => {
    const requestBody: any = {}

    if (dataPropertyParameters === null) {
        return requestBody
    }

    dataPropertyParameters.forEach(({ Key, PropertyPath }) => {
        dataItems.forEach((dataItem) => {
            const propertyValue = get(dataItem, PropertyPath)

            if (Array.isArray(requestBody[Key])) {
                requestBody[Key].push(propertyValue)
            } else {
                requestBody[Key] = [propertyValue]
            }
        })
    })

    return requestBody
}

export default createDataItemActionRequestBody
