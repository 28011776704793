import * as React from 'react'
import styled from '@emotion/styled'

import { Icon, ReadOnlyText } from '@planier/generic-components'
import NodeWarning from './NodeWarning'
import Styles from 'constants/Styles'

const NodeContainer = styled.div<{
    backgroundColor: string
    hoverColor: string
    mainColor: string
    borderColor: string | null
}>`
    width: 100%;
    height: 100%;
    border: 1px solid ${({ borderColor, mainColor }) => borderColor ?? mainColor};
    background-color: ${({ backgroundColor }) => backgroundColor};
    :hover,
    :focus {
        outline: none;
        background-color: ${({ hoverColor }) => hoverColor};
    }
`

const FlexContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
`
const ContentRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 10px);
    justify-content: space-between;
`

const CoveredBlock = styled.div<{ mainColor: string; isDisplayed: boolean }>`
    width: 8px;
    margin-right: 2px;
    background-color: ${({ mainColor, isDisplayed }) => (isDisplayed ? mainColor : 'transparent')};
`

const ContentContainer = styled.div`
    width: calc(100% - 5px);
`

const TextContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 2px;
    min-width: 0;
    padding-bottom: 1px;

    span {
        line-height: 13px;
    }
`

interface IBasicCalendarNodeProps {
    backgroundColor: string
    hoverColor: string
    mainColor: string
    borderColor?: string
    isCoveredOnLeft: boolean
    upperText: string
    warnings: string[] | null
    extraTextNode?: React.ReactNode
    icon?: string
    isBottomRightSignifierDisplayed?: boolean
}

const IconContainer = styled.div`
    position: absolute;
    top: 25%;
    right: 25px;
`

const BottomRightSignifier = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-bottom: 11px solid ${Styles.planierColor.blueTurquoise.turquoisePrimary};
    border-left: 11px solid transparent;
`

const BasicCalendarNode: React.FC<IBasicCalendarNodeProps> = ({
    backgroundColor,
    hoverColor,
    mainColor,
    isCoveredOnLeft,
    upperText,
    warnings,
    extraTextNode,
    icon,
    borderColor = null,
    isBottomRightSignifierDisplayed = false,
}) => {
    return (
        <NodeContainer
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            hoverColor={hoverColor}
            mainColor={mainColor}
            tabIndex={0}
        >
            <FlexContainer>
                <CoveredBlock isDisplayed={isCoveredOnLeft} mainColor={mainColor} />
                <ContentRow>
                    <ContentContainer>
                        <TextContentContainer>
                            <ReadOnlyText usage={'event_block'} textOverflow={'ellipsis'} rawColor={mainColor}>
                                {upperText ? upperText.toUpperCase() : ''}
                            </ReadOnlyText>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                {extraTextNode}
                                <NodeWarning warnings={warnings} />
                            </div>
                        </TextContentContainer>
                    </ContentContainer>
                    <div style={{ width: 3, height: '100%', backgroundColor: 'transparent' }}>
                        {icon && (
                            <IconContainer>
                                <Icon colorTheme="primary" size={14}>
                                    {icon}
                                </Icon>
                            </IconContainer>
                        )}
                        {isBottomRightSignifierDisplayed && <BottomRightSignifier />}
                    </div>
                </ContentRow>
            </FlexContainer>
        </NodeContainer>
    )
}

export default BasicCalendarNode
