import IStoredOptionsRequestParameters from '../Types/IStoredOptionsRequestParameters'
import { getValueAsArray } from '@planier/generic-utilities'
import IStoredOptionsComponentDependency from '../Types/IStoredOptionsComponentDependency'
import IGetStoredComponentValue from '../Types/IGetStoredComponentValue'
import { set } from 'lodash-es'

const compileDependencyParams = (
    getStoredComponentValue: IGetStoredComponentValue,
    dependsOn: IStoredOptionsComponentDependency[]
): Record<string, unknown> => {
    const dependencyParams = {}

    dependsOn.forEach(({ additionalParams, componentId, parameterName, filterType }) => {
        const selectedDependentValues = getStoredComponentValue(componentId)

        if (!selectedDependentValues) {
            return
        }

        const valuesArray = getValueAsArray(selectedDependentValues)

        if (valuesArray.length === 0) {
            return
        }

        // Create new and supplement existing property objects
        if (filterType) {
            set(dependencyParams, parameterName, { FilterType: filterType, IdList: valuesArray })
        } else {
            set(dependencyParams, parameterName, valuesArray)
        }

        if (!additionalParams) {
            return
        }

        // Set each individual additional parameter
        Object.keys(additionalParams).forEach((key) => {
            const value = additionalParams[key]

            set(dependencyParams, key, value)
        })
    })

    return dependencyParams
}

interface IRequestParameters {
    limit: IStoredOptionsRequestParameters['Limit']
    extraRows: IStoredOptionsRequestParameters['ExtraRows']
    dependsOn: IStoredOptionsComponentDependency[]
    getStoredComponentValue: IGetStoredComponentValue
}

export const getStoredOptionsRequestParameters = (
    { getStoredComponentValue, dependsOn, limit, extraRows }: IRequestParameters,
    dynamicParams: Record<string, unknown> | undefined,
    additionalDataFetchParameters: Partial<IStoredOptionsRequestParameters>
): IStoredOptionsRequestParameters => {
    const dependencyParams = compileDependencyParams(getStoredComponentValue, dependsOn)

    const {
        Filters: additionalFilters,
        SortBy: additionalSortBy,
        ExtraRows: additionalExtraRows,
        GroupBy: additionalGroupBy,
        Offset: additionalOffset,
        Limit: additionalLimit,
        ...restOfAdditionalDataFetchParameters
    } = additionalDataFetchParameters

    const queryParams: IStoredOptionsRequestParameters = {
        Filters: { ...dependencyParams, ...dynamicParams, ...additionalFilters },
        SortBy: additionalSortBy ?? [],
        ExtraRows: additionalExtraRows ?? extraRows,
        GroupBy: additionalGroupBy ?? [],
        Offset: additionalOffset ?? 0,
        ...restOfAdditionalDataFetchParameters,
    }

    if (limit !== null || (additionalLimit !== null && additionalLimit !== undefined)) {
        queryParams.Limit = additionalLimit ?? limit
    }

    return queryParams
}
