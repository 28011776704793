import { ComponentProps, createRef, FC, PureComponent, ReactNode, RefObject, SyntheticEvent } from 'react'
import Fuse from 'fuse.js' // eslint-disable-line import/extensions
import styled from '@emotion/styled'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

import { anyValuesMissingFromItems, formValueItemMap } from './Utilities/UtilityFunctions'
import { OPEN_FALSE, OPEN_TRUE } from 'constants/Params'
import Menu from './components/Menu'
import ValueField from './components/ValueField'
import {
    IInputFilterFunction,
    IOnValueMissingFromItemsChange,
    ISelectAction,
    ISelectRowsFunction,
    TAllValues,
    TIdValuePair,
    TNewlySelected,
    TRowTextComponent,
    TSelectedValues,
    TValueFieldType,
} from './Interfaces'
import { fuseDefaults } from './FuzzySearch'
import Styles from 'constants/Styles'
import { isEqual, uniqueId } from 'lodash-es'
import { SelectedItemExtras } from '@planier/generic-components'
import { getTestId } from '@planier/test'

type TContainerProps = Pick<IDropdownProps<any>, 'width' | 'bordered' | 'fullWidth'>

const borderColor = Styles.supplementaryColor.opaqueDarkGrey
const Container = styled.div`
    display: inline-block;
    position: relative;
    width: ${({ fullWidth, width }: TContainerProps) => (fullWidth ? '100%' : width && `${width}px`)};

    border: ${({ bordered }: TContainerProps) => (bordered ? `1px solid ${borderColor}` : 'initial')};
    border-bottom: 0;
`

type TPopoverProps = ComponentProps<typeof Popover>
interface IMenuContainerProps extends TPopoverProps {
    className?: string
    width: number | undefined | null
}
const MenuContainer = styled<FC<IMenuContainerProps>>(({ className, ...rest }) => (
    <Popover classes={{ paper: className }} {...rest} />
))`
    width: ${({ width }) => `${width}px`};
`

export interface IDropdownProps<T> {
    bordered: boolean
    defaultText: string
    disabled: boolean
    filteringProps: (keyof T)[]
    fullWidth: boolean
    hideSearch: boolean
    inputFilterFunction?: IInputFilterFunction<T>
    isLoading: boolean
    itemIdField: keyof T
    itemOptionLabelFields: (keyof T)[]
    itemValueLabelFields: (keyof T)[]
    items: T[]
    menuHeight: number
    multiselect: boolean
    onBlur?: () => void
    onValueMissingFromItemsChange?: IOnValueMissingFromItemsChange
    RowTextComponent?: TRowTextComponent<T>
    selectAction: ISelectAction<T>
    textForMultipleSelected: string
    textInputId: string
    label: string
    translate: boolean
    valueFieldType?: TValueFieldType

    /**
     * When multiselect is false but there are multiple values anyway, this
     * is used in the value text to show that there are multiple selected values,
     * for example "usea typeName"
     */
    typeName?: string
    values: TAllValues<T>
    valueTextOverride?: string
    width: number | null
    menuWidth?: number | null
    onInputChange?: (userInput: string) => void
    onCloseMenu?: (userInput: string) => void
    required?: boolean
    spaceReservedForChipSelectionCount?: number
    errors?: string
    valuePickerId?: string
}

export interface IState<T> {
    menuOpen: boolean
    selectedNewItems: TNewlySelected<T>
    selectedValueItems: TSelectedValues<T>
    userInput: string
    valueItems: ReadonlyMap<T[keyof T], T>
}

const TextRowTypography = styled(Typography)`
    color: ${({ theme }) => theme.componentExtensions.inputs.textInputDefault};
`

function DefaultRowTextComponent<T>({
    item,
    itemOptionLabelFields,
}: {
    item: T
    itemIdField: keyof T
    itemOptionLabelFields: (keyof T)[]
}) {
    const label = itemOptionLabelFields.map((labelField) => item[labelField]).join(' ')
    return <TextRowTypography variant="bodyS">{label}</TextRowTypography>
}

// TODO: rewrite the component as function component and don't use defaultProps,
// which messes up with the types. We could define the optional types directly
// in IDropdownProps but then they'd be optional also within the Dropdown component.
export type TOptionalProps<T> = Pick<
    IDropdownProps<T>,
    | 'bordered'
    | 'disabled'
    | 'filteringProps'
    | 'fullWidth'
    | 'hideSearch'
    | 'isLoading'
    | 'itemIdField'
    | 'itemOptionLabelFields'
    | 'itemValueLabelFields'
    | 'menuHeight'
    | 'multiselect'
    | 'textInputId'
    | 'translate'
    | 'valueTextOverride'
    | 'width'
    | 'required'
    | 'menuWidth'
    | 'spaceReservedForChipSelectionCount'
>

export function dropdownDefaultProps<T>(): TOptionalProps<T> {
    return {
        bordered: false,
        disabled: false,
        filteringProps: ['Nimi', 'Id'] as (keyof T)[],
        fullWidth: false,
        hideSearch: false,
        isLoading: false,
        itemIdField: 'Id' as keyof T,
        itemOptionLabelFields: ['Id', 'Nimi'] as (keyof T)[],
        itemValueLabelFields: ['Nimi'] as (keyof T)[],
        menuHeight: 400,
        multiselect: true,
        textInputId: uniqueId('ctr_'),
        translate: true,
        valueTextOverride: '',
        width: 300,
        required: false,
        menuWidth: 300,
    }
}

/**
 * Dropdown.
 *
 * By default (if multiselect prop is true) the dropdown keeps all the
 * selections in its state until the dropdown is closed. Only at that
 * point the given selectAction function is called.
 */
export default class Dropdown<T> extends PureComponent<IDropdownProps<T>, IState<T>> {
    static readonly defaultProps = dropdownDefaultProps()

    state = {
        menuOpen: false,
        selectedNewItems: new Set<T[keyof T]>(),
        selectedValueItems: this.props.values.size > 0 ? new Set(this.props.values) : new Set<T[keyof T]>(),
        valueItems: this.saveInitialValuesWithTheirItems(),
        userInput: '',
    }

    componentDidMount(): void {
        if (this.canDoAutoSelection()) {
            this.autoSelectSingleItem()
        }
    }

    componentDidUpdate(oldProps: IDropdownProps<T>, oldState: IState<T>): void {
        const { items, values } = this.props

        const dropdownMenuJustOpened = !oldState.menuOpen && this.state.menuOpen
        const dropdownMenuJustClosed = oldState.menuOpen && !this.state.menuOpen
        const valuesChanged = oldProps.values !== this.props.values

        if (dropdownMenuJustOpened || dropdownMenuJustClosed || valuesChanged) {
            this.setState({
                selectedNewItems: new Set<T[keyof T]>(),
                selectedValueItems: new Set(values),
                userInput: oldState.userInput,
            })
        } else if (oldProps.isLoading && !this.props.isLoading) {
            // make sure the values matches the selected values after loading
            this.setState({ selectedValueItems: new Set(values) })
        }

        if (items.length > 0 && oldProps.items.length !== items.length) {
            // Initialise the fuzzy search class instance when items have been loaded.
            this.fuse = new Fuse(items, this.fuseOptions)
        }

        if (oldProps.items !== items && this.canDoAutoSelection()) {
            this.autoSelectSingleItem()
        }

        if (oldProps.items === items && oldProps.values === values) {
            return
        }

        const valueItems = this.saveValuesWithTheirItems()
        this.setState({ valueItems })

        const { onValueMissingFromItemsChange, itemIdField } = this.props

        if (!onValueMissingFromItemsChange) {
            return
        }

        const anyValueIsMissingFromItems = anyValuesMissingFromItems(items, values, itemIdField)

        if (anyValueIsMissingFromItems) {
            onValueMissingFromItemsChange(true)
        } else {
            onValueMissingFromItemsChange(false)
        }
    }

    private mainElementRef: RefObject<HTMLDivElement> = createRef()
    private fuseOptions: Fuse.IFuseOptions<T> = {
        ...fuseDefaults,

        keys: this.props.filteringProps as string[],
    }

    private fuse: Fuse<T> = new Fuse(this.props.items, this.fuseOptions)

    handleDropdownMenuOpen = (e: SyntheticEvent): void => {
        if (!this.state.menuOpen) {
            this.toggleDropdownOpen(OPEN_TRUE)
        }

        e.stopPropagation()
        e.preventDefault()
    }

    closeItemsMenu = (): void => {
        this.toggleDropdownOpen(OPEN_FALSE)

        const { onBlur, onCloseMenu } = this.props
        const { userInput } = this.state

        onCloseMenu && onCloseMenu(userInput)
        onBlur && onBlur()
    }

    closeMenuAndSelectItems = (): void => {
        const { selectedNewItems, selectedValueItems } = this.state

        this.callTheGivenSelectAction(selectedNewItems, selectedValueItems)
        this.closeItemsMenu()
    }

    handleValueFieldClick = (e: SyntheticEvent): void => {
        if (this.state.menuOpen) {
            this.closeMenuAndSelectItems()
        } else if (!this.props.disabled) {
            this.handleDropdownMenuOpen(e)
        }
    }

    toggleNewRow: ISelectRowsFunction<T> = (item: T) => {
        const newSelectedWithTheItemToggled = this.toggleRow(item, this.state.selectedNewItems)

        if (!this.props.multiselect) {
            this.setState({ selectedValueItems: new Set<T[keyof T]>() })

            this.callTheGivenSelectAction(newSelectedWithTheItemToggled, new Set<T[keyof T]>())
            this.closeItemsMenu()
        } else {
            this.setState({ selectedNewItems: newSelectedWithTheItemToggled })
        }
    }

    toggleValueRow: ISelectRowsFunction<T> = (item: T) => {
        const newMap = this.toggleRow(item, this.state.selectedValueItems)

        if (!this.props.multiselect) {
            this.setState({ selectedNewItems: new Set<T[keyof T]>() })
        }

        this.setState({ selectedValueItems: newMap })
    }

    private canDoAutoSelection() {
        const { required, items, values } = this.props

        return required && items.length === 1 && values.size === 0
    }

    private massToggleAllFilteredRows: ISelectRowsFunction<boolean> = (checkboxSelected: boolean) => {
        if (checkboxSelected) {
            this.selectAllFilteredItems()
        } else {
            this.unselectAllFilteredItems()
        }
    }

    private handleInputChange = (input: string) => {
        this.setState({ userInput: input })

        const { onInputChange } = this.props
        onInputChange && onInputChange(input)
    }

    private filterItems = (): T[] => {
        const { hideSearch, inputFilterFunction, items } = this.props
        const { userInput } = this.state

        if (userInput === '' || hideSearch) {
            return items
        }

        if (inputFilterFunction) {
            return (this.props.inputFilterFunction as IInputFilterFunction<T>)(userInput.toLowerCase(), items)
        } else if (this.fuse) {
            return this.fuse.search(userInput).map(({ item }) => item) as T[]
        } else {
            return items
        }
    }

    private autoSelectSingleItem() {
        const { items, itemIdField, selectAction } = this.props
        const itemId = items[0][itemIdField]

        selectAction(new Set([itemId]))
    }

    private callTheGivenSelectAction(selected: TNewlySelected<T>, valuesSelected: TSelectedValues<T>) {
        const newlySelectedValuesWithTheOldValues = this.getAllNewlySelectedValuesWithTheOldValues(
            selected,
            valuesSelected
        )

        const { selectAction, values } = this.props

        if (isEqual(newlySelectedValuesWithTheOldValues, values)) {
            return
        }

        selectAction(newlySelectedValuesWithTheOldValues)
    }

    private getAllNewlySelectedValuesWithTheOldValues(selected: TNewlySelected<T>, valuesSelected: TSelectedValues<T>) {
        const { values } = this.props

        const selectedValuesAsArray = [...selected]

        const oldValuesWithoutUnSelectedOnes = [...values].filter((item) => valuesSelected.has(item))
        const newlySelectedValuesWithTheOldValues = [...selectedValuesAsArray, ...oldValuesWithoutUnSelectedOnes]

        return new Set(newlySelectedValuesWithTheOldValues)
    }

    private toggleRow(item: T, currentlySelected: TSelectedValues<T> | TNewlySelected<T>) {
        const { itemIdField, multiselect } = this.props
        const id = item[itemIdField]

        const newMap = multiselect
            ? this.toggleRowWithMultiSelect(id, currentlySelected)
            : this.toggleRowWithoutMultiselect(id, currentlySelected)

        return newMap
    }

    private toggleRowWithMultiSelect(id: T[keyof T], currentlySelected: TSelectedValues<T> | TNewlySelected<T>) {
        const newMap = new Set(currentlySelected)

        if (newMap.has(id)) {
            newMap.delete(id)
        } else {
            newMap.add(id)
        }

        return newMap
    }

    private toggleRowWithoutMultiselect(id: T[keyof T], currentlySelected: TSelectedValues<T> | TNewlySelected<T>) {
        const newMap = new Set<T[keyof T]>()

        if (!currentlySelected.has(id)) {
            newMap.add(id)
        }

        return newMap
    }

    private toggleDropdownOpen(setOpen: boolean) {
        this.setState({ menuOpen: setOpen })
    }

    private selectAllFilteredItems() {
        const { itemIdField, values } = this.props
        const { selectedNewItems, selectedValueItems } = this.state
        const newSelected = new Set<T[keyof T]>(selectedNewItems)
        const newValueSelected = new Set<T[keyof T]>(selectedValueItems)

        const filteredItems = this.filterItems()

        filteredItems.forEach((item) => {
            const id = item[itemIdField]

            if (values.has(id)) {
                newValueSelected.add(id)
            } else {
                newSelected.add(id)
            }
        })

        this.setState({ selectedNewItems: newSelected, selectedValueItems: newValueSelected })
    }

    private unselectAllFilteredItems() {
        const { itemIdField } = this.props
        const { selectedNewItems: previouslySelectedNewItems, selectedValueItems: previouslySelectedValueItems } =
            this.state

        const newSelected = new Set(previouslySelectedNewItems)
        const newValueSelected = new Set(previouslySelectedValueItems)

        const filteredItems = this.filterItems()

        filteredItems.forEach((item) => {
            const id = item[itemIdField]

            newSelected.delete(id)
            newValueSelected.delete(id)
        })

        if (newValueSelected.size > 0) {
            this.unselectValuesMissingFromItemList(newValueSelected)
        }

        this.setState({ selectedNewItems: newSelected, selectedValueItems: newValueSelected })
    }

    private unselectValuesMissingFromItemList(newValueSelected: Set<T[keyof T]>) {
        const { itemIdField, items } = this.props
        const itemSet = new Set(items.map((item) => item[itemIdField]))

        newValueSelected.forEach((itemId) => {
            if (!itemSet.has(itemId)) {
                newValueSelected.delete(itemId)
            }
        })
    }

    private saveInitialValuesWithTheirItems(): Map<T[keyof T], T> {
        const { items, values, itemIdField } = this.props

        let valueMap = new Map<T[keyof T], T>()

        if (values.size > 0) {
            const idItemPairs = items
                .filter((item) => values.has(item[itemIdField]))
                .map((item): TIdValuePair<T> => [item[itemIdField], item])

            valueMap = new Map<T[keyof T], T>(idItemPairs)
        }

        return formValueItemMap(items, values, itemIdField, valueMap)
    }

    private saveValuesWithTheirItems(): Map<T[keyof T], T> {
        const { items, values, itemIdField } = this.props

        return formValueItemMap(items, values, itemIdField, this.state.valueItems)
    }

    render(): ReactNode {
        const {
            bordered,
            defaultText,
            disabled,
            fullWidth,
            hideSearch,
            isLoading,
            itemIdField,
            itemOptionLabelFields,
            itemValueLabelFields,
            items,
            menuHeight,
            multiselect,
            RowTextComponent,
            textForMultipleSelected,
            textInputId,
            translate,
            typeName,
            values,
            valueTextOverride,
            width,
            valueFieldType,
            menuWidth,
            spaceReservedForChipSelectionCount,
            errors,
            label,
            required,
            valuePickerId,
        } = this.props
        const { menuOpen, selectedNewItems, selectedValueItems, userInput, valueItems } = this.state

        const renderMenu = menuOpen && !disabled

        // @@TODO: This should not be necessary, right? When something relevant changes, the
        // appopriate callback function gets called, which handles updating state. filteredItems
        // should therefore always be up to date and no filtering needs to be performed inside render
        const filteredItems = this.filterItems()

        const menuWidthToUse = menuWidth ?? this.mainElementRef.current?.offsetWidth ?? 0

        const selectedItems = items.filter((item: Record<any, any>) => values.has(item.Id)) as Record<any, any>[]

        const testId = getTestId(['VALUE_PICKER'], valuePickerId)

        return (
            <>
                <Container
                    bordered={bordered}
                    fullWidth={fullWidth}
                    onClick={disabled ? undefined : this.handleDropdownMenuOpen}
                    ref={this.mainElementRef}
                    width={width}
                    data-testid={testId}
                >
                    <ValueField<T>
                        errors={errors}
                        defaultText={defaultText}
                        disabled={disabled}
                        isLoading={isLoading}
                        itemIdField={itemIdField}
                        items={items}
                        isRequired={required}
                        label={label}
                        itemValueLabelFields={itemValueLabelFields}
                        multiselect={multiselect}
                        onClick={this.handleValueFieldClick}
                        selectedNewItems={selectedNewItems}
                        selectedValueItems={selectedValueItems}
                        spaceReservedForChipSelectionCount={spaceReservedForChipSelectionCount}
                        textForMultipleSelected={textForMultipleSelected}
                        translate={translate}
                        typeName={typeName}
                        valueFieldType={valueFieldType}
                        valueItems={valueItems}
                        values={values}
                        valueTextOverride={valueTextOverride}
                    />
                    <MenuContainer
                        anchorEl={this.mainElementRef.current}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        onClose={this.closeMenuAndSelectItems}
                        open={renderMenu}
                        width={menuWidthToUse}
                    >
                        {renderMenu && (
                            <Menu<T>
                                componentWidth={menuWidthToUse}
                                fullWidth={fullWidth}
                                hideSearch={hideSearch}
                                inputFilterFunction={this.handleInputChange}
                                isLoading={isLoading}
                                itemIdField={itemIdField}
                                itemOptionLabelFields={itemOptionLabelFields}
                                items={filteredItems}
                                menuHeight={menuHeight}
                                multiselect={multiselect}
                                RowTextComponent={RowTextComponent || DefaultRowTextComponent}
                                selectAction={this.toggleNewRow}
                                selectAllAction={this.massToggleAllFilteredRows}
                                selectedNewItems={selectedNewItems}
                                selectedValueItems={selectedValueItems}
                                selectValueRowAction={this.toggleValueRow}
                                textInputId={textInputId}
                                userInput={userInput}
                                values={values}
                            />
                        )}
                    </MenuContainer>
                </Container>

                <SelectedItemExtras items={selectedItems} />
            </>
        )
    }
}
