import makeRequest, { ERequestMethod } from '@planier/rest-api'

// In payroll administration we use /Tapahtuma but in workforce scheduling we use the v2
const DELETION_DEFAULT_URL = '/v2/Tapahtuma'

export const deleteEvents = async (
    eventIds: string[],
    deletionReasonId: string,
    additionalInfo: string,
    deletionUrl = DELETION_DEFAULT_URL
): Promise<void> =>
    await makeRequest<void>({
        method: ERequestMethod.DELETE,
        url: deletionUrl,
        data: {
            Ids: eventIds,
            Poistosyykoodi: deletionReasonId,
            ...(additionalInfo && { Poistolisatieto: additionalInfo }),
        },
    })
