import * as React from 'react'

import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import ListCheckbox from './ListCheckbox'
import ListGroupButton from './ListGroupButton'
import styled from '@emotion/styled'

interface IOwnProps {
    isLoading: boolean
    rowChecked: boolean
    groupType: 'main' | 'sub' | ''
    groupOpened: boolean
    handleGroupButtonClick: (e: React.MouseEvent) => void
    handleRowClick: () => void
    massSelectable: boolean
    offset?: number
}

interface IListCheckboxGroupProps extends IOwnProps {}

// margins to position grouped row icons et. al.
const margins = {
    base: {
        checkbox: {
            marginLeft: '0px',
            marginRight: '13px',
        },
        groupicon: {
            marginLeft: '0px',
        },
    },
    main: {
        checkbox: {
            marginLeft: '0px',
            marginRight: '13px',
        },
        groupicon: {
            marginLeft: '35px',
        },
    },
    sub: {
        checkbox: {
            marginLeft: '20px',
            marginRight: '-8px',
        },
        groupicon: {
            marginLeft: '-7px',
        },
    },
}

const Container = styled.div<{ offset?: number }>`
    display: flex;
    align-items: center;
    transform: ${({ offset }) => offset && `translateX(${offset}px)`};
`

/**
 * Checkbox shown in the left side of the row.
 */
export const ListCheckboxGroupUnconnected: React.FC<IListCheckboxGroupProps> = ({
    rowChecked,
    isLoading,
    groupType,
    groupOpened,
    handleGroupButtonClick,
    handleRowClick,
    massSelectable,
    offset,
}) => {
    const renderSwitch = () => {
        const rowType = groupType === '' ? 'base' : groupType

        return (
            <Container offset={offset} rowType={rowType}>
                {massSelectable && (
                    <ListCheckbox
                        isLoading={isLoading}
                        onClick={handleRowClick}
                        rowChecked={rowChecked}
                        {...margins[rowType].checkbox}
                    />
                )}
                {rowType !== 'base' && (
                    <ListGroupButton
                        groupType={groupType}
                        groupOpened={groupOpened}
                        handleGroupButtonClick={handleGroupButtonClick}
                        {...margins[rowType].groupicon}
                    />
                )}
            </Container>
        )
    }

    if (isLoading) {
        return <LoadingIndicatorInline isLoading paddingLeft="4px" size={34} />
    }

    return renderSwitch()
}

export default ListCheckboxGroupUnconnected
