import * as React from 'react'
import { JSX, useLayoutEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Icon } from '@planier/generic-components'

type Props = {
    icon?: string | null
    children: React.ReactNode
    className?: string
    tooltip?: string | null
}

//TODO IF WE FALLBACK TO ICONS, CHENGE 0 TO iconSize
const IconPlaceholder = styled.div`
    width: ${0};
`

const FieldItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 4px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 18px;
    width: 100%;
`

const IconContainer = styled.div`
    margin-right: 7px;
`

const InfoIconContainer = styled.div<{ height: number }>`
    display: flex;
    margin-left: 10px;
    align-items: flex-start;
    position: relative;
    height: ${({ height }) => height}px;

    & .info-icon {
        position: absolute;
        top: ${({ height }) => (height < 80 && height > 50 ? '50%' : '25%')};
    }
`

const FieldItemContentContainer = styled.div`
    min-height: 49px;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ChildContainer = styled.div`
    display: flex;
    flex-direction: column;
`

/**
 * You can use this in forms when there are fields for which you need to display
 * an icon. The component simply renders the icon or an empty space for it if the
 * icon isn't given, and then next to it renders the actual content given with
 * children property next
 */
const FieldContainer = ({ tooltip, children }: Props): JSX.Element => {
    const contentRef = useRef<HTMLDivElement>(null)
    const [contentHeight, setContentHeight] = useState<number>(0)

    useLayoutEffect(() => {
        if (!contentRef.current) {
            return
        }

        const { height } = contentRef.current.getBoundingClientRect()
        setContentHeight(height)
    }, [])

    const iconSpace = <IconPlaceholder />

    const infoIcon = tooltip && (
        <InfoIconContainer height={contentHeight}>
            <Icon tooltip={tooltip} colorTheme="primary" tooltipChildWrapperElement="span" className="info-icon">
                info_outlined
            </Icon>
        </InfoIconContainer>
    )

    return (
        <FieldItemContainer>
            <IconContainer>{iconSpace}</IconContainer>
            <FieldItemContentContainer ref={contentRef}>
                <ChildContainer>{children}</ChildContainer>
                {infoIcon}
            </FieldItemContentContainer>
        </FieldItemContainer>
    )
}

export default FieldContainer
