import * as React from 'react'
import { JSX } from 'react'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import styled from '@emotion/styled'
import { getNavigationSectionId } from '../../../utils/NavigationSectionIds'

interface ISectionLabelProps {
    children: React.ReactNode
    to: string
    isCurrentPage: boolean
    className?: string
    variant?: string
    sectionName: string
}

interface IStyledLinkProps extends TypographyProps {
    component: 'a'
    href: string
    isCurrentPage: boolean
}

const StyledLink = styled<React.FC<IStyledLinkProps>>(Typography, {
    shouldForwardProp: (prop: keyof IStyledLinkProps) => prop !== 'isCurrentPage',
})`
    text-decoration: none;
    display: flex;
    padding-left: 10px;
    line-height: 42px;
    height: 42px;
    justify-content: flex-start;
    align-items: center;
    transition: background-color ${({ theme }) => theme.tokens.transitionQuick};

    ${({ isCurrentPage, theme }) =>
        isCurrentPage &&
        `
        color: ${theme.colors.primaryTeal120};
        background-color: ${theme.colors.primaryCyan11};
    `}

    > * {
        margin-right: 12px;
    }

    :hover {
        background-color: ${({ isCurrentPage, theme }) => !isCurrentPage && theme.colors.primaryChampion30};
    }
`

const TabLabel = ({
    className,
    isCurrentPage,
    children,
    to,
    variant = 'bodyM',
    sectionName,
}: ISectionLabelProps): JSX.Element => {
    const testId = getNavigationSectionId(sectionName ?? '')
    return (
        <StyledLink
            data-testid={testId}
            className={className}
            component="a"
            href={to}
            isCurrentPage={isCurrentPage}
            variant={variant}
        >
            {children}
        </StyledLink>
    )
}

export default TabLabel
