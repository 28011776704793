/**
 * Inserts given items to array at the index given.
 */
export const insertToArray = <T>(arrayToInsertIn: T[], index: number, ...newItems: T[]): T[] => {
    const isIndexOutOfRange = index < 0 || index > arrayToInsertIn.length
    if (isIndexOutOfRange) {
        throw new RangeError('Index out of bounds!')
    }

    return [...arrayToInsertIn.slice(0, index), ...newItems, ...arrayToInsertIn.slice(index)]
}

export const getValueAsArray = <T>(value: T): T | T[] | null | undefined => {
    if (value === null || value === undefined) {
        return value
    }

    if (value instanceof Set) {
        return [...value]
    }

    if (Array.isArray(value)) {
        return value
    }

    return [value]
}

export const removeDuplicates = (value: any[]): any[] => {
    return [...new Set(value)]
}

/**
 * Removes duplicate objects from an array based on given key.
 * Keeps the last iteration of the object with given key.
 *
 * @param array
 * @param key
 * @returns
 */
export const removeDuplicateObjectsInArrayBasedOnKey = (array: any[], key: string): any[] => {
    return [...new Map(array.map((item) => [item[key], item])).values()]
}
