import * as React from 'react'
import styled from '@emotion/styled'

import { TranslatedButton } from './Buttons/Button/'
import Icon from './Icon/'
import ReadOnlyText from './ReadOnlyText'
import { useAppTheme } from '@planier/theme'

export interface IFile {
    name: string
    content: unknown
}

const StyledButton = styled(TranslatedButton)`
    height: 24px;
`

const SmallMargin = styled.div`
    margin-left: 3px;
`

interface IFileUploadProps {
    onChange: (file: IFile) => void
    value: IFile | null | undefined
    label: string
    isRequired?: boolean
    displayStyle: 'default' | 'image'
}

const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
    })

const UploadedFile: React.FC<{ file: IFile; onDelete: () => void }> = (props) => {
    return (
        <Row>
            <ReadOnlyText usage="list_link">{props.file.name}</ReadOnlyText>
            <TranslatedButton
                data-testid="Stc_Form_RemoveFileBtn"
                variant="text"
                onClick={props.onDelete}
                startIcon={<Icon>close</Icon>}
            >
                Poista tiedosto
            </TranslatedButton>
        </Row>
    )
}
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const RowWithMargin = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const EmptyPhotoPicker = styled.div`
    margin-left: 40px;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    border-radius: 60px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    align-text: center;
    text-align: center;
    align-content: center;
    display: flex;
    padding-left: 3px;
    margin-right: 10x;
    border: ${({ theme }) => `2px solid ${theme.colors.primaryTeal120}`};
    background: ${({ theme }) => theme.colors.primaryTeal11};

    &:hover {
        background: ${({ theme }) => theme.colors.primaryCyan60};
    }
`

const DefaultDisplayContainer = styled.div`
    margin-right: 10px;
`

const FileUpload: React.FC<IFileUploadProps> = ({ onChange, value, displayStyle = 'default' }) => {
    const hiddenFileInput = React.useRef(null)
    const theme = useAppTheme()

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current && hiddenFileInput.current.click) {
            hiddenFileInput.current.click()
        }
    }

    const _onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target && e.target.files && e.target.files.length > 0) {
            const content = await toBase64(e.target.files[0])
            const name = e.target.files[0].name

            onChange({ name: name, content: content })
        }
    }

    return (
        <div>
            {value ? <UploadedFile file={value} onDelete={() => onChange(null)} /> : null}
            {!value ? (
                <RowWithMargin>
                    {displayStyle === 'image' ? (
                        <EmptyPhotoPicker data-testid="Stc_Form_PhotoUpload" onClick={handleClick}>
                            <ReadOnlyText translate usage="h5" rawColor={theme.colors.primaryTeal120}>
                                Lisää kuva
                            </ReadOnlyText>
                        </EmptyPhotoPicker>
                    ) : null}
                    {displayStyle === 'default' ? (
                        <DefaultDisplayContainer>
                            <div data-testid="Stc_Form_AddFileBtn">
                                <StyledButton onClick={handleClick}>Lisää tiedosto</StyledButton>
                            </div>
                            <SmallMargin />
                            <ReadOnlyText usage="dropdown_label" translate>
                                Ei valittua tiedostoa
                            </ReadOnlyText>
                        </DefaultDisplayContainer>
                    ) : null}
                    <input type="file" onChange={_onChange} ref={hiddenFileInput} style={{ display: 'none' }} />
                </RowWithMargin>
            ) : null}
        </div>
    )
}

export default FileUpload
