import * as React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import moment, { Moment, MomentInput } from 'moment'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import TimeInput from 'components/molecules/TimeInput'
import Label from 'components/atoms/Label'
import { isNullableDateTimeRange } from '@planier/generic-utilities'
import { selectValuePickerValue, setValuePickerValueAction, IPageTimeRangePickerOwnProps } from '@planier/value-picker'
import { getTestId } from '@planier/test'

interface IStateProps {
    endTime: Moment | null
    startTime: Moment | null
}

interface IDispatchProps {
    setValuePickerValue: typeof setValuePickerValueAction
}

interface IOwnState {
    endTime: Moment | string
    startTime: Moment | string
}

export interface IPageTimeRangePickerProps extends IPageTimeRangePickerOwnProps, IStateProps, IDispatchProps {}

const Hyphen = styled.div`
    display: flex;
    align-items: flex-end;
    padding-bottom: 6px;
`

const ContainerGrid = styled(Grid)`
    max-width: 200px;
`

const TIME_PICKER_WIDTH = '88px'

export class PageTimeRangePickerUnconnected extends React.Component<IPageTimeRangePickerProps, IOwnState> {
    state: IOwnState = {
        endTime: '',
        startTime: '',
    }

    componentDidUpdate(prevProps: IPageTimeRangePickerProps): void {
        const { startTime, endTime } = this.props

        if (moment.isMoment(startTime) && !startTime.isSame(prevProps.startTime || undefined)) {
            this.setState({ startTime })
        }
        if (moment.isMoment(endTime) && !endTime.isSame(prevProps.endTime || undefined)) {
            this.setState({ endTime })
        }
    }

    handleEndTimeSelection = (endTime: Moment | string): void => {
        this.setState({ endTime })

        // Update the global state only when the user removes the chosen time
        // or when it is a valid Moment object.
        if (endTime === '' || moment.isMoment(endTime)) {
            this.props.setValuePickerValue(
                {
                    start: this.props.startTime,
                    end: endTime ? endTime : null,
                },
                this.props.valuePickerId
            )
        }
    }

    handleStartTimeSelection = (startTime: Moment | string): void => {
        this.setState({ startTime })

        if (startTime === '' || moment.isMoment(startTime)) {
            this.props.setValuePickerValue(
                {
                    start: startTime ? startTime : null,
                    end: this.props.endTime,
                },
                this.props.valuePickerId
            )
        }
    }

    render(): React.ReactNode {
        const { text, valuePickerId } = this.props
        const { endTime, startTime } = this.state
        const testId = getTestId(['VALUE_PICKER'], valuePickerId)

        return (
            <ContainerGrid container data-testid={testId}>
                {text.Label && <Label doNotTranslate>{text.Label}</Label>}
                <Grid item>
                    <TimeInput
                        changeAction={this.handleStartTimeSelection}
                        description={text.StartPlaceholder}
                        rawTimeValue={startTime as MomentInput}
                        translate={false}
                        width={TIME_PICKER_WIDTH}
                    />
                </Grid>
                <Hyphen>&nbsp; - &nbsp;</Hyphen>
                <Grid item>
                    <TimeInput
                        changeAction={this.handleEndTimeSelection}
                        description={text.EndPlaceholder}
                        rawTimeValue={endTime as MomentInput}
                        translate={false}
                        width={TIME_PICKER_WIDTH}
                    />
                </Grid>
            </ContainerGrid>
        )
    }
}

const mapStateToProps = (state: RootState, { valuePickerId }: IPageTimeRangePickerOwnProps) => {
    const pickerValue = selectValuePickerValue(state, valuePickerId)
    const timeRange = isNullableDateTimeRange(pickerValue)
        ? pickerValue
        : {
              start: null,
              end: null,
          }

    return {
        endTime: timeRange.end,
        startTime: timeRange.start,
    }
}

const mapDispatchToProps = {
    setValuePickerValue: setValuePickerValueAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageTimeRangePickerUnconnected)
