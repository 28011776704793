import { FC } from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import Styles from 'constants/Styles'
import { Button, Icon, MoreButtonWithMenu, Tooltip } from '@planier/generic-components'
import { openModalAction } from '@planier/modal'
import { IValuePickerModalProps, VALUE_PICKER_MODAL_ID } from '@planier/modal-components'
import { selectCalendarValuePickerIds } from '../../State/ConfigurableCalendarSelectors'
import ICalendarQuickActions from '../../Types/ICalendarQuickActions'
import { Translation } from '@planier/localization'
import SearchButton from './SearchButton'
import { BoundThunk } from '@planier/generic-state'

import { downloadCalendarDataToExcelThunk } from '../../Thunks/ConfigurableCalendarThunks'

interface IOwnProps {
    calendarId: string
    hiddenValuePickerIds?: string[]
    quickActions: ICalendarQuickActions | null
    areCalendarFiltersToBeDisplayed: boolean
}

interface IStateProps {
    valuePickerIds: string[]
}

interface IDispatchProps {
    openModal: typeof openModalAction
    downloadCalendarDataToExcel: BoundThunk<typeof downloadCalendarDataToExcelThunk>
}

const childClassName = 'pln-CalendarFilters-element'

const FiltersContainer = styled.div`
    display: flex;
    width: 200px;
    height: 49px;
    min-height: 49px;
    border: thin solid ${Styles.calendar.innerBorder};
    align-items: center;
    padding-left: 10px;

    .${childClassName} {
        height: 27px;
        min-width: 27px;
        max-width: 27px;
    }

    && > *:first-of-type {
        margin-right: 10px;
    }
`

interface ICalendarFiltersProps extends IOwnProps, IStateProps, IDispatchProps {}

export const CalendarFilters: FC<ICalendarFiltersProps> = ({
    calendarId,
    hiddenValuePickerIds,
    openModal,
    valuePickerIds,
    quickActions,
    areCalendarFiltersToBeDisplayed,
    downloadCalendarDataToExcel,
}) => {
    const handleClick = () => {
        const modalProps: IValuePickerModalProps = {
            valuePickerIds: Array.isArray(hiddenValuePickerIds)
                ? valuePickerIds.filter((valuePickerId) => !hiddenValuePickerIds.includes(valuePickerId))
                : valuePickerIds,
        }

        openModal(calendarId + VALUE_PICKER_MODAL_ID, modalProps)
    }

    return (
        <FiltersContainer>
            {calendarId === 'TyontekijanakymaCalendar' ? (
                <Button
                    className={childClassName}
                    iconButton
                    onClick={() => downloadCalendarDataToExcel()}
                    variant="outlined"
                >
                    <Icon tooltip={'Lataa tulostettava työvuorolista'}>print</Icon>
                </Button>
            ) : null}
            {areCalendarFiltersToBeDisplayed && (
                <Tooltip
                    title={Translation.translateKey('calendar.CalendarFilters.FiltersButton.Tooltip')}
                    wrapperElementClassName={childClassName}
                >
                    <Button className={childClassName} iconButton onClick={handleClick} variant="outlined">
                        <Icon>filter_list</Icon>
                    </Button>
                </Tooltip>
            )}
            <SearchButton calendarId={calendarId} className={childClassName} />
            {quickActions && quickActions.menuItemsData.length > 0 && (
                <Tooltip
                    title={Translation.translateKey('calendar.CalendarFilters.QuickActionsButton.Tooltip')}
                    wrapperElementClassName={childClassName}
                >
                    <MoreButtonWithMenu className={childClassName} icon="visibility" {...quickActions} />
                </Tooltip>
            )}
        </FiltersContainer>
    )
}

const mapStateToProps = (state: RootState, { calendarId }: IOwnProps): IStateProps => ({
    valuePickerIds: selectCalendarValuePickerIds(state, calendarId),
})

const mapDispatchToProps = {
    openModal: openModalAction,
    downloadCalendarDataToExcel: downloadCalendarDataToExcelThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarFilters)
