import * as React from 'react'
import { JSX } from 'react'
import styled from '@emotion/styled'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Button, IStyledButtonProps } from '../Button'

interface ILoadingButtonProps extends IStyledButtonProps {
    loading: boolean
}

const ButtonContainer = styled.div`
    position: relative;
`

const LoadingSpinner = styled(CircularProgress)<{ size: number }>`
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
    position: absolute;
    left: calc(50% - ${({ size }) => size / 2}px);
    top: calc(50% - ${({ size }) => size / 2}px);
`

const LoadingButton = ({ loading, disabled = false, ...buttonProps }: ILoadingButtonProps): JSX.Element => (
    <ButtonContainer>
        <Button disabled={disabled || loading} {...buttonProps} />

        {loading && <LoadingSpinner size={24} />}
    </ButtonContainer>
)

export default LoadingButton
