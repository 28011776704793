// eslint-disable-next-line no-shadow
enum EValuePickerType {
    Dropdown = 'Dropdown',
    DropdownV2 = 'DropdownV2',
    DropdownV3 = 'DropdownV3',
    TagList = 'TagList',
    TimeRange = 'TimeRange',
    DateRange = 'DateRange',
    ConfigurableList = 'ConfigurableList',
    DynamicValuePicker = 'DynamicValuePicker',
    TextSearch = 'TextSearch',
    Toggle = 'Toggle',
    AdditionalServicesPicker = 'AdditionalServicesPicker',
    CheckboxPicker = 'CheckboxPicker',
    NumberPicker = 'NumberPicker',
    SingleDayPicker = 'SingleDayPicker',
    MultiDayPicker = 'MultiDayPicker',
    TextPicker = 'TextPicker',
    TimePicker = 'TimePicker',
    TimeRangePicker = 'TimeRangePicker',
    DayPickerWithRangeAndWeekdays = 'DayPickerWithRangeAndWeekdays',
    TransactionPricePicker = 'TransactionPricePicker',
    TransactionSalaryPicker = 'TransactionSalaryPicker',
    Hidden = 'Hidden',
    RepetitiveEvent = 'RepetitiveEvent',
    DynamicData = 'DynamicData',
    ColorPicker = 'ColorPicker',
    DateRangeV2 = 'DateRangeV2',
    FilePicker = 'FilePicker',
    SkillPicker = 'SkillPicker',
    EventReservationRestrictionRulePicker = 'EventReservationRestrictionRulePicker',
    ExternalIdentifierValuePicker = 'ExternalIdentifierValuePicker',
    RadioButtonListValuePicker = 'RadioButtonListValuePicker',
    SubPricePicker = 'SubPricePicker',
}

export default EValuePickerType
