import { FC, ComponentProps, ReactNode } from 'react'
import styled from '@emotion/styled'

import GroupNode from './GroupNode'
import Styles from 'constants/Styles'
import { getIsLegacyEnvironment } from '@planier/legacy-environment-adapter'
import WorkUnitLink from 'components/atoms/AsiakasYksikko'

type TGroupNodeProps = ComponentProps<typeof GroupNode>

export interface IWorkUnitGroupNodeProps extends Pick<TGroupNodeProps, 'groupedByData' | 'kpiData'> {
    workUnitId: number
    name: string
}

const StyledWorkUnitLink = styled(WorkUnitLink)`
    color: ${Styles.planierColor.blueTurquoise.textColor};
    font-size: 12px;
    line-height: 15px;

    :hover {
        text-decoration: underline;
    }
`

const isLegacyEnvironment = getIsLegacyEnvironment()

// This is not the final version. Specs still missing.
const WorkUnitGroupNode: FC<IWorkUnitGroupNodeProps> = ({ name, kpiData, workUnitId, groupedByData }) => {
    let primaryText: ReactNode = name
    let overridePrimaryTextEvenWhenGrouped = false

    if (isLegacyEnvironment) {
        if (groupedByData?.groupMainTitle) {
            primaryText = groupedByData.groupMainTitle
            overridePrimaryTextEvenWhenGrouped = true
        }

        primaryText = <StyledWorkUnitLink yksikko={{ Nimi: primaryText as string, Id: workUnitId }} />
    }

    return (
        <GroupNode
            groupedByData={groupedByData}
            kpiData={kpiData}
            overridePrimaryTextEvenWhenGrouped={overridePrimaryTextEvenWhenGrouped}
            primaryText={primaryText}
        />
    )
}

export default WorkUnitGroupNode
