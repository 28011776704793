import { connect } from 'react-redux'
import * as React from 'react'
import { JSX, useState } from 'react'
import { flatMap, get, isNil, set, uniq } from 'lodash-es'
import { RootState } from 'typesafe-actions'

import { ExtraComponent, SubTabs } from '@planier/generic-components'
import Header from './Header'
import Footer from './Footer'
import { executeFormViewSubmitActionThunk, resetFormViewValuePickerValuesThunk } from '../../Thunks/FormViewThunks'
import {
    selectValuePickersNotes,
    selectValuePickersSideEffects,
    selectValuePickerValues,
    ValuePickerStandaloneWithoutStore,
} from '@planier/value-picker'

import { BoundThunk } from '@planier/generic-state'
import IFormViewConfigurationV2 from '../../Types/V2/IFormViewConfigurationV2'
import IFormViewFieldConfigurationV2 from '../../Types/V2/IFormViewFieldConfigurationV2'
import FormViewLoadingIndicator from '../FormViewLoadingIndicator'
import FormViewFailedInitializationIndicator from '../FormViewFailedInitializationIndicator'
import styled from '@emotion/styled'
import { FormFieldState } from '../../Types/V2/FormFieldStateEnum'
import FormFieldContainerV2 from '../FormFieldContainerV2'
import FormErrorAlert from '../FormErrorAlertV2'
import Tabs from './Tabs'
import {
    checkIfTabFieldsHasValidationErrors,
    checkIfTabHasRequiredFields,
    formHasMultipleFieldsInOneRow,
    modifiedFieldStates,
    useScreenDimensions,
    validate,
} from './utils'
import { Translation } from '@planier/localization'
import FormViewTitle from './FormViewTitle'
import { NotVoid } from 'lodash'

interface IFormViewTabsProps {
    Name: string
    isFormInitialValueSet: boolean
    FieldIds: string[]
    FieldStates: Record<string, FormFieldState>
    FieldValues: Record<string, unknown>
    FieldConfiguration: Record<string, IFormViewFieldConfigurationV2>
    FieldErrors: Record<string, string>
    FieldNotes: Record<string, string>
    onFieldValueSet: (id: string) => void
}
interface IOwnProps {
    additionalSubmitRequestProps?: Record<string, unknown>
    formItemIdentifiers?: any[]
    formConfiguration: IFormViewConfigurationV2 | null
    isLoading: boolean
    initializationFailed: boolean
    onClose?: () => void //If provided, there will be close icon and cancel button
    onSubmitCallback?: () => void
}

interface IStateProps {
    fieldValues: Record<string, unknown>
    fieldValueSideEffects: { key: string; value: string }[]
    fieldNotes: Record<string, string>
}

interface IDispatchProps {
    executeFormViewSubmitAction: BoundThunk<typeof executeFormViewSubmitActionThunk>
}

export type Tab = {
    name: string
    hasRequiredFields: boolean
    hasValidationErrors: boolean
}

export const FORM_FULL_WIDTH = 964
export const FORM_HALF_WIDTH = 728
export const FORM_TAB_WIDTH = 200
export const getFormContentMaxWidth = (width: number): number => width - FORM_TAB_WIDTH

export const FORM_HEIGHT = document.documentElement.offsetHeight - 120
export const FORM_CONTENT_MAX_HEIGHT = FORM_HEIGHT - 82 - 70

const FormContainer = styled.div<{ height: number; width: number }>`
    height: calc(${({ height }) => height}px - 120px);
    width: ${({ width }) => width}px;
`

const ContentContainer = styled.div<{ height: number }>`
    display: flex;
    flex-direction: row;
    height: 100%;
    height: calc(${({ height }) => height}px - 120px - 82px - 70px);
`

const SectionWithoutAccordionContainer = styled.div`
    margin-bottom: 16px;
    margin-top: 16px;
    padding-bottom: 16px;
`

const FormContentContainer = styled.div<{ height: number; width: number }>`
    padding-bottom: 32px;
    width: ${({ width }) => width}px;
    overflow-y: scroll;
    flex: 1;
    height: calc(${({ height }) => height}px - 120px - 82px - 70px);
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex: 1;
`

const ExtrasContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 2px;
    gap: 5px;
`

interface IFormViewFieldProps {
    Id: string
    isFormInitialValueSet: boolean
    State: FormFieldState
    Value: unknown
    Configuration: IFormViewFieldConfigurationV2
    Note: string
    Error?: string
    onValueSet: (Id: string) => NotVoid
}

const FormViewFieldComp = (props: IFormViewFieldProps): JSX.Element => {
    const formFieldStaticProps: any = {}

    const { ComponentStaticParameters, Tooltip, Title, TitleInfo, IconId, ValuePickerId, Extras } = props.Configuration

    ComponentStaticParameters.forEach((x) => set(formFieldStaticProps, x.Name, x.Value))

    return (
        <FormFieldContainerV2 icon={IconId} tooltip={Tooltip}>
            {Title && <FormViewTitle title={Title} titleInfo={TitleInfo} />}
            <ValuePickerStandaloneWithoutStore
                label={props.Configuration.Label}
                valuePickerId={ValuePickerId}
                context={'form'}
                contextId={props.Configuration.Id}
                error={props.Error}
                onValueChange={() => props.onValueSet(props.Configuration.Id)}
                value={props.Value}
                note={props.Note}
                isRequired={props.State === FormFieldState.Required}
                valuePickerComponentProperties={formFieldStaticProps}
                isInitialValueSet={props.isFormInitialValueSet}
            />

            {!isNil(Extras) && (
                <ExtrasContainer>
                    {Extras?.map(({ Component, Props }) => (
                        <ExtraComponent key={`${Component}-${props.Id}`} Component={Component} Props={Props} />
                    ))}
                </ExtrasContainer>
            )}
        </FormFieldContainerV2>
    )
}

const FormViewSection: React.FunctionComponent<IFormViewTabsProps> = (props) => {
    const groupedFieldsToRender: [IFormViewFieldConfigurationV2[]?] = []
    Object.keys(props.FieldConfiguration).map((field) => {
        const rowOrder = props.FieldConfiguration[field].RowOrder
        if (!!groupedFieldsToRender[rowOrder] && groupedFieldsToRender[rowOrder] instanceof Array) {
            groupedFieldsToRender[rowOrder]?.push(props.FieldConfiguration[field])
        } else {
            groupedFieldsToRender[rowOrder] = [props.FieldConfiguration[field]]
        }
    })

    const fields = groupedFieldsToRender.map((row, index) => {
        return (
            <RowContainer key={`rowContainer-${index}`}>
                {row?.map((field) => {
                    if (props.FieldStates[field.Id] !== FormFieldState.Hidden) {
                        return (
                            <FormViewFieldComp
                                Id={field.Id}
                                key={field.Id}
                                State={props.FieldStates[field.Id]}
                                Value={props.FieldValues[field.Id]}
                                Note={props.FieldNotes[field.Id]}
                                Error={props.FieldErrors[field.Id]}
                                onValueSet={props.onFieldValueSet}
                                isFormInitialValueSet={props.isFormInitialValueSet}
                                Configuration={props.FieldConfiguration[field.Id]}
                            />
                        )
                    }
                    return null
                })}
            </RowContainer>
        )
    })

    return <SectionWithoutAccordionContainer>{fields}</SectionWithoutAccordionContainer>
}

export interface IFormViewBaseProps extends IOwnProps, IStateProps, IDispatchProps {}

export const FormViewBaseUnconnected: React.FunctionComponent<IFormViewBaseProps> = (props) => {
    const [submitInProgress, setSubmitInProgress] = useState<boolean>(false)

    //Don't display required field errors before submit attempt
    const [userHasTriedSubmit, setUserHasTriedSubmit] = useState(false)
    const [selectedTab, setSelectedTab] = useState<string | null>(null)
    const [selectedSubTab, setSelectedSubTab] = useState<string | null>(null)

    const initialFieldConfig = flatMap(props.formConfiguration?.Sections, 'Fields') as IFormViewFieldConfigurationV2[]

    const fieldStates: Record<string, FormFieldState> = modifiedFieldStates(
        initialFieldConfig,
        props.fieldValueSideEffects
    )

    const renderCancel: boolean = props.onClose !== undefined

    const [touchedFields, setTouchedFields] = useState<string[]>([])

    const isInitialValueSet = touchedFields.length === 0

    const validationErrors: Record<string, string> = validate(
        fieldStates,
        props.fieldValues,
        props.formConfiguration?.SubmitOnlyChangedValues ?? false,
        touchedFields
    )

    const setFieldTouched = (id: string) => {
        setTouchedFields((values) => {
            const newValues = [...values]
            newValues.push(id)
            const uniqNewValues = uniq(newValues)

            return uniqNewValues
        })
    }

    const anyValidationErrors = Object.keys(validationErrors).length > 0

    const anyErrorsShouldBeRendered = userHasTriedSubmit && anyValidationErrors

    const disableSubmit = submitInProgress || props.isLoading || anyErrorsShouldBeRendered

    const displayedErrors = userHasTriedSubmit ? validationErrors : {}

    const handleSubmitBase = async (values: Record<string, unknown>, continueAfterSubmit = false) => {
        setUserHasTriedSubmit(true)

        if (anyValidationErrors) {
            return
        }

        const submitValues: Record<string, unknown> = {}

        initialFieldConfig
            .filter((x) => fieldStates[x.Id] !== FormFieldState.Hidden && fieldStates[x.Id] !== FormFieldState.Disabled)
            .filter(
                (x) =>
                    !props.formConfiguration?.SubmitOnlyChangedValues ||
                    initialFieldConfig.find((conf) => conf.Id === x.Id && conf.IsAlwaysIncluded) ||
                    touchedFields.find((f) => f === x.Id)
            )
            .forEach((field) => {
                field.DataSourcePropertyMapping.forEach((prop) => {
                    const fieldValue = props.fieldValues[field.Id]
                    const rawValue = prop.ComponentParameterName
                        ? get(fieldValue, prop.ComponentParameterName)
                        : fieldValue

                    const value = rawValue instanceof Set ? Array.from(rawValue) : rawValue

                    set(submitValues, prop.OutputProperty, value)
                })
            })

        setSubmitInProgress(true)

        try {
            if (!props.formConfiguration?.SubmitOnlyChangedValues || touchedFields.length > 0) {
                await props.executeFormViewSubmitAction(
                    props.formConfiguration?.Id || '', //Never should fall back to empty string
                    props.formItemIdentifiers || [],
                    submitValues,
                    props.additionalSubmitRequestProps,
                    continueAfterSubmit,
                    Boolean(props.onSubmitCallback)
                )
            }

            setSubmitInProgress(false)

            if (props.onSubmitCallback) {
                props.onSubmitCallback()
            }

            if (!continueAfterSubmit) {
                props.onClose && props.onClose()
            }
        } catch (error) {
            setSubmitInProgress(false)
            // Error already handled inside `executeFormViewSubmitAction`
        }
    }

    const handleSubmitWithContinue = async (values: Record<string, unknown>) => {
        const continueAfterSubmit = true
        await handleSubmitBase(values, continueAfterSubmit)
    }

    const handleSetSelectedTab = (tab: string) => {
        setSelectedTab(tab)
        setSelectedSubTab(null)
    }
    const handleSelectSubTab = (tab: string) => setSelectedSubTab(tab)

    const DEFAULT_TAB_NAME = Translation.translateKey('Perustiedot')
    const tabs: Tab[] =
        props?.formConfiguration?.Tabs.map((tab) => {
            const hasRequiredFields = checkIfTabHasRequiredFields(tab, fieldStates)
            const hasValidationErrors = checkIfTabFieldsHasValidationErrors(tab, validationErrors)

            if (!tab.Name) {
                return { name: DEFAULT_TAB_NAME, hasRequiredFields, hasValidationErrors }
            }
            return {
                name: tab.Name,
                hasRequiredFields,
                hasValidationErrors,
            }
        }) ?? []

    const visibleTab =
        props.formConfiguration?.Tabs.filter((tab) => {
            const tabName = tab.Name ?? DEFAULT_TAB_NAME
            return selectedTab ? tabName === selectedTab : tabName === DEFAULT_TAB_NAME
        }) ?? []

    const { height } = useScreenDimensions()

    const hasMoreThanOneItemInSomeRow = formHasMultipleFieldsInOneRow(props.formConfiguration)
    const width = hasMoreThanOneItemInSomeRow ? FORM_FULL_WIDTH : FORM_HALF_WIDTH

    return (
        <FormContainer height={height} width={width}>
            <Header title={props.formConfiguration?.Title} renderCancel={renderCancel} onClose={props.onClose} />
            <ContentContainer height={height}>
                {props.initializationFailed ? (
                    <FormViewFailedInitializationIndicator />
                ) : props.isLoading || !props.formConfiguration ? (
                    <FormViewLoadingIndicator />
                ) : (
                    <>
                        <Tabs
                            tabs={tabs}
                            selectedTab={selectedTab}
                            onSelectTab={handleSetSelectedTab}
                            userHasTriedSubmit={userHasTriedSubmit}
                        />
                        <FormContentContainer height={height} width={getFormContentMaxWidth(width)}>
                            {visibleTab.map((tab) => {
                                const fieldConfigurations: Record<string, IFormViewFieldConfigurationV2> = {}
                                const subTabs = tab.SubTabs.map((subTab) => subTab.Name) ?? []
                                const visibleSubTab =
                                    tab.SubTabs.find((subTab) =>
                                        selectedSubTab === null ? true : subTab.Name === selectedSubTab
                                    ) ?? null

                                visibleSubTab?.Fields.forEach((field) => set(fieldConfigurations, field.Id, field))

                                return (
                                    <div key={tab.Name || 'main'}>
                                        <SubTabs
                                            subTabs={subTabs}
                                            onSelectSubTab={handleSelectSubTab}
                                            selectedSubTab={selectedSubTab}
                                        />
                                        <FormViewSection
                                            isFormInitialValueSet={isInitialValueSet}
                                            key={tab.Name || 'main'}
                                            Name={tab.Name || ''}
                                            onFieldValueSet={setFieldTouched}
                                            FieldStates={fieldStates}
                                            FieldErrors={displayedErrors}
                                            FieldValues={props.fieldValues}
                                            FieldConfiguration={fieldConfigurations}
                                            FieldNotes={props.fieldNotes}
                                            FieldIds={visibleSubTab?.Fields.map((field) => field.Id) ?? []}
                                        />
                                    </div>
                                )
                            })}
                            <FormErrorAlert ShowError={anyErrorsShouldBeRendered} />
                        </FormContentContainer>
                    </>
                )}
            </ContentContainer>
            <Footer
                submitAndContinue={() => handleSubmitWithContinue(props.fieldValues)}
                submit={() => handleSubmitBase(props.fieldValues, false)}
                disableSubmit={disableSubmit}
                displayedErrors={displayedErrors}
                HasSaveAndContinue={props.formConfiguration?.HasSaveAndContinue}
                renderCancel={renderCancel}
                formId={props.formConfiguration?.Id}
                loading={submitInProgress}
                onClose={props.onClose}
            />
        </FormContainer>
    )
}

// Todo fix this old "Class style" of mapStateToProps and mapDispatchToProps
// This is a copy pasta from previous component.. needs a refactor..
const mapStateToProps = (state: RootState, { formConfiguration }: IOwnProps): IStateProps => {
    const initialFieldConfig = flatMap(formConfiguration?.Sections, 'Fields') as IFormViewFieldConfigurationV2[]

    const keys: Record<string, string> = {}

    initialFieldConfig.forEach((x) => set(keys, x.Id, x.ValuePickerId))

    const formFieldValues = selectValuePickerValues(state, keys)

    const currentValueSideEffects = selectValuePickersSideEffects(state, keys)

    const fieldNotes = selectValuePickersNotes(state, keys)
    return {
        fieldValues: formFieldValues,
        fieldValueSideEffects: currentValueSideEffects,
        fieldNotes: fieldNotes,
    }
}

const mapDispatchToProps = {
    executeFormViewSubmitAction: executeFormViewSubmitActionThunk,
    resetFormViewValuePickerValues: resetFormViewValuePickerValuesThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormViewBaseUnconnected)
