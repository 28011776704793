import moment from 'moment'
import { IDataSourceItem } from '@planier/data-source-types'

import { ISummaryLabel } from './SummaryLabels'
import { Moment } from 'moment'

/**
 * @todo create generic type library and move this EventModel there
 */
interface IEventModel extends IDataSourceItem {
    StartTime: Moment
    IsPublished: boolean
    JobTitle?: {
        Id: number
        Lyhenne: string | null
        Name: string
    }
    WorkUnit?: {
        Id: number
        Name: string
    }
    Info: string
    IsRecurringEvent: boolean
    ColorId: string | null
    HasEmployee: boolean
    EndTime: Moment
    EventClassId: number
    EventType: {
        Id: number
        Lyhenne: string
        Name: string
    }
    Skills: {
        Id: number
        Name: string
    }[]
    WorkTimeRuleViolations: string[]
    Employee: {
        Id: number
        Name: string
    } | null
}

interface IWorkUnitAttachmentModel extends IDataSourceItem {
    Name: string
    WorkUnitIdentifier: {
        Id: number
        Name: string
    }
}

interface IWorkUnitGroupModel extends IDataSourceItem {
    Name: string
}

interface IEmployeeAttachmentModel extends IDataSourceItem {
    Name: string
}

export const generateSummaryLabelsForItem = (dataSourceId: string, item: IDataSourceItem): ISummaryLabel[] => {
    if (dataSourceId === 'Event' && item) {
        const event = item as IEventModel
        const startTime = moment(event.StartTime)
        const endTime = moment(event.EndTime)
        const summaryLabels: ISummaryLabel[] = [
            { Icon: 'calendar_month', Text: startTime.format('D.M.YYYY') },
            { Icon: 'access_time', Text: `${startTime.format('HH:mm')} - ${endTime.format('HH:mm')}` },
            { Icon: 'account_circle', Text: event.JobTitle?.Name ?? '' },
            { Icon: 'domain', Text: event.WorkUnit?.Name ?? '' },
        ]

        return summaryLabels
    }

    if (dataSourceId === 'WorkUnitAttachment' && item) {
        const workUnitAttachment = item as IWorkUnitAttachmentModel
        const summaryLabels: ISummaryLabel[] = [
            { Icon: 'domain', Text: workUnitAttachment.WorkUnitIdentifier?.Name ?? '' },
            { Icon: 'attach_file', Text: workUnitAttachment.Name ?? '' },
        ]

        return summaryLabels
    }

    if (dataSourceId === 'WorkUnitGroup' && item) {
        const workUnitGroup = item as IWorkUnitGroupModel
        const summaryLabels: ISummaryLabel[] = [{ Icon: 'account_tree', Text: workUnitGroup.Name ?? '' }]

        return summaryLabels
    }

    if (dataSourceId === 'EmployeeAttachment' && item) {
        const employeeAttachment = item as IEmployeeAttachmentModel
        const summaryLabels: ISummaryLabel[] = [{ Icon: 'attach_file', Text: employeeAttachment.Name ?? '' }]

        return summaryLabels
    }

    return []
}
