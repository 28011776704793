import * as React from 'react'

import { SystemInternalLink, TooltipWithEllipsis } from '@planier/generic-components'

interface IEmployeeProps {
    tyontekija: { Nimi: string | null; Id: number | null } | null
    className?: string
}

const employeeCardUrlStart = 'BSP/employeeInfo.aspx?employeeID='
const windowParams = 'toolbar=yes,scrollbars=yes,resizable=yes,left=300,width=1200,height=900'

const linkProps = {
    display: 'inline' as const,
    noWrap: true,
    variant: 'bodyXS' as const,
}

const Employee: React.FC<IEmployeeProps> = ({ tyontekija, className }) => {
    if (tyontekija === null || tyontekija.Nimi === null) {
        return null
    }

    const { Nimi, Id } = tyontekija

    return (
        <TooltipWithEllipsis title={Nimi}>
            {(ref) => (
                <SystemInternalLink
                    className={className}
                    muiLinkProps={linkProps}
                    ref={ref}
                    to={`${employeeCardUrlStart}${Id}`}
                    windowParams={windowParams}
                >
                    {Nimi}
                </SystemInternalLink>
            )}
        </TooltipWithEllipsis>
    )
}

export default Employee
