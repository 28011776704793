import { JSX } from 'react'
import styled from '@emotion/styled'
import { ReadOnlyText } from './'
import { ITabItemProps } from '../form-view/Components/FormView/Tabs'
import { Translation } from '@planier/localization'
import { useAppTheme } from '@planier/theme'

interface ISubTabsProps {
    selectedSubTab: string | null
    subTabs: (string | null)[]
    onSelectSubTab: (subTab: string) => void
}

interface ISubTabItemProps extends ITabItemProps {}

const SubTabContainer = styled.div`
    margin-left: 32px;
    display: flex;
    flex-direction: row;
`

const SubTabField = styled.div<{ selected: boolean }>`
    display: flex;
    height: 48px;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: ${({ theme }) => `background-color ${theme.tokens.transitionQuick}`};
    border-bottom: 2px solid ${({ selected, theme }) => (selected ? theme.colors.primaryTeal120 : 'transparent')};

    background-color: ${({ selected, theme }) =>
        selected ? theme.colors.primaryCyan11 : theme.colors.neutralsWhite100};

    &:hover {
        background-color: ${({ theme }) => theme.colors.neutralsGrey11};
    }
`

const SubTabItem = ({ selected, text, onClick }: ISubTabItemProps): JSX.Element => {
    const { colors, componentExtensions } = useAppTheme()

    return (
        <>
            {text && (
                <SubTabField key={text} selected={!!selected} onClick={onClick}>
                    <ReadOnlyText
                        translate={Translation.has(text)}
                        usage="button"
                        rawColor={selected ? colors.primaryTeal120 : componentExtensions.text.disabledDarker}
                    >
                        {text}
                    </ReadOnlyText>
                </SubTabField>
            )}
        </>
    )
}

const SubTabs = ({ selectedSubTab, subTabs, onSelectSubTab }: ISubTabsProps): JSX.Element => {
    return (
        <SubTabContainer>
            {subTabs?.map((subTab, index) => {
                const handleOnSelectSubTab = () => {
                    subTab && onSelectSubTab(subTab)
                }

                const selected = subTab === selectedSubTab ? true : index === 0 && selectedSubTab === null

                return (
                    <SubTabItem key={subTab} selected={selected} text={subTab ?? ''} onClick={handleOnSelectSubTab} />
                )
            })}
        </SubTabContainer>
    )
}

export default SubTabs
