import styled from '@emotion/styled'

import { WeekDaySelection, RadioButton, ReadOnlyText } from '@planier/generic-components'
import SingleDayPicker from './SingleDayPicker'

import { Grid } from '@material-ui/core'

import { Moment } from 'moment'
import DropdownPicker from './DropdownPicker'

type EventRecurrenceTimeCategoryType = 'Day' | 'Week' | 'Month'

interface IRepetitiveEventParameters {
    StartDate?: Moment
    EndDate?: Moment
    EveryNth: number
    EventRecurrenceHolidayHandlingType: number
    EventRecurrenceTimeCategory: EventRecurrenceTimeCategoryType

    //Weekly properties
    DaysOfWeek: number[]

    //Monthly properties
    DayNumber: number
}

const defaultIRepetitiveEventParameters: IRepetitiveEventParameters = {
    EveryNth: 1,
    EventRecurrenceHolidayHandlingType: 1,
    EventRecurrenceTimeCategory: 'Day',
    DaysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    DayNumber: 1,
}

const GridWithSmallMargin = styled(Grid)`
    margin-right: 8px;
`

interface IRepetitiveEventProps {
    onChange: (value: IRepetitiveEventParameters) => void
    value: IRepetitiveEventParameters | null
    error?: string
}

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
`

const RadioButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 24px -9px;
`

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
`

const ColumnFlex1 = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const RadioButtonContainer = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:last-child) {
        padding-right: 47px;
    }
`

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryChampion11};
    padding-bottom: 24px;
    margin-top: 8px;
    padding-top: 24px;
    margin-left: -32px;
    padding-left: 32px;
    padding-right: 63px;
    margin-right: -32px;
`

const LabelText = styled(ReadOnlyText)`
    color: ${({ theme }) => theme.componentExtensions.text.primary};
`

const nthDayOptions = [
    {
        Id: 1,
        Label: 'joka päivä',
    },
    {
        Id: 2,
        Label: 'joka toinen päivä',
    },
    {
        Id: 3,
        Label: 'joka kolmas päivä',
    },
    {
        Id: 4,
        Label: 'joka neljäs päivä',
    },
    {
        Id: 5,
        Label: 'joka viides päivä',
    },
    {
        Id: 6,
        Label: 'joka kuudes päivä',
    },
]

const nthWeekOptions = [
    {
        Id: 1,
        Label: 'joka viikko',
    },
    {
        Id: 2,
        Label: 'joka toinen viikko',
    },
    {
        Id: 3,
        Label: 'joka kolmas viikko',
    },
    {
        Id: 4,
        Label: 'joka neljäs viikko',
    },
    {
        Id: 5,
        Label: 'joka viides viikko',
    },
    {
        Id: 6,
        Label: 'joka kuudes viikko',
    },
]

const nthMonthOptions = [
    {
        Id: 1,
        Label: 'joka kuukausi',
    },
    {
        Id: 2,
        Label: 'joka toinen kuukausi',
    },
    {
        Id: 3,
        Label: 'joka kolmas kuukausi',
    },
    {
        Id: 4,
        Label: 'joka neljäs kuukausi',
    },
    {
        Id: 5,
        Label: 'joka viides kuukausi',
    },
    {
        Id: 6,
        Label: 'joka kuudes kuukausi',
    },
]

const nthDayInMonthOptions: { Id: number; Label: string }[] = [
    ...Array.from(Array(31).keys()).map((x) => {
        return {
            Id: x + 1,
            Label: `${x + 1}. päivä`,
        }
    }),
    {
        Id: -1,
        Label: 'viimeinen päivä',
    },
]

const EventRecurrenceHolidayHandlingTypeOptions = [
    {
        Id: 1,
        Label: 'Lisää tapahtuma',
    },
    {
        Id: 2,
        Label: 'Älä lisää tapahtumaa',
    },
    {
        Id: 4,
        Label: 'Lisää tapahtuma seuraavalle arkipäivälle',
    },
    {
        Id: 3,
        Label: 'Lisää tapahtuma edelliselle arkipäivälle',
    },
]

const generateDescriptionText = (value: IRepetitiveEventParameters) => {
    let repetitionText = ''

    switch (value.EventRecurrenceTimeCategory) {
        case 'Day':
            repetitionText = nthDayOptions.find((x) => x.Id === value.EveryNth)?.Label ?? ''
            break

        case 'Week':
            repetitionText = nthWeekOptions.find((x) => x.Id === value.EveryNth)?.Label ?? ''
            break

        case 'Month':
            repetitionText = nthMonthOptions.find((x) => x.Id === value.EveryNth)?.Label ?? ''
            break

        default:
            break
    }

    const startText = value.StartDate ? `aloitetaan ${value.StartDate.format('DD.MM.YYYY')},` : ''
    const endText = value.EndDate ? `päättyy ${value.EndDate.format('DD.MM.YYYY')},` : 'ei päättymispäivää'

    return `Toistuu ${repetitionText}, ${startText} ${endText}`
}

const RepetitiveEvent: React.FC<IRepetitiveEventProps> = ({ value, onChange, error }) => {
    const nonNullValue = value ? value : { ...defaultIRepetitiveEventParameters }

    const isDailyMode = nonNullValue.EventRecurrenceTimeCategory === 'Day'

    const setDailyMode = () => {
        const newValue = { ...nonNullValue, EventRecurrenceTimeCategory: 'Day' }

        onChange(newValue)
    }

    const isWeeklyMode = nonNullValue.EventRecurrenceTimeCategory === 'Week'

    const setWeeklyMode = () => {
        const newValue = { ...nonNullValue, EventRecurrenceTimeCategory: 'Week' }
        onChange(newValue)
    }

    const isMonthlyMode = nonNullValue.EventRecurrenceTimeCategory === 'Month'

    const setMonthlyMode = () => {
        const newValue = { ...nonNullValue, EventRecurrenceTimeCategory: 'Month' }
        onChange(newValue)
    }

    const startDayValue = nonNullValue.StartDate

    const onStartDayChange = (newStartDay: Moment) => {
        const newValue = { ...nonNullValue, StartDate: newStartDay }
        onChange(newValue)
    }

    const endDayValue = nonNullValue.EndDate
    const onEndDayChange = (newEndDay: Moment) => {
        const newValue = { ...nonNullValue, EndDate: newEndDay }
        onChange(newValue)
    }

    const nthDay = nonNullValue.EveryNth
    const nthWeek = nonNullValue.EveryNth
    const nthMonth = nonNullValue.EveryNth

    const onNthDayChange = (newNthValue: number) => {
        const newValue = { ...nonNullValue, EveryNth: newNthValue }
        onChange(newValue)
    }

    const weekdays = new Set(nonNullValue.DaysOfWeek)

    const onWeekDayClick = (isoDayIndex: number) => {
        const copiedValueSet = new Set(nonNullValue.DaysOfWeek)
        copiedValueSet.has(isoDayIndex) ? copiedValueSet.delete(isoDayIndex) : copiedValueSet.add(isoDayIndex)

        const newValue = { ...nonNullValue, DaysOfWeek: [...copiedValueSet] }
        onChange(newValue)
    }

    const dayNumber = nonNullValue.DayNumber

    const onDayNumberChange = (newDayNumber: number) => {
        const newValue = { ...nonNullValue, DayNumber: newDayNumber }
        onChange(newValue)
    }

    const EventRecurrenceHolidayHandlingType = nonNullValue.EventRecurrenceHolidayHandlingType

    const onEventRecurrenceHolidayHandlingTypeChange = (newType: number) => {
        const newValue = { ...nonNullValue, EventRecurrenceHolidayHandlingType: newType }
        onChange(newValue)
    }

    const descriptionText = generateDescriptionText(nonNullValue)

    return (
        <Container>
            <LabelText usage="h4">Toistuvuus</LabelText>
            <RadioButtonRow>
                <RadioButtonContainer>
                    <RadioButton checked={isDailyMode} onValueSet={setDailyMode} label="Päivittäin" />
                </RadioButtonContainer>
                <RadioButtonContainer>
                    <RadioButton checked={isWeeklyMode} onValueSet={setWeeklyMode} label="Viikoittain" />
                </RadioButtonContainer>
                <RadioButtonContainer>
                    <RadioButton checked={isMonthlyMode} onValueSet={setMonthlyMode} label="Kuukausittain" />
                </RadioButtonContainer>
            </RadioButtonRow>
            <RowContainer>
                <ColumnFlex1>
                    <SingleDayPicker
                        errors={error}
                        onChange={onStartDayChange}
                        value={startDayValue}
                        label={'Alkamispäivä'}
                    />
                </ColumnFlex1>
                <ColumnFlex1>
                    <SingleDayPicker onChange={onEndDayChange} value={endDayValue} label={'Päättymispäivä'} />
                </ColumnFlex1>
            </RowContainer>
            {isDailyMode ? (
                <RowContainer>
                    <ColumnFlex1>
                        <DropdownPicker
                            value={nthDay}
                            options={nthDayOptions}
                            onChange={onNthDayChange}
                            label={'Toistuu'}
                        />
                    </ColumnFlex1>
                </RowContainer>
            ) : null}
            {isWeeklyMode ? (
                <>
                    <ColumnContainer>
                        <DropdownPicker
                            value={nthWeek}
                            options={nthWeekOptions}
                            onChange={onNthDayChange}
                            label={'Toistuu'}
                        />
                    </ColumnContainer>
                    <ColumnContainer>
                        <WeekDaySelection selectedWeekDays={new Set<number>(weekdays)} onChange={onWeekDayClick} />
                    </ColumnContainer>
                </>
            ) : null}
            {isMonthlyMode ? (
                <RowContainer>
                    <ColumnFlex1>
                        <DropdownPicker
                            width={140}
                            value={nthMonth}
                            options={nthMonthOptions}
                            onChange={onNthDayChange}
                            label={'Toistuu'}
                        />
                    </ColumnFlex1>
                    <ColumnFlex1>
                        <DropdownPicker
                            width={140}
                            value={dayNumber}
                            options={nthDayInMonthOptions}
                            onChange={onDayNumberChange}
                            label={'Ajankohta'}
                        />
                    </ColumnFlex1>
                </RowContainer>
            ) : null}

            <RowContainer>
                <GridWithSmallMargin item>
                    <DropdownPicker
                        value={EventRecurrenceHolidayHandlingType}
                        options={EventRecurrenceHolidayHandlingTypeOptions}
                        onChange={onEventRecurrenceHolidayHandlingTypeChange}
                        label={'Lakisääteisten juhlapäivien käsittely'}
                    />
                </GridWithSmallMargin>
            </RowContainer>
            <ReadOnlyText fontWeight={'bold'} usage="bodyXS">
                {descriptionText}
            </ReadOnlyText>
        </Container>
    )
}

export default RepetitiveEvent

export { IRepetitiveEventParameters }
