import makeRequest, { ERequestMethod } from '@planier/rest-api'
import { VIEW_ENGINE_URL } from '@planier/data-source'
import tarvenakymaConfiguration from '../tmp/TarvenakymaCalendarConfiguration'
import tyontekijanakymaConfiguration from '../tmp/TyontekijanakymaCalendarConfiguration'
import IConfigurableCalendarConfiguration from '../Types/IConfigurableCalendarConfiguration'

export const queryCalendarConfiguration = async (
    calendarId: string
): Promise<IConfigurableCalendarConfiguration | null> => {
    if (calendarId === 'TarvenakymaCalendar') {
        return tarvenakymaConfiguration
    } else if (calendarId === 'TyontekijanakymaCalendar') {
        return tyontekijanakymaConfiguration
    }

    const response = await makeRequest<IConfigurableCalendarConfiguration | null>({
        method: ERequestMethod.GET,
        url: `${VIEW_ENGINE_URL}/Calendar?id=${calendarId}`,
    })

    return response
}
