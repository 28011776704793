import * as React from 'react'
import { FormikProps } from 'formik'

import { EDataSourceActionCrudType } from '@planier/data-source'
import { TranslatedButton } from '@planier/generic-components'
import { getLogger } from '@planier/log'
import { isEmpty } from 'lodash-es'

const Log = getLogger('form-view.SaveAndContinueButton')

interface ISaveAndContinueButtonProps {
    formSubmitActionCrudType: EDataSourceActionCrudType | null
    onClick: () => void
    formErrors: FormikProps<any>['errors'] | string[]
    disabled?: boolean
}

const SaveAndContinueButton: React.FC<ISaveAndContinueButtonProps> = ({
    formSubmitActionCrudType,
    onClick,
    formErrors,
    disabled,
}) => {
    if (formSubmitActionCrudType !== EDataSourceActionCrudType.Create) {
        Log.error("Can't save and continue for other than creating items")
    }

    const hasErrors = !isEmpty(formErrors)

    return (
        <TranslatedButton disabled={hasErrors || disabled} onClick={onClick}>
            form-view.SaveAndContinueButton.Create
        </TranslatedButton>
    )
}

export default SaveAndContinueButton
