import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { RootState } from 'typesafe-actions'

import {
    queryCalendarDataThunk,
    registerDateHandlerForCalendarsDataSourceThunk,
} from '../Thunks/ConfigurableCalendarThunks'
import { EValuePickerType, initializeValuePickersAction } from '@planier/value-picker'
import {
    selectAreAllValuePickersInitializedForCalendarDataSources,
    selectCalendarDates,
    selectCalendarsDataSourcesAreInitialized,
} from '../State/ConfigurableCalendarSelectors'
import { selectCurrentPage } from '@planier/navigation/State/NavigationSelectors'

// passing the default values for the date range picker configurations is useless since we don't
// do anything with it and instead pass the value directly. However, it's better to keep it
// typed as required (because this is a very rare use case) so we'll just pass null values
const dateRangeDefaultValuesMock = {
    StartFromCurrentDay: null,
    StartFromCurrentDayUnit: null,
    EndFromCurrentDay: null,
    EndFromCurrentDayUnit: null,
}

// TODO: these must be changed to be something more dynamic,
const initialDatesValuePickerId = 'CalendarInitialDates'
const calendarDatesValuePickerId = 'CalendarDatePicker'

interface IOptions {
    shouldInitializeData?: boolean
    shouldFetchData?: boolean
}

type TInitialDate = string | null | undefined

interface IUseDatesForCalendarReturn {
    initializeDatesToStore: (startDate: TInitialDate, endDate: TInitialDate) => void
    fetchData: () => void
    getIsReadyToFetchData: () => boolean
}

interface IInitialDates {
    initialStartDate: TInitialDate
    initialEndDate: TInitialDate
}

/**
 * Handles the start and end date for calendars having dates  as its headers.
 * Whenever either the start and end date changes (and when they're initially set),
 * fetches the data.
 *
 * Alternatively, using the `isDataFetched` and/or `isDataInitialized` parameters you can
 * control whether it initializes and fetches the data. With the return values of the hook
 * you can handle them from elsewhere if you wish.
 */
const useDatesForCalendar = (
    calendarIds: string[] | string,
    initialDates: IInitialDates,
    { shouldFetchData = true, shouldInitializeData = true }: IOptions = {}
): IUseDatesForCalendarReturn => {
    const dispatch = useDispatch()
    const currentDates = useSelector(selectCalendarDates)
    const page = useSelector(selectCurrentPage)

    const areCalendarsDataSourcesInitialized = useSelector<RootState>((state) =>
        selectCalendarsDataSourcesAreInitialized(state, Array.isArray(calendarIds) ? calendarIds : [calendarIds])
    )

    const areCalendarValuePickersInitializedForDataSources = useSelector((state: RootState) =>
        selectAreAllValuePickersInitializedForCalendarDataSources(
            state,
            Array.isArray(calendarIds) ? calendarIds : [calendarIds]
        )
    )

    const initializeDatesToStore: IUseDatesForCalendarReturn['initializeDatesToStore'] = useCallback(
        (startDate, endDate) => {
            if (!areCalendarsDataSourcesInitialized || !areCalendarValuePickersInitializedForDataSources) {
                return
            }

            const dates = {
                start: startDate ? moment(startDate) : null,
                end: endDate ? moment(endDate) : null,
            }

            dispatch(
                initializeValuePickersAction({
                    page,
                    valuePickers: [
                        {
                            configuration: {
                                DependsOn: [],
                                DataRequestParameterName: 'AikavaliAlkuaika',
                                ValuePickerType: EValuePickerType.DateRange,
                                ValuePickerId: initialDatesValuePickerId,
                                DefaultValues: dateRangeDefaultValuesMock,
                            },
                            value: dates,
                        },
                    ],
                })
            )

            dispatch(
                initializeValuePickersAction({
                    page,
                    valuePickers: [
                        {
                            configuration: {
                                DependsOn: [],
                                DataRequestParameterName: 'AikavaliAlkuaika',
                                ValuePickerType: EValuePickerType.DateRange,
                                ValuePickerId: calendarDatesValuePickerId,
                                DefaultValues: dateRangeDefaultValuesMock,
                            },
                            value: dates,
                        },
                    ],
                })
            )

            dispatch(
                registerDateHandlerForCalendarsDataSourceThunk(Array.isArray(calendarIds) ? calendarIds : [calendarIds])
            )
        },
        [
            areCalendarValuePickersInitializedForDataSources,
            areCalendarsDataSourcesInitialized,
            calendarIds,
            dispatch,
            page,
        ]
    )

    useEffect(() => {
        if (!shouldInitializeData) {
            return
        }

        const { initialStartDate, initialEndDate } = initialDates

        initializeDatesToStore(initialStartDate, initialEndDate)
    }, [initializeDatesToStore, shouldInitializeData, initialDates])

    const fetchData: IUseDatesForCalendarReturn['fetchData'] = useCallback(() => {
        const calendarIdsAsArray = Array.isArray(calendarIds) ? calendarIds : [calendarIds]

        calendarIdsAsArray.forEach((calendarId) => {
            dispatch(queryCalendarDataThunk(calendarId))
        })
    }, [calendarIds, dispatch])

    const getIsReadyToFetchData: IUseDatesForCalendarReturn['getIsReadyToFetchData'] = useCallback(() => {
        return Boolean(currentDates && areCalendarValuePickersInitializedForDataSources)
    }, [areCalendarValuePickersInitializedForDataSources, currentDates])

    useEffect(() => {
        if (!shouldFetchData || !getIsReadyToFetchData()) {
            return
        }

        fetchData()
    }, [fetchData, getIsReadyToFetchData, shouldFetchData])

    return {
        initializeDatesToStore,
        fetchData,
        getIsReadyToFetchData,
    }
}

export default useDatesForCalendar
