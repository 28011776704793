import * as React from 'react'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import TooMuchDataWarning from '../TooMuchDataWarning'
import { BoundThunk } from '@planier/generic-state'
import {
    IDataSourcePropertyModel,
    selectDataItemIds,
    selectDataSourceDataStatus,
    selectDataSourceGroupData,
    selectDataSourceItems,
    selectDataSourcePropertiesConfiguration,
    selectIsDataSourceDataFetched,
} from '@planier/data-source'
import { EViewDataStatus, IDataSourceItem, TDataSourceItemId } from '@planier/data-source-types'
import { VALUE_PICKER_MODAL_ID, ValuePickerModal } from '@planier/modal-components'
import {
    initializeCalendarThunk,
    queryCalendarDataThunk,
    reInitializeCalendarDataThunk,
    setEmptyCellNodesThunk,
    updateCalendarNodeSelectionsThunk,
} from '../../Thunks/ConfigurableCalendarThunks'
import {
    selectCalendarCrossAxis,
    selectCalendarDataSourceId,
    selectCalendarDisplayMode,
    selectCalendarGranularity,
    selectCalendarHeaderBars,
    selectGroupNodeSearchFilter,
    selectNodeTypes,
} from '../../State/ConfigurableCalendarSelectors'
import CalendarBase from '../CalendarBase'
import ICalendarHeaderBar from '../../Types/ICalendarHeaderBar'
import IMainAxisHeader from '../../Types/IMainAxisHeader'
import ICalendarMainData from '../../Types/ICalendarMainData'
import { constructMainAxisData, constructMainAxisHeaders } from '../../Utilities/CalendarUtilities'
import ICalendarNodeType from '../../Types/ICalendarNodeType'
import ICalendarGranularity from '../../Types/ICalendarGranularity'
import ICalendarCrossAxis from '../../Types/ICalendarCrossAxis'
import ICalendarDataSourceGroupData from '../../Types/ICalendarDataSourceGroupData'
import ECalendarDisplayMode from '../../Constants/ECalendarDisplayMode'
import CalendarLoading from '../CalendarLoading'
import { selectCalendarDates, selectCalendarInitialDates } from './../../State/ConfigurableCalendarSelectors'
import { IDateRange } from '@planier/value-picker'
import ICalendarQuickActions from '../../Types/ICalendarQuickActions'
import IPublicHoliday from '../../Types/IPublicHoliday'
import { flatten } from 'lodash-es'

export interface IOwnProps {
    calendarId: string
    dependentValue?: unknown
    hiddenValuePickerIds?: string[]
    onMainAxisDataChange?: (mainAxisData: ICalendarMainData[]) => void
    quickActions?: ICalendarQuickActions | null
    horizontalScrollbarHeight?: number
    setRefForElementForHorizontalScrollbar?: (element: HTMLDivElement) => void
    isDataFetchedInInitialization?: boolean
    areCalendarFiltersToBeDisplayed?: boolean
    publicHolidays: IPublicHoliday[]
    areGroupsGrouped?: boolean
    highlightDatesOutsideInitialDates: boolean
}

interface IStateProps {
    crossAxis: ICalendarCrossAxis | null
    dataSourceDataFetched: boolean
    dataSourceItems: IDataSourceItem[]
    dataSourceProperties: IDataSourcePropertyModel[]
    displayMode: ECalendarDisplayMode | null
    granularity: ICalendarGranularity | null
    groupData: ICalendarDataSourceGroupData[]
    headerBars: ICalendarHeaderBar[]
    itemIds: TDataSourceItemId[]
    nodeTypes: ICalendarNodeType[]
    dateRange: IDateRange | null
    initialDates: IDateRange
    dataStatus: EViewDataStatus | null
    groupDataNodeSearchFilter: string
}

interface IDispatchProps {
    reInitializeCalendarData: BoundThunk<typeof reInitializeCalendarDataThunk>
    initializeCalendar: BoundThunk<typeof initializeCalendarThunk>
    queryCalendarData: BoundThunk<typeof queryCalendarDataThunk>
    updateCalendarNodeSelections: BoundThunk<typeof updateCalendarNodeSelectionsThunk>
    setEmptyCellNodes: BoundThunk<typeof setEmptyCellNodesThunk>
}

export interface IConfigurableCalendarProps extends IOwnProps, IStateProps, IDispatchProps {}

/**
 * Component that calculates the positions of elements and passes elements' data to CalendarBase.
 */
export const ConfigurableCalendarUnconnected: React.FunctionComponent<IConfigurableCalendarProps> = ({
    calendarId,
    crossAxis,
    dataSourceDataFetched,
    dataSourceItems,
    dataSourceProperties,
    displayMode,
    granularity,
    groupData,
    headerBars,
    hiddenValuePickerIds,
    initializeCalendar,
    reInitializeCalendarData,
    itemIds,
    nodeTypes,
    onMainAxisDataChange,
    queryCalendarData,
    updateCalendarNodeSelections,
    dateRange,
    initialDates,
    horizontalScrollbarHeight,
    setRefForElementForHorizontalScrollbar,
    quickActions = null,
    isDataFetchedInInitialization = true,
    areCalendarFiltersToBeDisplayed = true,
    areGroupsGrouped = false,
    dataStatus,
    publicHolidays,
    groupDataNodeSearchFilter,
    highlightDatesOutsideInitialDates,
    setEmptyCellNodes,
}) => {
    const [mainAxisData, setMainAxisData] = useState<ICalendarMainData[]>([])
    // Ordered array of headers
    const [mainAxisHeaders, setMainAxisHeaders] = useState<IMainAxisHeader[]>([])
    const [columnAmount, setColumnAmount] = useState<number>(0)

    useEffect(() => {
        initializeCalendar(calendarId)
    }, [calendarId, initializeCalendar, isDataFetchedInInitialization])

    useEffect(() => {
        reInitializeCalendarData(calendarId)
    }, [calendarId, crossAxis, reInitializeCalendarData])

    useEffect(() => {
        if (!dateRange || !crossAxis || !granularity || !displayMode) {
            return
        }

        const { start, end } = dateRange

        const mainData = constructMainAxisData(
            dataSourceItems,
            dataSourceProperties,
            groupData,
            crossAxis,
            nodeTypes,
            granularity,
            displayMode,
            start,
            end,
            areGroupsGrouped,
            groupDataNodeSearchFilter
        )

        const emptyCellNodes = flatten(mainData.map((x) => x.nodes.filter((y) => y.isEmptyCell))).map((x) => {
            return {
                Id: x.id as string,
                StartTime: x.date,
                ...x.props,
            }
        })

        setEmptyCellNodes(calendarId, emptyCellNodes)

        onMainAxisDataChange && onMainAxisDataChange(mainData)

        setMainAxisData(mainData)
    }, [
        calendarId,
        crossAxis,
        dataSourceProperties,
        displayMode,
        granularity,
        groupData,
        nodeTypes,
        onMainAxisDataChange,
        setEmptyCellNodes,
        dataSourceItems,
        dateRange,
        areGroupsGrouped,
        groupDataNodeSearchFilter,
    ])

    useEffect(() => {
        updateCalendarNodeSelections(calendarId)
    }, [calendarId, itemIds, updateCalendarNodeSelections])

    useEffect(() => {
        if (!dateRange) {
            return
        }

        const { start, end } = dateRange

        const { start: initialStart, end: initialEnd } = highlightDatesOutsideInitialDates ? initialDates : dateRange

        const headers = constructMainAxisHeaders(start, end, initialStart, initialEnd, publicHolidays)

        setMainAxisHeaders(headers)

        // The lowest (base) header level's node amount is always equal to the amount of cross axes.
        if (headers.length === 0) {
            setColumnAmount(0)
        } else {
            const {
                nodes: { beforeInitialNodes, initialNodes, afterInitialNodes },
            } = headers[0]
            const totalNodesAmount = beforeInitialNodes.length + initialNodes.length + afterInitialNodes.length

            setColumnAmount(totalNodesAmount)
        }
    }, [calendarId, dateRange, headerBars, highlightDatesOutsideInitialDates, initialDates, publicHolidays])

    const handleFiltersChange = () => {
        queryCalendarData(calendarId)
    }

    if (dataStatus === EViewDataStatus.LimitExceeded) {
        return <TooMuchDataWarning />
    }

    if (!dataSourceDataFetched) {
        return <CalendarLoading />
    }

    return (
        <React.Fragment>
            <CalendarBase
                areCalendarFiltersToBeDisplayed={areCalendarFiltersToBeDisplayed}
                calendarId={calendarId}
                columnAmount={columnAmount}
                headers={mainAxisHeaders}
                hiddenValuePickerIds={hiddenValuePickerIds}
                horizontalScrollbarHeight={horizontalScrollbarHeight}
                mainAxisData={mainAxisData}
                quickActions={quickActions}
                setRefForElementForHorizontalScrollbar={setRefForElementForHorizontalScrollbar}
            />

            {areCalendarFiltersToBeDisplayed && (
                <ValuePickerModal modalId={calendarId + VALUE_PICKER_MODAL_ID} onConfirm={handleFiltersChange} />
            )}
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState, { calendarId }: IOwnProps): IStateProps => {
    const dataSourceId = selectCalendarDataSourceId(state, calendarId)

    return {
        crossAxis: selectCalendarCrossAxis(state, calendarId),
        dataSourceDataFetched: selectIsDataSourceDataFetched(state, dataSourceId),
        dataSourceItems: selectDataSourceItems(state, dataSourceId),
        dataSourceProperties: selectDataSourcePropertiesConfiguration(state, dataSourceId),
        displayMode: selectCalendarDisplayMode(state, calendarId),
        granularity: selectCalendarGranularity(state, calendarId),
        groupData: selectDataSourceGroupData(state, dataSourceId) as ICalendarDataSourceGroupData[],
        headerBars: selectCalendarHeaderBars(state, calendarId),
        itemIds: selectDataItemIds(state, dataSourceId),
        nodeTypes: selectNodeTypes(state, calendarId),
        dateRange: selectCalendarDates(state),
        initialDates: selectCalendarInitialDates(state),
        dataStatus: selectDataSourceDataStatus(state, dataSourceId),
        groupDataNodeSearchFilter: selectGroupNodeSearchFilter(state, calendarId),
    }
}

const mapDispatchToProps = {
    initializeCalendar: initializeCalendarThunk,
    queryCalendarData: queryCalendarDataThunk,
    updateCalendarNodeSelections: updateCalendarNodeSelectionsThunk,
    setEmptyCellNodes: setEmptyCellNodesThunk,
    reInitializeCalendarData: reInitializeCalendarDataThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurableCalendarUnconnected)
