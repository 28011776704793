import * as React from 'react'

import StyledDatepicker, { IStyledDatepickerProps } from './StyledDatepicker'
import { Translate } from '@planier/localization'

export interface ITranslatedDatepickerProps extends IStyledDatepickerProps {}

const TranslatedDatepicker: React.FunctionComponent<ITranslatedDatepickerProps> = (props) => (
    <Translate translate={{}}>{(translated) => <StyledDatepicker {...translated} {...props} />}</Translate>
)

export default TranslatedDatepicker
