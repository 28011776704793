import * as React from 'react'

import { ListElementText } from '@planier/configurable-list-components'

export interface IMonetaryAmountProps {
    amount: number
}

const monetaryUnit = '€'

/**
 * For displaying preformatted monetary amounts. If the amount is 0, only
 * a hyphen is rendered.
 */
const MonetaryAmount: React.FunctionComponent<IMonetaryAmountProps> = ({ amount }) => {
    return amount === 0 ? (
        <React.Fragment>-</React.Fragment>
    ) : (
        <ListElementText displayValue={`${amount} ${monetaryUnit}`} />
    )
}

export default MonetaryAmount
