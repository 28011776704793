/* eslint-disable no-restricted-syntax */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Moment } from 'moment'
import { IEvent } from 'packages/event'
import { getEventsForEmployee, getEventTypeCategories } from './EmployeeCalendarThunks'
import { IIdentifier } from 'packages/event/Types/IEvent'
import { Cancel } from 'axios'

export interface IEmployeeCalendarState {
    events: IEvent[]
    selectedEvents: IEvent[]
    isLoadingEvents: boolean
    isLoadingCategories: boolean
    eventTypeCategories: IIdentifier[]
    selectedDays: Moment[]
    hoveredDay: Moment | undefined
    selectedMonth: Moment | undefined
}

export const initialState = <IEmployeeCalendarState>{
    events: [],
    selectedEvents: [],
    isLoadingEvents: false,
    isLoadingCategories: false,
    eventTypeCategories: [],
    selectedDays: [],
    hoveredDay: undefined,
    selectedMonth: undefined,
}

const employeeCalendarSlice = createSlice({
    name: 'employeeCalendar',
    initialState,
    reducers: {
        setSelectDays: (state, { payload }: PayloadAction<Moment[]>) => {
            state.selectedDays = payload
            state.selectedEvents = []
        },
        setHoveredDay: (state, { payload }: PayloadAction<Moment>) => {
            state.hoveredDay = payload
        },
        modalClosed: (state) => {
            state.selectedDays = []
            state.events = []
            state.selectedEvents = []
        },
        setSelectedMonth: (state, { payload }: PayloadAction<Moment>) => {
            state.selectedMonth = payload
        },
        setSelectedEvents: (state, { payload }: PayloadAction<IEvent[]>) => {
            state.selectedEvents = payload
        },
        toggleSelectedEvent: (state, { payload }: PayloadAction<IEvent>) => {
            const isSelected = state.selectedEvents.some(({ Id }) => Id === payload.Id)

            if (isSelected) {
                state.selectedEvents = state.selectedEvents.filter(({ Id }) => Id !== payload.Id)
                return
            }

            state.selectedEvents = [...state.selectedEvents, payload]
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEventsForEmployee.pending, (state) => {
            state.isLoadingEvents = true
        }),
            builder.addCase(getEventsForEmployee.fulfilled, (state, { payload }) => {
                state.events = payload
                state.isLoadingEvents = false
            }),
            builder.addCase(getEventsForEmployee.rejected, (state, { payload }) => {
                // stay in loading mode if user navigated to another month
                if ((payload as Cancel)?.message !== 'canceled by user') {
                    state.isLoadingEvents = false
                }
            }),
            builder.addCase(getEventTypeCategories.pending, (state) => {
                state.isLoadingCategories = true
            }),
            builder.addCase(getEventTypeCategories.fulfilled, (state, { payload }) => {
                state.isLoadingCategories = false
                state.eventTypeCategories = payload
            }),
            builder.addCase(getEventTypeCategories.rejected, (state) => {
                state.isLoadingCategories = false
            })
    },
})

export const { setSelectDays, setHoveredDay, modalClosed, setSelectedMonth, setSelectedEvents, toggleSelectedEvent } =
    employeeCalendarSlice.actions

export default employeeCalendarSlice.reducer
