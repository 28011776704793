/* eslint-disable no-restricted-syntax */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import IMessageThread, { EMessageType, IEmployeeIdentifier } from '../Types/IMessageThread'
import IMessagingState from '../Types/IMessagingState'
import {
    addCommentToEvent,
    getCommentsForSelectedItemIds,
    getMessagesForThread,
    getMessageThreadsForEmployee,
    replyToMessageThread,
    startNewMessageThread,
    updateMessageThreadIsReadStatusById,
} from './MessagingThunks'
import { IMessagingDataSourceItem } from '../Types/IMessagingDataSourceItem'
import { isEmpty, isNil, uniqBy } from 'lodash-es'
import { IEmployeeDataSourceItem } from '../Types/IEmployeeDataSourceItem'
import { IEvent } from 'packages/event'
import { isSelectedIdsMessages } from '../Utils/messageUtils'

const defaultAsyncState = {
    data: [],
    error: undefined,
    isFetching: false,
}

export const initialState = <IMessagingState>{
    showMessageThread: false,
    viewedMessageThread: undefined,
    threadMessages: defaultAsyncState,
    showNewMessageForm: false,
    showNewCommentForm: false,
    replyMessageValue: '',
    messageThreads: defaultAsyncState,
    commentThreads: defaultAsyncState,
    replyToThread: defaultAsyncState,
    selectedIds: undefined,
    selectedEvents: [],
    threadInitialized: false,
    selectedRecipients: [],
    newMessageForm: {
        message: undefined,
        title: undefined,
        messageType: EMessageType.MobileMessage,
        sendEmail: false,
        isPosting: false,
    },
    selectedTab: undefined,
    newCommentForm: {
        message: undefined,
        isVisibleForEmployee: false,
        isVisibleForExternalUser: false,
        messageType: EMessageType.Comment,
        isPosting: false,
    },
}

const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {
        setShowMessageThread: (state, { payload }: PayloadAction<boolean>) => {
            state.showMessageThread = payload
        },
        setShowNewMessageForm: (state, { payload }: PayloadAction<boolean>) => {
            state.showNewMessageForm = payload
        },
        setShowNewCommentForm: (state, { payload }: PayloadAction<boolean>) => {
            state.showNewCommentForm = payload
        },
        setReplyMessageValue: (state, { payload }: PayloadAction<string>) => {
            state.replyMessageValue = payload
        },
        setViewedMessageThread: (state, { payload }: PayloadAction<IMessageThread>) => {
            state.viewedMessageThread = payload
        },
        setSelectedIds: (
            state,
            { payload }: PayloadAction<IEmployeeDataSourceItem[] | IMessagingDataSourceItem[] | IEvent[]>
        ) => {
            if (!isEmpty(payload) && isSelectedIdsMessages(payload[0])) {
                state.selectedIds = (payload as IMessagingDataSourceItem[]).filter(
                    ({ EmployeeId }) => !isNil(EmployeeId)
                )
            }

            state.selectedIds = payload
        },
        setSelectedEvents: (state, { payload }: PayloadAction<IEvent[]>) => {
            state.selectedEvents = payload
        },
        setThreadInitialized: (state) => {
            state.threadInitialized = true
        },
        setSelectedRecipients: (state, { payload }: PayloadAction<IEmployeeIdentifier[]>) => {
            state.selectedRecipients = uniqBy(payload, 'Id').filter(({ Id }) => !isNil(Id))
        },
        newMessageFormMessageChanged: (state, { payload }: PayloadAction<string>) => {
            state.newMessageForm.message = payload
        },
        newMessageFormTitleChanged: (state, { payload }: PayloadAction<string>) => {
            state.newMessageForm.title = payload
        },
        newMessageFormMessageTypeChanged: (state, { payload }: PayloadAction<EMessageType>) => {
            state.newMessageForm.messageType = payload
        },
        newMessageFormSendEmailChanged: (state, { payload }: PayloadAction<boolean>) => {
            state.newMessageForm.sendEmail = payload
        },
        tabSelected: (state, { payload }: PayloadAction<string | undefined>) => {
            state.selectedTab = payload
        },
        modalClosed: (state) => {
            state.newCommentForm = initialState.newCommentForm
            state.newMessageForm = initialState.newMessageForm
            state.commentThreads = initialState.commentThreads
            state.messageThreads = initialState.messageThreads
            state.threadMessages = initialState.threadMessages
            state.selectedRecipients = initialState.selectedRecipients
            state.selectedEvents = initialState.selectedEvents
            state.replyMessageValue = ''
            state.showMessageThread = false
            state.showNewCommentForm = false
            state.showNewMessageForm = false
            state.selectedIds = undefined
            state.threadInitialized = false
            state.viewedMessageThread = undefined
        },
        newCommentFormMessageChanged: (state, { payload }: PayloadAction<string>) => {
            state.newCommentForm.message = payload
        },
        newCommentFormVisibilityForEmployeeChanged: (state, { payload }: PayloadAction<boolean>) => {
            state.newCommentForm.isVisibleForEmployee = payload
        },
        newCommentFormVisibilityForExternalUserChanged: (state, { payload }: PayloadAction<boolean>) => {
            state.newCommentForm.isVisibleForExternalUser = payload
        },
        resetThreadMessages: (state) => {
            state.threadMessages = initialState.threadMessages
        },
        resetViewedMessageThread: (state) => {
            state.viewedMessageThread = undefined
        },
    },
    extraReducers: (builder) => {
        // get message threads for employee
        builder.addCase(getMessageThreadsForEmployee.pending, (state) => {
            state.messageThreads.isFetching = true
        }),
            builder.addCase(getMessageThreadsForEmployee.fulfilled, (state, { payload }) => {
                state.messageThreads.data = payload
                state.messageThreads.isFetching = false
            }),
            builder.addCase(getMessageThreadsForEmployee.rejected, (state) => {
                state.messageThreads.error = 'we have an error!'
                state.messageThreads.isFetching = false
            }),
            // get message threads messages and show message thread
            builder.addCase(getMessagesForThread.pending, (state) => {
                state.threadMessages.isFetching = true
            }),
            builder.addCase(getMessagesForThread.fulfilled, (state, { payload }) => {
                state.threadMessages.data = payload
                state.threadMessages.isFetching = false
            }),
            builder.addCase(getMessagesForThread.rejected, (state) => {
                state.threadMessages.error = 'error fetching messages for thread'
                state.threadMessages.isFetching = false
            }),
            // send new message to selectedItems (employees)
            builder.addCase(startNewMessageThread.pending, (state) => {
                state.newMessageForm.isPosting = true
            }),
            builder.addCase(startNewMessageThread.fulfilled, (state) => {
                state.showNewMessageForm = false
                state.newMessageForm = initialState.newMessageForm
            }),
            builder.addCase(startNewMessageThread.rejected, (state) => {
                state.newMessageForm.isPosting = false
            }),
            // Initialize comments form and fetch comment threads
            builder.addCase(getCommentsForSelectedItemIds.pending, (state) => {
                state.commentThreads.isFetching = true
            }),
            builder.addCase(getCommentsForSelectedItemIds.fulfilled, (state, { payload }) => {
                state.commentThreads.data = payload
                state.commentThreads.isFetching = false
            }),
            builder.addCase(getCommentsForSelectedItemIds.rejected, (state) => {
                state.commentThreads.error = 'error fetching comments for event'
                state.commentThreads.isFetching = false
            }),
            // Comment on an Event
            builder.addCase(addCommentToEvent.pending, (state) => {
                state.newCommentForm.isPosting = true
            }),
            builder.addCase(addCommentToEvent.fulfilled, (state) => {
                state.showNewCommentForm = false
                state.newCommentForm = initialState.newCommentForm
            }),
            builder.addCase(addCommentToEvent.rejected, (state) => {
                state.newCommentForm.isPosting = false
            }),
            // Replying on a message Thread
            builder.addCase(replyToMessageThread.pending, (state) => {
                state.replyToThread.isFetching = true
            }),
            builder.addCase(replyToMessageThread.fulfilled, (state, { payload }) => {
                state.replyToThread.isFetching = false
                state.threadMessages.data = payload
                state.replyMessageValue = ''
            }),
            builder.addCase(replyToMessageThread.rejected, (state) => {
                state.replyToThread.isFetching = false
            }),
            // Update messageThread status
            builder.addCase(
                updateMessageThreadIsReadStatusById.pending,
                (
                    state,
                    {
                        meta: {
                            arg: { IsRead, ThreadId },
                        },
                    }
                ) => {
                    const index = state.messageThreads.data.findIndex((thread) => thread.Id === ThreadId)
                    if (index > -1) {
                        state.messageThreads.data[index].LatestMessage.IsRead = IsRead
                        state.messageThreads.data[index].LatestMessage.IsLoading = true
                    }
                }
            ),
            builder.addCase(updateMessageThreadIsReadStatusById.fulfilled, (state, { payload }) => {
                const index = state.messageThreads.data.findIndex((thread) => thread.Id === payload.ThreadId)
                if (index > -1) {
                    state.messageThreads.data[index].LatestMessage.IsRead = payload.IsRead
                    state.messageThreads.data[index].LatestMessage.IsLoading = false
                }
            }),
            builder.addCase(
                updateMessageThreadIsReadStatusById.rejected,
                (
                    state,
                    {
                        meta: {
                            arg: { IsRead, ThreadId },
                        },
                    }
                ) => {
                    const index = state.messageThreads.data.findIndex((thread) => thread.Id === ThreadId)
                    if (index > -1) {
                        state.messageThreads.data[index].LatestMessage.IsRead = !IsRead
                        state.messageThreads.data[index].LatestMessage.IsLoading = false
                    }
                }
            )
    },
})

export const {
    setViewedMessageThread,
    setShowMessageThread,
    setShowNewMessageForm,
    setReplyMessageValue,
    setThreadInitialized,
    setSelectedRecipients,
    newMessageFormMessageChanged,
    newMessageFormMessageTypeChanged,
    newMessageFormSendEmailChanged,
    newMessageFormTitleChanged,
    tabSelected,
    setShowNewCommentForm,
    setSelectedIds,
    setSelectedEvents,
    newCommentFormMessageChanged,
    newCommentFormVisibilityForEmployeeChanged,
    newCommentFormVisibilityForExternalUserChanged,
    resetThreadMessages,
    resetViewedMessageThread,
    modalClosed,
} = messagingSlice.actions

export default messagingSlice.reducer
