import { useEffect, useState } from 'react'
import makeRequest, { ERequestMethod } from '@planier/rest-api'

const useQuery = <T>(
    url: string,
    method: ERequestMethod = ERequestMethod.POST,
    requestBody?: unknown,
    dependencies?: unknown[]
): { data: T | undefined; isLoading: boolean; error: string | null } => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [error, setError] = useState<string | null>(null)

    const [data, setData] = useState<T | undefined>(undefined)

    useEffect(
        () => {
            setIsLoading(true)
            const iif = async () => {
                try {
                    const response = await makeRequest<T, unknown>({
                        url: url,
                        method: method,
                        data: requestBody ?? {},
                    })

                    if (response) {
                        setData(response)
                    }
                } catch (e) {
                    setError(e)
                } finally {
                    setIsLoading(false)
                }
            }

            iif()
        },
        // arbitrary dependencies for a custom hook is generally a bad pattern, had to disable linting for now
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies ? dependencies : []
    )

    return { isLoading, error, data }
}

export default useQuery
