import styled from '@emotion/styled'
import { JSX } from 'react'
import ReadOnlyText from '../ReadOnlyText'
import Icon from '../Icon/Icon'
import { useAppTheme } from '@planier/theme'

type Props = {
    header?: string
    body?: string
    className?: string
}

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 12px;
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.pacificCyan30};
    border-left: 8px solid ${({ theme }) => theme.colors.pacificCyan100};
    border-radius: ${({ theme }) => theme.tokens.radiusDefault};
`

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const InfoBox = ({ header, body, className }: Props): JSX.Element => {
    const { componentExtensions } = useAppTheme()

    return (
        <Container className={className}>
            <Icon color={componentExtensions.icons.iconNeutral}>info_outline</Icon>

            <TextContainer>
                {header && <ReadOnlyText usage="bodyS">{header}</ReadOnlyText>}
                {body && <ReadOnlyText usage="bodyS">{body}</ReadOnlyText>}
            </TextContainer>
        </Container>
    )
}

export default InfoBox
