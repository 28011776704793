import * as React from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { CancelToken } from 'axios'
import { RootState } from 'typesafe-actions'
import { getValueAsSet, getValueInSet } from '@planier/generic-utilities'
import { TagList } from '@planier/generic-components'
import { Dropdown, DropdownWithDynamicApiSearch } from '@planier/generic-components-dropdown'
import {
    EValuePickerType,
    IDropdownValuePickerOwnProps,
    ITagListValuePickerOwnProps,
    TValuePickerWithOptionsWithoutStoreOwnProps,
} from '@planier/value-picker'
import {
    fetchValuePickerWithOptionsDataThunk,
    setValuePickerWithOptionsValueThunk,
} from './Thunks/ValuePickerWithOptionsThunks'
import { BoundThunk } from '@planier/generic-state'
import {
    selectDidStoredOptionsComponentFetchFail,
    selectIsStoredOptionsComponentFetching,
    selectStoredOptionsComponentAllOptions,
    storedOptionsInitializeAction,
    TStoredOptionsSelectedItemIds,
} from '@planier/stored-options'
import ValueLink from './Components/ValueLink'
import { getLogger } from '@planier/log'
import { isEmpty, isEqual } from 'lodash-es'

const Log = getLogger('value-picker.ValuePickerWithOptions')

interface IStateProps {
    options: any
    fetchFailed: boolean
    fetching: boolean
}

interface IDispatchProps {
    fetchValuePickerWithOptionsData: BoundThunk<typeof fetchValuePickerWithOptionsDataThunk>
    setValuePickerWithOptionsValue: BoundThunk<typeof setValuePickerWithOptionsValueThunk>
    storedOptionsInitialize: typeof storedOptionsInitializeAction
}

function eqSet(as: Set<any>, bs: Set<any>) {
    if (as.size !== bs.size) {
        return false
    }

    for (const a of as) {
        if (!bs.has(a)) {
            return false
        }
    }

    return true
}

interface IOwnProps {
    selected: TStoredOptionsSelectedItemIds
    isInitialized: boolean
}
type TValuePickerWithOptionsProps = IStateProps &
    IDispatchProps &
    TValuePickerWithOptionsWithoutStoreOwnProps &
    IOwnProps

export const ValuePickerWithOptionsWithoutStoreUnconnected: React.FC<TValuePickerWithOptionsProps> = ({
    disabled,
    enableTextSearch = true,
    fetchFailed,
    fetchValuePickerWithOptionsData,
    fetching,
    itemIdField,
    multiselect = false,
    options,
    selected,
    textSearchProps,
    valuePickerId,
    valuePickerType,
    useDynamicApiSearch,
    setValuePickerWithOptionsValue,
    onChange,
    required = false,
    contextId,
    ...props
}) => {
    //With multiselect null & undefined values needs to be replaced with empty set
    const value = useMemo(
        () => (multiselect ? (selected ? getValueAsSet(selected) : new Set()) : new Set(selected ? [selected] : [])),
        [multiselect, selected]
    )

    if (!value) {
        Log.error(`Value is null in value picker options ${JSON.stringify(props)} + ${JSON.stringify(selected)}`)
    }

    const internalHandleValueSelect = useCallback(
        (newSelected: any, fetchDependentData: boolean) => {
            //Multiselect -> take the set
            //Single select -> if it is a set, take its first value, otherwise set value as null
            const newValue = multiselect
                ? newSelected
                : newSelected && newSelected.size > 0
                ? newSelected.values().next().value
                : null

            const optionsWithSideEffects = options.filter((x) => x.SideEffect)

            const sideEffectsNested = multiselect
                ? optionsWithSideEffects.filter((x) => [...newValue].find((y) => y === x.Id)).map((x) => x.SideEffect)
                : optionsWithSideEffects.filter((x) => x.Id === newValue).map((x) => x.SideEffect)

            const sideEffects = sideEffectsNested ? sideEffectsNested.flat() : []

            setValuePickerWithOptionsValue(
                valuePickerId,
                newValue,
                contextId,
                { fetchDataForDependentValuePickers: fetchDependentData },
                sideEffects
            )

            return newValue
        },
        [contextId, multiselect, options, setValuePickerWithOptionsValue, valuePickerId]
    )

    const handleValueSelect = useCallback(
        (newSelected: any) => {
            if (isEqual(selected, newSelected)) {
                return
            }

            const newValue = internalHandleValueSelect(newSelected, true)

            onChange && onChange(newValue)
        },
        [selected, internalHandleValueSelect, onChange]
    )

    useEffect(() => {
        if (fetching) {
            return
        }

        //Clean input if there are no options
        if (!options && value.size > 0) {
            handleValueSelect(new Set())
        } else if (options && !(value.size === 0)) {
            //Filter old values which are not included in the options
            // Check if all values are included in options
            const newValues = [...value].filter((val) => options.find((opt) => opt.Id === val))

            //If values are not equal propagate change to changed fields, otherwise update sideeffects etc.
            if (!eqSet(new Set(newValues), value)) {
                handleValueSelect(new Set(newValues))
            } else {
                internalHandleValueSelect(new Set(newValues), false)
            }
        } else if (options && options.length === 1 && value.size === 0 && required) {
            //Auto select if only one option and its required field
            handleValueSelect(new Set([options[0].Id]))
        }
        // adding the required dependencies causes a crash, disabling until it can be properly refactored
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, required, fetching])

    const fetchOptionsWithDynamicApiRequests = useCallback(
        async (userInput: string, cancelToken: CancelToken) => {
            const Ids = !isEmpty(value) ? [...value] : undefined

            await fetchValuePickerWithOptionsData(
                valuePickerId,
                { useCachedValues: false },
                { Vapaatekstihaku: userInput, Ids },
                cancelToken
            )
        },
        [fetchValuePickerWithOptionsData, value, valuePickerId]
    )

    const valuePickerWithOptionsCommonProps = {
        disabled: disabled || fetchFailed,
        enableTextSearch,
        filteringProps: textSearchProps,
        isLoading: fetching,
        itemIdField,
        multiselect,
        translate: false,
    }

    switch (valuePickerType) {
        case EValuePickerType.DropdownV2:
        case EValuePickerType.Dropdown: {
            const {
                text,
                itemOptionLabelFields,
                itemValueLabelFields = ['Id', 'Nimi'],
                dropdownWidth,
                dropdownMenuWidth,
                singleValueLinkStart = null,
                singleValueLinkTooltip = null,
                valueFieldType,
                spaceReservedForChipSelectionCount,
                errors,
                label,
                notes,
            } = props as IDropdownValuePickerOwnProps

            const dropdownProps = {
                ...valuePickerWithOptionsCommonProps,
                label: label ?? text.Label,
                defaultText: fetchFailed ? text.FetchError : text.Label,
                itemOptionLabelFields,
                itemValueLabelFields,
                textForMultipleSelected: text.MultipleSelected,
                selectAction: handleValueSelect,
                items: options,
                values: value,
                required,
                valueFieldType,
                menuWidth: dropdownMenuWidth,
                width: dropdownWidth,
                spaceReservedForChipSelectionCount,
                valueTextOverride: fetchFailed ? text.FetchError : notes,
                errors,
            }

            const dropdown = useDynamicApiSearch ? (
                <DropdownWithDynamicApiSearch fetchData={fetchOptionsWithDynamicApiRequests} {...dropdownProps} />
            ) : (
                <Dropdown {...dropdownProps} disabled={dropdownProps.disabled || fetching} />
            )

            const valueLink =
                singleValueLinkStart && singleValueLinkTooltip ? (
                    <ValueLink
                        tooltip={singleValueLinkTooltip}
                        valueLinkStart={singleValueLinkStart}
                        valuePickerValues={selected ? getValueInSet(selected) : new Set()}
                    />
                ) : null

            return (
                <React.Fragment>
                    {dropdown}
                    {valueLink}
                </React.Fragment>
            )
        }
        case EValuePickerType.TagList: {
            const { mainColorField, supportColorField, itemLabelFields } = props as ITagListValuePickerOwnProps
            const tagListProps = {
                ...valuePickerWithOptionsCommonProps,
                mainColorField,
                supportColorField,
                itemLabelFields,
                tagInfo: options,
                value: value,
                onChange: handleValueSelect,
            }
            return <TagList {...tagListProps} />
        }
        default:
            Log.error('no matching value picker type found')
            return null
    }
}

const mapStateToProps = (
    state: RootState,
    { valuePickerId }: TValuePickerWithOptionsWithoutStoreOwnProps
): IStateProps => ({
    options: selectStoredOptionsComponentAllOptions(state, valuePickerId),
    fetchFailed: selectDidStoredOptionsComponentFetchFail(state, valuePickerId),
    fetching: selectIsStoredOptionsComponentFetching(state, valuePickerId),
})

const mapDispatchToProps = {
    fetchValuePickerWithOptionsData: fetchValuePickerWithOptionsDataThunk,
    setValuePickerWithOptionsValue: setValuePickerWithOptionsValueThunk,
    storedOptionsInitialize: storedOptionsInitializeAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuePickerWithOptionsWithoutStoreUnconnected)
