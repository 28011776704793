import Radio from '@material-ui/core/Radio'
import * as React from 'react'
import { JSX } from 'react'
import styled from '@emotion/styled'
import ReadOnlyText from '../../ReadOnlyText'
import { Translation } from '@planier/localization'

type Props = {
    label: string
    onValueSet?: () => void
    checked: boolean
    disabled?: boolean
    testId?: string | undefined
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const StyledRadioButton = styled(Radio)<{ disabled?: boolean; checked: boolean }>`
    &.MuiRadio-root {
        :hover {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.textInputDefault}15;
        }
        color: ${({ disabled, theme, checked }) =>
            disabled
                ? theme.componentExtensions.icons.iconDisabledLighter
                : checked
                ? theme.componentExtensions.icons.iconAction
                : theme.componentExtensions.icons.iconDisabledDarker};
    }
`

const RadioButton = ({ label, onValueSet, checked, disabled, testId }: Props): JSX.Element => {
    const handleOnChange = () => {
        if (!checked) {
            onValueSet && onValueSet()
        }
    }

    const id = `radio-button-${label}`

    return (
        <Container>
            <StyledRadioButton
                checked={checked}
                onChange={handleOnChange}
                color="primary"
                disabled={disabled}
                id={id}
                data-testid={testId}
            />
            <label htmlFor={id}>
                <ReadOnlyText translate={Translation.has(label)} usage="bodyS">
                    {label}
                </ReadOnlyText>
            </label>
        </Container>
    )
}

export default RadioButton
