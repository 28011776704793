import { connect, useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import { JSX, memo, useEffect } from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import EListViewActionFunctionalityType from '../../../Types/EListViewActionFunctionalityType'
import {
    executeDownloadActionThunk,
    executeSingleListItemActionThunk,
    openListActionConfirmationDialogThunk,
    openListModalThunk,
} from '../../../Thunks/ConfigurableListThunks'
import { IDataSourceAction, selectDataSourceItem, selectIsLoadingItem, TDataSourceId } from '@planier/data-source'
import { IDataSourceItem, TDataSourceItemId } from '@planier/data-source-types'
import { IListViewIconAction } from '../../../interfaces/TDisplayAction'
import {
    selectChildIdsDeep,
    selectDefaultOpenedItems,
    selectGroupDepth,
    selectIsGroupOpened,
    selectIsItemSelected,
    selectOpenedSubrows,
    selectSelectedItemIds,
} from '../../../State/ConfigurableListSelectors'
import { TListIconData } from '../../../interfaces/IList'
import IListViewAction from '../../../interfaces/IListViewAction'
import IListViewColumnModel from '../../../interfaces/IListViewColumnModel'
import ListCheckboxGroup from './ListCheckboxGroup'
import RowActions from './RowActions'
import RowElements from './RowElements'
import Styles, { light_grey_110, orange_20 } from 'constants/Styles'
import TFunctionalityAction, {
    IListViewConfigurableListModalAction,
    IListViewConfigurableModalAction,
} from '../../../interfaces/TFunctionalityAction'
import IListActionConfirmationDialogModalProps from '../../../interfaces/IListActionConfirmationDialogModalProps'
import { openFormViewConfigurableModalThunk, openFormViewCustomModalThunk } from '@planier/form-view'
import { BoundThunk } from '@planier/generic-state'
import { ROW_HEIGHT } from '../../../Constants/RowConstants'
import {
    setDefaultOpenedItem,
    toggleGroupSimilarRowsAction,
    toggleRowItemSelectionAction,
} from '../../../State/ConfigurableListActions'
import { generateSummaryLabelsForItem } from '@planier/generic-components/SummaryLabel/Utils'
import moment from 'moment'
import { get, isEmpty, isNil, set } from 'lodash-es'

const {
    layout: { pageDefaultLeftPadding },
} = Styles

interface IStateProps {
    isLoading: boolean
    item?: IDataSourceItem
    rowChecked: boolean
    groupOpened: boolean
    openedSubrows: string[]
}

interface IDispatchProps {
    executeSingleListItemAction: BoundThunk<typeof executeSingleListItemActionThunk>
    openFormViewConfigurableModal: BoundThunk<typeof openFormViewConfigurableModalThunk>
    openFormViewCustomModal: BoundThunk<typeof openFormViewCustomModalThunk>
    openListActionConfirmationDialog: BoundThunk<typeof openListActionConfirmationDialogThunk>
    openListModal: BoundThunk<typeof openListModalThunk>
    toggleRowItemSelection: typeof toggleRowItemSelectionAction
    executeDownloadAction: BoundThunk<typeof executeDownloadActionThunk>
    toggleGroupSimilarRows: typeof toggleGroupSimilarRowsAction
}

interface IOwnProps {
    firstRow: boolean
    iconData?: TListIconData
    itemId: TDataSourceItemId
    listId: string
    massSelectable: boolean
    metadataForColumns: IListViewColumnModel[]
    onRowSelect?: (item: IDataSourceItem) => void
    rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]
    isSelectable: boolean
    actionConfigurations: IDataSourceAction[]
    dataSourceId: TDataSourceId | null
    isScrolling?: boolean
    /**
     * Virtualized list's styling for rows so that it can position them correctly
     */
    style?: React.CSSProperties
}

export interface IRowProps extends IStateProps, IDispatchProps, IOwnProps {}

// We use this to override the background-color of the row actions when hovering the row
const rowActionsClassName = 'pln-rowactions-container'

const Container = styled.div<{
    firstRow: boolean
    rowIsSelectable: boolean
    isRowSelected: boolean
    isHighlighted: boolean
    isOpenedGroup: boolean
    openedSubrows?: string[]
    groupType: string
}>`
    height: ${ROW_HEIGHT}px;
    padding-left: ${pageDefaultLeftPadding};
    align-items: center;
    border-top: ${({ firstRow, theme }) =>
        firstRow ? `1px solid ${theme.componentExtensions.border.secondary}` : 'none'};
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
    display: inline-flex;
    width: auto;

    ${({ isOpenedGroup, groupType, isRowSelected, theme }) =>
        (isOpenedGroup || groupType === 'sub') &&
        !isRowSelected &&
        `
        background-color: ${theme.colors.primaryChampion11};

        & .${rowActionsClassName} {
            background-color: ${theme.colors.primaryChampion11};
        }
    `}

    ${({ isHighlighted }) =>
        isHighlighted &&
        `
        background-color: ${orange_20};

        & .${rowActionsClassName} {
            background-color: ${orange_20};
        }
    `}

    ${({ isRowSelected, theme }) =>
        isRowSelected &&
        `
        background-color: ${theme.colors.primaryCyan11};

        & .${rowActionsClassName} {
            background-color: ${theme.colors.primaryCyan11};
        }
    `}


    cursor: ${({ rowIsSelectable }) => (rowIsSelectable ? 'pointer' : 'default')};

    :hover {
        background-color: ${light_grey_110};

        & .${rowActionsClassName} {
            background-color: ${light_grey_110};
        }
    }
`

const inferGroupType = (item: IDataSourceItem) => {
    const isNormalRow = isNil(item.MetaData?.IsParent)
    const isParent = item.MetaData?.IsParent

    return isNormalRow ? '' : isParent ? 'main' : 'sub'
}

/**
 * Yksittäinen listan rivi.
 * Rivillä näytetään normaalitilassa ollessaan alussa checkbox,
 * jonka jälkeen renderöidään kaikkki listan sarakkeet ja näiden
 * jälkeen rivin ikonit.
 * Jos rivi lataa tietoa, renderöidään checkboxin tilalla
 * latausindikaattori ja kaikille sarakekomponteille ja
 * ikoneille annetaan disabled-propsi.
 */
export const Row = (props: IRowProps): JSX.Element | null => {
    const dispatch = useDispatch()

    const groupDepth = useSelector((state: RootState) => selectGroupDepth(state, props.dataSourceId, props.itemId))
    const groupChildIds = useSelector((state: RootState) => selectChildIdsDeep(state, props.dataSourceId, props.itemId))

    const selectedItems = useSelector((state: RootState) => selectSelectedItemIds(state, props.listId))
    const defaultOpenedItems = useSelector((state: RootState) => selectDefaultOpenedItems(state, props.listId))

    useEffect(() => {
        // open rows marked as DefaultOpen only once
        if (props.item?.MetaData?.DefaultOpen && !defaultOpenedItems?.has(props.itemId)) {
            dispatch(toggleGroupSimilarRowsAction(true, props.itemId, props.listId))
            dispatch(setDefaultOpenedItem(props.itemId, props.listId))
        }
    }, [defaultOpenedItems, dispatch, props.item?.MetaData?.DefaultOpen, props.itemId, props.listId])

    useEffect(() => {
        // if row is marked as checked due to its children being selected, add it to the actual selection
        if (props.rowChecked && !selectedItems.has(props.itemId)) {
            dispatch(toggleRowItemSelectionAction(true, props.itemId, props.listId))
        }
    }, [dispatch, props.itemId, props.listId, props.rowChecked, selectedItems])

    const handleRowActionClick = (actionType: EListViewActionFunctionalityType, callbackProps: unknown) => {
        switch (actionType) {
            case EListViewActionFunctionalityType.Download: {
                const { executeDownloadAction, listId, item } = props
                const actionConfiguration = callbackProps as IDataSourceAction

                if (!item) {
                    return
                }

                executeDownloadAction(listId, [item.Id], actionConfiguration.Id)
                break
            }
            case EListViewActionFunctionalityType.Immediate: {
                const { executeSingleListItemAction, listId, item } = props
                const actionConfiguration = callbackProps as IDataSourceAction

                if (!item) {
                    return
                }

                executeSingleListItemAction(listId, item.Id, actionConfiguration.Id)
                break
            }

            case EListViewActionFunctionalityType.ConfigurableModal: {
                const { dataSourceId, item, openFormViewConfigurableModal } = props
                const modalProps = callbackProps as IListViewConfigurableModalAction

                if (!item) {
                    return
                }

                const addProps = {} as Record<string, any>
                if (modalProps.AdditionalPropertyMapping) {
                    modalProps.AdditionalPropertyMapping.forEach((mapping) =>
                        set(addProps, mapping.Key, get(item, mapping.PropertyName))
                    )
                }

                openFormViewConfigurableModal(
                    modalProps.FormId,
                    dataSourceId,
                    [item.Id],
                    addProps,
                    modalProps.IgnoreInitialValueFields
                )
                break
            }

            case EListViewActionFunctionalityType.Confirmation: {
                const { listId, item, openListActionConfirmationDialog } = props

                if (!item) {
                    return
                }

                const modalProps: IListActionConfirmationDialogModalProps = {
                    ...(callbackProps as Pick<
                        IListActionConfirmationDialogModalProps,
                        'actionConfiguration' | 'confirmationDialogTexts'
                    >),
                    listId,
                    itemIds: [item.Id],
                }

                openListActionConfirmationDialog(modalProps)
                break
            }

            case EListViewActionFunctionalityType.CustomModal: {
                const { item, openFormViewCustomModal } = props
                const { formId, dataSourceId } = callbackProps as {
                    formId: string
                    dataSourceId: string
                }

                if (!item) {
                    return
                }

                openFormViewCustomModal(formId, dataSourceId, [item.Id])
                break
            }

            case EListViewActionFunctionalityType.ListModal: {
                const { openListModal, item, listId: sourceListId, dataSourceId } = props

                if (!item) {
                    return
                }

                const {
                    ListId: listIdForModal,
                    ValuePickerPropertyMapping,
                    ModalTitle,
                    HiddenValuePickerIds,
                } = callbackProps as IListViewConfigurableListModalAction

                const summaryLabels = generateSummaryLabelsForItem(dataSourceId ?? '', item)

                openListModal({
                    sourceListId,
                    listIdForModal,
                    selectedItemIds: [item.Id],
                    valuePickerValuesPropertyMapping: ValuePickerPropertyMapping,
                    modalTitle: ModalTitle,
                    hiddenValuePickerIds: HiddenValuePickerIds,
                    summaryLabels,
                })
                break
            }

            default:
                break
        }
    }

    const handleRowClick = async () => {
        const { item, onRowSelect, isSelectable, massSelectable, toggleRowItemSelection, rowChecked, itemId, listId } =
            props

        // jos rivi on group parent, togglaa kaikki childit
        const isParent = item?.MetaData?.IsParent
        const childIds = isParent ? groupChildIds : []

        if (massSelectable) {
            toggleRowItemSelection(!rowChecked, itemId, listId)
            childIds?.forEach((sub) => {
                toggleRowItemSelection(!rowChecked, sub, listId)
            })
        } else if (isSelectable && onRowSelect && item) {
            onRowSelect(item)
        }

        // open child rows if parent was selected
        if (!rowChecked && !isEmpty(childIds)) {
            dispatch(toggleGroupSimilarRowsAction(true, itemId, listId, childIds))
        }
    }

    const handleGroupButtonClick = (e: React.MouseEvent) => {
        const { toggleGroupSimilarRows, groupOpened, itemId, listId } = props

        // close child groups when closing their parent group
        const childIds = groupOpened ? groupChildIds : undefined

        toggleGroupSimilarRows(!groupOpened, itemId, listId, childIds)

        e.stopPropagation()
    }

    const {
        actionConfigurations,
        dataSourceId,
        firstRow,
        iconData,
        isLoading,
        item,
        itemId,
        massSelectable,
        metadataForColumns,
        rowActions,
        rowChecked,
        groupOpened,
        isSelectable,
        style,
        listId,
        isScrolling,
    } = props

    if (!item || dataSourceId === null) {
        return null
    }

    const createdAt = moment(item.CreatedTimestamp)
    const isNewItem = createdAt ? createdAt.isBetween(moment().subtract(15, 'minutes'), moment()) : false

    const { Kayttooikeudet } = item

    const groupType = inferGroupType(item)

    const offset = groupDepth * 30 - (groupType === 'sub' ? 20 : 0)

    return (
        <Container
            firstRow={firstRow}
            isRowSelected={rowChecked}
            isHighlighted={isNewItem}
            onClick={handleRowClick}
            rowIsSelectable={isSelectable}
            style={style}
            isOpenedGroup={groupOpened}
            groupType={groupType}
        >
            <ListCheckboxGroup
                isLoading={isLoading}
                rowChecked={rowChecked}
                groupType={groupType}
                groupOpened={groupOpened}
                handleGroupButtonClick={handleGroupButtonClick}
                handleRowClick={handleRowClick}
                massSelectable={massSelectable}
                offset={offset}
            />

            <RowElements
                dataSourceId={dataSourceId}
                isLoading={isLoading}
                item={item}
                itemId={itemId}
                metadataForColumns={metadataForColumns}
                offset={offset}
            />

            <RowActions
                actionConfigurations={actionConfigurations}
                className={rowActionsClassName}
                iconData={iconData}
                iconsDisabled={isLoading}
                itemId={itemId}
                onRowActionClick={handleRowActionClick}
                permissions={Kayttooikeudet}
                rowActions={rowActions}
                listId={listId}
                firstRow={firstRow}
                renderPlaceholder={isScrolling}
            />
        </Container>
    )
}

const mapStateToProps = (state: RootState, { itemId, listId, dataSourceId }: IOwnProps): IStateProps => {
    const item = selectDataSourceItem(state, dataSourceId, itemId)
    const rowChecked = selectIsItemSelected(state, listId, itemId, dataSourceId)
    const isLoading = selectIsLoadingItem(state, dataSourceId, itemId)
    const groupOpened = selectIsGroupOpened(state, listId, itemId)
    const openedSubrows = selectOpenedSubrows(state, listId, dataSourceId)

    return {
        isLoading,
        item,
        rowChecked,
        groupOpened,
        openedSubrows,
    }
}

const mapDispatchToProps = {
    executeSingleListItemAction: executeSingleListItemActionThunk,
    openFormViewConfigurableModal: openFormViewConfigurableModalThunk,
    openFormViewCustomModal: openFormViewCustomModalThunk,
    openListActionConfirmationDialog: openListActionConfirmationDialogThunk,
    openListModal: openListModalThunk,
    toggleRowItemSelection: toggleRowItemSelectionAction,
    executeDownloadAction: executeDownloadActionThunk,
    toggleGroupSimilarRows: toggleGroupSimilarRowsAction,
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(Row))
