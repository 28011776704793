import IConfigurableCalendarConfiguration from '../Types/IConfigurableCalendarConfiguration'
import ECrossAxisGranularityType from '../Constants/ECrossAxisGranularityType'
import ECalendarDisplayMode from '../Constants/ECalendarDisplayMode'
import { EActionFunctionalityType } from '@planier/view-engine'

const configuration: IConfigurableCalendarConfiguration = {
    DataSourceId: 'TyontekijanakymaDataSource',
    DisplayMode: ECalendarDisplayMode.FullDay,
    Granularity: { Type: ECrossAxisGranularityType.TIME, Unit: 'd', Value: '1' },
    Title: 'Työntekijät',
    NodeActions: [
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_Action_Edit',
                FormId: 'WorkforceSchedulingEventCreateFormView',
                AdditionalPropertyMapping: [],
                AdditionalInitialValueSourceProperties: [
                    { key: 'Tyontekija.Id', PropertyPath: 'EmployeeId', liftToArray: true },
                    { key: 'Alkamisaika', PropertyPath: 'StartTime', liftToArray: true, format: 'toDate' },
                ],
                AdditionalInitialValues: [],
                IgnoreInitialValueFields: [],
                IgnoreOriginalItemIds: true,
                PermissionId: 'CREATE',
            },
            Display: {
                Icon: 'add',
                Label: 'Luo uusi',
            },
            IsPrimaryAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tyontekijanakyma_Action_Edit',
                FormId: 'TyontekijanakymaTapahtumaEditFormView',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'edit',
                Label: 'Muokkaa',
            },
            IsPrimaryAction: true,
            IsDoubleClickAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'Tyontekijanakyma_Action_Copy_Tapahtuma',
                // TODO: currently there's no permission of its own for this so just use UPDATE for now
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'content_copy',
                Label: 'Kopioi',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tyontekijanakyma_CreateTemplateFromEvent',
                PermissionId: 'UPDATE',
                FormId: 'CreateTemplateFromEvent',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
                reloadTemporaryData: true,
            },

            Display: {
                Icon: 'backup_table',
                Label: 'Luo tapahtumapohja',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_Action_EventRecurrence_SetEndTime',
                FormId: 'RepetitiveEventSetEndDate',
                PermissionId: 'RECURRING_EVENT_SET_END_DATE',
            },
            Display: {
                Icon: 'delete',
                Label: 'Päätä tapahtumasarja',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'Tyontekijanakyma_Action_Swap_Tapahtuma',
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'swap_horizontal',
                Label: 'Vaihda',
            },
            RequiresMultipleItems: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.CustomModal,
                DataSourceActionId: 'Tyontekijanakyma_Action_Publish_Events',
                FormId: 'PublishEventsConfirmationDialog',
                PermissionId: 'PUBLISH',
            },
            Display: {
                Icon: 'publish',
                Label: 'Julkaise',
            },
            IsPrimaryAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tyontekijanakyma_Action_CreateEventTemplateGroup',
                FormId: 'EventTemplateGroupCreateView',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
                // TODO: need to add a field to output the selected IDs
                // TODO: there's no permission of its own for this. Maybe this action
                // actually doesn't need a permission either? Maybe the PermissionId should
                // be nullable for cases like this?
                PermissionId: 'UPDATE',
                SelectedItemsOutputProperty: 'EventIds',
            },
            Display: {
                Icon: 'folder_open',
                Label: 'Luo profiili',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.CustomModal,
                DataSourceActionId: 'Tyontekija_Action_Remove_Tapahtuma',
                FormId: 'WorkforceSchedulingDeletionConfirmationDialog',
                PermissionId: 'DELETE',
            },
            Display: {
                Icon: 'delete',
                Label: 'Poista',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.CustomModal,
                DataSourceActionId: 'Event_Cancel_TarvenakymaDataSource',
                FormId: 'CancelEventForm_TarvenakymaDataSource',
                PermissionId: 'CANCEL_PUBLISHED_EVENT',
            },
            Display: {
                Icon: 'cancel',
                Label: 'Peru vuoro',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'Tyontekijanakyma_Action_Remove_Tyontekija',
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'person_remove',
                Label: 'Poista työntekijä',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'ConfirmTransfersToEvents',
                // TODO: currently there's no permission of its own for this so just use UPDATE for now
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'commute',
                Label: 'Luo tapahtuma siirtymästä',
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tyontekijanakyma_Action_CreateLeave',
                PermissionId: 'UPDATE',
                FormId: 'CreateLeaveForm',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
            },
            Display: {
                Icon: 'airline_seat_individual_suite',
                Label: 'Merkitse poissaoloksi',
            },
        },
    ],
    ValuePickerIds: [
        'WorkforceScheduling_PlanningPeriods',
        'WorkforceScheduling_Calendar_Filter_EventType',
        'WorkforceScheduling_Calendar_Filter_Pool',
        'WorkforceScheduling_Calendar_Filter_Employees',
        'WorkforceScheduling_Calendar_Filter_Domain',
        'WorkforceScheduling_Calendar_Filter_WorkUnit',
        'WorkforceScheduling_Calendar_Filter_JobTitle',
        'WorkforceScheduling_Calendar_Filter_Cla',
    ],
    NodeTypes: [
        {
            Id: 1,
            Component: 'VuoroCalendarNode',
            PropertyMapping: [
                {
                    ComponentProperty: 'isRecurring',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_IsRecurringEvent',
                },
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TyontekijanakymaDataSource_Tyontekija' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TyontekijanakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'ammattinimikeShorthand',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_AmmattinimikeAbbreviation',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_WorkTimeRuleViolations',
                },
                {
                    ComponentProperty: 'jobTitleName',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_AmmattinimikeName',
                },
                {
                    ComponentProperty: 'additionalTopText',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_AsiakasName',
                },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TyontekijanakymaDataSource_EventClassId',
                MatchingValues: [1, 2],
            },
        },
        {
            Id: 1,
            Component: 'TransferNode',
            PropertyMapping: [
                { ComponentProperty: 'published', DataSourcePropertyId: 'TyontekijanakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'travelInformationText',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_Lisatiedot',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_WorkTimeRuleViolations',
                },
                { ComponentProperty: 'isTemporary', DataSourcePropertyId: 'TyontekijanakymaDataSource_IsTemporary' },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TyontekijanakymaDataSource_EventClassId',
                MatchingValues: [9],
            },
        },
        {
            Id: 2,
            Component: 'TyrkkyCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TyontekijanakymaDataSource_Tyontekija' },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TyontekijanakymaDataSource_EventClassId',
                MatchingValues: [6],
            },
        },
        {
            Id: 3,
            Component: 'EstoCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TyontekijanakymaDataSource_Tyontekija' },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TyontekijanakymaDataSource_EventClassId',
                MatchingValues: [7],
            },
        },
        {
            Id: 4,
            Component: 'MuuTapahtumaCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TyontekijanakymaDataSource_Tyontekija' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TyontekijanakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'tapahtumatyyppiShorthand',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_EventTypeAbbreviation',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_WorkTimeRuleViolations',
                },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TyontekijanakymaDataSource_EventClassId',
                MatchingValues: [3, 8, 10, 11],
            },
        },
        {
            Id: 5,
            Component: 'MuuTapahtumaCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TyontekijanakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TyontekijanakymaDataSource_Tyontekija' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TyontekijanakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'tapahtumatyyppiShorthand',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_EventTypeName',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_WorkTimeRuleViolations',
                },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TyontekijanakymaDataSource_EventClassId',
                MatchingValues: [12],
            },
        },
    ],
    SelectorObjects: [
        {
            SelectorProperties: [{ Property: 'Tyontekija.Id', Transformation: 'None' }],
            Title: 'Työntekijän tapahtumat',
        },
        {
            SelectorProperties: [{ Property: 'Alkamisaika', Transformation: 'Date' }],
            Title: 'Päivän tapahtumat',
        },
        {
            SelectorProperties: [{ Property: 'Ammattinimike.Id', Transformation: 'None' }],
            Title: 'Ammattinimikkeen tapahtumat',
        },
        { SelectorProperties: [], Title: 'Kaikki tapahtumat' },
    ],
    CrossAxis: {
        Component: 'EmployeeGroupNode',
        PropertyMapping: [
            { ComponentProperty: 'employeeId', GroupDataPropertyPath: 'EmployeeId' },
            { ComponentProperty: 'infoText', GroupDataPropertyPath: 'InfoText' },
            { ComponentProperty: 'name', GroupDataPropertyPath: 'Text' },
            { ComponentProperty: 'kpiData', GroupDataPropertyPath: 'CounterData' },
        ],
    },
    MainAxis: [
        {
            Level: 1,
            Component: 'ViikkoLabel',
            ParameterMapping: [{ Text: 'Name' }],
            StartProperty: 'StartTime',
            EndProperty: 'EndTime',
        },
    ],
    AdditionalDataFetchParameters: {
        Limit: 2000,
        ExtraRows: 'OmitAll',
        GroupBy: [
            /* Currently semi hard coded in the backend */
        ],
    },
}

export default configuration
