import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import * as React from 'react'

import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import Heading from 'components/atoms/Heading'
import OsiokohtaisetAsetuksetMuuttuneetAsetukset, {
    IMuuttuneetAsetustenArvot,
} from '../organisms/OsiokohtaisetAsetuksetMuuttuneetAsetukset'
import { Translation } from '@planier/localization'

interface IDialoginPainikkeetProps {
    isSubmitting: boolean
    onClose?: () => void
    onkoAsetuksetTallennettu: boolean
    onkoNapitDisabloitu: boolean
    submit: () => void
}

const DialoginPainikkeet: React.FunctionComponent<IDialoginPainikkeetProps> = (props) =>
    !props.onkoAsetuksetTallennettu ? (
        <React.Fragment>
            {props.isSubmitting && <LoadingIndicatorInline isLoading={props.isSubmitting} />}

            <Button disabled={props.onkoNapitDisabloitu} onClick={props.onClose}>
                {Translation.translateKey('OsiokohtaisetAsetukset.peruuta')}
            </Button>

            <Button
                color="primary"
                disabled={props.onkoNapitDisabloitu}
                form="OsiokohtaisetAsetuksetForm"
                onClick={props.submit}
                type="submit"
            >
                {Translation.translateKey('OsiokohtaisetAsetukset.tallenna')}
            </Button>
        </React.Fragment>
    ) : (
        <Button color="primary" onClick={props.onClose}>
            {Translation.translateKey('OsiokohtaisetAsetukset.ok')}
        </Button>
    )

export interface IOsiokohtaisetAsetuksetTallennusDialogiProps {
    isSubmitting: boolean
    muuttuneetAsetustenArvot: IMuuttuneetAsetustenArvot
    onClose: () => void
    onkoAsetuksetTallennettu: boolean
    open: boolean
    submit: () => void
}

const OsiokohtaisetAsetuksetTallennusDialogi: React.FunctionComponent<IOsiokohtaisetAsetuksetTallennusDialogiProps> = (
    props
) => {
    const onkoNapitDisabloitu = props.isSubmitting
    const onClose = !onkoNapitDisabloitu ? props.onClose : undefined

    return (
        <Dialog maxWidth={false} onClose={onClose} open={props.open}>
            <DialogTitle>
                <Heading>
                    {!props.onkoAsetuksetTallennettu
                        ? 'OsiokohtaisetAsetukset.tallennetaanMuutokset'
                        : 'OsiokohtaisetAsetukset.asetuksetTallennettu'}
                </Heading>
            </DialogTitle>
            <DialogContent>
                {!props.onkoAsetuksetTallennettu && (
                    <OsiokohtaisetAsetuksetMuuttuneetAsetukset
                        muuttuneetAsetustenArvot={props.muuttuneetAsetustenArvot}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <DialoginPainikkeet
                    isSubmitting={props.isSubmitting}
                    onClose={onClose}
                    onkoAsetuksetTallennettu={props.onkoAsetuksetTallennettu}
                    onkoNapitDisabloitu={onkoNapitDisabloitu}
                    submit={props.submit}
                />
            </DialogActions>
        </Dialog>
    )
}

export default OsiokohtaisetAsetuksetTallennusDialogi
