import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { TranslatedButton } from '@planier/generic-components'
import { closeModalAction, ModalBase, selectIsModalOpen, selectModalProps } from '@planier/modal'
import { ValuePickerGroup } from '@planier/value-picker'
import IVPModalProps from '../Types/IValuePickerModalProps'
import styled from '@emotion/styled'
import { isPlainObject } from 'lodash-es'

interface IOwnProps {
    modalId: string
    onConfirm: () => void
}

interface IStateProps {
    modalOpen: boolean
    modalProps: Record<string, unknown> | undefined
}

interface IDispatchProps {
    closeModal: (modalId: string) => void
}

interface IValuePickerModalProps extends IStateProps, IDispatchProps, IOwnProps {}

const ContentContainer = styled.div`
    padding-top: 13px;
`

const getValuePickerIds = (modalProps: Partial<IVPModalProps> | undefined): string[] | null => {
    if (!isPlainObject(modalProps) || !Array.isArray((modalProps as IVPModalProps).valuePickerIds)) {
        return null
    }

    return modalProps ? (modalProps as IVPModalProps).valuePickerIds : null
}

export const ValuePickerModalUnconnected: React.FunctionComponent<IValuePickerModalProps> = ({
    closeModal,
    modalId,
    modalOpen,
    modalProps,
    onConfirm,
}) => {
    const handleModalClose = () => {
        closeModal(modalId)
    }

    const handleConfirmButtonPress = () => {
        onConfirm()
        closeModal(modalId)
    }

    const valuePickerIds = getValuePickerIds(modalProps)

    if (!modalOpen || !valuePickerIds) {
        return null
    }

    const confirmButton = (
        <TranslatedButton onClick={handleConfirmButtonPress}>
            modal-components.ValuePickerModal.ConfirmButtonLabel
        </TranslatedButton>
    )

    return (
        <ModalBase actionButtons={confirmButton} handleClose={handleModalClose} modalId={modalId}>
            <ContentContainer>
                <ValuePickerGroup valuePickerIds={valuePickerIds} />
            </ContentContainer>
        </ModalBase>
    )
}

const mapStateToProps = (state: RootState, { modalId }: IOwnProps): IStateProps => ({
    modalOpen: selectIsModalOpen(state, modalId),
    modalProps: selectModalProps(state, modalId),
})

const mapDispatchToProps = {
    closeModal: closeModalAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuePickerModalUnconnected)
