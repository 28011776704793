import * as React from 'react'

import {
    ISelectRowsFunction,
    TNewlySelected,
    TSelectedValues,
    TRowTextComponent,
    TAllValues,
} from '../../../../Interfaces'
import ItemRow from './ItemRow'
import MassSelectionRow from './MassSelectionRow'
import ValueRow from './ValueRow'

interface IMenuContentRowProps<T> {
    listHeight: number
    itemIdField: keyof T
    itemOptionLabelFields: (keyof T)[]
    items: T[]
    selectAction: ISelectRowsFunction<T>
    selectAllAction: ISelectRowsFunction<boolean>
    selectedNewItems: TNewlySelected<T>
    selectedValueItems: TSelectedValues<T>
    selectValueRowAction: ISelectRowsFunction<T>
    RowTextComponent: TRowTextComponent<T>
    index: number
    style: React.CSSProperties
    multiselect: boolean
    values: TAllValues<T>
}

let cachedVisibleNonValueItems: any[]
let cachedVisibleValueItems: any[]
let cachedVisibleItems: any[]
let cachedValues: ReadonlySet<any>

function getVisibleValueAndNonValueItems<T>(
    itemIdField: keyof T,
    visibleItems: T[],
    values: TAllValues<T>,
    showValuesInBeginning: boolean
) {
    if (cachedVisibleItems === visibleItems && cachedValues === values) {
        return { visibleValueItems: cachedVisibleValueItems, visibleNonValueItems: cachedVisibleNonValueItems }
    }

    cachedValues = values
    cachedVisibleItems = visibleItems

    cachedVisibleValueItems = showValuesInBeginning ? visibleItems.filter((item) => values.has(item[itemIdField])) : []
    cachedVisibleNonValueItems = showValuesInBeginning
        ? visibleItems.filter((item) => !values.has(item[itemIdField]))
        : visibleItems

    if (!showValuesInBeginning) {
        return { visibleValueItems: [] as any[], visibleNonValueItems: cachedVisibleNonValueItems }
    }

    return { visibleValueItems: cachedVisibleValueItems, visibleNonValueItems: cachedVisibleNonValueItems }
}

export default class MenuContentRow<T> extends React.Component<IMenuContentRowProps<T>> {
    render(): React.ReactNode {
        const {
            items,
            selectAction,
            selectAllAction,
            selectedNewItems,
            itemIdField,
            itemOptionLabelFields,
            selectedValueItems,
            selectValueRowAction,
            RowTextComponent,
            index,
            style: virtualizedListRowStyle,
            multiselect,
            values,
        } = this.props

        const showValuesInBeginning = multiselect || values.size === 1
        const { visibleValueItems, visibleNonValueItems } = getVisibleValueAndNonValueItems(
            itemIdField,
            items,
            values,
            showValuesInBeginning
        )

        const isFirstRow = index === 0

        if (multiselect && isFirstRow) {
            return (
                <MassSelectionRow
                    itemIdField={itemIdField}
                    items={items}
                    selectAllAction={selectAllAction}
                    selectedNewItems={selectedNewItems}
                    selectedValueItems={selectedValueItems}
                    virtualizedListRowStyle={virtualizedListRowStyle}
                />
            )
        }

        const indexToUse = multiselect ? index - 1 : index

        const valueItem = visibleValueItems[indexToUse]

        if (showValuesInBeginning && valueItem) {
            const isLastValueItem = indexToUse === visibleValueItems.length - 1
            const rowItemComponentInstance = (
                <RowTextComponent item={valueItem} itemOptionLabelFields={itemOptionLabelFields} />
            )

            return (
                <ValueRow
                    item={valueItem}
                    itemIdField={itemIdField}
                    renderHr={isLastValueItem}
                    rowItemComponentInstance={rowItemComponentInstance}
                    selectAction={selectValueRowAction}
                    selectedValueItems={selectedValueItems}
                    virtualizedListRowStyle={virtualizedListRowStyle}
                />
            )
        }

        const nonValueItemIndex = indexToUse - visibleValueItems.length

        const item = visibleNonValueItems[nonValueItemIndex]

        const rowItemComponentInstance = <RowTextComponent item={item} itemOptionLabelFields={itemOptionLabelFields} />

        return (
            <ItemRow<T>
                item={item}
                itemIdField={itemIdField}
                multiselect={multiselect}
                rowItemComponentInstance={rowItemComponentInstance}
                selectAction={selectAction}
                selectedNewItems={selectedNewItems}
                virtualizedListRowStyle={virtualizedListRowStyle}
            />
        )
    }
}
