import moment, { Moment } from 'moment'

import { TimeInputV2 } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from '@planier/value-picker'
import { JSX } from 'react'

interface ITimePickerProps extends IValuePickerCommonOwnProps<Moment> {}

const FormViewTimeInput = ({ value, onChange, label, errors, valuePickerId }: ITimePickerProps): JSX.Element => {
    return <TimeInputV2 label={label ?? ''} errors={errors} value={value ? moment(value) : null} onChange={onChange} valueInputId={valuePickerId} />
}

export default FormViewTimeInput
