import IDataSourceConfiguration from '../../../Types/IDataSourceConfiguration'
import EDataSourceActionCrudType from '../../../Constants/EDataSourceActionCrudType'
import EDataSourceActionMethods from '../../../Constants/EDataSourceActionMethods'
import getEventDataSourceProperties from './Properties'

export const getTestConfiguration = (configuration?: Partial<IDataSourceConfiguration>): IDataSourceConfiguration => ({
    Id: 'TyontekijanakymaDataSource',
    Actions: [
        {
            Id: 'Tyontekijanakyma_Action_Create',
            DataPropertyParameters: [],
            EndpointUrl: '/v2/Tapahtuma',
            Method: EDataSourceActionMethods.POST,
            CrudType: EDataSourceActionCrudType.Create,
            StaticParameters: {},
            SuccessMessage: 'Tapahtumat luotu onnistuneesti',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'Tyontekijanakyma_Action_Edit',
            DataPropertyParameters: [],
            EndpointUrl: '/v2/Tapahtuma',
            Method: EDataSourceActionMethods.PATCH,
            CrudType: EDataSourceActionCrudType.Update,
            StaticParameters: {},
            SuccessMessage: 'Tapahtuman muokkaus onnistui',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'Tyontekijanakyma_Action_Remove_Tyontekija',
            DataPropertyParameters: [{ Key: 'TapahtumaIdt', PropertyPath: 'Id' }],
            EndpointUrl: '/v2/Tapahtuma/Tyontekija',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            SuccessMessage: 'Työntekijä poistettu tapahtumalta',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'Tyontekijanakyma_Action_Remove_Tapahtuma',
            DataPropertyParameters: [{ Key: 'Ids', PropertyPath: 'Id' }],
            EndpointUrl: '/v2/Tapahtuma',
            Method: EDataSourceActionMethods.DELETE,
            StaticParameters: {},
            SuccessMessage: 'Valitut tapahtumat poistettu',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'Tyontekijanakyma_Action_Copy_Tapahtuma',
            DataPropertyParameters: [{ Key: 'Ids', PropertyPath: 'Id' }],
            EndpointUrl: '/v2/Tapahtuma/Copy',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            SuccessMessage: 'Valitut tapahtumat kopioitu',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'Tyontekijanakyma_Action_Swap_Tapahtuma',
            DataPropertyParameters: [{ Key: 'Ids', PropertyPath: 'Id' }],
            EndpointUrl: '/v2/Tapahtuma/Swap',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            SuccessMessage: 'Valitut tapahtumat vaihdettu',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'Publish_Events',
            DataPropertyParameters: [{ Key: 'Ids', PropertyPath: 'Id' }],
            EndpointUrl: '/v2/Tapahtuma/Publish',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            SuccessMessage: 'Valitut tapahtumat julkaistu',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'CreateEventTemplateGroup',
            DataPropertyParameters: [{ Key: 'EventIds', PropertyPath: 'Id' }],
            EndpointUrl: '/v2/Tapahtuma/EventTemplateGroup',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            SuccessMessage: 'Tapahtumaprofiili luotu onnistuneesti',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
        {
            Id: 'CreateEventsFromEventTemplateGroup',
            DataPropertyParameters: [],
            EndpointUrl: '/v2/Tapahtuma/CreateEventsFromEventTemplateGroup',
            Method: EDataSourceActionMethods.POST,
            StaticParameters: {},
            SuccessMessage: 'Tapahtumat luotu onnistuneesti',
            DataSourceId: 'TyontekijanakymaDataSource',
        },
    ],
    Url: 'v2/Tapahtuma/Tyontekijanakyma',
    Properties: getEventDataSourceProperties(),
    DependsOn: ['TarvenakymaDataSource'],
    ...configuration,
})
