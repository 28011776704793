import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { BoundThunk } from '@planier/generic-state'
import { publishEventsThunk } from '../../State/Thunks/WorkforceSchedulingThunks'
import { TDataSourceItemId } from '@planier/data-source-types'
import {
    selectActiveCalendarId,
    selectSelectedNodesForActiveCalendar,
} from '../../State/Selectors/WorkforceSchedulingSelectors'
import PublishConfirmationDialogBase from './PublishConfirmationDialogBase'
import { EVENTS_PUBLISHING_CONFIRMATION_DIALOG_ID } from '../../Constants/WorkforceSchedulingConstants'

interface IDispatchProps {
    publishEvents: BoundThunk<typeof publishEventsThunk>
}

interface IStateProps {
    activeCalendarSelectedNodes: ReadonlySet<TDataSourceItemId>
    activeCalendarId: string | null
}

interface IPublishEventsConfirmationDialogProps extends IStateProps, IDispatchProps {}

const PublishEventsConfirmationDialogUnconnected: React.FC<IPublishEventsConfirmationDialogProps> = ({
    publishEvents,
    activeCalendarSelectedNodes,
    activeCalendarId,
}) => {
    if (!activeCalendarId) {
        return null
    }

    const onSubmit = () => publishEvents(activeCalendarId)

    return (
        <PublishConfirmationDialogBase
            modalId={EVENTS_PUBLISHING_CONFIRMATION_DIALOG_ID}
            onSubmit={onSubmit}
            selectedEvents={activeCalendarSelectedNodes}
        />
    )
}

const mapStateToProps = (state: RootState): IStateProps => ({
    activeCalendarSelectedNodes: selectSelectedNodesForActiveCalendar(state),
    activeCalendarId: selectActiveCalendarId(state),
})

const mapDispatchToProps = {
    publishEvents: publishEventsThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishEventsConfirmationDialogUnconnected)
