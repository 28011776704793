import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import { TextInputV2 } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from '@planier/value-picker'
import { debounce } from 'lodash-es'

interface ITextSearchPickerProps extends IValuePickerCommonOwnProps<string> {
    multiline?: boolean
}

const defaultDebounceTime = 50

const TextPicker: React.FC<ITextSearchPickerProps> = ({ required, label, multiline, value, onChange, errors }) => {
    const [val, setValue] = useState<string>('')

    useEffect(() => {
        if (typeof value === 'string') {
            setValue(value)
        }
        if (value === null) {
            setValue('')
        }
    }, [value])

    // useCallback can't know the dependencies of _.debounce so it complains about that even if
    // we list the dependencies properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValuePickerValueDebounced = useCallback(
        debounce((newUserInput: string) => {
            onChange(newUserInput)
        }, defaultDebounceTime),
        [onChange]
    )

    const handleChange = (input: string) => {
        const newValue = input

        setValue(newValue)
        setValuePickerValueDebounced(newValue)
    }

    return (
        <TextInputV2
            multiline={multiline}
            placeholder={label}
            errors={errors}
            isRequiredField={required}
            label={label}
            onChange={handleChange}
            value={val}
        />
    )
}

export default TextPicker
