import { createSelector } from 'reselect'

import { selectOpenModals } from '@planier/modal'
import { LIST_MODAL_ID_PREFIX, IConfigurableListModalProps } from '@planier/configurable-list'
import TListModalReducerState from '../Types/TListModalReducerState'

export const selectOpenListModals = createSelector(
    selectOpenModals,
    (openModals: TListModalReducerState): TListModalReducerState => {
        const openListModalsAsMapArray = [...openModals]
            .filter(([, { props }]) => Boolean(props?.listId))
            .filter(([modalId, modal]) => {
                const isListModal =
                    modalId === LIST_MODAL_ID_PREFIX + (modal.props as IConfigurableListModalProps).listId
                return isListModal
            })

        return new Map(openListModalsAsMapArray)
    }
)
