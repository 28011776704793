import { useDispatch, useSelector } from 'react-redux'
import NotificationSystem, { Style, System } from 'react-notification-system'
import * as React from 'react'
import { JSX, useEffect, useRef } from 'react'
import { RootState } from 'typesafe-actions'
import _Log from 'common/Log'
import { PlanierTheme, useAppTheme } from '@planier/theme'
import { clearNotifications } from '@planier/notifications/Actions/NotificationActions'
import { isEmpty } from 'lodash-es'

const Log = new _Log('NotificationHandler')

const getNotificationStyle = ({ colors }: PlanierTheme): Style => ({
    NotificationItem: {
        DefaultStyle: {
            color: colors.neutralsGrey100,
            padding: '12px 12px 24px 12px',
            borderRadius: '5px',
        },
        success: {
            backgroundColor: colors.primaryCyan11,
            borderTop: `5px solid ${colors.semanticSuccess100}`,
        },
        error: {
            backgroundColor: colors.semanticWarning11,
            borderTop: `5px solid ${colors.semanticWarning100}`,
        },
        warning: {
            backgroundColor: colors.secondaryYellow11,
            borderTop: `5px solid ${colors.secondaryYellow100}`,
        },
    },
    Dismiss: {
        DefaultStyle: {
            top: '8px',
            backgroundColor: 'transparent',
            borderRadius: 0,
            fontSize: '24px',
            width: '24px',
            height: '24px',
            fontFamily: 'sans-serif',
            fontWeight: 100,
        },
        success: {
            color: colors.semanticSuccess100,
        },
        error: {
            color: colors.semanticWarning100,
        },
        warning: {
            color: colors.secondaryYellow120,
        },
    },
})

/**
 * Hoitaa notifikaatioiden näyttämisen.
 *
 * HUOM. Tämä ei toistaiseksi hoida koko ohjelmiston notifikaatioita,
 * vaan pelkästään Reduxin action-creatoreilla dispatchattujen
 * actionien virheet.
 */
const NotificationHandler = (): JSX.Element => {
    const theme = useAppTheme()
    const dispatch = useDispatch()

    const notifications = useSelector((state: RootState) => state.notifications)
    const notificationSystemRef = useRef<NotificationSystem>()

    useEffect(() => {
        if (isEmpty(notifications)) {
            return
        }

        const showNotifications = (notificationSystem: System) => {
            notifications.forEach((notification) => {
                notificationSystem.addNotification(notification)
            })
        }

        const notificationSystem = notificationSystemRef.current

        if (notificationSystem === null) {
            Log.debug('Notification system not yet initialised. Not displaying notifications.')
            return
        }

        showNotifications(notificationSystem)
        dispatch(clearNotifications())
    }, [dispatch, notifications])

    return <NotificationSystem ref={notificationSystemRef} style={getNotificationStyle(theme)} />
}

export default NotificationHandler
