import * as React from 'react'

import Translation from '../Utils/Translation'
import { getLogger } from '@planier/log'
import { mapValues } from 'lodash-es'

const Log = getLogger('localization.Translate')

interface ITranslationArgument {
    key?: string
    params?: Record<string, unknown>
    bypassValue?: React.ReactNode
}

export type TTranslatable = ITranslationArgument | string

export const isTranslatable = (maybeTranslatable: TTranslatable | unknown): maybeTranslatable is TTranslatable => {
    if (typeof maybeTranslatable === 'string') {
        return true
    }

    if (typeof maybeTranslatable !== 'object') {
        return false
    }

    return Boolean(
        (maybeTranslatable as ITranslationArgument).bypassValue || (maybeTranslatable as ITranslationArgument).key
    )
}

export interface ITranslatableMap {
    [key: string]: ITranslationArgument | string | undefined
}

interface ITranslatedMap {
    [key: string]: React.ReactNode
}

interface ITranslateProps {
    translate: ITranslatableMap
    children: (translated: ITranslatedMap) => JSX.Element
}

const Translate: React.FunctionComponent<ITranslateProps> = ({ children, translate }) => {
    const translated = mapValues(translate, (translationArgument) => {
        if (!translationArgument) {
            return undefined
        }

        if (typeof translationArgument === 'string') {
            return Translation.translateKey(translationArgument)
        }

        const { key, params, bypassValue } = translationArgument

        if (bypassValue) {
            return bypassValue
        }

        if (!key) {
            Log.debug('Käännökselle pitää määritellä joko `key` tai `bypassValue`')
            return undefined
        }

        const translatedText = Translation.translateKey(key, params)

        return translatedText
    })

    return children(translated)
}

export default Translate
