import { Translation } from '@planier/localization'

interface IGetErrorsForFormFieldReturn<T = string> {
    isFieldErrored: boolean
    error: T | null
}

export const getErrorsForFormField = <T = string>(
    fieldName: string,
    formErrors: Record<string, T>,
    touchedFields: Record<string, unknown>
): IGetErrorsForFormFieldReturn<T> => {
    const error = formErrors[fieldName] ?? null
    const isTouched = touchedFields[fieldName]

    const isFieldErrored = Boolean(isTouched && error)

    const localizedError = error && Translation.has(error) ? ((Translation.translateKey(error) as unknown) as T) : error

    return { isFieldErrored, error: localizedError }
}
