import { connect } from 'react-redux'
import * as React from 'react'
import { useEffect, useImperativeHandle, useRef } from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'
import { WindowScroller } from 'react-virtualized'
import Paper from '@material-ui/core/Paper'

import ColumnManagementModal from './components/ColumnManagementModal'
import { initializeListThunk, resetListDataThunk } from './Thunks/ConfigurableListThunks'
import {
    selectDataSourceId,
    selectIsColumnManagementAllowed,
    selectIsListAutoFetchDataEnabled,
    selectListViewFilterIds,
} from './State/ConfigurableListSelectors'
import { selectIsDataSourceDataLoading, selectIsDataSourceInitialized } from '@planier/data-source'
import { IDataSourceItem } from '@planier/data-source-types'
import { TListIconData } from './interfaces/IList'
import ListActionConfirmationDialog from './components/ListActionConfirmationDialog'
import ListContent from './components/ListContent'
import ListFilterListener from './components/ListFilterListener'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import RowHeaders from './components/RowHeaders'
import { BoundThunk } from '@planier/generic-state'
import { IInitialValuePickersValues, selectAreValuePickersInitialised } from '@planier/value-picker'
import useFilterItselfWhenSetToDoSo from './Hooks/useFilterItselfWhenSetToDoSo'
import { resetAllSelectedListItemsAction } from './State/ConfigurableListActions'

interface IOwnProps {
    additionalDataSourceDataRequestFiltersParameters?: Record<string, unknown> | null
    iconData?: TListIconData
    listId: string
    onRowSelect?: (item: IDataSourceItem) => void
    isVirtualized?: boolean
    shouldAddScrollListener?: boolean
    initialValuePickersValues?: IInitialValuePickersValues | null
    shouldEmptyDataWhenUnmounting?: boolean
    resetValuePickerValues?: boolean
}

interface IStateProps {
    columnManagementAllowed: boolean
    dataLoading: boolean
    isDataSourceInitialized: boolean
    isAutoFetchEnabled: boolean
    valuePickersAreInitialized: boolean
}

interface IDispatchProps {
    initializeList: BoundThunk<typeof initializeListThunk>
    resetAllSelectedListItems: typeof resetAllSelectedListItemsAction
    resetListData: BoundThunk<typeof resetListDataThunk>
}

export interface IConfigurableListOwnProps extends IOwnProps {}

export interface IConfigurableListProps extends IOwnProps, IStateProps, IDispatchProps {}

// Ensure that it takes enough room. Otherwise for example in dialogs it might
// cause the dialog to change its height constantly as loading indicator spins
// around
const StyledLoadingIndicatorInline = styled(LoadingIndicatorInline)`
    min-height: 65px;
    display: block;
    padding-left: 30px;
`

const Container = styled(Paper, {
    shouldForwardProp: (propName) => propName !== 'isVirtualized',
})<{ isVirtualized: boolean }>`
    overflow: ${({ isVirtualized }) => (isVirtualized ? 'hidden' : 'auto')};
    /* Padding is necessary to display each row's box shadow correctly. */
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    margin: 0 8px 8px 8px;
    flex: 1 1 auto;
`

export interface IConfigurableListHandles {
    recalculateScrollPosition: () => void
}

export const ConfigurableListUnconnected = React.forwardRef<IConfigurableListHandles, IConfigurableListProps>(
    (
        {
            dataLoading,
            iconData,
            listId,
            initializeList,
            isAutoFetchEnabled,
            additionalDataSourceDataRequestFiltersParameters,
            isDataSourceInitialized,
            columnManagementAllowed,
            onRowSelect,
            isVirtualized = false,
            shouldAddScrollListener = false,
            initialValuePickersValues = null,
            valuePickersAreInitialized,
            resetAllSelectedListItems,
            resetListData,
            shouldEmptyDataWhenUnmounting = true,
            resetValuePickerValues = false,
        },
        ref
    ) => {
        const listContainerRef = useRef<HTMLDivElement>(null)
        const windowScrollRef = useRef<WindowScroller>(null)

        useEffect(() => {
            initializeList(
                listId,
                additionalDataSourceDataRequestFiltersParameters,
                initialValuePickersValues,
                resetValuePickerValues
            )

            // When list unmounts
            return () => {
                resetAllSelectedListItems(listId)

                if (shouldEmptyDataWhenUnmounting) {
                    resetListData(listId)
                }
            }
        }, [
            resetAllSelectedListItems,
            initializeList,
            additionalDataSourceDataRequestFiltersParameters,
            listId,
            initialValuePickersValues,
            resetListData,
            shouldEmptyDataWhenUnmounting,
            resetValuePickerValues,
        ])

        useFilterItselfWhenSetToDoSo(listId)

        useImperativeHandle(
            ref,
            () => ({
                recalculateScrollPosition: () => {
                    windowScrollRef.current && windowScrollRef.current.updatePosition()
                },
            }),
            []
        )

        if (!isDataSourceInitialized) {
            return <StyledLoadingIndicatorInline isLoading size={50} />
        }

        return (
            <Container elevation={2} ref={listContainerRef} isVirtualized={isVirtualized}>
                {!isVirtualized && <RowHeaders isLoadingInitialData={dataLoading} listId={listId} />}
                <ListContent
                    iconData={iconData}
                    isLoadingInitialData={dataLoading}
                    isVirtualized={isVirtualized}
                    listId={listId}
                    onRowSelect={onRowSelect}
                    ref={windowScrollRef}
                    shouldAddScrollListener={shouldAddScrollListener}
                />
                {isAutoFetchEnabled && valuePickersAreInitialized && <ListFilterListener listId={listId} />}
                {columnManagementAllowed && <ColumnManagementModal listId={listId} />}
                <ListActionConfirmationDialog />
            </Container>
        )
    }
)

ConfigurableListUnconnected.displayName = 'ConfigurableListUnconnectedForwardRef'

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => {
    const dataSourceId = selectDataSourceId(state, listId)
    const filterIds = selectListViewFilterIds(state, listId)
    const isDataSourceInitialized = selectIsDataSourceInitialized(state, dataSourceId)

    return {
        columnManagementAllowed: selectIsColumnManagementAllowed(state, listId),
        dataLoading: selectIsDataSourceDataLoading(state, dataSourceId),
        isDataSourceInitialized,
        isAutoFetchEnabled: selectIsListAutoFetchDataEnabled(state, listId),
        valuePickersAreInitialized: isDataSourceInitialized && selectAreValuePickersInitialised(state, filterIds),
    }
}

const mapDispatchToProps = {
    initializeList: initializeListThunk,
    resetAllSelectedListItems: resetAllSelectedListItemsAction,
    resetListData: resetListDataThunk,
}

const options = { forwardRef: true }

export default connect(mapStateToProps, mapDispatchToProps, null, options)(ConfigurableListUnconnected)
