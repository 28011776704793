import { RootState } from 'typesafe-actions'

import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import IValuePicker from '../types/IValuePicker'
import TValuePickerId from '../types/TValuePickerId'
import { every, isEqual, set } from 'lodash-es'
import { getLogger } from '@planier/log'

const ValuePickerLog = getLogger('valuePickerInitialised')

const selectValuePicker = (state: RootState, valuePickerId: TValuePickerId): IValuePicker | undefined =>
    state.valuePicker.get(valuePickerId)

export const selectIsValuePickerInitialized = (state: RootState, valuePickerId: TValuePickerId): boolean =>
    Boolean(selectValuePicker(state, valuePickerId))

/**
 * Given a list of value picker IDs, returns the value pickers ID that are not currently present in the state.
 */
export const selectNonExistentValuePickerIds = (
    state: RootState,
    valuePickerIds: TValuePickerId[]
): TValuePickerId[] => {
    const nonExistingValuePickerIds = valuePickerIds.filter((valuePickerId) => !state.valuePicker.has(valuePickerId))

    return nonExistingValuePickerIds
}

export const selectValuePickerConfiguration = (
    state: RootState,
    valuePickerId: TValuePickerId
): TValuePickerConfiguration | null => {
    const valuePicker = selectValuePicker(state, valuePickerId)

    return valuePicker ? valuePicker.configuration : null
}

// TODO: to cache this properly, we'd need to do quite a lot of refactoring
// At the moment, ValuePickerGroup receives the valuePickerIds as own props
// and on every render it's a new array due to how it's derived above it
// in the component tree. That's why even if we cached this selector, it
// wouldn't do any good.
// ValuePickerGroup ideally would be completely independent so that
// it could for example simply receive a list Id and get the value picker IDs
// from store by itself.
export const selectValuePickerConfigurations = (
    state: RootState,
    valuePickerIds: TValuePickerId[]
): TValuePickerConfiguration[] => {
    const configurations = valuePickerIds
        .map((valuePickerId) => selectValuePicker(state, valuePickerId))
        .filter((valuePicker): valuePicker is IValuePicker => Boolean(valuePicker))
        .map((valuePicker) => valuePicker.configuration)

    return configurations
}

export const selectChangedDependentValuePickerConfigurations = (
    state: RootState,
    changedValuePickerId: TValuePickerId
): TValuePickerConfiguration[] => {
    const dependentValuePickers = Array.from(state.valuePicker.values())
        .map(({ configuration }) => configuration)
        .filter((configuration) =>
            configuration.DependsOn.find(({ ValuePickerId }) => ValuePickerId === changedValuePickerId)
        )

    return dependentValuePickers
}

export const selectValuePickerValue = (
    state: RootState,
    valuePickerId: TValuePickerId,
    contextId?: string
): unknown => {
    const valuePicker = state.valuePicker.get(valuePickerId)

    if (!valuePicker) {
        return null
    }

    if (contextId) {
        return valuePicker.contextualValues?.get(contextId)
    }

    return valuePicker.value
}

export const selectValuePickerNotes = (
    state: RootState,
    valuePickerId: TValuePickerId,
    contextId?: string
): string | null | undefined => {
    const valuePicker = state.valuePicker.get(valuePickerId)

    if (!valuePicker) {
        return null
    }

    if (contextId) {
        return valuePicker.contextualValueNotes?.get(contextId)
    }

    return valuePicker.notes
}

export const selectValuePickerSideEffects = (
    state: RootState,
    valuePickerId: TValuePickerId,
    contextId: string
): { key: string; value: string }[] => {
    const valuePicker = state.valuePicker.get(valuePickerId)

    if (!valuePicker) {
        return []
    }

    const sideEffects = valuePicker.contextualValueSideEffects?.get(contextId)

    if (!sideEffects) {
        return []
    }

    return Array.isArray(sideEffects) ? sideEffects : [sideEffects]
}

export const selectValuePickersSideEffects = (
    state: RootState,
    valuePickerIds: Record<string, TValuePickerId>
): { key: string; value: string }[] => {
    const values: { key: string; value: string }[] = []

    for (const contextId in valuePickerIds) {
        const valuePickerId = valuePickerIds[contextId]

        const value = selectValuePickerSideEffects(state, valuePickerId, contextId)

        values.push(...value)
    }

    return values
}

export const selectValuePickersNotes = (
    state: RootState,
    valuePickerIds: Record<string, TValuePickerId>
): Record<string, string> => {
    const values: Record<string, string> = {}

    for (const contextId in valuePickerIds) {
        const valuePickerId = valuePickerIds[contextId]

        const value = (selectValuePickerNotes(state, valuePickerId, contextId) as string) ?? null

        if (value) {
            set(values, contextId, value)
        }
    }

    return values
}

export const selectValuePickerValues = (
    state: RootState,
    valuePickerIds: Record<string, TValuePickerId>
): Record<TValuePickerId, string> => {
    const values: Record<TValuePickerId, string> = {}

    for (const contextId in valuePickerIds) {
        const valuePickerId = valuePickerIds[contextId]

        const value = selectValuePickerValue(state, valuePickerId, contextId)

        set(values, contextId, value)
    }

    return values
}

type TValuePickerValuesObject = { [key: string]: unknown }

const selectValuePickerValuesNonCached = (
    state: RootState,
    valuePickerIds: TValuePickerId[]
): TValuePickerValuesObject => {
    return valuePickerIds.reduce((values, valuePickerId) => {
        const valuePickerValue = selectValuePickerValue(state, valuePickerId)

        values[valuePickerId] = valuePickerValue
        return values
    }, {} as TValuePickerValuesObject)
}

const valueCache = new Map<string, TValuePickerValuesObject>()
// exported only to make testing easier
export const resetValueCache = (): void => {
    valueCache.forEach((_value, key) => {
        valueCache.delete(key)
    })
}
/**
 * Returns an object in which the given value picker IDs are keys and
 * the value of each value picker are the values.
 *
 * Note that this uses a custom solution for caching. Neither reselect
 * or re-reselect is good enough for this purpose, because of the dynamic
 * nature of the valuePickerIds parameter.
 * For example if we used re-reselect and had either the valuePickerIds as
 * the cache key as a stringified form, it still couldn't compute the new
 * values properly without resetting its cache a lot of the time. It would
 * either have to use
 *  1. `selectValuePicker` selector (which simply returns the state for all
 *     value pickers) and compute the TValuePickerValuesObject object itself or
 *  2. a reselect-cached selector (with deep equality check) to get the TValuePickerValuesObject
 *     and simply return it, thinking that re-reselect's caching would tie it
 *     to the `valuePickerIds` key.
 *
 * In the first case the cache would be reset any time any value picker value changed.
 * It still wouldn't happen that often, but could still cause a lot of unnecessary
 * costly re-renders in a lot of cases.
 *
 * In the second case, the reselect-cached selector itself wouldn't care about the
 * valuePickerIds parameter and would reset its cache any time it would be called
 * with different valuePickerIds. So for example in workforce scheduling, where we listen
 * to the changes of the values of the given value pickers in two calendars separately,
 * it would always be called subsequently with different valuePickerIds parameter (as each
 * calendar has different value pickers) and that's why it would always reset its cache
 * and return a new object and would force the re-reselect selector to return a new
 * object too.
 *
 * Because of that we use a custom solution
 */
export const selectValuePickersValues = (
    state: RootState,
    valuePickerIds: TValuePickerId[]
): TValuePickerValuesObject => {
    const valuePickerIdsAsString = valuePickerIds.join()

    if (!valueCache.has(valuePickerIdsAsString)) {
        valueCache.set(valuePickerIdsAsString, selectValuePickerValuesNonCached(state, valuePickerIds))
        return valueCache.get(valuePickerIdsAsString) as TValuePickerValuesObject
    }

    const anyValueHasChanged = valuePickerIds.some((valuePickerId) => {
        const currentValue = (valueCache.get(valuePickerIdsAsString) as TValuePickerValuesObject)[valuePickerId]
        const newValue = selectValuePickerValue(state, valuePickerId)

        return !isEqual(newValue, currentValue)
    })

    if (anyValueHasChanged) {
        valueCache.set(valuePickerIdsAsString, selectValuePickerValuesNonCached(state, valuePickerIds))
    }

    return valueCache.get(valuePickerIdsAsString) as TValuePickerValuesObject
}

export const selectAreValuePickersInitialised = (state: RootState, valuePickerIds: TValuePickerId[]): boolean => {
    return every(valuePickerIds, (valuePickerId) => {
        if (!state.valuePicker.has(valuePickerId)) {
            ValuePickerLog.warn(`Value picker ${valuePickerId} was not initialized properly`)
            return false
        }
        return true
    })
}
