import * as React from 'react'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import styled from '@emotion/styled'

import { Tooltip } from '@planier/generic-components'

interface IPageDescriptionProps {
    descriptionText: string | null
    className?: string
}

const StyledInfoIcon = styled(InfoOutlinedIcon)`
    height: 14px;
    width: 14px;
`

const PageDescription: React.FC<IPageDescriptionProps> = ({ descriptionText, className }) => {
    if (!descriptionText) {
        return null
    }

    return (
        <Tooltip className={className} title={descriptionText}>
            <StyledInfoIcon color="primary" />
        </Tooltip>
    )
}

export default PageDescription
