import styled from '@emotion/styled'
import { Icon, ReadOnlyText } from '@planier/generic-components'
import * as React from 'react'
import { JSX } from 'react'
import { useAppTheme } from '@planier/theme'

type IFormViewTitleProps = {
    title: string | null
    titleInfo: string | null
}

const Container = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
`

const StyledIcon = styled(Icon)`
    margin-left: 10px;
    cursor: pointer;
`

const FormViewTitle = ({ title, titleInfo }: IFormViewTitleProps): JSX.Element => {
    const { componentExtensions } = useAppTheme()

    return (
        <Container>
            <ReadOnlyText usage="h5" rawColor={componentExtensions.text.primary}>
                {title}
            </ReadOnlyText>

            <StyledIcon tooltip={titleInfo} color={componentExtensions.icons.iconAction} hideIfNoTooltip>
                info_outline
            </StyledIcon>
        </Container>
    )
}

export default FormViewTitle
