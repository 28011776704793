import * as React from 'react'
import styled from '@emotion/styled'

import { IDataSourceGroupData } from '@planier/data-source-types'
import { AggregatedDataSummaryFromGroupData } from '@planier/data-source'
import { TranslatedTypography } from '@planier/generic-components'

interface IOwnProps {
    groupData: IDataSourceGroupData[] | null
    publishNotification: string
}

interface IPublishDialogContentProps extends IOwnProps {}

const AggregatedDataContainer = styled.div`
    margin-bottom: 32px;
`

const Container = styled.div`
    margin-bottom: 24px;
`

const PublishDialogContent: React.FC<IPublishDialogContentProps> = ({ groupData, publishNotification }) => {
    if (!groupData || groupData.length === 0) {
        return null
    }

    return (
        <Container>
            <AggregatedDataContainer>
                <AggregatedDataSummaryFromGroupData groupData={groupData} />
            </AggregatedDataContainer>

            <TranslatedTypography variant="subtitle1">{publishNotification}</TranslatedTypography>
        </Container>
    )
}

export default PublishDialogContent
