import * as React from 'react'
import styled from '@emotion/styled'

import INavigationSubsection from '../../../Types/INavigationSubsection'
import TabLabel from './TabLabel'

interface ISubsectionTabProps {
    children: string
    to: string
    currentPageSubsection: INavigationSubsection | null
    subsection: INavigationSubsection | null
}

const StyledSectionLabel = styled(TabLabel)`
    padding-left: 60px;
    font-weight: 400;

    ${({ isCurrentPage }) =>
        isCurrentPage &&
        `
        font-weight: 700;
    `}
`

const SubsectionTab: React.FC<ISubsectionTabProps> = ({ to, children, subsection, currentPageSubsection }) => {
    const subsectionIsTheCurrentPage = Boolean(subsection && subsection.Id === currentPageSubsection?.Id)

    return (
        <StyledSectionLabel isCurrentPage={subsectionIsTheCurrentPage} to={to}>
            {children}
        </StyledSectionLabel>
    )
}

export default SubsectionTab
