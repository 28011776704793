import makeRequest, { ERequestMethod } from '@planier/rest-api'
import IRedirectionParameters from '../Types/IRedirectionParameters'
import { EOperatingSystem } from '@planier/browser-utilities'
import { logoutSyncedAcrossTabs } from '../Utilities/LogoutUtilities'
import { deleteJwt } from '@planier/authentication'

export const login = async (username: string, password: string): Promise<string | void> =>
    makeRequest<string | void>({
        method: ERequestMethod.POST,
        url: '/Login',
        data: {
            username,
            password,
        },
    })

export const logout = (): void => {
    deleteJwt()
    logoutSyncedAcrossTabs()
}

export const fetchMicrosoftLoginStatus = async (): Promise<boolean> => {
    return await makeRequest<boolean>({
        method: ERequestMethod.GET,
        url: '/IsAzureADSignInModuleEnabled',
    })
}

export const requestPasswordReset = async (username: string): Promise<void> =>
    makeRequest<void>({
        method: ERequestMethod.POST,
        url: '/Login/RequestPasswordReset',
        data: {
            username,
        },
    })

export const resetPassword = async (
    newPassword: string,
    resetRequestId: string,
    operatingSystem: EOperatingSystem
): Promise<IRedirectionParameters> =>
    makeRequest<IRedirectionParameters>({
        method: ERequestMethod.PUT,
        url: '/Login/PasswordReset',
        data: {
            newPassword,
            resetRequestId,
            operatingSystem,
        },
    })

export const signUp = async (
    newPassword: string,
    guid: string,
    operatingSystem: EOperatingSystem
): Promise<IRedirectionParameters> =>
    makeRequest<IRedirectionParameters>({
        method: ERequestMethod.POST,
        url: '/Login/SignUp',
        data: {
            NewPassword: newPassword,
            Guid: guid,
            // Do not call this function without having this as accepted
            TermsAndConditionsAccepted: true,
            operatingSystem,
        },
    })
