import * as React from 'react'
import styled from '@emotion/styled'

import { Icon, SystemInternalLink, Tooltip } from '@planier/generic-components'

interface IValueLinkProps {
    valuePickerValues: ReadonlySet<string | number>
    valueLinkStart: string
    tooltip: string
    className?: string
}

const HiddenIcon = styled(Icon)`
    visibility: hidden;
`

const ValueLink: React.FC<IValueLinkProps> = ({ className, valuePickerValues, valueLinkStart, tooltip }) => {
    if (valuePickerValues.size !== 1) {
        // reserve space so that its height doesn't mess with things when it comes up
        return <HiddenIcon colorTheme="primary">open_in_new</HiddenIcon>
    }

    const valueLinkWithValue = valueLinkStart + [...valuePickerValues][0]

    return (
        <Tooltip title={tooltip} wrapperElementClassName={className}>
            <SystemInternalLink
                className={className}
                openInNewTab
                to={valueLinkWithValue}
                windowParams="toolbar=yes,scrollbars=yes,resizable=yes,left=300,width=1100,height=900"
            >
                <Icon colorTheme="primary">open_in_new</Icon>
            </SystemInternalLink>
        </Tooltip>
    )
}

export default ValueLink
