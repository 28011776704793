import * as React from 'react'
import { Field } from 'formik'
import styled from '@emotion/styled'

import Label from 'components/atoms/Label'
import IListViewColumnModel from '../../../interfaces/IListViewColumnModel'
import { FormikCheckbox } from '@planier/generic-components'

interface IColumnSettingsRowProps {
    columnSettings: IListViewColumnModel
    lastRow: boolean
}

const RowContainer = styled.div<{ lastRow: boolean }>`
    border-color: ${({ theme }) => theme.colors.neutralsGrey30};
    border-style: solid;
    border-width: 1px 0;
    border-bottom-width: ${({ lastRow }: { lastRow: boolean }) => (lastRow ? '1px' : '0')};
    padding-top: 14px;
    padding-bottom: 14px;
`

const ColumnSettingsRow: React.FunctionComponent<IColumnSettingsRowProps> = ({ columnSettings, lastRow }) => {
    const { Id, Header } = columnSettings

    return (
        <RowContainer lastRow={lastRow}>
            <Label doNotTranslate text={Header}>
                <Field component={FormikCheckbox} name={`${Id}.Visible`} />
            </Label>
        </RowContainer>
    )
}

export default ColumnSettingsRow
