import { NumberInput } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from 'packages/value-picker'

interface INumberPickerProps extends IValuePickerCommonOwnProps<number> {}

const TextSearchPicker: React.FC<INumberPickerProps> = ({
    required,
    label,
    value,
    onChange,
    errors,
    valuePickerId,
}) => {
    return (
        <NumberInput
            isRequiredField={required}
            label={label}
            errors={errors}
            placeholder={label}
            onChange={onChange}
            value={value}
            valueInputId={valuePickerId}
        />
    )
}

export default TextSearchPicker
