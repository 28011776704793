import { RootState } from 'typesafe-actions'
import * as Sentry from '@sentry/react'

import { createAsyncThunk } from '@reduxjs/toolkit'
import { IThunkBaseAction } from '@planier/generic-state'
import { displayErrorToaster, displaySuccessToaster } from '@planier/notifications'
import {
    selectCalendarDataSourceId,
    selectCalendarDates,
    selectSelectedCalendarNodes,
} from '@planier/calendar/State/ConfigurableCalendarSelectors'
import { executeDataItemActionThunk, fetchDataSourceDataThunk } from '@planier/data-source'
import { TDataSourceItemId } from '@planier/data-source-types'
import {
    fetchValuePickerConfigurationsThunk,
    IDateRange,
    resetValuePickerValuesAction,
    setValuePickersValuesAction,
    setValuePickerValueAction,
} from '@planier/value-picker'
import {
    fetchValuePickerWithOptionsDataThunk,
    setValuePickerWithOptionsValueThunk,
} from '@planier/value-picker-with-options'
import publishSuunnittelujaksoversio from '../../WebApi/PublishSuunnittelujaksoversio'
import optimizeSuunnittelujaksoversio from '../../WebApi/OptimizeSuunnittelujaksoversio'
import { asyncOperationStartedAction, asyncOperationSucceededAction } from '@planier/async-operation'
import {
    ADD_EMPLOYEE_DATA_SOURCE_ACTION_ID,
    PLANNING_PERIODS_VALUE_PICKER_ID,
    TARVENAKYMA_PUBLISH_EVENTS_ACTION_ID,
    TYONTEKIJA_PUBLISH_EVENTS_ACTION_ID,
    TARVENAKYMA_CALENDAR_ID,
    TYONTEKIJANAKYMA_CALENDAR_ID,
    FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_START_DATE,
    FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_END_DATE,
    FORM_VIEW_CREATE_PLANNING_PERIOD,
    DATA_SOURCE_PLANNING_PERIOD,
    FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_WORK_UNITS,
    FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_WORK_EMPLOYEES,
    FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_POOLS,
} from '../../Constants/WorkforceSchedulingConstants'
import {
    VALUE_PICKER_EMPLOYEES,
    VALUE_PICKER_PLANNING_PERIODS_RECENT,
    VALUE_PICKER_POOL,
    VALUE_PICKER_WORK_UNIT,
} from '../../Constants/WorkforceSchedulingValuePickerConstants'
import {
    selectCombinedFilterIds,
    selectCurrentlySelectedEmployees,
    selectCurrentlySelectedPools,
    selectCurrentlySelectedWorkUnits,
    selectDemandCalendarValuePickersWithValues,
    selectEmployeeCalendarValuePickersWithValues,
    selectPlanningPeriod,
    selectPlanningPeriodEmployeeIds,
    selectPlanningPeriodPoolIds,
    selectPlanningPeriodWorkUnitIds,
} from '../Selectors/WorkforceSchedulingSelectors'
import ISuunnittelujakso from '../../Types/ISuunnittelujakso'
import {
    storedOptionsDataFetchedAction,
    IStoredOptionsOptionIdentifierObject,
    selectStoredOptionsComponentAllOptions,
    storedOptionsSetDataAction,
} from '@planier/stored-options'
import { getLogger } from '@planier/log'
import {
    setCalendarInitialValuePickersValues,
    selectCalendarInitialValuePickersValues,
    selectCalendarInitialDates,
    IPublicHoliday,
} from '@planier/calendar'
import { IFetchStoredOptionsDataThunkOptions } from '@planier/stored-options'
import { openFormViewConfigurableModalThunk } from '@planier/form-view'
import { formatForDataUsage } from '@planier/dates'
import makeRequest, { ERequestMethod } from '@planier/rest-api'

const Log = getLogger('workforce-scheduling.WorkforceSchedulingThunks')

const getCalendarDataSourceId = (storeState: RootState, calendarId: string): string => {
    const dataSourceId = selectCalendarDataSourceId(storeState, calendarId)
    if (!dataSourceId) {
        throw new Error(`No datasource for calendar '${calendarId}'`)
    }
    return dataSourceId
}

export const publishSuunnittelujaksoversioThunk = (
    suunnittelujaksoversioId: number,
    tarvenakymaCalendarId: string,
    tyontekijanakymaCalendarId: string
): IThunkBaseAction => async (dispatch, getState) => {
    try {
        await publishSuunnittelujaksoversio(suunnittelujaksoversioId)

        const state = getState()
        const tarvenakymaDatasourceID = getCalendarDataSourceId(state, tarvenakymaCalendarId)
        const tyontekijanakymaDatasourceID = getCalendarDataSourceId(state, tyontekijanakymaCalendarId)

        const dataSourceDataFetchPromises = [
            dispatch(fetchDataSourceDataThunk(tarvenakymaDatasourceID)),
            dispatch(fetchDataSourceDataThunk(tyontekijanakymaDatasourceID)),
        ]
        await Promise.all(dataSourceDataFetchPromises)

        dispatch(displaySuccessToaster('workforce-scheduling.PublishingSucceeded'))
    } catch (e) {
        dispatch(
            displayErrorToaster(e, {
                startOfErrorMessage: 'workforce-scheduling.PublishingFailed',
            })
        )

        return e
    }
}

export const optimizeSuunnittelujaksoversioThunk = (
    suunnittelujaksoversioId: number,
    tarvenakymaCalendarId: string,
    tyontekijanakymaCalendarId: string
): IThunkBaseAction => async (dispatch, getState) => {
    try {
        dispatch(asyncOperationStartedAction())

        await optimizeSuunnittelujaksoversio(suunnittelujaksoversioId)

        const state = getState()
        const tarvenakymaDatasourceID = getCalendarDataSourceId(state, tarvenakymaCalendarId)
        const tyontekijanakymaDatasourceID = getCalendarDataSourceId(state, tyontekijanakymaCalendarId)

        const dataSourceDataFetchPromises = [
            dispatch(fetchDataSourceDataThunk(tarvenakymaDatasourceID)),
            dispatch(fetchDataSourceDataThunk(tyontekijanakymaDatasourceID)),
        ]
        await Promise.all(dataSourceDataFetchPromises)

        dispatch(displaySuccessToaster('workforce-scheduling.Optimize-Succeeded'))
    } catch (e) {
        dispatch(
            displayErrorToaster(e, {
                startOfErrorMessage: 'workforce-scheduling.Optimize-Failed',
            })
        )

        return e
    }

    dispatch(asyncOperationSucceededAction())
}

export const addEventEmployeeThunk = (
    calendarId: string,
    eventIds: ReadonlySet<TDataSourceItemId>,
    employeeId: TDataSourceItemId | null
): IThunkBaseAction => async (dispatch) => {
    const failureMessage = 'workforce-scheduling.AddEmployee.Failed'

    await dispatch(
        updateEventEmployeeThunk(ADD_EMPLOYEE_DATA_SOURCE_ACTION_ID, calendarId, eventIds, employeeId, failureMessage)
    )
}

export const publishEventsThunk = (calendarId: string): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()
    const selectedNodes = selectSelectedCalendarNodes(state, calendarId)
    const actionId =
        calendarId === TARVENAKYMA_CALENDAR_ID
            ? TARVENAKYMA_PUBLISH_EVENTS_ACTION_ID
            : TYONTEKIJA_PUBLISH_EVENTS_ACTION_ID

    try {
        const dataSourceId = getCalendarDataSourceId(state, calendarId)

        await dispatch(executeDataItemActionThunk(dataSourceId, [...selectedNodes], actionId))
    } catch (e) {
        dispatch(
            displayErrorToaster(e, {
                startOfErrorMessage: 'workforce-scheduling.PublishEventsFailed',
            })
        )
    }
}

export const updateEventEmployeeThunk = (
    actionId: string,
    calendarId: string,
    eventIds: ReadonlySet<TDataSourceItemId>,
    employeeId: TDataSourceItemId | null,
    failureMessage: string
): IThunkBaseAction => async (dispatch, getState) => {
    const actionParameters = {
        TyontekijaId: employeeId,
    }

    try {
        const dataSourceId = getCalendarDataSourceId(getState(), calendarId)

        await dispatch(executeDataItemActionThunk(dataSourceId, [...eventIds], actionId, { actionParameters }))
    } catch (e) {
        dispatch(
            displayErrorToaster(e, {
                startOfErrorMessage: failureMessage,
            })
        )
    }
}

export const fetchSelectedPlanningPeriodThunk = (
    planningPeriodId: number,
    fetchOptions?: IFetchStoredOptionsDataThunkOptions
): IThunkBaseAction<ISuunnittelujakso[] | null> => async (dispatch) => {
    const newPlanningPeriods = await dispatch(
        fetchValuePickerWithOptionsDataThunk<ISuunnittelujakso>(PLANNING_PERIODS_VALUE_PICKER_ID, fetchOptions, {
            VersioIds: [planningPeriodId],
        })
    )

    return newPlanningPeriods
}

// When selecting a planning period, in most cases we have it correctly in the store. However,
// it might be that the user edited the planning period's dates by which we form the dates
// of the calendars. To make sure we always have the correct dates, we need to fetch the
// planning periods, check if the dates changed and if they did, then set the new planning
// periods to the store.
const updatePlanningPeriodThunkToStoreIfDatesChanged = (
    currentPlanningPeriod: ISuunnittelujakso
): IThunkBaseAction => async (dispatch) => {
    const newPlanningPeriods = await dispatch(
        fetchSelectedPlanningPeriodThunk(currentPlanningPeriod.ActiveVersionId, { shouldDataBeSavedToStore: false })
    )

    if (!newPlanningPeriods) {
        Log.error('Planning period was not found')
        return
    }

    const newPlanningPeriod = newPlanningPeriods[0]

    if (!newPlanningPeriods) {
        Log.error('Planning period was not found')
        return
    }

    if (
        newPlanningPeriod.EndDate === currentPlanningPeriod.EndDate &&
        newPlanningPeriod.StartDate === currentPlanningPeriod.EndDate
    ) {
        return
    }

    storedOptionsDataFetchedAction(newPlanningPeriods, PLANNING_PERIODS_VALUE_PICKER_ID)
}

const setPlanningPeriodToUrl = (planningPeriodId: TDataSourceItemId) => {
    const currentUrl = window.location.href
    const endHasSlash = currentUrl[currentUrl.length - 1] === '/'

    const pathParts = currentUrl.split('/')
    const lastPathPart = pathParts[pathParts.length - (endHasSlash ? 2 : 1)]
    const hasUrlPlanningPeriodAlready = !isNaN(Number(lastPathPart))

    const urlWithNewPlanningPeriod = hasUrlPlanningPeriodAlready
        ? window.location.href.replace(lastPathPart, '' + planningPeriodId)
        : `${currentUrl}${endHasSlash ? '' : '/'}${planningPeriodId}`

    window.history.pushState({}, '', urlWithNewPlanningPeriod)
}

export const setSelectedPlanningPeriodThunk = (planningPeriodId: number): IThunkBaseAction => async (
    dispatch,
    getState
) => {
    setPlanningPeriodToUrl(planningPeriodId)
    dispatch(setValuePickerValueAction(new Set([planningPeriodId]), PLANNING_PERIODS_VALUE_PICKER_ID))

    const planningPeriodInStore = selectPlanningPeriod(getState())

    // If the user created a new planning period and selected that, it's not in the store for
    // the planning period value picker, so we need to fetch it.
    if (!planningPeriodInStore) {
        await Promise.all([
            await dispatch(fetchSelectedPlanningPeriodThunk(planningPeriodId)),
            await dispatch(fetchRecentPlanningPeriodsThunk()),
        ])
    } else {
        dispatch(updatePlanningPeriodThunkToStoreIfDatesChanged(planningPeriodInStore))
    }
}

export const fetchRecentPlanningPeriodsThunk = (): IThunkBaseAction => async (dispatch) => {
    await dispatch(fetchValuePickerWithOptionsDataThunk(VALUE_PICKER_PLANNING_PERIODS_RECENT))
}

export const initializeWorkforceSchedulingPageThunk = (planningPeriodId: number | null): IThunkBaseAction => async (
    dispatch
) => {
    try {
        await Promise.all([
            await dispatch(fetchValuePickerConfigurationsThunk([PLANNING_PERIODS_VALUE_PICKER_ID])),
            await dispatch(fetchValuePickerConfigurationsThunk([VALUE_PICKER_PLANNING_PERIODS_RECENT])),
        ])

        await Promise.all([
            await (planningPeriodId !== null
                ? dispatch(fetchSelectedPlanningPeriodThunk(planningPeriodId))
                : undefined),
            await dispatch(fetchRecentPlanningPeriodsThunk()),
        ])

        if (planningPeriodId) {
            dispatch(setValuePickerWithOptionsValueThunk(PLANNING_PERIODS_VALUE_PICKER_ID, new Set([planningPeriodId])))
        }
    } catch (e) {
        dispatch(
            displayErrorToaster(e, {
                startOfErrorMessage: 'workforce-scheduling.PlanningPeriodFetchFailed',
            })
        )
    }
}

export const fetchPlanningPeriodThunk = (planningPeriodId: number): IThunkBaseAction => async (dispatch) => {
    const options = undefined

    await dispatch(
        fetchValuePickerWithOptionsDataThunk(PLANNING_PERIODS_VALUE_PICKER_ID, options, {
            VersioIds: [planningPeriodId],
        })
    )
}

const fetchAndSetSelectedPlanningPeriodPropertyValuesThunk = (
    planningPeriodPropertyValueIds: number[] | null,
    valuePickerId: string
): IThunkBaseAction => async (dispatch, getState) => {
    if (!planningPeriodPropertyValueIds) {
        return
    }

    const currentItemsInValuePicker = selectStoredOptionsComponentAllOptions(getState(), valuePickerId)
    const currentItemIdsInValuePicker = new Set(currentItemsInValuePicker.map((item) => item.Id))

    const anyMissingPlanningPeriodPropertyValues = planningPeriodPropertyValueIds.filter(
        (itemId) => !currentItemIdsInValuePicker.has(itemId)
    )

    let missingPlanningPeriodPropertyValueObjects: IStoredOptionsOptionIdentifierObject[] | null = null

    if (anyMissingPlanningPeriodPropertyValues.length > 0) {
        missingPlanningPeriodPropertyValueObjects = await dispatch(
            fetchValuePickerWithOptionsDataThunk(
                valuePickerId,
                { shouldDataBeSavedToStore: false },
                { Ids: anyMissingPlanningPeriodPropertyValues }
            )
        )

        if (missingPlanningPeriodPropertyValueObjects) {
            dispatch(
                storedOptionsSetDataAction(
                    [...missingPlanningPeriodPropertyValueObjects, ...currentItemsInValuePicker],
                    valuePickerId
                )
            )
        } else {
            Log.error('No items found for the given IDs')
        }
    }

    dispatch(setValuePickerValueAction(new Set(planningPeriodPropertyValueIds), valuePickerId))
}

export const fetchAndSetSelectedPlanningPeriodEmployeesThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const planningPeriodEmployees = selectPlanningPeriodEmployeeIds(getState())

    await dispatch(
        fetchAndSetSelectedPlanningPeriodPropertyValuesThunk(planningPeriodEmployees, VALUE_PICKER_EMPLOYEES)
    )
}

export const fetchAndSetSelectedPlanningPeriodWorkUnitsThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const planningPeriodWorkUnits = selectPlanningPeriodWorkUnitIds(getState())

    await dispatch(
        fetchAndSetSelectedPlanningPeriodPropertyValuesThunk(planningPeriodWorkUnits, VALUE_PICKER_WORK_UNIT)
    )
}

export const fetchAndSetSelectedPlanningPeriodPoolsThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const planningPeriodPools = selectPlanningPeriodPoolIds(getState())

    await dispatch(fetchAndSetSelectedPlanningPeriodPropertyValuesThunk(planningPeriodPools, VALUE_PICKER_POOL))
}

export const saveCurrentValuePickersValuesToMemoryThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()

    const demandCalendarValuePickersWithValues = selectDemandCalendarValuePickersWithValues(state)
    const demandCalendarValuePickersWithValueInMap = Object.keys(demandCalendarValuePickersWithValues).reduce(
        (valuesAsMap, key) => valuesAsMap.set(key, demandCalendarValuePickersWithValues[key]),
        new Map<string, unknown>()
    )

    const employeeCalendarValuePickersWithValues = selectEmployeeCalendarValuePickersWithValues(state)
    const employeeCalendarValuePickersWithValueInMap = Object.keys(employeeCalendarValuePickersWithValues).reduce(
        (valuesAsMap, key) => valuesAsMap.set(key, employeeCalendarValuePickersWithValues[key]),
        new Map<string, unknown>()
    )

    dispatch(setCalendarInitialValuePickersValues(TARVENAKYMA_CALENDAR_ID, demandCalendarValuePickersWithValueInMap))
    dispatch(
        setCalendarInitialValuePickersValues(TYONTEKIJANAKYMA_CALENDAR_ID, employeeCalendarValuePickersWithValueInMap)
    )
}

export const restorePlanningPeriodValuePickersValuesThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const state = getState()

    const demandCalendarValuePickersWithValues = selectCalendarInitialValuePickersValues(state, TARVENAKYMA_CALENDAR_ID)
    const employeeCalendarValuePickersWithValues = selectCalendarInitialValuePickersValues(
        state,
        TYONTEKIJANAKYMA_CALENDAR_ID
    )
    const dateRangeValuePickerValue = selectCalendarInitialDates(state)

    if (!demandCalendarValuePickersWithValues || !employeeCalendarValuePickersWithValues) {
        Log.error('No values known for either of the calendars')
        return
    }

    const valuePickersWithValuesCombined = new Map([
        ...demandCalendarValuePickersWithValues,
        ...employeeCalendarValuePickersWithValues,
        ['CalendarDatePicker', dateRangeValuePickerValue],
    ])

    dispatch(setValuePickersValuesAction(valuePickersWithValuesCombined))
}

export const openModalToCreatePlanningPeriodBasedOnFiltersThunk = (): IThunkBaseAction => async (
    dispatch,
    getState
) => {
    const state = getState()

    const selectedWorkUnits = selectCurrentlySelectedWorkUnits(state)
    const selectedEmployees = selectCurrentlySelectedEmployees(state)
    const selectedPools = selectCurrentlySelectedPools(state)
    const selectedDateRange = selectCalendarDates(state)

    const additionalRequestProps = undefined
    const ignoreInitialFields = undefined
    const submitButtonAdditionalFunctionality = undefined

    const initialFormValuesOverride = {
        [FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_WORK_UNITS]: Array.from(selectedWorkUnits),
        [FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_WORK_EMPLOYEES]: Array.from(selectedEmployees),
        [FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_POOLS]: Array.from(selectedPools),
        [FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_START_DATE]: selectedDateRange
            ? [formatForDataUsage(selectedDateRange.start)]
            : [],
        [FORM_FIELD_PLANNING_PERIOD_CREATE_FORM_END_DATE]: selectedDateRange
            ? [formatForDataUsage(selectedDateRange.end)]
            : [],
    }

    dispatch(
        openFormViewConfigurableModalThunk(
            FORM_VIEW_CREATE_PLANNING_PERIOD,
            DATA_SOURCE_PLANNING_PERIOD,
            [],
            additionalRequestProps,
            ignoreInitialFields,
            submitButtonAdditionalFunctionality,
            initialFormValuesOverride
        )
    )
}

const removePlanningPeriodFromUrl = () => {
    const currentUrl = window.location.href
    const endHasSlash = currentUrl[currentUrl.length - 1] === '/'

    const pathParts = currentUrl.split('/')
    const lastPathPart = pathParts[pathParts.length - (endHasSlash ? 2 : 1)]

    const urlWithNewPlanningPeriod = window.location.href.replace(lastPathPart, '')

    window.history.pushState({}, '', urlWithNewPlanningPeriod)
}

export const resetFiltersThunk = (): IThunkBaseAction => async (dispatch, getState) => {
    const valuePickerIds = selectCombinedFilterIds(getState())

    const valuePickersWithResettedValues = valuePickerIds.reduce((valuePickerValuesMap, valuePickerId) => {
        // At least for now assume all the filters to be dropdowns
        valuePickerValuesMap.set(valuePickerId, { value: new Set() })
        return valuePickerValuesMap
    }, new Map<string, { value: Set<string> }>())

    dispatch(resetValuePickerValuesAction(valuePickersWithResettedValues))
}

export const unselectPlanningPeriodThunk = (): IThunkBaseAction => async (dispatch) => {
    removePlanningPeriodFromUrl()

    dispatch(setValuePickerValueAction(new Set(), PLANNING_PERIODS_VALUE_PICKER_ID))

    dispatch(resetFiltersThunk())
}

export const fetchPublicHolidays = createAsyncThunk<IPublicHoliday[], IDateRange>(
    'workforceScheduling-fetch-public-holidays',
    async (dateRange: IDateRange, { rejectWithValue }) => {
        try {
            const publicHolidays = await makeRequest<IPublicHoliday[]>({
                url: `/common/PublicHolidays?fromDate=${dateRange.start.format(
                    'YYYY-MM-DD'
                )}&toDate=${dateRange.end.format('YYYY-MM-DD')}`,
                method: ERequestMethod.GET,
                data: {},
            })
            return publicHolidays
        } catch (exception) {
            Sentry.captureException({
                exception,
                action: 'workforceScheduling-fetch-public-holidays',
            })
            return rejectWithValue(exception)
        }
    }
)
