import * as React from 'react'
import styled from '@emotion/styled'

import { TranslatedButton } from '@planier/generic-components/Buttons'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

const lessFiltersText = 'value-picker.ValuePickerGroup.lessFiltersText'
const moreFiltersText = 'value-picker.ValuePickerGroup.moreFiltersText'

interface IVisibilityToggleButtonProps {
    valuePickersVisibilityToggable: boolean
    allValuePickersVisible: boolean
    onClick: () => void
}

const Button = styled(TranslatedButton)`
    margin-left: 35px;
`

const ArrowIcon: React.FC<{ allValuePickersVisible: boolean }> = ({ allValuePickersVisible }) => {
    const Icon = allValuePickersVisible ? KeyboardArrowUpIcon : KeyboardArrowDownIcon
    return <Icon />
}

const VisibilityToggleButton: React.FC<IVisibilityToggleButtonProps> = ({
    valuePickersVisibilityToggable,
    allValuePickersVisible,
    onClick,
}) => {
    if (!valuePickersVisibilityToggable) {
        return null
    }

    const buttonText = allValuePickersVisible ? lessFiltersText : moreFiltersText

    const endIcon = <ArrowIcon allValuePickersVisible={allValuePickersVisible} />

    return (
        <Button muiButtonProps={{ endIcon }} onClick={onClick} variant="outlined">
            {buttonText}
        </Button>
    )
}

export default VisibilityToggleButton
