import * as React from 'react'

import IAsiakas from 'interfaces/IAsiakas'
import { ListElementText } from '@planier/configurable-list-components'

interface IWorkUnitProps {
    yksikko: IAsiakas | null
}

const WorkUnitId: React.FC<IWorkUnitProps> = ({ yksikko }) => {
    return yksikko ? <ListElementText displayValue={yksikko.Id} /> : null
}

export default WorkUnitId
