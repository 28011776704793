import { RootState } from 'typesafe-actions'

import { selectDataSourceItemsByIds, selectIsDataSourceInitialized } from '@planier/data-source'
import {
    TDataSourceItemId,
    IDataSourceDataRequestParameters,
    ISelectorObject,
    IDataSourceItem,
} from '@planier/data-source-types'
import { selectValuePickerValue, IDateRange } from '@planier/value-picker'
import IConfigurableCalendarConfiguration from '../Types/IConfigurableCalendarConfiguration'
import IConfigurableCalendar from '../Types/IConfigurableCalendar'
import ICalendarAction from '../Types/ICalendarAction'
import { selectDataSourceValuePickerIds } from '@planier/data-source'
import { areSame } from '@planier/dates'
import IEmptyCellNode from '../Types/IEmptyCellNode'
import { ICalendarDataTemplate } from '../Types/ICalendarDataTemplate'

export const selectCalendar = (state: RootState, calendarId: string): IConfigurableCalendar | undefined =>
    state.calendar.get(calendarId)

export const selectCalendarAdditionalDataFetchParameters = (
    state: RootState,
    calendarId: string
): null | Partial<IDataSourceDataRequestParameters> => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.AdditionalDataFetchParameters ?? null
}

export const selectCalendarConfiguration = (
    state: RootState,
    calendarId: string
): null | IConfigurableCalendarConfiguration => {
    const calendar = selectCalendar(state, calendarId)

    return calendar?.configuration ?? null
}

export const selectCalendarHeaderBars = (
    state: RootState,
    calendarId: string
): IConfigurableCalendarConfiguration['MainAxis'] => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.MainAxis ?? []
}

export const selectCalendarDataSourceId = (state: RootState, calendarId: string): string | null => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.DataSourceId ?? null
}

export const selectCalendarCrossAxis = (
    state: RootState,
    calendarId: string
): IConfigurableCalendarConfiguration['CrossAxis'] | null => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.CrossAxis ?? null
}

export const selectNodeTypes = (
    state: RootState,
    calendarId: string
): IConfigurableCalendarConfiguration['NodeTypes'] => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.NodeTypes ?? []
}

export const selectCalendarGranularity = (
    state: RootState,
    calendarId: string
): IConfigurableCalendarConfiguration['Granularity'] | null => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.Granularity ?? null
}

/**
 * Returns the calendar value picker IDs as they are defined in the calendar's
 * configuration. Note that doesn't return any dynamic value picker IDs (at least
 * the calendar date picker).
 */
export const selectCalendarValuePickerIds = (
    state: RootState,
    calendarId: string
): IConfigurableCalendarConfiguration['ValuePickerIds'] => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.ValuePickerIds ?? []
}

export const selectAreAllValuePickersInitializedForCalendarDataSource = (
    state: RootState,
    calendarId: string
): boolean => {
    const dataSourceId = selectCalendarDataSourceId(state, calendarId)

    if (!dataSourceId) {
        return false
    }

    const calendarValuePickers = selectCalendarValuePickerIds(state, calendarId)
    const valuePickersForDataSource = new Set(selectDataSourceValuePickerIds(state, dataSourceId))

    const areAllInitialized = calendarValuePickers.every((valuePickerId) =>
        valuePickersForDataSource.has(valuePickerId)
    )

    return areAllInitialized
}

export const selectAreAllValuePickersInitializedForCalendarDataSources = (
    state: RootState,
    calendarIds: string[]
): boolean => {
    return calendarIds.every((calendarId) =>
        selectAreAllValuePickersInitializedForCalendarDataSource(state, calendarId)
    )
}

export const selectCalendarDisplayMode = (
    state: RootState,
    calendarId: string
): IConfigurableCalendarConfiguration['DisplayMode'] | null => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.DisplayMode ?? null
}

export const selectCalendarTitle = (state: RootState, calendarId: string): string | null => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.Title ?? null
}

export const selectCalendarSelectorObjects = (state: RootState, calendarId: string): ISelectorObject[] => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.SelectorObjects ?? []
}

export const selectSelectedCalendarNodes = (state: RootState, calendarId: string): ReadonlySet<TDataSourceItemId> => {
    const calendar = selectCalendar(state, calendarId)

    return calendar?.selectedNodes ?? new Set<TDataSourceItemId>()
}

export const selectIsCalendarNodeSelected = (
    state: RootState,
    calendarId: string,
    nodeId: TDataSourceItemId
): boolean => {
    return selectSelectedCalendarNodes(state, calendarId).has(nodeId)
}

export const selectEmptyNodeCellsByIds = (state: RootState, calendarId: string, itemIds: string[]): IEmptyCellNode[] =>
    itemIds.map((id) => selectEmptyCellNode(state, calendarId, id)).filter((data) => data) as IEmptyCellNode[]

export const selectEmptyCellNode = (
    state: RootState,
    calendarId: string,
    emptyCellId: string
): IEmptyCellNode | undefined => {
    const calendar = selectCalendar(state, calendarId)

    if (!calendar) {
        return undefined
    }

    const cell = calendar.emptyCellNodes.get(emptyCellId)

    return cell
}

export const selectCalendarNodeActions = (state: RootState, calendarId: string): ICalendarAction[] => {
    const configuration = selectCalendarConfiguration(state, calendarId)

    return configuration?.NodeActions ?? []
}

export const selectCalendarDates = (state: RootState): IDateRange | null => {
    // TODO: get rid of the hard coded 'CalendarDatePicker'
    const dateValuePickerValue = selectValuePickerValue(state, 'CalendarDatePicker') as IDateRange

    return dateValuePickerValue
}

export const selectCalendarInitialDates = (state: RootState): IDateRange => {
    // TODO: get rid of the hard coded 'CalendarInitialDates'
    const initialDates = selectValuePickerValue(state, 'CalendarInitialDates') as IDateRange

    return initialDates
}

export const selectHaveDatesChanged = (state: RootState): boolean => {
    const initialDates = selectCalendarInitialDates(state)
    const currentDates = selectCalendarDates(state)

    if (!currentDates) {
        return false
    }

    return !areSame(initialDates.start, currentDates.start) || !areSame(initialDates.end, currentDates.end)
}

export const selectCalendarDataSourceIsInitialized = (state: RootState, calendarId: string): boolean => {
    const dataSourceId = selectCalendarDataSourceId(state, calendarId)

    if (!dataSourceId) {
        return false
    }

    const dataSourceIsInitialized = selectIsDataSourceInitialized(state, dataSourceId)

    return dataSourceIsInitialized
}

/**
 * Does the same thing as selectCalendarDataSourceIsInitialized but runs the check for multiple calendars.
 */
export const selectCalendarsDataSourcesAreInitialized = (state: RootState, calendarIds: string[]): boolean => {
    const calendarsDataSourcesAreInitialized = calendarIds.every((id) =>
        selectCalendarDataSourceIsInitialized(state, id)
    )

    return calendarsDataSourcesAreInitialized
}

export const selectCalendarDataTemplates = (state: RootState, calendarId: string): ICalendarDataTemplate[] => {
    const calendar = selectCalendar(state, calendarId)

    return calendar?.dataTemplates ?? []
}

export const selectSelectedNodeObjects = (state: RootState, calendarId: string): IDataSourceItem[] => {
    const dataSourceId = selectCalendarDataSourceId(state, calendarId)

    if (!dataSourceId) {
        return []
    }

    const selectedNodeIds = [...selectSelectedCalendarNodes(state, calendarId).values()]
    const selectedNodeObjects = selectDataSourceItemsByIds(state, dataSourceId, selectedNodeIds)

    const selectedEmptyCellNodeObjects = selectEmptyNodeCellsByIds(
        state,
        calendarId,
        selectedNodeIds as string[]
    ).map((item) => emptyNodeCellIntoDataSourceItem(item))

    return selectedNodeObjects.concat(selectedEmptyCellNodeObjects)
}

export const selectCalendarInitialValuePickersValues = (
    state: RootState,
    calendarId: string
): ReadonlyMap<string, unknown> | null => {
    const calendar = selectCalendar(state, calendarId)

    return calendar?.initialValuePickersValues ?? null
}

export const selectGroupNodeSearchFilter = (state: RootState, calendarId: string): string => {
    const calendar = selectCalendar(state, calendarId)

    return calendar?.groupNodeSearchFilter ?? ''
}
const emptyNodeCellIntoDataSourceItem = (cellNode: IEmptyCellNode): IDataSourceItem => {
    return {
        ...cellNode,
        Id: cellNode.Id,
        Disabled: false,
        Kayttooikeudet: [
            {
                Toiminto: 'CREATE',
                OnkoSallittu: true,
                SyyKieltoon: null,
            },
        ],
    }
}
