import * as React from 'react'
import { JSX, memo } from 'react'
import styled from '@emotion/styled'
import RowElement from './RowElement'
import IListViewColumnModel from '../../../interfaces/IListViewColumnModel'
import { TDataSourceId } from '@planier/data-source'

export interface IRowElementsProps {
    dataSourceId: TDataSourceId
    item: any
    itemId: string | number
    metadataForColumns: IListViewColumnModel[]
    isLoading: boolean
    offset?: number
}

// prevent offsetting more than the column's width
const calculateOffset = (width: number, offset?: number) => {
    if (!offset) {
        return
    }

    // rem to px
    const widthAsPx = width * 14

    return offset <= widthAsPx ? offset : widthAsPx
}

const Container = styled.div`
    margin-left: 15px;
    display: flex;
    align-items: center;
    flex-grow: 1;
`

const RowElements = ({
    dataSourceId,
    item,
    itemId,
    metadataForColumns,
    isLoading,
    offset,
}: IRowElementsProps): JSX.Element => {
    let firstVisibleElementId: string | null = null

    return (
        <Container>
            {metadataForColumns.map(({ Id, ViewComponentId, ParameterMapping, StaticParameters, Visible, Width }) => {
                if (!Visible) {
                    return null
                }

                if (!firstVisibleElementId) {
                    firstVisibleElementId = Id
                }

                return (
                    <RowElement
                        componentDisabled={isLoading}
                        dataSourceId={dataSourceId}
                        item={item}
                        itemId={itemId}
                        key={Id}
                        propsData={ParameterMapping}
                        staticPropsData={StaticParameters}
                        viewComponentId={ViewComponentId}
                        width={Width}
                        offset={firstVisibleElementId === Id ? calculateOffset(Width, offset) : undefined}
                    />
                )
            })}
        </Container>
    )
}

export default memo(RowElements)
