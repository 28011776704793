import { useState } from 'react'
import * as React from 'react'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import INavigationSectionWithSubsections from '../../Types/INavigationSectionWithSubsections'
import INavigationSubsection from '../../Types/INavigationSubsection'
import {
    selectSectionsWithSubsections,
    selectCurrentSectionWithSubsections,
    selectCurrentSubsection,
} from '../../State/NavigationSelectors'
import Drawer from '@material-ui/core/Drawer'
import { Icon } from '@planier/generic-components'
import Section from './Section'
import { getLogger } from '@planier/log'
const Log = getLogger('MainNavigationNewUnconnected')

interface IStateProps {
    items: INavigationSectionWithSubsections[]
    currentPageSection: INavigationSectionWithSubsections | null
    currentPageSubsection: INavigationSubsection | null
}

interface IMainNavigationNewProps extends IStateProps {}

type TNavigationItemWithIcon = Omit<INavigationSectionWithSubsections, 'Icon'> & {
    Icon: string
}
type TStyledDrawerProps = React.ComponentProps<typeof Drawer> & { isOpen: boolean }

const StyledDrawer = styled<React.FC<TStyledDrawerProps>>(Drawer, { shouldForwardProp: (prop) => prop !== 'isOpen' })`
    > *:first-of-type {
        width: 40px;
        margin-top: 4.571rem;
        height: calc(100% - 4.571rem);
        overflow: hidden;

        ${({ isOpen }) =>
            isOpen &&
            `
            width: 330px;
            overflow-y: auto;
        `}
    }
`

const ToggleButtonContainer = styled.div`
    height: 4.571rem;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MainNavigationNewUnconnected: React.FC<IMainNavigationNewProps> = ({
    items,
    currentPageSection,
    currentPageSubsection,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const itemsWithIcon = items.filter((item): item is TNavigationItemWithIcon => Boolean(item.Icon))

    if (itemsWithIcon.length !== items.length) {
        Log.error("All navigation sections don't have icon defined")
    }

    const handleDrawerOpenToggle = () => {
        setIsOpen((currentIsOpen) => !currentIsOpen)
    }

    return (
        <React.Fragment>
            <ToggleButtonContainer data-testid="STC_HEADER_MAIN_NAV_TOGGLE">
                <Icon onClick={handleDrawerOpenToggle} size={24}>
                    {isOpen ? 'close' : 'menu'}
                </Icon>
            </ToggleButtonContainer>
            <StyledDrawer isOpen={isOpen} variant="permanent">
                {itemsWithIcon.map((item) => (
                    <Section
                        currentPageSection={currentPageSection}
                        currentPageSubsection={currentPageSubsection}
                        isDrawerOpen={isOpen}
                        item={item}
                        key={item.Id}
                    />
                ))}
            </StyledDrawer>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    return {
        items: selectSectionsWithSubsections(state),
        currentPageSection: selectCurrentSectionWithSubsections(state),
        currentPageSubsection: selectCurrentSubsection(state),
    }
}

export default connect(mapStateToProps)(MainNavigationNewUnconnected)
