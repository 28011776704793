import styled from '@emotion/styled'
import * as React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { IOsionAsetus } from '../../reducers/OsiokohtaisetAsetuksetReducer'
import { haeTietotyyppiMaarittely } from '../../OsiokohtaisetAsetuksetTietotyyppiMaaritykset'
import { Translation } from '@planier/localization'
import { map } from 'lodash-es'
import { chain } from 'lodash'

const HeaderCell = styled(TableCell)`
    color: #000;
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    font-family: ${({ theme }) => theme.typography.h5.fontFamily};
`

const ArrowCell = styled(TableCell)`
    padding: 1px;
    font-size: 2rem;
`

const ArrowHeaderCell = styled(TableCell)`
    padding: 0;
`

const OldCell = styled(TableCell)`
    color: #000;
    opacity: 0.5;
    padding-right: 1rem;
    text-align: right;
`

const OldHeaderCell = styled(HeaderCell)`
    padding-right: 1rem;
    text-align: right;
`

const NewCell = styled(TableCell)`
    padding-left: 1rem;
`

const NewHeaderCell = styled(HeaderCell)`
    padding-left: 1rem;
`

export interface IMuuttuneetAsetustenArvot {
    [key: string]: {
        asetus: IOsionAsetus
        uusi: any
        vanha: any
    }
}

export interface IOsiokohtaisetAsetuksetMuuttuneetAsetuksetProps {
    muuttuneetAsetustenArvot: IMuuttuneetAsetustenArvot
}

const OsiokohtaisetAsetuksetMuuttuneetAsetukset: React.FunctionComponent<
    IOsiokohtaisetAsetuksetMuuttuneetAsetuksetProps
> = (props) => {
    const formatoidutArvot = chain(props.muuttuneetAsetustenArvot)
        .mapValues(({ asetus, uusi, vanha }) => {
            const { formatoija } = haeTietotyyppiMaarittely(asetus.Tietotyyppi)

            return {
                asetus,
                uusi: formatoija(uusi, asetus),
                vanha: formatoija(vanha, asetus),
            }
        })
        .value()

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <HeaderCell>{Translation.translateKey('OsiokohtaisetAsetukset.muuttuneet.asetus')}</HeaderCell>
                    <OldHeaderCell>{Translation.translateKey('OsiokohtaisetAsetukset.muuttuneet.vanha')}</OldHeaderCell>
                    <ArrowHeaderCell />
                    <NewHeaderCell>{Translation.translateKey('OsiokohtaisetAsetukset.muuttuneet.uusi')}</NewHeaderCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {map(formatoidutArvot, ({ asetus, uusi, vanha }, avain) => (
                    <TableRow key={avain}>
                        <TableCell>{asetus.NimiAsiakkaalle}</TableCell>
                        <OldCell>{vanha}</OldCell>
                        <ArrowCell>→</ArrowCell>
                        <NewCell>{uusi}</NewCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default OsiokohtaisetAsetuksetMuuttuneetAsetukset
