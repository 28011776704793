import keyMirror from 'keymirror'

export default keyMirror({
    Asiakas: null,
    LoginsivuLogo: null,
    PalautteenJattaminenSallittu: null,
    PalautteenJattaminenNappiNaytetaanToistaiseksi: null,
    AsxBookingCancelTranslationKey: null,
    AsxBookingConfirmationTranslationKey: null,
    AsxBookingHideSelectAll: null,
    AsxBookingProposalTranslationKey: null,
    AsxCreateOrderSendButtonTranslationKey: null,
    AsxHideEmployeeId: null,
    AsxLongOrderSelectReason: null,
    AsxOrderSelectCla: null,
    AsxOrderSelectClaDictionaryKey: null,
    AsxPiilotaYksikonYleiskuvaus: null,
    AsxPiilotaYksikonYleiskuvausKentta: null,
    AsxPitkatilausErikoistaitojenSyottoEstetty: null,
    AsxShiftsSelectCla: null,
    AsxTilausErikoistaitojenSyottoEstetty: null,
    NaytaAsiakLisatietojaTTextra: null,
    SalliPitkantilauksenHloMaaranAsetusASX: null,
    Tietosuojaseloste_Linkki: null,
    TilauskohtainenLaskutusviite: null,
    NaytaTilauksissaProjektinumeronSyotto: null,
    AsxProjectCodeIsRequired: null,
    AsxVuoroValintaResourceList: null,
    AsxPiilotaTilauksenLisatietoTyontekijalleKentta: null,
    AsxPiilotaTilauksenLisatietoTarvelistasta: null,
    AsxAsetaYksikonLisatietoAutomUuteenTilaukseen: null,
    AsxBookingShowPoolFilter: null,
    ActiveInstructionsEnabled: null,
    AsxBookingLimitAmountOfSelectableEmployees: null,
})
