import * as React from 'react'
import { JSX } from 'react'

import { Color } from 'constants/Styles'
import { TextBoxWithBackgroundColor, Tooltip } from '@planier/generic-components'
import moment, { Moment } from 'moment'
import { PlanierTheme, useAppTheme } from '@planier/theme'

type TTooltipProps = React.ComponentProps<typeof Tooltip>

type Props = {
    isEventReservationConstraintOk: boolean
    eventReservationConstraintIsOkStartingFrom: string
    eventReservationStatus?: EEventReservationStatus
    eventReservationStatusChangeDateTime?: string | Moment
    tooltipChildWrapperElement?: TTooltipProps['childWrapperElement']
    tooltip?: string
    isOnlyManualOfferingEnabled?: boolean
}

// eslint-disable-next-line no-shadow
enum EEventReservationStatus {
    EventNotAvailable = 1,
    Reserved = 2,
    Reservable = 3,
    ReservableInFuture = 4,
    RequestByEmployeePending = 5,
    RequestableByEmployee = 6,
    RequestableByEmployeeInFuture = 7,
    OfferPending = 8,
    OfferDeclined = 9,
    EmployeeNotAvailable = 10,
    RequestByEmployeeCancelled = 11,
    RequestByEmployeeDeclined = 12,
    EmployeeSuitable = 13,
    OfferedButNotAvailable = 14,
    Assigned = 15,
    EmployeeNotSuitable = 16,
}

const reservationStatusMap = ({ colors, eventTemplates }: PlanierTheme) => ({
    [EEventReservationStatus.EventNotAvailable]: {
        text: 'VUORO EI SAATAVILLA',
        color: colors.neutralsGrey60,
        backgroundColor: colors.neutralsWhite100,
        borderColor: colors.neutralsGrey30,
    },
    [EEventReservationStatus.OfferedButNotAvailable]: {
        text: 'TARJOTTU, EI SAATAVILLA',
        color: colors.neutralsGrey60,
        backgroundColor: colors.neutralsWhite100,
        borderColor: colors.neutralsGrey30,
    },
    [EEventReservationStatus.Reserved]: {
        text: 'VUORO VARATTU',
        color: colors.semanticSuccess120,
        backgroundColor: colors.semanticSuccess11,
        borderColor: colors.semanticSuccess11,
    },
    [EEventReservationStatus.Assigned]: {
        text: 'VUORO LISÄTTY',
        color: colors.semanticSuccess120,
        backgroundColor: colors.semanticSuccess11,
        borderColor: colors.semanticSuccess11,
    },
    [EEventReservationStatus.Reservable]: {
        text: 'VARATTAVISSA',
        color: colors.primaryTeal140,
        backgroundColor: colors.primaryTeal11,
        borderColor: colors.primaryTeal60,
    },
    [EEventReservationStatus.ReservableInFuture]: {
        text: 'VARATTAVISSA',
        color: colors.primaryTeal140,
        backgroundColor: colors.primaryTeal11,
        borderColor: colors.primaryTeal60,
    },
    [EEventReservationStatus.RequestByEmployeePending]: {
        text: 'TYÖNTEKIJÄN EHDOTUS',
        color: eventTemplates.primary.event2,
        backgroundColor: eventTemplates.secondary.event2,
        borderColor: '#b6d0ff',
    },
    [EEventReservationStatus.RequestableByEmployee]: {
        text: 'EHDOTETTAVISSA',
        color: eventTemplates.primary.event2,
        backgroundColor: eventTemplates.secondary.event2,
        borderColor: '#b6d0ff',
    },
    [EEventReservationStatus.RequestableByEmployeeInFuture]: {
        text: 'EHDOTETTAVISSA',
        color: eventTemplates.primary.event2,
        backgroundColor: eventTemplates.secondary.event2,
        borderColor: '#b6d0ff',
    },
    [EEventReservationStatus.OfferPending]: {
        text: 'ODOTTAA VASTAUSTA',
        color: colors.neutralsGrey100,
        backgroundColor: colors.secondaryYellow100,
        borderColor: colors.secondaryYellow100,
    },
    [EEventReservationStatus.OfferDeclined]: {
        text: 'HYLÄTTY',
        color: eventTemplates.primary.event4,
        backgroundColor: eventTemplates.secondary.event4,
        borderColor: colors.secondaryPink100,
    },
    [EEventReservationStatus.EmployeeNotAvailable]: {
        text: 'EI KÄYTETTÄVISSÄ',
        color: colors.neutralsGrey60,
        backgroundColor: colors.neutralsWhite100,
        borderColor: colors.neutralsGrey30,
    },
    [EEventReservationStatus.RequestByEmployeeCancelled]: {
        text: 'TYÖNTEKIJÄ PERUNUT EHDOTUKSEN',
        color: eventTemplates.primary.event4,
        backgroundColor: eventTemplates.secondary.event4,
        borderColor: colors.secondaryPink100,
    },
    [EEventReservationStatus.RequestByEmployeeDeclined]: {
        text: 'EHDOTUS HYLÄTTY',
        color: eventTemplates.primary.event4,
        backgroundColor: eventTemplates.secondary.event4,
        borderColor: colors.secondaryPink100,
    },
    [EEventReservationStatus.EmployeeSuitable]: {
        text: 'SOVELTUU',
        color: eventTemplates.primary.event1,
        backgroundColor: eventTemplates.secondary.event1,
        borderColor: '#a49ada',
    },
    [EEventReservationStatus.EmployeeNotSuitable]: {
        text: 'EI SOVELTUVA',
        color: eventTemplates.primary.event4,
        backgroundColor: eventTemplates.secondary.event4,
        borderColor: colors.secondaryPink100,
    },
})

const createTextForReservationConstraint = (input: string | Moment): string => {
    if (!input) {
        return ''
    }

    const inputAsMoment = moment(input)

    const today = moment(new Date())

    const isToday = inputAsMoment.clone().startOf('day').isSame(today.startOf('day'))

    if (isToday) {
        return inputAsMoment.format('HH:mm')
    } else {
        return inputAsMoment.format('DD.MM. HH:mm')
    }
}

const ListElementReservationStatus = ({
    isEventReservationConstraintOk,
    eventReservationConstraintIsOkStartingFrom,
    eventReservationStatus,
    eventReservationStatusChangeDateTime,
    tooltipChildWrapperElement = 'div',
    tooltip,
    isOnlyManualOfferingEnabled,
}: Props): JSX.Element => {
    const theme = useAppTheme()

    let color: Color | string = 'success_100'
    let backgroundColor: Color | string = 'success_10'
    let borderColor: Color | string | undefined
    let icon = undefined

    if (!isEventReservationConstraintOk && eventReservationConstraintIsOkStartingFrom) {
        color = 'event_transition'
        backgroundColor = 'event_transition_2'
    }

    let text = isEventReservationConstraintOk
        ? 'VARATTAVISSA'
        : createTextForReservationConstraint(eventReservationConstraintIsOkStartingFrom)

    if (
        !isEventReservationConstraintOk &&
        (eventReservationConstraintIsOkStartingFrom === null ||
            eventReservationConstraintIsOkStartingFrom === undefined ||
            eventReservationConstraintIsOkStartingFrom === '')
    ) {
        text = 'EI VARATTAVISSA'
    }

    if (eventReservationStatus) {
        const reservationStatusProps = reservationStatusMap(theme)[eventReservationStatus] as any

        text = reservationStatusProps.text
        color = reservationStatusProps.color
        backgroundColor = reservationStatusProps.backgroundColor
        borderColor = reservationStatusProps.borderColor ?? reservationStatusProps.color
    }

    if (eventReservationStatusChangeDateTime) {
        text += ' ' + createTextForReservationConstraint(eventReservationStatusChangeDateTime)
    }

    if (isOnlyManualOfferingEnabled) {
        color = theme.colors.neutralsGrey60
        backgroundColor = theme.colors.neutralsWhite100
        borderColor = theme.colors.neutralsGrey30
        icon = 'lock'
    }

    borderColor = borderColor ?? color

    return (
        <Tooltip childWrapperElement={tooltipChildWrapperElement} title={tooltip}>
            <TextBoxWithBackgroundColor
                borderColor={borderColor}
                textColor={color}
                text={text}
                backgroundColor={backgroundColor}
                icon={icon}
            />
        </Tooltip>
    )
}

export default ListElementReservationStatus
