import * as React from 'react'

import { ConfirmationDialog } from '@planier/modal'
import IDeletionReason from '../Types/IDeletionReason'
import DeletionReasons from './DeletionReasons'

type TConfirmationDialogProps = React.ComponentProps<typeof ConfirmationDialog>
interface IOwnProps {
    areDeletionReasonsDisplayed: boolean
    deletionReasons: IDeletionReason[]
    isLoading?: boolean
    isOpen: boolean
    dialogId: string
    onClose: TConfirmationDialogProps['onClose']
    onSubmit: TConfirmationDialogProps['onSubmit']
    reasonsQueryFailed: boolean
    title: TConfirmationDialogProps['title']
    subtitle: TConfirmationDialogProps['subtitle']
    initialValues: TConfirmationDialogProps['initialValues']
    confirmButtonText?: TConfirmationDialogProps['confirmButtonText']
}

interface IDeletionConfirmationDialogBaseProps extends IOwnProps {}

const DeletionConfirmationDialogBase: React.FC<IDeletionConfirmationDialogBaseProps> = ({
    deletionReasons,
    isLoading = false,
    dialogId,
    isOpen,
    onClose,
    onSubmit,
    reasonsQueryFailed,
    title,
    subtitle,
    initialValues,
    confirmButtonText = 'modal-deletion-confirmation.ConfirmButtonLabel',
    areDeletionReasonsDisplayed,
}) => {
    const extraContent = areDeletionReasonsDisplayed ? (
        <DeletionReasons
            deletionReasons={deletionReasons}
            isLoading={isLoading}
            reasonsQueryFailed={reasonsQueryFailed}
        />
    ) : null

    return (
        <ConfirmationDialog
            confirmButtonText={confirmButtonText}
            disableSubmit={isLoading || reasonsQueryFailed}
            extraContent={extraContent}
            initialValues={initialValues}
            modalId={dialogId}
            modalOpen={isOpen}
            onClose={onClose}
            onSubmit={onSubmit}
            subtitle={subtitle}
            title={title}
        />
    )
}

export default DeletionConfirmationDialogBase
