import { TSortType } from '@planier/data-source-types'

const defaultSortType: TSortType = 'ASC'

const changeSortTypeOrGetDefault = (sortOrder: TSortType | undefined): TSortType => {
    if (!sortOrder) {
        return defaultSortType
    } else if (sortOrder === 'ASC') {
        return 'DESC'
    }

    return 'ASC'
}

export default changeSortTypeOrGetDefault
