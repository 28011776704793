import { FormikValues } from 'formik'

import { IDataSourceConfiguration } from '@planier/data-source'
import { getObjectDiff } from '@planier/generic-utilities'
import IFormViewFieldModel from '../../Types/IFormViewFieldModel'

import { IFormViewComponentRegistry } from '../../FormViewComponentRegistry'
import IFormViewFieldState from '../../Types/IFormViewFieldState'
import { getPropertyName } from './InternalUtils'
import { getLogger } from '@planier/log'
import { set } from 'lodash-es'

const Log = getLogger('form-view.getFormValuesForDataSource')

const getOutputPropertyName = (
    fieldId: string,
    outputPropertyName: string | undefined,
    dataSourcePropertyId: string | null,
    dataSource: IDataSourceConfiguration
): string | null => {
    if (outputPropertyName) {
        return outputPropertyName
    }

    return getPropertyName(fieldId, dataSourcePropertyId, dataSource)
}

export const getFormValuesForDataSource = (
    initialValues: Record<string, unknown>,
    values: Record<string, unknown>,
    statefulFields: (IFormViewFieldState & IFormViewFieldModel)[],
    dataSource: IDataSourceConfiguration,
    getFieldComponent: IFormViewComponentRegistry['getFieldComponent'],
    { onlyChangedValues = true } = {}
): FormikValues => {
    const changedFormikValues = getObjectDiff(initialValues, values)

    const changedDataSourcePropertyValues: FormikValues = {}

    const fieldsToInclude = onlyChangedValues
        ? statefulFields.filter(
              ({ Id, IsAlwaysIncluded, visible }) =>
                  IsAlwaysIncluded || (visible && Object.keys(changedFormikValues).includes(Id))
          )
        : statefulFields

    for (const fieldConfiguration of fieldsToInclude) {
        const value = values[fieldConfiguration.Id as keyof typeof values]

        for (const mapping of fieldConfiguration.DataSourcePropertyMapping) {
            let propertyName: string | null
            try {
                propertyName = getOutputPropertyName(
                    fieldConfiguration.Id,
                    mapping.OutputProperty,
                    mapping.DataSourcePropertyId,
                    dataSource
                )
            } catch (error) {
                Log.warn(error.message)
                continue
            }

            if (!propertyName) {
                // If the property name resolves to null, don't include the property into the response.
                continue
            }

            const Component = fieldConfiguration.InputComponentId
                ? getFieldComponent(fieldConfiguration.InputComponentId)
                : null

            const identity = (a: typeof value): typeof value => a
            const formatValue = Component && Component.formatOnSubmit ? Component.formatOnSubmit : identity

            const propertyValue = mapping.ComponentParameterName
                ? formatValue(value)[mapping.ComponentParameterName]
                : formatValue(value)

            set(changedDataSourcePropertyValues, propertyName, propertyValue)
        }
    }

    return changedDataSourcePropertyValues
}
