import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import AbstractInput from 'components/atoms/AbstractInput'
import DateUtility from 'common/DateUtility'
import { Datepicker } from '@planier/generic-components'
import { getTestId } from '@planier/test'

const DashContainer = styled.span`
    width: 10px;
    margin-bottom: 6px;
    align-self: flex-end;
`

const Container = styled.div`
    display: flex;
`

class DateRange extends AbstractInput {
    render() {
        const {
            endChangeAction,
            endLabelText,
            endTime,
            loppuaikaPaivanLopusta,
            maxAlkuaika,
            maxEndDate,
            minLoppuaika,
            minStartDate,
            noValidation,
            onEndDateChange,
            onStartDateChange,
            startChangeAction,
            startLabelText,
            startTime,
            startDatePickerClassName,
            endDatePickerClassName,
            className,
            datePickerTestId,
        } = this.props

        const testId = getTestId(['VALUE_PICKER'], datePickerTestId)

        return (
            <Container className={className} data-testid={testId}>
                <Datepicker
                    changeAction={startChangeAction}
                    className={startDatePickerClassName}
                    hintText={startLabelText}
                    maxDate={maxAlkuaika ? DateUtility.asMoment(maxAlkuaika) : undefined}
                    minDate={minStartDate ? DateUtility.asMoment(minStartDate) : undefined}
                    noValidation={noValidation}
                    onChange={onStartDateChange}
                    value={startTime ? DateUtility.asMoment(startTime) : undefined}
                />
                <DashContainer> - </DashContainer>
                <Datepicker
                    aikaPaivanLopusta={loppuaikaPaivanLopusta}
                    changeAction={endChangeAction}
                    className={endDatePickerClassName}
                    hintText={endLabelText}
                    maxDate={maxEndDate ? DateUtility.asMoment(maxEndDate) : undefined}
                    minDate={minLoppuaika ? DateUtility.asMoment(minLoppuaika) : undefined}
                    noValidation={noValidation}
                    onChange={onEndDateChange}
                    value={endTime ? DateUtility.asMoment(endTime) : undefined}
                />
            </Container>
        )
    }
}

DateRange.propTypes = {
    endChangeAction: PropTypes.object,
    endLabelText: PropTypes.string,
    endTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    loppuaikaPaivanLopusta: PropTypes.bool,
    maxAlkuaika: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxEndDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minLoppuaika: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    minStartDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    noValidation: PropTypes.bool,
    onEndDateChange: PropTypes.func,
    onStartDateChange: PropTypes.func,
    startChangeAction: PropTypes.object,
    startLabelText: PropTypes.string,
    startTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startDatePickerClassName: PropTypes.string,
    endDatePickerClassName: PropTypes.string,
    className: PropTypes.string,
}

DateRange.defaultProps = {
    loppuaikaPaivanLopusta: false,
    noValidation: false,
    onEndDateChange: () => null,
    onStartDateChange: () => null,
    value: DateUtility.now(),
}

export default DateRange
