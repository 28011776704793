import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import { StyledTextInput } from '@planier/generic-components'
import { ITextSearchPickerOwnProps } from '@planier/value-picker'
import { debounce } from 'lodash-es'
import { getTestId } from '@planier/test'

interface ITextSearchPickerProps extends ITextSearchPickerOwnProps {}

const defaultDebounceTime = 250

const TextSearchPicker: React.FC<ITextSearchPickerProps> = ({ label, value, valuePickerId, onChange }) => {
    const [val, setValue] = useState<string>('')

    useEffect(() => {
        if (typeof value === 'string') {
            setValue(value)
        }
    }, [value])

    // useCallback can't know the dependencies of _.debounce so it complains about that even if
    // we list the dependencies properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setValuePickerValueDebounced = useCallback(
        debounce((newUserInput: string) => {
            onChange(newUserInput)
        }, defaultDebounceTime),
        [onChange, valuePickerId]
    )

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value

        setValue(newValue)
        setValuePickerValueDebounced(newValue)
    }

    const testId = getTestId(['VALUE_PICKER'], valuePickerId)

    return (
        <StyledTextInput
            label={label}
            name={valuePickerId}
            width="176px"
            onChange={handleChange}
            value={val}
            testId={testId}
        />
    )
}

export default TextSearchPicker
