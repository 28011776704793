import styled from '@emotion/styled'
import { IEvent } from 'packages/event'
import { Checkbox, Icon, ReadOnlyText } from '@planier/generic-components'
import { JSX } from 'react'
import { ISummaryLabel, SummaryLabel } from '@planier/generic-components/SummaryLabel/SummaryLabels'
import moment from 'moment'
import EventDot from './EventDot'
import { useDispatch, useSelector } from 'react-redux'
import { TFunctionalityAction } from '@planier/view-engine'
import { dotProps } from './DayLogic'
import { translate } from '@planier/localization'
import { initEventAction } from '../State/EmployeeCalendarThunks'
import { selectSelectedEvent } from '../State/EmployeeCalendarSelectors'
import { IEmployeeDataSourceItem } from 'packages/messaging/Types/IEmployeeDataSourceItem'
import { useAppTheme } from '@planier/theme'
import { RootState } from 'typesafe-actions'
import { toggleSelectedEvent } from '../State/EmployeeCalendarReducer'
import { CancelEventAction, DeleteEventAction, EditEventAction, getPermission } from '../Utils/eventActions'
import ActionButton from './ActionButton'

type Props = {
    event: IEvent
    employee?: IEmployeeDataSourceItem
    drawBottomBorder?: boolean
}

const Container = styled.div<{ bottomBorder?: boolean }>`
    padding: 8px 24px 12px 24px;
    width: 100%;
    ${({ bottomBorder, theme }) =>
        bottomBorder && `border-bottom: 1px solid ${theme.componentExtensions.border.secondary};`}
`

const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`

const Row = styled.div<{ height?: number }>`
    height: ${({ height }) => height && `${height}px`};
    display: flex;
    flex-direction: row;
    margin-left: 30px;
`

const SummaryLabels = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
`

const StyledSummaryLabel = styled(SummaryLabel)`
    flex: 1 1 100%;
    justify-content: flex-start;
    align-items: flex-start;
`

const SummaryLabelPlaceholder = styled.div`
    flex: 1 1 100%;
`

export const EventDotBox = styled.div`
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
`

export const EventTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`

const ActionButtons = ({ event, employee }: Pick<Props, 'event' | 'employee'>): JSX.Element => {
    const dispatch = useDispatch()

    const initAction = async (action: TFunctionalityAction) => {
        dispatch(initEventAction(action, [event], employee))
    }

    const canBeCanceled = getPermission(event, CancelEventAction)?.OnkoSallittu

    const removeAction = canBeCanceled
        ? {
              icon: 'cancel',
              tooltip: 'Peru vuoro',
              permission: getPermission(event, CancelEventAction),
              onClick: () => initAction(CancelEventAction),
          }
        : {
              icon: 'delete',
              tooltip: 'remove-button',
              permission: getPermission(event, DeleteEventAction),
              onClick: () => initAction(DeleteEventAction),
          }

    const actions = [
        {
            icon: 'edit',
            tooltip: 'muokkaa',
            permission: getPermission(event, EditEventAction),
            onClick: () => initAction(EditEventAction),
        },
        removeAction,
    ]

    return (
        <ButtonContainer>
            {actions.map(({ icon, tooltip, permission, onClick }) => (
                <ActionButton
                    key={icon}
                    size="small"
                    onClick={onClick}
                    disabled={!permission?.OnkoSallittu}
                    data-testid="event-action"
                >
                    <Icon tooltip={permission?.SyyKieltoon || translate(tooltip)}>{icon}</Icon>
                </ActionButton>
            ))}
        </ButtonContainer>
    )
}

const EventCard = ({ event, employee, drawBottomBorder = false }: Props): JSX.Element => {
    const dispatch = useDispatch()
    const theme = useAppTheme()

    const isSelected = useSelector((state: RootState) => selectSelectedEvent(state, event.Id))

    const handleCheckboxClick = () => {
        dispatch(toggleSelectedEvent(event))
    }

    const eventTimePeriod = `${moment(event.StartTime).format('HH:mm')}–${moment(event.EndTime).format('HH:mm')}`
    const summaryLabels = [
        {
            Icon: 'access_time',
            Text: eventTimePeriod,
        },
        {
            Icon: 'place',
            Text: event.WorkUnit ? `${event.WorkUnit?.Id} ${event.WorkUnit?.Name}` : null,
        },
        {
            Icon: 'account_circle_outlined',
            Text: event.JobTitle?.Name,
        },
    ] as ISummaryLabel[]

    const labelsBlock = summaryLabels.map((summaryLabel, index) => {
        if (!summaryLabel.Text) {
            return <SummaryLabelPlaceholder key={`placeholder-${index}`} />
        }

        return <StyledSummaryLabel key={summaryLabel.Text} fontUsage="bodyS" summaryLabel={summaryLabel} noPadding />
    })

    return (
        <Container bottomBorder={drawBottomBorder} data-testid="calendar-event-card">
            <HeaderRow>
                <EventTextContainer>
                    <Checkbox onClick={handleCheckboxClick} value={Boolean(isSelected)} noPadding />
                    <EventDotBox>
                        <EventDot {...dotProps(event.EventTypeCategory?.Id as number, theme)} size={12} />
                    </EventDotBox>
                    <ReadOnlyText usage="bodyS">{event.EventType.Name}</ReadOnlyText>
                </EventTextContainer>

                <ActionButtons event={event} employee={employee} />
            </HeaderRow>

            {event.EventGroup && <Row height={20}>{translate('reserving_event_group')}</Row>}

            <Row>
                <SummaryLabels>{labelsBlock}</SummaryLabels>
            </Row>
        </Container>
    )
}

export default EventCard
