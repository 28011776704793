import * as React from 'react'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import URL from 'common/URL'
import INavigationSectionWithSubsections from '../../../Types/INavigationSectionWithSubsections'
import { Expandable } from '@planier/generic-components'
import TabLabel from './TabLabel'
import INavigationSubsection from '../../../Types/INavigationSubsection'
import SubsectionTab from './SubsectionTab'

interface ISectionWithSubsectionsTabProps {
    item: INavigationSectionWithSubsections
    currentPageSubsection: INavigationSubsection | null
    isDrawerOpen: boolean
    iconElement: React.ReactNode
    isCurrentPage: boolean
}

type TStyledExpandableProps = React.ComponentProps<typeof Expandable> & {
    isCurrentPage: boolean
}

const StyledExpandable = styled<React.FC<TStyledExpandableProps>>(Expandable, {
    shouldForwardProp: (prop: keyof TStyledExpandableProps) => prop !== 'isCurrentPage',
})`
    && .Mui-expanded {
        min-height: 42px;
        max-height: 42px;
        margin-top: 0;
        margin-bottom: 0;
        align-items: center;
    }

    && .MuiAccordionSummary-content {
        margin: 0;
    }

    && .MuiAccordionSummary-root {
        padding: 0 10px 0 0;
        min-height: 42px;
        max-height: 42px;
        transition: background-color ${({ theme }) => theme.tokens.transitionQuick};

        ${({ isCurrentPage, theme }) =>
            isCurrentPage &&
            `
            background-color: ${theme.colors.primaryCyan11};
        `}

        :hover {
            background-color: ${({ isCurrentPage, theme }) => !isCurrentPage && theme.colors.primaryChampion30};
        }
    }

    && .MuiAccordionDetails-root {
        flex-direction: column;
        padding: 0;
    }
`

const ExpandableItemContainer = styled.div`
    width: 330px;
`

const useExpansion = (isDrawerOpen: boolean) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const handleExpansionToggle = () => {
        setIsExpanded((currentIsExpanded) => !currentIsExpanded)
    }

    useEffect(() => {
        isDrawerOpen ? setIsExpanded(true) : setIsExpanded(false)
    }, [isDrawerOpen])

    return {
        isExpanded,
        handleExpansionToggle,
    }
}

const SectionWithSubsectionsTab: React.FC<ISectionWithSubsectionsTabProps> = ({
    isDrawerOpen,
    item,
    currentPageSubsection,
    iconElement,
    isCurrentPage,
}) => {
    const { isExpanded, handleExpansionToggle } = useExpansion(isDrawerOpen)

    return (
        <ExpandableItemContainer>
            <StyledExpandable
                isCurrentPage={isCurrentPage}
                label={
                    <TabLabel
                        isCurrentPage={isCurrentPage}
                        to={URL.oldClient(item.subsectionObjects[0].Url)}
                        variant="h4"
                        sectionName={item.Name}
                    >
                        {iconElement}
                        {item.Translation}
                    </TabLabel>
                }
                muiExpansionPanelProps={{ expanded: isExpanded, onChange: handleExpansionToggle }}
            >
                {item.subsectionObjects.map((subsection) => (
                    <SubsectionTab
                        currentPageSubsection={currentPageSubsection}
                        key={subsection.Url}
                        subsection={subsection}
                        to={URL.oldClient(subsection.Url)}
                    >
                        {subsection.Translation}
                    </SubsectionTab>
                ))}
            </StyledExpandable>
        </ExpandableItemContainer>
    )
}

export default SectionWithSubsectionsTab
