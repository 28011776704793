import * as React from 'react'

import { Toggle } from '@planier/generic-components'
import { ITogglePickerOwnProps } from '@planier/value-picker'
import { getTestId } from '@planier/test'

interface ITogglePickerProps extends ITogglePickerOwnProps {}

const TogglePicker: React.FC<ITogglePickerProps> = ({ label, onChange, value, icon, valuePickerId }) => {
    const testId = getTestId(['VALUE_PICKER', 'TOGGLE'], valuePickerId)

    return <Toggle checked={value ?? false} label={label} onChange={onChange} icon={icon} testId={testId} />
}

export default TogglePicker
