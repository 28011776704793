import { Field, FieldProps } from 'formik'
import * as React from 'react'

import { AsetusTietotyyppi } from '../../OsiokohtaisetAsetuksetTietotyyppiMaaritykset'
import { IOsionAsetus } from '../../reducers/OsiokohtaisetAsetuksetReducer'
import { ErrorMessage } from '@planier/generic-components'
import FormikDatepicker from 'components/atoms/Datepicker/FormikDatepicker'
import FormikRadioButtonGroup from 'components/atoms/RadioButtonGroup/FormikRadioButtonGroup'
import FormikTextInput from 'components/atoms/TextInput/FormikTextInput'
import FormikToggle from 'components/atoms/Toggle/FormikToggle'
import { FormikDropdown } from '@planier/generic-components-dropdown'
import IPossibleSelectedSetting from '../../Types/IPossibleSelectedSetting'
import { isEmpty, isNull } from 'lodash-es'

function createOnChangeWithConversion<T>(fieldName: string, form: FieldProps['form'], converter: (value: string) => T) {
    const onChange = (event: React.ChangeEvent<any>) => {
        let { value } = event.target

        if (value === '') {
            value = null
        } else {
            value = converter(value)
        }

        form.setFieldValue(fieldName, value)
    }

    return onChange
}

const convertNullToEmptyString = (value: any) => (isNull(value) ? '' : value)

interface IOsiokohtaisetAsetuksetInputWrapperFormikProps {
    asetus: IOsionAsetus
    id: string
}

/**
 * Wrapper-komponentti eri asetusten tietotyypeille.
 * Tarkoitus tulevaisuudessa refaktoroida kaikki omiksi komponenteikseen.
 */
const OsiokohtaisetAsetuksetInputWrapperFormik: React.FunctionComponent<
    IOsiokohtaisetAsetuksetInputWrapperFormikProps
> = ({ asetus, id }) => {
    if (asetus.Tietotyyppi === AsetusTietotyyppi.int) {
        return (
            <Field name={asetus.Nimi}>
                {({ meta, field: { value, ...field }, form }: FieldProps) => (
                    <FormikTextInput
                        field={{
                            value: convertNullToEmptyString(value),
                            ...field,
                            onChange: createOnChangeWithConversion<number>(field.name, form, Number),
                        }}
                        form={form}
                        fullWidth
                        id={id}
                        meta={meta}
                        type="number"
                    />
                )}
            </Field>
        )
    } else if (asetus.Tietotyyppi === AsetusTietotyyppi.double) {
        return (
            <Field name={asetus.Nimi}>
                {({ meta, field: { value, ...field }, form }: FieldProps) => (
                    <FormikTextInput
                        field={{
                            value: convertNullToEmptyString(value),
                            ...field,
                            onChange: createOnChangeWithConversion<number>(field.name, form, Number),
                        }}
                        form={form}
                        fullWidth
                        id={id}
                        meta={meta}
                        type="number"
                    />
                )}
            </Field>
        )
    } else if (asetus.Tietotyyppi === AsetusTietotyyppi.bool) {
        return (
            <Field name={asetus.Nimi}>
                {({ field }: FieldProps) => (
                    <FormikToggle
                        field={field}
                        id={id}
                        label={field.value ? 'toggle-paalla' : 'toggle-pois-paalta'}
                        switchProps={{ color: 'primary' }}
                    />
                )}
            </Field>
        )
    } else if (asetus.Tietotyyppi === AsetusTietotyyppi.Date) {
        return <Field component={FormikDatepicker} id={id} name={asetus.Nimi} />
    } else if (asetus.Tietotyyppi === AsetusTietotyyppi.string) {
        return (
            <Field name={asetus.Nimi}>
                {({ meta, field: { value, ...field }, form }: FieldProps) => (
                    <FormikTextInput
                        field={{
                            value: convertNullToEmptyString(value),
                            ...field,
                            onChange: createOnChangeWithConversion<string>(field.name, form, String),
                        }}
                        form={form}
                        fullWidth
                        id={id}
                        meta={meta}
                    />
                )}
            </Field>
        )
    } else if (asetus.Tietotyyppi === AsetusTietotyyppi.ListInt) {
        return (
            <Field name={asetus.Nimi}>
                {({ meta, field: { value, ...field }, form }: FieldProps) => (
                    <FormikTextInput
                        field={{
                            value: convertNullToEmptyString(value),
                            ...field,
                            onChange: createOnChangeWithConversion<string>(field.name, form, String),
                        }}
                        form={form}
                        fullWidth
                        id={id}
                        meta={meta}
                        placeholder="input-kokonaisluku-lista-placeholder"
                    />
                )}
            </Field>
        )
    } else {
        if (!asetus.MahdollisetArvot || isEmpty(asetus.MahdollisetArvot)) {
            return <ErrorMessage>OsiokohtaisetAsetukset.virhe.enumillaEiArvoja</ErrorMessage>
        } else if (asetus.MahdollisetArvot.length <= 3) {
            return (
                <Field name={asetus.Nimi}>
                    {({ meta, field: { value, ...field }, form }: FieldProps) => (
                        // TODO Uuden Material UI:n radiobuttonit toimivat pelkästään stringeillä.
                        //      Enumien muuttaminen stringeiksi olisi ehkä selkeämpää jossain muualla yhteisesti
                        //      enumeille niin olisi konsistentimpaa.
                        <FormikRadioButtonGroup
                            field={{
                                value: String(value),
                                ...field,
                                onChange: createOnChangeWithConversion<number>(asetus.Nimi, form, Number),
                            }}
                            form={form}
                            id={id}
                            meta={meta}
                            name={asetus.Nimi}
                            radioButtonsProps={(asetus.MahdollisetArvot || []).map(({ Selite, Arvo }) => ({
                                label: Selite,
                                key: Selite,
                                value: String(Arvo),
                                radioProps: { color: 'primary' },
                            }))}
                        />
                    )}
                </Field>
            )
        } else {
            return (
                <Field name={asetus.Nimi}>
                    {(passProps: FieldProps) => (
                        <FormikDropdown<IPossibleSelectedSetting>
                            itemIdField="Arvo"
                            itemOptionLabelFields={['Selite']}
                            itemValueLabelFields={['Selite']}
                            {...passProps}
                            defaultText={'select'}
                            filteringProps={['Selite']}
                            fullWidth={false}
                            items={asetus.MahdollisetArvot || []}
                            multiselect={false}
                            textForMultipleSelected=""
                            textInputId={id}
                        />
                    )}
                </Field>
            )
        }
    }
}

export default OsiokohtaisetAsetuksetInputWrapperFormik
