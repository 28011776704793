import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from '@emotion/styled'

import { TranslatedTypography } from '@planier/generic-components'

interface ISelectedRowsCounterProps {
    selectedItemsAmount: number
}

const CenterTextContainer = styled(Grid)`
    display: flex;
    align-items: center;
`

const SelectedRowsCounter: React.FunctionComponent<ISelectedRowsCounterProps> = ({ selectedItemsAmount }) => (
    <CenterTextContainer item>
        <TranslatedTypography variant="bodyXS">
            {{
                key: 'ConfigurableList.ListActionButtons.SelectedAmount',
                params: { amount: selectedItemsAmount },
            }}
        </TranslatedTypography>
    </CenterTextContainer>
)

export default SelectedRowsCounter
