import * as React from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { BoundThunk } from '@planier/generic-state'
import { fetchValuePickerConfigurationsThunk } from '../../Thunks/ValuePickerThunks'
import ValuePickerStateless from '../ValuePicker/ValuePickerStateless'
import { selectValuePickerConfiguration } from '../../State/ValuePickerSelectors'
import { TValuePickerConfiguration } from '../../types/TValuePickerConfiguration'

interface IOwnProps {
    valuePickerId: string
    label?: string
    contextId?: string
    context?: 'form' | 'list' | 'unknown'
    value: any
    error?: string
    isInitialValueSet: boolean
    note: string
    isRequired: boolean
    onValueChange: (id: string) => void
    valuePickerComponentProperties: any
}

interface IStateProps {
    valuePickerConfiguration: TValuePickerConfiguration | null
}

interface IDispatchProps {
    fetchValuePickerConfigurations: BoundThunk<typeof fetchValuePickerConfigurationsThunk>
}

interface IProps extends IOwnProps, IStateProps, IDispatchProps {}

/**
 * Renders a single value picker and takes care of its initialisation.
 */
const ValuePickerStandaloneWithoutStoreUnconnected: React.FunctionComponent<IProps> = ({
    valuePickerId,
    fetchValuePickerConfigurations,
    valuePickerConfiguration,
    label,
    contextId,
    context,
    note,
    value,
    error,
    isRequired,
    isInitialValueSet,
    onValueChange,
    valuePickerComponentProperties,
}) => {
    useEffect(() => {
        fetchValuePickerConfigurations([valuePickerId])
    }, [fetchValuePickerConfigurations, valuePickerId])

    if (!valuePickerConfiguration) {
        return null
    }

    return (
        <ValuePickerStateless
            isRequired={isRequired}
            notes={note}
            configuration={valuePickerConfiguration}
            valuePickerComponentProperties={valuePickerComponentProperties}
            onChange={onValueChange}
            error={error}
            valueFromStore={value}
            {...valuePickerConfiguration}
            valuePickerId={valuePickerId}
            label={label}
            isInitialValueSet={isInitialValueSet}
            contextId={contextId}
            context={context}
        />
    )
}

const mapStateToProps = (state: RootState, { valuePickerId }: IOwnProps) => ({
    valuePickerConfiguration: selectValuePickerConfiguration(state, valuePickerId),
})

const mapDispatchToProps = {
    fetchValuePickerConfigurations: fetchValuePickerConfigurationsThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ValuePickerStandaloneWithoutStoreUnconnected)
