import * as React from 'react'
import styled from '@emotion/styled'

import { IOnInputChangeFunction } from '../../Interfaces'
import { SEARCH_FIELD_HEIGHT } from './../../Constants'
import TextInput from 'components/atoms/TextInput'

const Container = styled.div`
    height: ${SEARCH_FIELD_HEIGHT}px;
`

interface ISearchFieldProps {
    inputChangeAction: IOnInputChangeFunction
    userInput: string
    textInputId: string
}

const SearchField: React.FunctionComponent<ISearchFieldProps> = ({
    userInput,
    inputChangeAction,
    textInputId,
}: ISearchFieldProps) => (
    <Container>
        <TextInput
            autoComplete="off"
            autoFocus
            changeAction={inputChangeAction}
            doNotTranslate={false}
            floatingLabelText="haku"
            id={textInputId}
            value={userInput}
        />
    </Container>
)

export default SearchField
