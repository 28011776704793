import { JSX } from 'react'
import { EValuePickerExtraComponent, ExtraComponent, ValuePickerExtra } from './'
import { flatten, isEmpty } from 'lodash-es'

type Props = {
    items: Record<any, any>[]
}

const SelectedItemExtras = ({ items }: Props): JSX.Element | null => {
    const itemsWithExtras = items.filter((item) => item.hasOwnProperty('Extras'))

    if (isEmpty(itemsWithExtras)) {
        return null
    }

    return (
        <>
            {flatten(itemsWithExtras).map((item, index) => {
                const extras: ValuePickerExtra[] = item?.Extras

                if (!extras) {
                    return null
                }

                return extras.map(({ Component, Props }: ValuePickerExtra) => {
                    const additionalProps = () => {
                        switch (Component) {
                            case EValuePickerExtraComponent.InfoTextLabel:
                                return { name: item.Name, first: index === 0 }

                            default:
                                return {}
                        }
                    }

                    return (
                        <ExtraComponent
                            key={`${Component}-${index}`}
                            Component={Component}
                            Props={{ ...Props, ...additionalProps() }}
                        />
                    )
                })
            })}
        </>
    )
}

export default SelectedItemExtras
