import IAsyncOperationState from '../Types/IAsyncOperationState'
import { IThunkBaseAction } from '@planier/generic-state'
import { displayErrorToaster, displaySuccessToaster } from '@planier/notifications'
import { asyncOperationFailedAction, asyncOperationSucceededAction } from '../State/AsyncOperationActions'

export const asyncOperationFailedWithErrorDisplayThunk = (
    error: Error,
    id = 'global',
    errorMessage = '',
    other: IAsyncOperationState['other'] = null
): IThunkBaseAction => async (dispatch) => {
    dispatch(displayErrorToaster(errorMessage ? errorMessage : error))
    dispatch(asyncOperationFailedAction(error, id, other))
}

export const asyncOperationSucceededWithSuccessDisplayThunk = (
    successMessage: string,
    id = 'global',
    other: IAsyncOperationState['other'] = null
): IThunkBaseAction => async (dispatch) => {
    dispatch(displaySuccessToaster(successMessage))
    dispatch(asyncOperationSucceededAction(id, other))
}
