import { RefObject, useCallback, useEffect } from 'react'

const useClickOutside = (ref: RefObject<any>, callback: () => void): void => {
    const handleClickOutside = useCallback(
        (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback()
            }
        },
        [callback, ref]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [handleClickOutside])
}

export default useClickOutside
