import styled from '@emotion/styled'
import { JSX } from 'react'
import { Icon, ReadOnlyText, StatusLabel, Tooltip } from '@planier/generic-components'
import IMessageThread, { EMessageType } from '../Types/IMessageThread'
import { formatDateTimeStringForDisplay } from '@planier/dates'
import { useDispatch } from 'react-redux'
import { updateMessageThreadIsReadStatusById } from '../State/MessagingThunks'
import CircularProgress from '@material-ui/core/CircularProgress'
import { EUserType } from '@planier/user'
import { useAppTheme } from '@planier/theme'

type ThreadProps = {
    thread: IMessageThread
    onClick: (thread: IMessageThread) => void
}

const MessageContainer = styled.div<ThreadProps & { isAdmin?: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 12px;
    flex: 1;
    border-radius: 4px;
    gap: 8px;
    cursor: pointer;
    transition: ${({ theme }) => `background-color ${theme.tokens.transitionQuick}`};

    ${({ theme, thread: { LatestMessage } }) =>
        !LatestMessage.IsRead && `outline: 2px solid ${theme.colors.primaryTeal100}`};

    background-color: ${({ theme, isAdmin }) =>
        isAdmin ? theme.colors.primaryCyan11 : theme.colors.primaryChampion11};

    &:hover {
        background-color: ${({ theme, isAdmin }) =>
            isAdmin ? theme.colors.primaryCyan30 : theme.colors.primaryChampion30};
    }
`

const IconContainer = styled.div`
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
`

const MarkAsUnreadButton = styled.div`
    display: flex;
    width: 40px;
    height: 100%;
    opacity: 0;
    align-items: center;
    justify-content: center;
    padding: 4px;
    transition: ${({ theme }) => `opacity ${theme.tokens.transitionQuick}`};
`

const RowContainer = styled.div`
    display: flex;
    width: 512px;
    max-width: 512px;
    flex-direction: row;

    &:hover ${MarkAsUnreadButton} {
        opacity: 1;
    }

    &:hover {
        cursor: pointer;
    }
`

const LoadingSpinner = styled(CircularProgress)<{ size: number }>`
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
`

const SpreadContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const UpperLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
`

const MessageContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 436px;
    gap: 4px;
`

const getThreadTypeText = ({ Type }: IMessageThread): string => {
    if (Type === EMessageType.MobileMessage) {
        return 'messaging.messageBig'
    }
    return 'messaging.announcementBig'
}

const MessageThread = ({ thread, onClick }: ThreadProps): JSX.Element | null => {
    const dispatch = useDispatch()
    const { colors } = useAppTheme()

    const handleOnClick = () => {
        onClick(thread)
    }

    const handleMarkMessageThreadAsUnread = () => {
        dispatch(updateMessageThreadIsReadStatusById({ ThreadId: thread.Id, IsRead: false }))
    }

    if (!thread.LatestMessage) {
        return null
    }

    return (
        <RowContainer>
            <MessageContainer
                thread={thread}
                isAdmin={thread.ThreadStartedCreatedByLogin?.Type === EUserType.Admin}
                onClick={handleOnClick}
            >
                <SpreadContent>
                    <UpperLeft>
                        <StatusLabel
                            variant={thread.Type === EMessageType.MobileMessage ? 'message' : 'announcement'}
                            text={getThreadTypeText(thread)}
                            translate
                        />
                        <ReadOnlyText usage="bodyXS">{`${thread.MessageCount} kpl`}</ReadOnlyText>
                    </UpperLeft>
                    <Icon color={colors.primaryTeal120}>keyboard_arrow_right</Icon>
                </SpreadContent>
                <MessageContent>
                    <ReadOnlyText usage="h5" color="primary_120">
                        {thread.Title}
                    </ReadOnlyText>
                    <ReadOnlyText usage="bodyM" color="dark_100" textOverflow="ellipsis">
                        {thread.LatestMessage.Content}
                    </ReadOnlyText>
                </MessageContent>
                <SpreadContent>
                    <ReadOnlyText usage="bodyS" color="dark_100">
                        {thread.LatestMessage.CreatedByLogin?.Name}
                    </ReadOnlyText>
                    <ReadOnlyText usage="bodyS" color="dark_100">
                        {formatDateTimeStringForDisplay(thread.LatestMessage.CreatedTimestamp)}
                    </ReadOnlyText>
                </SpreadContent>
            </MessageContainer>
            <MarkAsUnreadButton>
                <Tooltip translate="yes" title="messaging.markAsUnread">
                    <IconContainer onClick={handleMarkMessageThreadAsUnread}>
                        {thread.LatestMessage.IsLoading ? (
                            <LoadingSpinner size={20} />
                        ) : (
                            <Icon color={colors.primaryTeal120}>mark_email_unread</Icon>
                        )}
                    </IconContainer>
                </Tooltip>
            </MarkAsUnreadButton>
        </RowContainer>
    )
}

export default MessageThread
