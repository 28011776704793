import makeRequest, { ERequestMethod } from '@planier/rest-api'

const PUBLISH_SUUNNITTEKUJAKSOVERSIO_URL = 'Suunnittelujakso/Versio/Julkaise'

const postPublishSuunnittelujaksoversio = async (suunnittelujaksoversioId: number): Promise<void> => {
    await makeRequest({
        method: ERequestMethod.POST,
        url: PUBLISH_SUUNNITTEKUJAKSOVERSIO_URL + '/' + suunnittelujaksoversioId,
    })
}

export default postPublishSuunnittelujaksoversio
