import * as React from 'react'

import StyledToggle, { IStyledToggleProps } from './StyledToggle'
import { Translate, TTranslatable } from '@planier/localization'

export interface ITranslatedToggleProps extends IStyledToggleProps {
    label: TTranslatable
}

const TranslatedToggle: React.FunctionComponent<ITranslatedToggleProps> = ({ label, ...props }) => (
    <Translate translate={{ label }}>
        {({ label: toggleLabel }) => <StyledToggle label={toggleLabel} {...props} />}
    </Translate>
)

export default TranslatedToggle
