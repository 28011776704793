import { IThunkBaseAction } from '@planier/generic-state'
import { closeModalAction } from '@planier/modal'
import { saveColumnUserSettingsToServer, resetUserCustomSettingsForColumns } from '../webApi/ConfigurableListApi'
import { displayErrorToaster, displaySuccessToaster } from '@planier/notifications'
import { getObjectDiff } from '@planier/generic-utilities'
import IListViewUserSettingsForColumns from '../interfaces/IListViewUserSettingsForColumns'
import {
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
    asyncOperationFailedWithErrorDisplayThunk,
} from '@planier/async-operation'
import { fetchListViewMetaDataThunk } from './ConfigurableListThunks'
import { saveUserSettingsForColumnsAction } from '../State/ConfigurableListActions'
import EConfigurableListModal from '../Types/EConfigurableListModal'

interface ISaveUserSettingsForColumns {
    (
        listId: string,
        settingsForColumns: IListViewUserSettingsForColumns,
        initialValues: IListViewUserSettingsForColumns
    ): IThunkBaseAction
}

export const saveSettingsForColumnsThunk: ISaveUserSettingsForColumns = (
    listId,
    settingsForColumns,
    initialValues
) => async (dispatch) => {
    try {
        dispatch(closeModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`))

        dispatch(saveUserSettingsForColumnsAction(listId, settingsForColumns))

        const changedValueObjects: Partial<IListViewUserSettingsForColumns> = getObjectDiff(
            initialValues,
            settingsForColumns
        )

        const changedValuesWithTheId = Object.keys(changedValueObjects).map((columnId) => {
            return {
                ...changedValueObjects[columnId],
                ListViewColumnId: columnId,
            }
        })

        await saveColumnUserSettingsToServer(listId, changedValuesWithTheId)

        dispatch(displaySuccessToaster('configurable-list.ColumnManagementModal.SaveSucceeded'))
    } catch (e) {
        dispatch(displayErrorToaster('configurable-list.ColumnManagementModal.SaveFailed'))
    }
}

interface IResetUserCustomSettingsThunk {
    (listId: string): IThunkBaseAction
}

export const resetUserCustomSettingsForColumnsThunk: IResetUserCustomSettingsThunk = (listId) => async (dispatch) => {
    dispatch(asyncOperationStartedAction())

    try {
        await resetUserCustomSettingsForColumns(listId)

        await dispatch(fetchListViewMetaDataThunk(listId))

        dispatch(asyncOperationSucceededAction())
        dispatch(closeModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`))
    } catch (e) {
        await dispatch(asyncOperationFailedWithErrorDisplayThunk(e))
    }
}
