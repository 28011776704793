import * as React from 'react'

import {
    IFormViewFieldComponent,
    IFormViewFieldClassComponent,
    IFormViewCustomFormComponent,
} from '@planier/form-view-types'
import { getLogger } from '@planier/log'
const Log = getLogger('form-view.FormViewComponentRegistry')

type TFieldComponent = IFormViewFieldComponent | IFormViewFieldClassComponent

interface IFormViewRegistryFieldComponents {
    [componentId: string]: TFieldComponent | undefined
}

interface IFormViewRegistryCustomModalComponents {
    [componentId: string]: IFormViewCustomFormComponent | undefined
}

export interface IFormViewComponentRegistry {
    getFieldComponent: (componentId: string) => TFieldComponent | null
    getCustomModalComponent: (componentId: string) => IFormViewCustomFormComponent | null
    hasCustomModalComponent: (componentId: string) => boolean
}

const createRegistryState = (
    fieldComponents: null | IFormViewRegistryFieldComponents,
    customModalComponents: null | IFormViewRegistryCustomModalComponents
): IFormViewComponentRegistry => {
    const getFieldComponent = (componentId: string) => {
        if (!fieldComponents) {
            Log.error('Form field components not registered')
            return null
        }

        const Component = fieldComponents[componentId]

        if (!Component) {
            Log.error(`No form field component with ID '${componentId}'`)
            return null
        }

        return Component
    }

    const getCustomModalComponent = (componentId: string) => {
        if (!customModalComponents) {
            Log.error('Form custom modal components not registered')
            return null
        }

        const Component = customModalComponents[componentId]

        if (!Component) {
            Log.error(`No form custom modal component with ID '${componentId}'`)
            return null
        }

        return Component
    }

    const hasCustomModalComponent = (componentId: string) =>
        Boolean(customModalComponents && customModalComponents[componentId])

    return { getFieldComponent, getCustomModalComponent, hasCustomModalComponent }
}

const emptyRegistry = createRegistryState(null, null)

const FormViewComponentRegistry = React.createContext<IFormViewComponentRegistry>(emptyRegistry)

export default FormViewComponentRegistry

interface IFormViewComponentRegistryProviderProps {
    readonly fieldComponents: IFormViewRegistryFieldComponents
    readonly customModalComponents: IFormViewRegistryCustomModalComponents
}

export const FormViewComponentRegistryProvider: React.FunctionComponent<IFormViewComponentRegistryProviderProps> = ({
    children,
    fieldComponents,
    customModalComponents,
}) => {
    const registry = createRegistryState(fieldComponents, customModalComponents)

    return <FormViewComponentRegistry.Provider value={registry}>{children}</FormViewComponentRegistry.Provider>
}
