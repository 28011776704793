/* eslint-disable no-restricted-syntax */
import { createSlice } from '@reduxjs/toolkit'
import IWorkforceSchedulingState from 'packages/workforce-scheduling/Types/IWorkforceSchedulingState'
import { fetchPublicHolidays } from '../Thunks/WorkforceSchedulingThunks'

const initialState = <IWorkforceSchedulingState>{
    publicHolidays: [],
}

const workforceSchedulingSlice = createSlice({
    name: 'workforceScheduling',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPublicHolidays.fulfilled, (state, { payload }) => {
            state.publicHolidays = payload
        }),
            builder.addCase(fetchPublicHolidays.rejected, (state) => {
                state.publicHolidays = []
            })
    },
})

export default workforceSchedulingSlice.reducer
