import { connect } from 'react-redux'
import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { Icon } from '@planier/generic-components'
import Styles from 'constants/Styles'
import { IConfigurableListHeaderComponent } from '../../../interfaces/IList'
import ConfigurableListComponentRegistry from '../../../ConfigurableListComponentRegistry'
import { setColumnSortedAndFetchSortedDataThunk } from '../../../Thunks/ConfigurableListThunks'
import SortIcon from './SortIcon'
import { IDataSourceSortParameters, IDataSourceSortParameter } from '@planier/data-source-types'
import { BoundThunk } from '@planier/generic-state'

interface IOwnProps {
    headerComponent: IConfigurableListHeaderComponent | null
    listId: string
    text: string
    width: number
    sortBySettings: IDataSourceSortParameters
    sortByParameter: IDataSourceSortParameter['Property']
    sortOrder: IDataSourceSortParameter['Order'] | undefined
    tooltip: string | null
}

interface IDispatchProps {
    setColumnSortedAndFetchSortedData: BoundThunk<typeof setColumnSortedAndFetchSortedDataThunk>
}

export interface IRowHeaderElementProps extends IOwnProps, IDispatchProps {}

const Container = styled.div`
    width: ${({ width }: { width: number }) => `${width}rem`};
    min-width: ${({ width }) => `${width}rem`};
    display: flex;
    align-items: center;
    margin-left: 4px;
    margin-right: 4px;
    cursor: pointer;
`

const HeaderText = styled(Typography)`
    color: ${Styles.supplementaryColor.columnHeader};
`

const InfoIconTooltipContaienr = styled.span`
    padding-left: 4px;
    padding-top: 6px;
`

/**
 * Renderöi yksittäisen sarakkeen otsikon.
 */
export class RowHeaderElement extends React.Component<IRowHeaderElementProps> {
    static contextType = ConfigurableListComponentRegistry
    context: React.ContextType<typeof ConfigurableListComponentRegistry>

    getHeaderComponent = (headerComponent: IConfigurableListHeaderComponent): React.ReactNode => {
        const Component = this.context.getComponent(headerComponent.ComponentId)
        if (!Component) {
            return null
        }

        const staticParameters = headerComponent.StaticParameters.reduce((accumulatedValue, currentValue) => {
            const { Name, Value } = currentValue
            accumulatedValue[Name] = Value

            return accumulatedValue
        }, {} as { [key: string]: unknown })

        return <Component {...staticParameters} />
    }

    handleHeaderClick = (): void => {
        const { listId, sortByParameter, setColumnSortedAndFetchSortedData, sortOrder } = this.props

        sortByParameter && setColumnSortedAndFetchSortedData(listId, sortOrder, sortByParameter)
    }

    render(): React.ReactNode {
        const { headerComponent, text, width, sortByParameter, sortOrder, sortBySettings, tooltip } = this.props

        const headerContent = headerComponent ? (
            this.getHeaderComponent(headerComponent)
        ) : (
            <HeaderText variant="bodyS">{text}</HeaderText>
        )

        const infoIcon =
            tooltip === null ? null : (
                <Icon
                    colorTheme={'primary'}
                    size={16}
                    tooltip={tooltip}
                    tooltipChildWrapperElement={InfoIconTooltipContaienr}
                >
                    info_outlined
                </Icon>
            )

        return (
            <Container onClick={this.handleHeaderClick} role="button" width={width}>
                {headerContent}
                {infoIcon}
                <SortIcon
                    handleHeaderClick={this.handleHeaderClick}
                    sortByParameter={sortByParameter}
                    sortBySettings={sortBySettings}
                    sortOrder={sortOrder}
                />
            </Container>
        )
    }
}

const mapStateToProps = null
const mapDispatchToProps = {
    setColumnSortedAndFetchSortedData: setColumnSortedAndFetchSortedDataThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(RowHeaderElement)
