import type { Identifier, SideEffect } from '@planier/types'

export const getSideEffects = (value: number | number[], options: Identifier[], multiselect = false): SideEffect[] => {
    const optionsWithSideEffects = options.filter(({ SideEffect }) => SideEffect)

    const sideEffectsNested = multiselect
        ? optionsWithSideEffects
              .filter(({ Id }) => (value as number[]).find((y) => y === Id))
              .map(({ SideEffect }) => SideEffect)
        : optionsWithSideEffects.filter(({ Id }) => Id === value).map(({ SideEffect }) => SideEffect)

    return sideEffectsNested ? sideEffectsNested.flat() : []
}
