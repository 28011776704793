import { FC, JSX, useRef, useState } from 'react'
import PickersDay, { PickersDayProps } from '@material-ui/lab/PickersDay'
import DatePicker from '@material-ui/lab/DatePicker'

import TextInputV2 from '../TextInput/TextInputV2'
import { Translation } from '@planier/localization'
import {
    areSame,
    formatForDataUsage as formatDateForDataUsage,
    formatForDisplay as formatDateForDisplay,
    isValidDateInput,
    startOf,
    TDateObject,
    TDateStringDataFormat,
} from '@planier/dates'
import Icon from '../../Icon'
import styled from '@emotion/styled'
import { primary_120 } from 'constants/Styles'
import { convertDateInputForDataUsage } from '@planier/dates/Utilities/DateUtilities'

type TRenderDay = (
    day: TDateStringDataFormat,
    selectedDays: (TDateStringDataFormat | TDateObject | null)[],
    dayComponentProps: PickersDayProps<TDateObject | TDateStringDataFormat>
) => JSX.Element

const DateInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: flex-start;
    height: 100%;
`

const OpenCalendarButton = styled.button`
    display: flex;
    margin-left: 5px;
`

interface IDayPickerProps {
    value: TDateStringDataFormat[]
    onChange: (newDates: TDateStringDataFormat[]) => void
    multiselect?: boolean
    label?: string
    placeholder?: string
    onBlur?: () => void
    required?: boolean
    onOpen?: () => void
    isOpen?: boolean
    onClose?: () => void
    isDayDisplayedAsSelectedFunc?: (day: TDateStringDataFormat) => boolean
    renderDay?: (
        day: TDateStringDataFormat,
        selectedDays: (TDateStringDataFormat | TDateObject | null)[],
        dayComponentProps: PickersDayProps<TDateObject | TDateStringDataFormat>,
        defaultDayRenderer: TRenderDay
    ) => JSX.Element
    classNameTextInput?: string
    shouldDateBeDisabled?: (day: TDateStringDataFormat) => boolean
    isFieldErrored?: boolean
    validationError?: string | null
    errors?: string
}

// Note the typecast here to be type safe. I.e. when it receives null or undefined,
// it will return null, but for moments it'll return string
export const formatForDataUsage = ((dateObject: TDateObject | null | undefined) => {
    if (dateObject === null || dateObject === undefined) {
        return null
    }
    return formatDateForDataUsage(startOf(dateObject))
}) as ((param: TDateObject) => TDateStringDataFormat) & ((param: null | undefined) => null)

const formatForDisplay = (values: TDateStringDataFormat[]) => {
    return values.map((valueDate) => formatDateForDisplay(valueDate)).join(', ')
}

const filterOutDayFromValues = (dateValue: TDateStringDataFormat | TDateObject, values: TDateStringDataFormat[]) => {
    return values.filter((valueDate) => !areSame(valueDate, dateValue, 'day'))
}

/**
 * Picker for days.
 *
 * Uses the Material UI's DatePicker. There are a couple of technical things to note:
 * - MUI's DatePicker at least currently allows only for a single value. In case
 *   of multiselect, we circumvent that by simply rendering the rest of the selected
 *   dates as if they were really selected.
 * - We pass an empty onChange function to Material UI's DatePicker and instead use the `onDaySelect`
 *   for each day. This is because of a bit weird behavior by the MUI component: When there is any
 *   date disabled when the calendar menu is opened, it automatically selects the first enabled date.
 *   However, we can circumvent that behavior by giving the `onDaySelect` to days instead of passing
 *   `onChange` to the DatePicker itself.
 */
const DayPickerV2: FC<IDayPickerProps> = ({
    value,
    onChange,
    multiselect = true,
    label = 'generic-components.DayPicker.Label.DefaultValue',
    placeholder = 'generic-components.DayPicker.Label.DefaultValue',
    onBlur,
    onOpen,
    onClose,
    isOpen: isOpenProp,
    isDayDisplayedAsSelectedFunc,
    renderDay,
    required,
    errors,
    shouldDateBeDisabled,
}) => {
    const [isOpenState, setIsOpen] = useState(false)
    const [localDateInput, setLocalDateInput] = useState<string | undefined>(undefined)
    const [localDateInputError, setLocalDateInputError] = useState<string | undefined>(undefined)

    const datePickerRef = useRef<HTMLInputElement | null>(null)

    const isOpen = isOpenProp ?? isOpenState

    const isDateSelectedInCalendar = (dayInCalendar: TDateStringDataFormat | TDateObject) => {
        const isSelected = value.some((selectedDate) => areSame(selectedDate, dayInCalendar, 'day'))
        return isSelected
    }

    const handleOpen = () => {
        onOpen ? onOpen() : setIsOpen(true)
        datePickerRef.current?.focus()
    }

    const handleClose = () => {
        onClose ? onClose() : setIsOpen(false)

        onBlur && onBlur()
    }

    const handleDaySelect = (newlySelectedDate: TDateStringDataFormat | TDateObject | null) => {
        if (!newlySelectedDate) {
            return
        }

        const dateAsString = formatDateForDataUsage(newlySelectedDate)

        const isDateAlreadySelected = isDateSelectedInCalendar(dateAsString)
        if (multiselect && isDateAlreadySelected) {
            onChange(filterOutDayFromValues(dateAsString, value))
        } else {
            onChange(multiselect ? [...value, dateAsString] : [dateAsString])
        }

        if (!multiselect) {
            handleClose()
        }
    }

    const handleDaySelectByTyping = (input: TDateStringDataFormat) => {
        onChange([convertDateInputForDataUsage(input)])
    }

    const handleTextInputChange = (input: string) => {
        setLocalDateInput(input)
        if (!required && input === '') {
            onChange([])
            setLocalDateInput(undefined)
            setLocalDateInputError(undefined)
            return
        }
        if (isValidDateInput(input)) {
            handleDaySelectByTyping(input)
            setLocalDateInput(undefined)
            setLocalDateInputError(undefined)
        } else {
            setLocalDateInputError('Virheellinen päivämäärä')
        }
    }

    const defaultRenderDay: TRenderDay = (day, selectedDates, props) => {
        return (
            <PickersDay
                {...props}
                onDaySelect={handleDaySelect}
                selected={
                    isDateSelectedInCalendar(day) ||
                    (isDayDisplayedAsSelectedFunc && isDayDisplayedAsSelectedFunc(formatDateForDataUsage(day)))
                }
            />
        )
    }

    const handleDayRender: TRenderDay = (day, selectedDates, props) => {
        const dayAsString = formatDateForDataUsage(day)

        return renderDay
            ? renderDay(dayAsString, selectedDates, { ...props, onDaySelect: handleDaySelect }, defaultRenderDay)
            : defaultRenderDay(dayAsString, selectedDates, props)
    }

    const shouldDisableDate = (day: TDateStringDataFormat | TDateObject) => {
        // Check whether the prop is given when passing props to `DatePicker` so no need to check it here
        return (shouldDateBeDisabled as Exclude<IDayPickerProps['shouldDateBeDisabled'], undefined>)(
            formatDateForDataUsage(day)
        )
    }

    const labelToUse = Translation.has(label) ? Translation.translateKey(label) : label

    const placeholderToUse = Translation.has(placeholder) ? Translation.translateKey(placeholder) : placeholder

    return (
        <DatePicker
            allowSameDateSelection
            disableCloseOnSelect={multiselect}
            mask="__.__.____"
            onChange={() => null}
            onClose={handleClose}
            open={isOpen}
            renderDay={handleDayRender}
            ref={datePickerRef}
            renderInput={({ inputRef }) => {
                return (
                    <DateInputContainer ref={inputRef}>
                        <TextInputV2
                            onBlur={onBlur}
                            overrideStyle={{ width: '112px' }}
                            label={Translation.has(labelToUse) ? Translation.translateKey(labelToUse) : labelToUse}
                            isRequiredField={required}
                            onChange={handleTextInputChange}
                            placeholder={placeholderToUse}
                            errors={errors || localDateInputError}
                            value={localDateInput ?? formatForDisplay(value)}
                            AppendComponent={
                                <OpenCalendarButton onClick={isOpen ? undefined : handleOpen}>
                                    <Icon color={primary_120}>calendar_month</Icon>
                                </OpenCalendarButton>
                            }
                        />
                    </DateInputContainer>
                )
            }}
            shouldDisableDate={shouldDateBeDisabled ? shouldDisableDate : undefined}
            value={value[value.length - 1] ?? null}
        />
    )
}

export default DayPickerV2
