import styled from '@emotion/styled'
import { JSX } from 'react'
import { Checkbox } from '@planier/generic-components'
import { translate } from '@planier/localization'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedDaysEvents, selectSelectedEvents } from '../State/EmployeeCalendarSelectors'
import { isEmpty } from 'lodash-es'
import { setSelectedEvents } from '../State/EmployeeCalendarReducer'

const Container = styled.div`
    margin-bottom: 4px;
`

const ToggleAllEventsCheckbox = (): JSX.Element | null => {
    const dispatch = useDispatch()

    const allEvents = useSelector(selectSelectedDaysEvents)
    const selectedEvents = useSelector(selectSelectedEvents)

    if (isEmpty(allEvents)) {
        return null
    }

    const allEventsSelected = selectedEvents.length === allEvents.length

    const toggleAlLEvents = () => {
        const newSelection = !allEventsSelected ? allEvents : []

        dispatch(setSelectedEvents(newSelection))
    }

    return (
        <Container>
            <Checkbox
                id="select-all"
                value={allEventsSelected}
                label={translate('valitse-kaikki')}
                onClick={toggleAlLEvents}
                noPadding
            />
        </Container>
    )
}

export default ToggleAllEventsCheckbox
