import * as React from 'react'

import { Translate, TTranslatable } from '@planier/localization'
import StyledButton from './StyledButton'

export interface ITranslatedButtonProps extends React.ComponentProps<typeof StyledButton> {
    children: TTranslatable
}

const TranslatedButton: React.FC<ITranslatedButtonProps> = ({ children, ...props }) => (
    <Translate translate={{ children }}>
        {({ children: translatedText }) => <StyledButton {...props}>{translatedText}</StyledButton>}
    </Translate>
)

export default TranslatedButton
