import * as React from 'react'

import { SystemInternalLink, TooltipWithEllipsis } from '@planier/generic-components'

interface IWorkUnitProps {
    yksikko: { Id: number | null; Nimi: string | null } | null
    className?: string
}
const workUnitCardUrlStart = 'BSP/customerInfo.aspx?CustomerID='
const windowParams = 'toolbar=yes,scrollbars=yes,resizable=yes,left=300,width=1100,height=900'

const linkProps = {
    display: 'inline' as const,
    noWrap: true,
    variant: 'bodyXS' as const,
}

const WorkUnit: React.FC<IWorkUnitProps> = ({ yksikko, className }) => {
    if (yksikko === null || yksikko.Nimi === null) {
        return null
    }

    const { Nimi, Id } = yksikko

    return (
        <TooltipWithEllipsis title={Nimi}>
            {(ref) => (
                <SystemInternalLink
                    className={className}
                    muiLinkProps={linkProps}
                    ref={ref}
                    to={`${workUnitCardUrlStart}${Id}`}
                    windowParams={windowParams}
                >
                    {Nimi}
                </SystemInternalLink>
            )}
        </TooltipWithEllipsis>
    )
}

export default WorkUnit
