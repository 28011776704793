import * as React from 'react'
import { CancelToken } from 'axios'

import Dropdown, { dropdownDefaultProps, IDropdownProps } from './Dropdown'
import { makeRequestTemplate } from '@planier/rest-api'
import { debounce } from 'lodash-es'

interface IDropdownWithDynamicApiSearchProps<T> extends Omit<IDropdownProps<T>, 'onCloseMenu'> {
    fetchData: (input: string, cancelToken: CancelToken) => Promise<void>
}

interface IState {
    isLoading: boolean
}

const defaultDebounceTime = 600

class DropdownWithDynamicApiSearch<T> extends React.Component<IDropdownWithDynamicApiSearchProps<T>, IState> {
    static readonly defaultProps = dropdownDefaultProps()
    private cleanupApiCall: null | (() => void) = null

    state = {
        isLoading: false,
    }

    private fetchDataDebounced = debounce(async (userInput: string) => {
        const { cleanup } = makeRequestTemplate({
            execute: async (cancelToken) => {
                await this.props.fetchData(userInput, cancelToken)
            },
            after: () => {
                this.setState({ isLoading: false })
                this.cleanupApiCall = null
            },
        })

        this.cleanupApiCall = cleanup
    }, defaultDebounceTime)

    handleInputChange = (userInput: string): void => {
        this.cleanupApiCall && this.cleanupApiCall()

        this.setState({ isLoading: true })

        this.fetchDataDebounced(userInput)
    }

    // If a user has input a search term and then closes the menu,
    // the input is reseted but the data remains. That data is relevant
    // for the search term, but since that's now reseted, the data is now
    // out of sync with the (now empty) input. That's why we force a data query
    // when the menu closes if there was a search term used.
    handleCloseMenu = (userInput: string): void => {
        if (userInput) {
            this.fetchDataDebounced('')
        }
    }

    filterItems = (userInput: string, items: T[]): T[] => {
        return items
    }

    componentWillUnmount(): void {
        this.fetchDataDebounced.cancel()
        this.cleanupApiCall && this.cleanupApiCall()
    }

    render(): React.ReactNode {
        const { fetchData, isLoading, ...props } = this.props
        return (
            <Dropdown<T>
                inputFilterFunction={this.filterItems}
                isLoading={isLoading || this.state.isLoading}
                onCloseMenu={this.handleCloseMenu}
                onInputChange={this.handleInputChange}
                {...props}
            />
        )
    }
}

export default DropdownWithDynamicApiSearch
