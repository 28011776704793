import MockAdapter from 'axios-mock-adapter'
import axios, { AxiosRequestConfig } from 'axios'
import { getLogger } from '@planier/log'
const Log = getLogger('test.WebApiMock')
type HttpMethod = 'post' | 'get' | 'patch' | 'put' | 'delete'

export default class WebApiMock {
    axiosMock: MockAdapter
    constructor(axiosMock = new MockAdapter(axios)) {
        this.axiosMock = axiosMock

        // Log every mocked request so that if needed, we can easily see them (not just
        // in tests but also in stories)
        axios.interceptors.request.use((request) => {
            Log.warn('Making a mocked $0 request to url $1', request.method, request.url)
            return request
        })
    }

    getAxiosMock(): MockAdapter {
        return this.axiosMock
    }

    reset(): void {
        this.axiosMock.reset()
    }

    restore(): void {
        this.axiosMock.restore()
    }

    getHistoryForRequest(url: string, method: HttpMethod): AxiosRequestConfig | null {
        return this.axiosMock.history[method].find((axiosConfig) => axiosConfig.url === url) ?? null
    }
}
