export const setItemToLocalStorage = (key: string, value: string): void => {
    localStorage.setItem(key, value)
}

export const setJSONItemToLocalStorage = (key: string, value: unknown): void => {
    setItemToLocalStorage(key, JSON.stringify(value))
}

export const getItemFromLocalStorage = (key: string): string | null => {
    return localStorage.getItem(key)
}

export const getJSONItemFromLocalStorage = <T>(key: string): T | null => {
    const item = getItemFromLocalStorage(key)

    if (!item) {
        return null
    }

    try {
        return JSON.parse(item)
    } catch (e) {
        return null
    }
}
