import styled from '@emotion/styled'
import { JSX } from 'react'
import { IEventGroupsByDays } from '../Types/tyles'
import { LoadingPlaceholder, ReadOnlyText } from '@planier/generic-components'
import EventCard from './EventCard'
import { translate } from '@planier/localization'
import { capitalize, flatMap, isEmpty, times } from 'lodash-es'
import { IEmployeeDataSourceItem } from 'packages/messaging/Types/IEmployeeDataSourceItem'
import { useSelector } from 'react-redux'
import { selectIsLoadingEvents } from '../State/EmployeeCalendarSelectors'
import ToggleAllEventsCheckbox from './ToggleAllEventsCheckbox'
import MassEditControls from './MassEditControls'

type Props = {
    events: IEventGroupsByDays[]
    employee?: IEmployeeDataSourceItem
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 6px;
`

const Day = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    padding-left: 20px;
    background-color: ${({ theme }) => theme.colors.primaryChampion11};
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const DayContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
`

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 20px;
`

const HeaderContainer = styled.div`
    margin-left: 24px;
    margin-top: 24px;
`

const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 26px;
    margin-bottom: 16px;
`

const NoEventsText = styled.div`
    margin-left: 50px;
    margin-top: 10px;
    padding-bottom: 10px;
`

const SelectedDaysEvents = ({ events, employee }: Props): JSX.Element => {
    const isLoadingEvents = useSelector(selectIsLoadingEvents)

    const eventsBlock = () => {
        if (isLoadingEvents) {
            const flatEvents = flatMap(events, (e) => e.events)
            const placeholders = times(flatEvents.length || 1, (index) => (
                <LoadingPlaceholder key={`event-placeholder-${index}`} width={550} height={85} autoWidth />
            ))

            return <LoadingContainer>{placeholders}</LoadingContainer>
        }

        return events.map(({ day, events: dayEvents }) => (
            <DayContainer key={day.format('D.M.YYYY')}>
                <Day>
                    <ReadOnlyText usage="h5">{capitalize(day.format('dd D.M.YYYY'))}</ReadOnlyText>
                </Day>

                {dayEvents.map((event, index) => (
                    <EventCard
                        key={index}
                        event={event}
                        drawBottomBorder={index !== dayEvents.length - 1}
                        employee={employee}
                    />
                ))}

                {isEmpty(dayEvents) && (
                    <NoEventsText>
                        <ReadOnlyText usage="bodyS">{translate('employeeCalendar.noEvents')}</ReadOnlyText>
                    </NoEventsText>
                )}
            </DayContainer>
        ))
    }

    return (
        <Container>
            <HeaderContainer>
                <HeaderRow>
                    <ReadOnlyText usage="h4">{translate('employeeCalendar.selectedDaysEvents')}</ReadOnlyText>
                    <MassEditControls employee={employee} />
                </HeaderRow>
                <ToggleAllEventsCheckbox />
            </HeaderContainer>

            {eventsBlock()}
        </Container>
    )
}

export default SelectedDaysEvents
