import { WebApiMock } from '@planier/test'
import { getTestPageData } from './PageTestData'
import MockAdapter from 'axios-mock-adapter/types'
import { AxiosRequestConfig } from 'axios'

export default class PageWebApiMock extends WebApiMock {
    constructor(axiosMock?: MockAdapter) {
        super(axiosMock)
    }

    mockPageDataFetchRequest(pageData = getTestPageData(), statusCode = 200): void {
        this.axiosMock.onGet(`/Page/${pageData.Id}`).reply(statusCode, pageData)
    }

    getHistoryForPageDataFetchRequest(pageId = getTestPageData().Id): AxiosRequestConfig | null {
        return super.getHistoryForRequest(`/Page/${pageId}`, 'get')
    }
}
