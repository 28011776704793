import URL from 'common/URL'
import { deleteJwt } from '@planier/authentication'

const logoutKeyInStorage = 'pln-logout'

const syncLogoutAcrossBrowserTabs = (event: WindowEventMap['storage']) => {
    if (event.key !== logoutKeyInStorage) {
        return
    }
    deleteJwt()

    URL.goto('')
}

window.addEventListener('storage', syncLogoutAcrossBrowserTabs)

export const logoutSyncedAcrossTabs = (): void => {
    window.localStorage.setItem(logoutKeyInStorage, `${Date.now()}`)
}
