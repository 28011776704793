import * as React from 'react'

import StyledTextInput, { TStyledTextInputProps } from './StyledTextInput'
import { Translate, TTranslatable } from '@planier/localization'

interface ITranslatedTextInputOwnProps {
    placeholder?: TTranslatable
    label?: TTranslatable
    helperText?: TTranslatable
}

// Omit label here because label of `TranslatedTextInput` is `ITranslatable` whereas it passes
// the translated result of it to `StyledTextInput`
export type TTranslatedTextInputProps = ITranslatedTextInputOwnProps & Omit<TStyledTextInputProps, 'label'>

const TranslatedTextInput: React.FunctionComponent<TTranslatedTextInputProps> = ({
    placeholder,
    label,
    helperText,
    ...passProps
}) => (
    <Translate translate={{ placeholder, label, helperText }}>
        {(translated) => <StyledTextInput {...translated} {...passProps} />}
    </Translate>
)

export default TranslatedTextInput
