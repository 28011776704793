import { createAction } from 'typesafe-actions'
export const closeAllModalsAction = createAction('modal.CloseAll')<never>()

type TModalId = string
export const closeModalAction = createAction('modal.Close')<TModalId>()

interface IOpenModalActionOptions {
    closeOthers: boolean
}
export const openModalAction = createAction(
    'modal.Open',
    (
        modalId: string,
        props?: Record<string, unknown>,
        { closeOthers }: IOpenModalActionOptions = { closeOthers: false }
    ) => ({
        modalId,
        props,
        closeOthers,
    })
)()
