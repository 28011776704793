import * as React from 'react'
import styled from '@emotion/styled'

import { Icon } from '@planier/generic-components'

const CloseButtonContainer = styled.div`
    position: relative;
`

interface ICloseButtonProps {
    onClick: () => void
    className?: string
}

const CloseButton: React.FC<ICloseButtonProps> = ({ className, onClick }) => (
    <CloseButtonContainer>
        <Icon className={className} onClick={onClick}>
            close
        </Icon>
    </CloseButtonContainer>
)

export default CloseButton
