import { EActionFunctionalityType, TFunctionalityAction } from '@planier/view-engine'
import { IEvent } from 'packages/event'
import { IPermission } from 'packages/legacy/interfaces/IActionPermission'

export const EditEventAction: TFunctionalityAction = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: 'Event_Edit',
    FormId: 'Event_Edit',
    AdditionalPropertyMapping: [],
    IgnoreInitialValueFields: [],
    PermissionId: 'UPDATE',
}

export const DeleteEventAction: TFunctionalityAction = {
    FunctionalityType: EActionFunctionalityType.Immediate,
    AdditionalPropertyMapping: [],
    IgnoreInitialValueFields: [],
    ValuePickerPropertyMapping: [],
    HiddenValuePickerIds: [],
    DataSourceActionId: 'Event_Remove_Tapahtuma',
    ConfirmationText: null, //'Haluatko varmasti poistaa tapahtuman?',
    ConfirmButtonText: null, //'Poista',
    ListId: null,
    ModalTitle: null,
    PermissionId: 'DELETE',
}

export const CancelEventAction: TFunctionalityAction = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: 'Event_Cancel',
    FormId: 'CancelEventForm',
    AdditionalPropertyMapping: [],
    IgnoreInitialValueFields: [],
    PermissionId: 'CANCEL_PUBLISHED_EVENT',
}

export const getPermission = (event: IEvent, functionality: TFunctionalityAction): IPermission | undefined => {
    const permissions = event?.Kayttooikeudet

    if (!permissions) {
        return
    }

    return permissions.find((currentPermission) => currentPermission.Toiminto === functionality.PermissionId)
}
