import * as React from 'react'
import { FieldProps } from 'formik'

import DayPickerWithRangeAndWeekDays from './DayPickerWithRangeAndWeekDays'
import { getErrorsForFormField } from '@planier/generic-utilities'

interface IIFormikDayPickerOwnProps extends FieldProps {}

type TDayPickerWithRangeAndWeekDaysProps = React.ComponentProps<typeof DayPickerWithRangeAndWeekDays>

interface IFormikDayPickerProps
    extends IIFormikDayPickerOwnProps,
        Pick<
            TDayPickerWithRangeAndWeekDaysProps,
            | 'labelRangeStart'
            | 'labelRangeEnd'
            | 'labelDayPickerTextInput'
            | 'required'
            | 'defaultSelectedWeekDays'
            | 'shouldDateBeDisabled'
            | 'defaultPickerMode'
            | 'onModeChange'
        > {}

const FormikDayPickerWithRangeAndWeekDays: React.FC<IFormikDayPickerProps> = ({
    form,
    field,
    labelRangeStart,
    labelRangeEnd,
    required,
    labelDayPickerTextInput,
    defaultSelectedWeekDays,
    shouldDateBeDisabled,
    defaultPickerMode,
    onModeChange,
}) => {
    const handleChange: TDayPickerWithRangeAndWeekDaysProps['onChange'] = (selectedDays) => {
        form.setFieldValue(field.name, selectedDays)
    }

    const handleBlur = () => {
        const isTouched = true
        form.setFieldTouched(field.name, isTouched, form.validateOnBlur)
    }

    const handleModeChange: TDayPickerWithRangeAndWeekDaysProps['onModeChange'] = (newMode) => {
        const isTouched = false
        form.setFieldTouched(field.name, isTouched)

        onModeChange && onModeChange(newMode)
    }

    const { isFieldErrored, error } = getErrorsForFormField<string>(
        field.name,
        form.errors as Record<string, string>,
        form.touched
    )

    return (
        <DayPickerWithRangeAndWeekDays
            defaultPickerMode={defaultPickerMode}
            defaultSelectedWeekDays={defaultSelectedWeekDays}
            isFieldErrored={isFieldErrored}
            labelDayPickerTextInput={labelDayPickerTextInput}
            labelRangeEnd={labelRangeEnd}
            labelRangeStart={labelRangeStart}
            onBlur={handleBlur}
            onChange={handleChange}
            onModeChange={handleModeChange}
            required={required}
            shouldDateBeDisabled={shouldDateBeDisabled}
            validationError={error}
            value={field.value}
        />
    )
}

export default FormikDayPickerWithRangeAndWeekDays
