import styled from '@emotion/styled'
import React, { JSX } from 'react'
import Chip from './Chip'
import Icon from './Icon'
import ReadOnlyText from './ReadOnlyText'
import { translate } from '@planier/localization'
import PopoverWithComponent from './PopoverWithComponent'

type Props = {
    label: string
    children?: JSX.Element | JSX.Element[]
    onDelete?: () => void
}

const DeleteButton = styled.button`
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primaryTeal120};

    span:last-of-type {
        margin-left: 4px;
    }
`

const ChipWithPopover = ({ label, onDelete, children }: Props): JSX.Element => {
    return (
        <PopoverWithComponent
            anchorOriginHorizontal="left"
            openPopoverRenderer={(onMenuOpen) => <Chip label={label} onClick={onMenuOpen} variant="filled" />}
        >
            {onDelete && (
                <DeleteButton onClick={onDelete}>
                    <Icon>close</Icon>
                    <ReadOnlyText usage="smallButton" color="primary_120">
                        {translate('Poista')}
                    </ReadOnlyText>
                </DeleteButton>
            )}

            {children}
        </PopoverWithComponent>
    )
}

export default ChipWithPopover
