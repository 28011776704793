import * as React from 'react'
import { JSX, useState } from 'react'
import { Icon, MarkDown, Tooltip } from '@planier/generic-components'
import { ClassNames } from '@emotion/react'
import { isEmpty } from 'lodash-es'
import { Modal } from '@mui/material'
import styled from '@emotion/styled'
import IconButton from '@material-ui/core/IconButton'
import { useAppTheme } from '@planier/theme'

type Props = {
    value: string[]
}

const MAX_LINES = 20

const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
`

const ModalHeader = styled.div`
    position: sticky;
    top: 0;
    height: 64px;
    border-radius: 5px;
`

const ModalText = styled.div`
    padding: 4px 32px 32px;
    max-height: 90vh;
    overflow-y: auto;
`

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 10px;
    right: 10px;
`

const ListElementWarning = ({ value }: Props): JSX.Element | null => {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const { componentExtensions } = useAppTheme()

    if (!value || isEmpty(value)) {
        return null
    }

    const toggleModal = (event: React.MouseEvent) => {
        event.stopPropagation()
        setModalOpen((open) => !open)
    }

    const content = value.join('\n\n')

    return (
        <>
            <ClassNames>
                {({ css }) => (
                    <Tooltip
                        title={content}
                        className={css`
                            .tooltip {
                                // does work on firefox too despite the -webkit prefix
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: ${MAX_LINES};
                                overflow: hidden;

                                p {
                                    margin-bottom: 5px;
                                }
                            }
                        `}
                    >
                        <IconButton type="button" size="small" onClick={toggleModal}>
                            <Icon color={componentExtensions.icons.iconWarning}>warning</Icon>
                        </IconButton>
                    </Tooltip>
                )}
            </ClassNames>

            {modalOpen && (
                <Modal onClose={toggleModal} open>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <ModalHeader>
                            <CloseButton type="button" onClick={toggleModal}>
                                <Icon size={24}>close</Icon>
                            </CloseButton>
                        </ModalHeader>
                        <ModalText>
                            <MarkDown>{content}</MarkDown>
                        </ModalText>
                    </ModalContent>
                </Modal>
            )}
        </>
    )
}

export default ListElementWarning
