import { connect, useDispatch } from 'react-redux'
import * as React from 'react'
import { useEffect } from 'react'
import { RootState } from 'typesafe-actions'

import { closeModalAction, Modal, selectIsModalOpen, selectModalProps } from '@planier/modal'
import { IFormViewModalProps as IFormProps } from '@planier/form-view-types'
import { BoundThunk } from '@planier/generic-state'
import { selectFormViewConfiguration, selectIsLoading } from '../../State/FormViewSelectors'
import {
    initializeFormViewThunk,
    resetFormViewValuePickerOptionsThunk,
    resetFormViewValuePickerValuesThunk,
} from '../../Thunks/FormViewThunks'
import FormViewV2 from '../FormView/FormView'
import IFormViewConfigurationV2 from 'packages/form-view/Types/V2/IFormViewConfigurationV2'
import { isPlainObject } from 'lodash-es'
import { resetFormViewAction } from '../../State/FormViewActions'

interface IOwnProps {
    formId: string
    onClose?: () => void
}

interface IStateProps {
    formView: IFormViewConfigurationV2 | undefined
    modalOpen: boolean
    modalProps: Record<string, unknown> | undefined
    isLoading: boolean
}

interface IDispatchProps {
    closeModal: typeof closeModalAction
    resetFormViewValuePickerValues: BoundThunk<typeof resetFormViewValuePickerValuesThunk>
    resetFormViewValuePickerOptions: BoundThunk<typeof resetFormViewValuePickerOptionsThunk>
    initializeFormView: BoundThunk<typeof initializeFormViewThunk>
}

export interface IFormViewModalProps extends IOwnProps, IStateProps, IDispatchProps {}

const areFormModalProps = (modalProps: unknown): IFormProps | undefined => {
    if (!isPlainObject(modalProps)) {
        return
    }

    const { formId, items } = modalProps as IFormProps

    if (!Array.isArray(items) || typeof formId !== 'string') {
        return
    }

    return modalProps as IFormProps
}

export const FormViewModalUnconnected: React.FunctionComponent<IFormViewModalProps> = ({
    closeModal,
    formId,
    formView,
    initializeFormView,
    modalOpen,
    modalProps,
    isLoading,
    onClose,
    resetFormViewValuePickerValues,
    resetFormViewValuePickerOptions,
}) => {
    const dispatch = useDispatch()
    const modalProperties = areFormModalProps(modalProps)

    useEffect(() => {
        if (modalProperties && modalOpen) {
            initializeFormView(
                formId,
                modalProperties.items?.map((x) => x.Id) ?? [],
                modalProperties.ignoreInitialValueFields ?? [],
                modalProperties.initialFormValuesOverride,
                modalProperties.overrideDataSourceId ? modalProperties.dataSourceId : null
            )
        }
    }, [formId, modalProperties, modalOpen, initializeFormView])

    if (!modalProperties || !modalOpen) {
        return null
    }

    const formItemIds = modalProperties.items?.map((x) => x.Id) ?? []

    const handleModalCloseButtonPress = () => {
        handleClose()
        resetFormViewValuePickerValues(formId)
        resetFormViewValuePickerOptions(formId)

        // Event_Edit overlaps between Event and EmployeeCalendar, so it needs to be reset
        if (formId === 'Event_Edit') {
            dispatch(resetFormViewAction(formId))
        }
    }

    const handleClose = (isContinuingAfterClosing = false) => {
        if (!isContinuingAfterClosing) {
            closeModal(formId)
        }
        onClose && onClose()
    }

    return (
        <Modal
            handleClose={handleModalCloseButtonPress}
            modalId={formId}
            isClosedOnEscapeDown={true} //Maybe remove
        >
            <FormViewV2
                isLoading={isLoading}
                initializationFailed={false}
                additionalSubmitRequestProps={modalProperties.additionalRequestProps}
                formItemIdentifiers={formItemIds}
                formConfiguration={formView || null}
                onClose={handleModalCloseButtonPress}
                onSubmitCallback={modalProperties.onSubmitCallback}
            />
        </Modal>
    )
}

const mapStateToProps = (state: RootState, { formId }: IOwnProps): IStateProps => ({
    formView: selectFormViewConfiguration(state, formId),
    modalOpen: selectIsModalOpen(state, formId),
    modalProps: selectModalProps(state, formId),
    isLoading: selectIsLoading(state, formId),
})

const mapDispatchToProps = {
    closeModal: closeModalAction,
    resetFormViewValuePickerValues: resetFormViewValuePickerValuesThunk,
    resetFormViewValuePickerOptions: resetFormViewValuePickerOptionsThunk,
    initializeFormView: initializeFormViewThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(FormViewModalUnconnected)
