import TValuePickerId from '../types/TValuePickerId'
import localValuePickerConfigurations from '../tmp/ValuePickerConfigurations'
import { TValuePickerConfiguration } from '../types/TValuePickerConfiguration'
import makeRequest, { ERequestMethod } from '@planier/rest-api'

export const fetchDynamicValuePickerConfigurations = async (): Promise<TValuePickerConfiguration[]> => {
    try {
        return await makeRequest<TValuePickerConfiguration[]>({
            method: ERequestMethod.GET,
            url: `/ViewEngine/ValuePicker`,
        })
    } catch (e) {
        return []
    }
}

export const getStaticValuePickerConfigurations = (valuePickerIds: TValuePickerId[]): TValuePickerConfiguration[] => {
    const valuePickerConfigurationFromServer: TValuePickerConfiguration[] = []

    const valuePickersFromServerPatched = valuePickerConfigurationFromServer.map((valuePickerConfiguration) => {
        const localConfiguration = localValuePickerConfigurations.find(
            (localValuePickerConfiguration) =>
                localValuePickerConfiguration.ValuePickerId === valuePickerConfiguration.ValuePickerId
        )
        return { ...valuePickerConfiguration, ...localConfiguration }
    })

    const localValuePickers = localValuePickerConfigurations.filter((valuePickerConfiguration) =>
        valuePickerIds.includes(valuePickerConfiguration.ValuePickerId)
    )

    return valuePickersFromServerPatched.concat(localValuePickers)
}
