import styled from '@emotion/styled'
import { FormModal, selectIsModalOpen } from '@planier/modal'
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { changeLanguageThunk } from './Thunks/LanguageChangeThunks'
import { RadioButton } from '@planier/generic-components'

export const LANGUAGE_CHANGE_MODAL_ID = 'change-language-form'
export const LANGUAGE_CHANGE_IN_PROGRESS_OPERATION_ID = 'legacy-newer.LanguageChangeInProgress'

const LanguageSelectionContainer = styled.div`
    margin-top: 24px;
    margin-bottom: 24px;
`

const LanguageChangeForm: FC = () => {
    const dispatch = useDispatch()
    const userLanguage = useSelector((state: RootState) => state.loginUser?.Kieli)
    const [selectedLanguage, setSelectedLanguage] = useState(userLanguage)

    const isOpen = useSelector((state: RootState) => selectIsModalOpen(state, LANGUAGE_CHANGE_MODAL_ID))

    const handleSubmit = () => {
        if (selectedLanguage !== undefined) {
            dispatch(changeLanguageThunk(selectedLanguage as string))
        }
    }

    const formikProps = {
        initialValues: {},
        onSubmit: handleSubmit,
    }

    return (
        <FormModal
            formId={LANGUAGE_CHANGE_MODAL_ID}
            formikProps={formikProps}
            modalId={LANGUAGE_CHANGE_MODAL_ID}
            open={isOpen}
            title="language-change"
        >
            <LanguageSelectionContainer>
                <RadioButton
                    checked={selectedLanguage === 'fi'}
                    label={'Suomi'}
                    onValueSet={() => setSelectedLanguage('fi')}
                    testId="STC_LANG_CHANGE_FINNISH_RADIOBUTTON"
                />
                <RadioButton
                    checked={selectedLanguage === 'en'}
                    label={'English'}
                    onValueSet={() => setSelectedLanguage('en')}
                    testId="STC_LANG_CHANGE_ENGLISH_RADIOBUTTON"
                />
            </LanguageSelectionContainer>
        </FormModal>
    )
}

export default LanguageChangeForm
