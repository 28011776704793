import { useCallback, useState } from 'react'

import { toDateObject, TDateStringDataFormat, getDatesWithinRange } from '@planier/dates'
import WeekDaySelection from './WeekDaySelection'

interface IUseWeekDaysReturn {
    resetSelectedWeekDays: () => void
    getAllDatesWithinRangeByWeekdays: (
        rangeStart?: TDateStringDataFormat | null,
        rangeEnd?: TDateStringDataFormat | null,
        selectedWeekDays?: number[]
    ) => TDateStringDataFormat[]
    WeekDaySelectionComponent: typeof WeekDaySelection
    weekDaySelectionComponentProps: React.ComponentProps<typeof WeekDaySelection>
}

/**
 * Hook to handle state for week days. We can't wrap the state
 * in the `WeekDaySelection` component itself, because the
 * `DayRangePickerWithWeekDays` needs to be aware of the state to be
 * able to determine the selected days. We can wrap it to this hook
 * however.
 */
const useWeekDays = (
    rangeStart: null | TDateStringDataFormat,
    rangeEnd: null | TDateStringDataFormat,
    defaultSelectedWeekDays: number[],
    onChange: (newDates: TDateStringDataFormat[]) => void
): IUseWeekDaysReturn => {
    const [selectedWeekDays, setSelectedWeekDays] = useState<ReadonlySet<number>>(new Set(defaultSelectedWeekDays))

    const getAllDatesWithinRangeByWeekdays: IUseWeekDaysReturn['getAllDatesWithinRangeByWeekdays'] = (
        rangeStartParam = rangeStart,
        rangeEndParam = rangeEnd,
        weekDays = [...selectedWeekDays]
    ) => {
        if (!rangeStartParam || !rangeEndParam) {
            return []
        }

        const datesWithinRange = getDatesWithinRange(rangeStartParam, rangeEndParam)
        const datesByWeekDays = datesWithinRange.filter((date) =>
            weekDays.some((weekDayIndex) => weekDayIndex === toDateObject(date).isoWeekday())
        )

        return datesByWeekDays
    }

    const resetSelectedWeekDays: IUseWeekDaysReturn['resetSelectedWeekDays'] = useCallback(() => {
        setSelectedWeekDays(new Set(defaultSelectedWeekDays))
    }, [defaultSelectedWeekDays])

    const handleWeekDayChange = (isoDayIndex: number) => {
        const copiedSet = new Set(selectedWeekDays)
        selectedWeekDays.has(isoDayIndex) ? copiedSet.delete(isoDayIndex) : copiedSet.add(isoDayIndex)
        setSelectedWeekDays(copiedSet)

        if (!rangeStart || !rangeEnd) {
            return
        }

        if (copiedSet.size === 0) {
            onChange([])
        } else {
            const newValue = getAllDatesWithinRangeByWeekdays(rangeStart, rangeEnd, [...copiedSet])
            onChange(newValue)
        }
    }

    const weekDaySelectionComponentProps = {
        onChange: handleWeekDayChange,
        selectedWeekDays,
    }

    return {
        resetSelectedWeekDays,
        getAllDatesWithinRangeByWeekdays,
        WeekDaySelectionComponent: WeekDaySelection,
        weekDaySelectionComponentProps,
    }
}

export default useWeekDays
