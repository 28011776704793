import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { get } from 'lodash-es'

import { selectValuePickerValue } from '@planier/value-picker'
import { isAfter, isBefore, TDateStringDataFormat, toDateObject } from '@planier/dates'
import { IFormViewFieldComponentStaticProps } from '@planier/form-view-types'
import {
    selectStoredOptionsOptionById,
    selectStoredOptionsOptionByPropertyMatchingValue,
} from '@planier/stored-options'
import { getLogger } from '@planier/log'

const Log = getLogger('form-view.useShouldDisableDates')

type TStaticProps = IFormViewFieldComponentStaticProps & {
    dependeeValuePickerId?: string
    startPath?: TDateStringDataFormat
    endPath?: TDateStringDataFormat
    propertyNameToFindObjectBy?: string
}

interface IUseShouldDisableDatesReturn {
    staticPropsToPass: IFormViewFieldComponentStaticProps
    shouldDisableDate: undefined | ((day: TDateStringDataFormat) => boolean)
}

const dependeeValuePickerHasValidValue = (valuePickerValue: unknown): valuePickerValue is Set<string | number> => {
    if (valuePickerValue && valuePickerValue instanceof Set && valuePickerValue.size === 1) {
        return true
    }

    Log.error('Given value picker was not value picker with options or there were no values or too much values')
    return false
}

/**
 * Hook to produce a function to disable dates before and/or after dates
 * for day pickers through a dependee value picker with options (e.g. a dropdown
 * value picker).
 *
 * To work, expects at least the `dependeeValuePickerId` and either the `startPath`
 * or `endPath` within the static props given. By default selects the object having
 * the start or end path by its Id matching the value of the dependee value picker.
 * Optionally you can give the `propertyNameToFindObjectBy` field. When given,
 * it will be used to select the object.
 *
 * Note that it expects the dependee value picker to have only a single value.
 *
 * For now it's a rather unsophisticated solution but one that works.
 * Later on, once https://haahtelahr.atlassian.net/browse/PL-128 has been
 * done, there can be done a better solution.
 */
const useShouldDisableDates = (staticProps: TStaticProps = {}): IUseShouldDisableDatesReturn => {
    const { dependeeValuePickerId, startPath, endPath, propertyNameToFindObjectBy, ...restOfStaticProps } = staticProps

    const valuePickerValue = useSelector((state: RootState) =>
        dependeeValuePickerId ? selectValuePickerValue(state, dependeeValuePickerId) : null
    )
    const valuePickerValueObject = useSelector((state: RootState) => {
        if (!dependeeValuePickerId || (!startPath && !endPath)) {
            return null
        }

        if (!dependeeValuePickerHasValidValue(valuePickerValue)) {
            return null
        }

        if (propertyNameToFindObjectBy) {
            return selectStoredOptionsOptionByPropertyMatchingValue(
                state,
                dependeeValuePickerId,
                propertyNameToFindObjectBy as any,
                [...valuePickerValue][0]
            )
        }

        return selectStoredOptionsOptionById(state, dependeeValuePickerId, [...valuePickerValue][0])
    })

    if (!startPath && !endPath) {
        return { staticPropsToPass: restOfStaticProps, shouldDisableDate: undefined }
    }

    if (!dependeeValuePickerHasValidValue(valuePickerValue)) {
        return { staticPropsToPass: restOfStaticProps, shouldDisableDate: undefined }
    }

    const start = startPath ? get(valuePickerValueObject, startPath) : null
    const end = endPath ? get(valuePickerValueObject, endPath) : null

    const shouldDisableDate = (day: TDateStringDataFormat) => {
        const isBeforeStart = start ? isBefore(toDateObject(day), toDateObject(start), 'day') : false
        if (isBeforeStart) {
            return true
        }

        const isAfterEnd = end ? isAfter(toDateObject(day), toDateObject(end), 'day') : false

        return isAfterEnd
    }

    return {
        staticPropsToPass: restOfStaticProps,
        shouldDisableDate,
    }
}

export default useShouldDisableDates
