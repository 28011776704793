import { IDataSourceItem, ISelectorProperty, TDataSourceItemId } from '@planier/data-source-types'
import moment from 'moment'
import { getLogger } from '@planier/log'
import { get } from 'lodash-es'

const Log = getLogger('data-source.FilterwithDataSourcePropertyPage')

const getValueBySelectorProperty = (item: unknown, selectorProperty: ISelectorProperty): string => {
    switch (selectorProperty.Transformation) {
        case null:
        case 'None':
            return get(item, selectorProperty.Property)
        case 'Date':
            return moment(get(item, selectorProperty.Property)).format('LL')
        default: {
            Log.error('selectorProperty_Transformation value is incorrect!')
            return ''
        }
    }
}

/**
 * Basically selects all the items that match *any* currently selected item based on *all*
 * the selector properties.
 *
 * To explain a bit more, as an example, let's take a situation where  we want all the events
 * of a given employee from the same day. The properties could be `EmployeeId` and `StartTime` (with
 * transformation `Date`). Then we'd select all the items where the `EmployeeId` and the `StartTime`
 * match. If there are multiple items currently selected, then do the check for each possible item (`allItems`)
 * and basically do a union of all them.
 *
 * If there aren't any selectedProperties, returns all the item IDs
 */
function filterWithDataSourceProperties(
    allItems: IDataSourceItem[],
    selectorProperties: ISelectorProperty[],
    currentlySelectedItems: IDataSourceItem[]
): TDataSourceItemId[] {
    if (selectorProperties.length === 0) {
        return allItems.map((item) => item.Id)
    }

    const newSelectedItems = allItems
        .filter((item) => {
            const itemMatchesSelectorPropertiesOfCurrentlySelectedItems = currentlySelectedItems.some(
                (currentlySelectedItem) => {
                    return selectorProperties.every((selectorProperty) => {
                        const itemPropertyValue = getValueBySelectorProperty(item, selectorProperty)

                        const valueItemPropertyValue = getValueBySelectorProperty(
                            currentlySelectedItem,
                            selectorProperty
                        )

                        return itemPropertyValue === valueItemPropertyValue
                    })
                }
            )

            return itemMatchesSelectorPropertiesOfCurrentlySelectedItems
        })
        .map((item) => item.Id)

    const uniqueselectedItems = [...new Set(newSelectedItems)]
    return uniqueselectedItems
}

export default filterWithDataSourceProperties
