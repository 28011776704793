import styled from '@emotion/styled'
import { FormModal } from '@planier/modal'
import { useDispatch } from 'react-redux'
import { translate } from '@planier/localization'
import { MultiChipPicker, TextInputV2 } from '@planier/generic-components'
import { useQuery } from '@planier/generic-utilities'
import { IDropdownListOption } from 'packages/generic-components/Inputs/Dropdown/Dropdown'
import { useField } from 'formik'
import * as Yup from 'yup'
import { AdditionalOptionsProps, ChipValue } from 'packages/generic-components/Inputs/MultiChipPicker/MultiChipPicker'
import { Report, upsertListReportThunk } from '../Thunks/ConfigurableListThunks'
import { ThunkDispatch } from 'redux-thunk'
import { Action } from 'redux'
import { useState } from 'react'
import { isEmpty, isNil } from 'lodash-es'

type Props = {
    listId: string
    modalId: string
    onReportCreated: (reportName: string) => void
    dataSourceProperties?: Record<string, any>
}

const validationSchema = Yup.object().shape({
    reportName: Yup.string().required('validation-mandatory'),
})

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
`

const NameField = (): JSX.Element => {
    const [field, , helpers] = useField('reportName')

    return (
        <TextInputV2
            {...field}
            label={translate('configurableList.reports.reportName')}
            placeholder={translate('write')}
            overrideStyle={{ width: '256px' }}
            onChange={helpers.setValue}
            autoFocus
            isRequiredField
        />
    )
}

const MultiChipPickerField = (props: any): JSX.Element => {
    const { name } = props

    const [field, , helpers] = useField(name)

    return <MultiChipPicker {...props} value={field.value} onChange={helpers.setValue} />
}

const WindowFunctionPickerField = (props: any): JSX.Element => {
    const { name, options } = props

    const [field, , helpers] = useField(name)

    const { data: windowFunctionOptions } = useQuery<{ ListData: IDropdownListOption[] }>(
        'ViewEngine/WindowFunctionType/Identifier'
    )

    const windowFunctionProps: AdditionalOptionsProps = {
        pickerType: 'Dropdown',
        pickerProps: {
            isMultiSelect: false,
            showClearSelection: false,
            label: translate('configurableList.reports.windowFunction'),
            options: windowFunctionOptions?.ListData || [],
        },
        defaultValue: ['Sum'],
    }

    const opts = options.map((option: any) => {
        return {
            ...option,
            additionalOptions: windowFunctionProps,
        }
    })

    return <MultiChipPicker {...props} options={opts} value={field.value} onChange={helpers.setValue} />
}

const CreateReportModal = ({ listId, modalId, dataSourceProperties, onReportCreated }: Props): JSX.Element => {
    const dispatch = useDispatch<ThunkDispatch<any, any, Action>>()

    const [submitting, setSubmitting] = useState<boolean>(false)

    const dataSources: any[] =
        dataSourceProperties?.ListData.map((property: any) => ({ ...property, Name: property.DisplayName })) || []

    const groupingFieldOptions = dataSources.filter(({ CanBeGroupingTarget }) => CanBeGroupingTarget)
    const calculationFieldOptions = dataSources.filter(({ CanBeWindowFunctionTarget }) => CanBeWindowFunctionTarget)

    const submitForm = async ({ reportName, groupingFields, propertyFields, calculationFields }: any) => {
        const report: Report = {
            name: reportName,
            includeDataSourcePropertyIds: propertyFields.map((field: ChipValue) => field.value),
            groupByDataSourceProperties: groupingFields.map((field: ChipValue) => ({
                dataSourcePropertyId: field.value,
                label: '',
            })),
            windowFunctions: calculationFields.map((field: ChipValue) => ({
                dataSourcePropertyId: field.value,
                function: field.additionalOptionsValue[0],
                label: '',
                windowFunctionApplyConditions: [],
                windowFunctionProportionConditions: [],
            })),
        }

        setSubmitting(true)

        await dispatch(upsertListReportThunk(report, listId))

        onReportCreated(reportName)
    }

    const formikProps = {
        initialValues: { reportName: '', groupingFields: [], propertyFields: [], calculationFields: [] },
        onSubmit: submitForm,
        validationSchema,
    }

    // show disabled picker when data is still loading to prevent UI shift when loaded
    const windowFunctionPicker = (isNil(dataSourceProperties?.ListData) || !isEmpty(calculationFieldOptions)) && (
        <WindowFunctionPickerField
            name="calculationFields"
            title={translate('configurableList.reports.calculationFields')}
            placeholder={translate('select')}
            options={calculationFieldOptions}
        />
    )

    return (
        <FormModal
            title={translate('configurableList.reports.createReportModalTitle')}
            formId="createReport"
            formikProps={formikProps}
            modalId={modalId}
            disableSubmit={submitting}
            disableSubmitWhenInvalid
            open
        >
            <InputsContainer>
                <NameField />
                <MultiChipPickerField
                    name="groupingFields"
                    title={translate('configurableList.reports.groupingFields')}
                    placeholder={translate('select')}
                    options={groupingFieldOptions}
                />
                {windowFunctionPicker}
                <MultiChipPickerField
                    name="propertyFields"
                    title={translate('configurableList.reports.propertyFields')}
                    placeholder={translate('select')}
                    options={groupingFieldOptions}
                />
            </InputsContainer>
        </FormModal>
    )
}

export default CreateReportModal
