import * as React from 'react'
import { connect } from 'react-redux'
import moment, { Moment } from 'moment'
import { RootState } from 'typesafe-actions'

import DateRange from 'components/molecules/DateRange'
import { TNullableDate, selectValuePickerValue, setValuePickerValueAction } from '@planier/value-picker'
import { isNullableMoment, isNullableDateTimeRange } from '@planier/generic-utilities'
import { IPageDateRangePickerOwnProps } from '@planier/value-picker'

interface IStateProps {
    endDate: TNullableDate
    startDate: TNullableDate
}

interface IDispatchProps {
    setValuePickerValue: typeof setValuePickerValueAction
}

export interface IPageDateRangePickerProps extends IPageDateRangePickerOwnProps, IStateProps, IDispatchProps {}

class PageDateRangePickerUnconnected extends React.Component<IPageDateRangePickerProps> {
    handleEndDateChange = (endDate: Moment) => {
        if (!isNullableMoment(endDate)) {
            return
        }

        const formattedDate = moment.isMoment(endDate) ? endDate.endOf('day') : endDate

        this.props.setValuePickerValue(
            {
                start: this.props.startDate,
                end: formattedDate,
            },
            this.props.valuePickerId
        )
    }

    handleStartDateChange = (startDate: Moment) => {
        if (!isNullableMoment(startDate)) {
            return
        }

        const formattedDate = moment.isMoment(startDate) ? startDate.startOf('day') : startDate

        this.props.setValuePickerValue(
            {
                start: formattedDate,
                end: this.props.endDate,
            },
            this.props.valuePickerId
        )
    }

    render(): React.ReactNode {
        const { endDate, startDate, datePickerTestId } = this.props

        return (
            <DateRange
                endLabelText="end-date-short"
                endTime={endDate}
                noValidation
                onEndDateChange={this.handleEndDateChange}
                onStartDateChange={this.handleStartDateChange}
                startLabelText="start-date-short"
                startTime={startDate}
                dateRangeTestId={datePickerTestId}
            />
        )
    }
}

const mapStateToProps = (state: RootState, { valuePickerId }: IPageDateRangePickerOwnProps) => {
    const pickerValue = selectValuePickerValue(state, valuePickerId)
    const dateRange = isNullableDateTimeRange(pickerValue)
        ? pickerValue
        : {
              start: null,
              end: null,
          }

    return {
        startDate: dateRange.start,
        endDate: dateRange.end,
    }
}

const mapDispatchToProps = {
    setValuePickerValue: setValuePickerValueAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(PageDateRangePickerUnconnected)
