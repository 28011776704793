import Grid from '@material-ui/core/Grid'
import { ReactNode, FunctionComponent } from 'react'
import styled from '@emotion/styled'

const ContainerGrid = styled(Grid)`
    padding: 15px;
`

interface IWithExcelButton {
    readonly truncateWidth: number
}

const FlexibleGrid = styled(Grid)<IWithExcelButton>`
    ${({ truncateWidth }) => (truncateWidth ? `max-width: calc(100vw - ${truncateWidth}px);` : '')}
`

interface IProps {
    buttons?: ReactNode
    accumulatedData?: ReactNode
    excelButton?: ReactNode
}

const ListExcelButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-left: 8px;
    min-width: 108px;
`

const ListContainerGrid: FunctionComponent<IProps> = ({ buttons, accumulatedData, excelButton }) => (
    <ContainerGrid container justifyContent="space-between">
        <FlexibleGrid item truncateWidth={300}>
            <Grid container spacing={1}>
                {buttons}
            </Grid>
        </FlexibleGrid>
        {accumulatedData}
        {excelButton && <ListExcelButtonContainer>{excelButton}</ListExcelButtonContainer>}
    </ContainerGrid>
)

export default ListContainerGrid
