import * as React from 'react'
import { FieldProps } from 'formik'

import Checkbox, { ICheckboxProps } from './Checkbox'

interface IFormikDatepickerOwnProps extends FieldProps {}

type IFormikCheckboxProps = IFormikDatepickerOwnProps & Pick<ICheckboxProps, 'disabled' | 'className' | 'indeterminate'>

class FormikCheckbox extends React.Component<IFormikCheckboxProps> {
    handleClick = (newChecked: boolean): void => {
        const {
            form: { setFieldValue },
            field: { name },
        } = this.props

        setFieldValue(name, newChecked)
    }

    render(): React.ReactNode {
        const { disabled, field, className, indeterminate } = this.props

        return (
            <Checkbox
                className={className}
                disabled={disabled}
                indeterminate={indeterminate}
                onClick={this.handleClick}
                value={field.value}
            />
        )
    }
}

export default FormikCheckbox
