import { ChangeEvent, FC, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { Button, Icon, Tooltip } from '@planier/generic-components'
import TextInput from 'components/atoms/TextInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import { setCalendarGroupNodeSearchFilter } from '../../State/ConfigurableCalendarActions'
import { RootState } from 'typesafe-actions'
import { selectGroupNodeSearchFilter } from '../../State/ConfigurableCalendarSelectors'

const SearchTextInputContainer = styled.div<{ hasFocus: boolean }>`
    ${({ hasFocus }) =>
        hasFocus &&
        `
        background-color: white;
        z-index: 1;
        width: 180px;
        position: absolute;

    `}
`

interface ISearchButtonProps {
    className: string
    calendarId: string
}

const SearchAdornmentInTextField: FC = () => {
    return (
        <InputAdornment position="end">
            <Icon>search</Icon>
        </InputAdornment>
    )
}

const searchAdornmentAsProp = {
    endAdornment: <SearchAdornmentInTextField />,
}

const RemoveSearchFieldAdornment: FC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <InputAdornment position="end">
            <Icon onClick={onClick}>clear</Icon>
        </InputAdornment>
    )
}

const useHandleSearch = (calendarId: string) => {
    const dispatch = useDispatch()
    const searchValue = useSelector((state: RootState) => selectGroupNodeSearchFilter(state, calendarId))

    const handleSearch = useCallback(
        (newValue: string) => {
            dispatch(setCalendarGroupNodeSearchFilter(calendarId, newValue.toLowerCase()))
        },
        [calendarId, dispatch]
    )

    return {
        searchValue,
        handleSearch,
    }
}

const SearchButton: FC<ISearchButtonProps> = ({ className, calendarId }) => {
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [hasSearchFocus, setHasSearchFocus] = useState(false)
    const { handleSearch, searchValue } = useHandleSearch(calendarId)

    const handleSearchToggle = () => {
        const newIsSearchOpen = !isSearchOpen
        setIsSearchOpen(newIsSearchOpen)

        if (newIsSearchOpen) {
            setHasSearchFocus(true)
        }
    }

    const handleSearchBlur = () => {
        setHasSearchFocus(false)
    }

    const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSearch(e.target.value)
    }

    const handleRemoveSearchField = () => {
        setIsSearchOpen(false)
        handleSearch('')
    }

    const search = isSearchOpen ? (
        <SearchTextInputContainer hasFocus={hasSearchFocus}>
            <TextInput
                autoFocus
                MuiInputProps={
                    hasSearchFocus
                        ? searchAdornmentAsProp
                        : { endAdornment: <RemoveSearchFieldAdornment onClick={handleRemoveSearchField} /> }
                }
                onBlur={handleSearchBlur}
                onChange={onSearchChange}
                size="small"
                value={searchValue}
                variant="outlined"
            />
        </SearchTextInputContainer>
    ) : (
        <Tooltip title={'calendar.CalendarFilters.Search.Tooltip'} wrapperElementClassName={className}>
            <Button className={className} iconButton onClick={handleSearchToggle} variant="outlined">
                <Icon>search</Icon>
            </Button>
        </Tooltip>
    )

    return search
}

export default SearchButton
