import { ReactNode } from 'react'
import * as React from 'react'
import reactCss from 'reactcss'

import Styles from 'constants/Styles'
import { Translation } from '@planier/localization'

interface IOwnProps {
    children: ReactNode
    color?: string
    context?: string
    doNotTranslate?: boolean
    fontWeight?: string | number
    paddingTop?: string
    required?: boolean
    text?: string
    textAbove?: boolean
    textCenter?: boolean
    verticalAlign?: string
}

/**
 *
 * @deprecated
 *
 * 3. tason otsikko.
 *
 * Huomaa, että voit käyttää myös SubHeading-komponenttia.
 *
 * Jos käytät yhdessä käyttöliittymäkomponentin kanssa, anna otsikko text-propsissa
 * ja käyttöliittymäkomponentti elementin sisällä.
 * Jos käytät itsekseen ilman käyttöliittymäkomponenttia, anna otsikko elementin
 * sisällä.
 *
 * Käytä doNotTranslate-lippua tagissa, jos teksti EI ole käännöskoodi.
 * Käytä textAbove-lippua, jos haluat näyttää tekstin käyttöliittymäkomponentin päällä.
 * Käytä required-lippua, jos haluat lisätä pakollista kenttää indikoivan merkin otsikkoon.
 *
 * Esimerkki:
 * <Label text='my-labels-translation-code'><CheckBox /></Label>
 */
class Label extends React.Component<IOwnProps> {
    static readonly defaultProps = {
        color: Styles.mainColor.darkGrey,
        doNotTranslate: false,
        required: false,
        textAbove: false,
    }

    static readonly displayName = 'Label'

    styles(): any {
        return reactCss(
            {
                default: {
                    atom: {
                        fontFamily: Styles.font.text.family,
                        fontSize: Styles.font.levelThreeTitle.size,
                        color: this.props.color,
                        paddingTop: this.props.paddingTop,
                        alignItems: 'center',
                    },
                    text: {
                        fontFamily: Styles.font.text.family,
                        fontSize: Styles.font.levelThreeTitle.size,
                        color: this.props.color,
                        fontWeight: this.props.fontWeight,
                        textAlign: 'left',
                        width: '100%',
                        verticalAlign: this.props.verticalAlign,
                    },
                },
                textCenter: {
                    text: {
                        textAlign: 'center',
                    },
                },
                textAbove: {
                    atom: {
                        display: 'block',
                    },
                },
            } as any,
            this.props
        )
    }

    private renderLabelWithComponent() {
        const { children, text, textAbove } = this.props

        const textToRender = this.renderText(text)
        const style = this.styles().atom
        if (textAbove) {
            return (
                <label>
                    <span style={style}>{textToRender}</span>
                    {children}
                </label>
            )
        }

        return (
            <label style={style}>
                {children}
                {textToRender}
            </label>
        )
    }

    private renderStandaloneLabel() {
        const { children } = this.props
        const text = this.renderText(children)

        return <label style={this.styles().text}>{text}</label>
    }

    private renderText(rawText: any) {
        const { doNotTranslate, required } = this.props

        let text = doNotTranslate ? rawText : Translation.translateKey(rawText)
        text = `${text}${required ? '*' : ''}`

        return text
    }

    render(): React.ReactNode {
        const { children } = this.props
        if (typeof children === 'string' || typeof children === 'number') {
            // title without a component
            return this.renderStandaloneLabel()
        }
        // a component's title
        return this.renderLabelWithComponent()
    }
}

export default Label
