import * as React from 'react'

import TextInput from 'components/atoms/TextInput'
import { Translation } from '@planier/localization'
import { getLogger } from '@planier/log'
const Log = getLogger('generic-components.TimeLength')
export interface ITimeLengthProps {
    handleChange: (minutes: number) => void
    disabled: boolean
    onBlur?: () => void
    width: string
    valueInMinutes: number
}

interface IState {
    userInput: string
}

const convertTimeValueToString = (timeValue: number) => (timeValue < 10 ? '0' + timeValue : '' + timeValue)

const formTextValue = (valueInMinutes: number) => {
    const overflowMinutes = valueInMinutes % 60
    const hours = (valueInMinutes - overflowMinutes) / 60

    const overflowMinutesAsString = convertTimeValueToString(overflowMinutes)
    const hoursAsString = convertTimeValueToString(hours)

    return `${hoursAsString}:${overflowMinutesAsString}`
}

const transformTextValueToMinutes = (textValue: string) => {
    const containsSemicolon = textValue.indexOf(':') !== -1
    const containsColon = textValue.indexOf('.') !== -1

    if (!containsSemicolon && !containsColon) {
        Log.error('value does not contain either semicolon or colon')
        return 0
    }

    const separatorCharacter = containsSemicolon ? ':' : '.'

    const hoursAndMinutes = textValue.split(separatorCharacter)
    const hoursAsMinutes = Number(hoursAndMinutes[0]) * 60

    const totalMinutes = hoursAsMinutes + Number(hoursAndMinutes[1])

    return totalMinutes
}

const description = Translation.translateKey('GenericComponents.TimeLength')

/**
 * Component to use when you need to have time length as an input.
 */
class TimeLength extends React.Component<ITimeLengthProps, IState> {
    static readonly defaultProps = {
        disabled: false,
        width: '110px',
    }

    state = {
        userInput: typeof this.props.valueInMinutes === 'number' ? formTextValue(this.props.valueInMinutes) : '',
    }

    componentDidUpdate(prevProps: ITimeLengthProps): void {
        if (prevProps.valueInMinutes === this.props.valueInMinutes) {
            return
        }

        const valueAsText = formTextValue(this.props.valueInMinutes)
        this.setState({ userInput: valueAsText })
    }

    handleTimeLengthChanged = (textValue: string): void => {
        if (textValue.length > 5) {
            return
        }

        // TODO: this isn't quite enough, since the if currently allows for any characters..
        // this also isn't very good because you can't overwrite the time values nicely

        const hasTwoNumbersOnBothSidesSeparator = /^[0-9][0-9](:|.)[0-9][0-9]/.test(textValue)
        const saveChangeOnlyInComponentState = !hasTwoNumbersOnBothSidesSeparator

        if (saveChangeOnlyInComponentState) {
            this.setState({ userInput: textValue })
            return
        }

        const { handleChange } = this.props
        const valueInMinutes = transformTextValueToMinutes(textValue)

        handleChange(valueInMinutes)
    }

    render(): React.ReactNode {
        const { disabled, width, onBlur } = this.props
        const { userInput } = this.state

        return (
            <TextInput
                changeAction={this.handleTimeLengthChanged}
                disabled={disabled}
                floatingLabelText={description}
                onBlur={onBlur}
                value={userInput}
                width={width}
            />
        )
    }
}

export default TimeLength
