import * as React from 'react'
import TapahtumaCalendarNode from './TapahtumaNode/TapahtumaCalendarNode'
import { IEvent } from '@planier/event'
import Styles, { getColor30ForColorId, getColor60ForColorId } from 'constants/Styles'

export interface IVuoroCalendarNodeProps {
    ammattinimikeShorthand: string
    endDatetime: string
    published: boolean
    startDatetime: string
    tyoaikasaantorikkeet: string[] | null
    tyontekijaId?: number | null
    jobTitleName: string
    isRecurring: boolean
    colorId: string | null
    /**
     * Meant to be the name of the work unit or employee, depending on the calendar.
     * When a shift is filled, is shown next to the job title shorthand.
     */
    additionalTopText: string | undefined
    item: IEvent
}

const getUpperText = (
    isFilledShift: boolean,
    jobTitleShorthand: string,
    jobTitleName: string,
    additionalTopText: string | null
) => {
    if (!isFilledShift) {
        return jobTitleName
    }

    if (!jobTitleShorthand) {
        return additionalTopText
    }

    return `${jobTitleShorthand} ${additionalTopText ? '| ' + additionalTopText : ''}`
}

const filledBackgroundColor = '#D6F1F4'
const filledHoverColor = '#BBE0E5'
const unfilledHoverColor = '#F3F9FA'
const mainColor = Styles.planierColor.blueTurquoise.textColor

const VuoroCalendarNode: React.FunctionComponent<IVuoroCalendarNodeProps> = ({
    ammattinimikeShorthand,
    endDatetime,
    published,
    startDatetime,
    tyoaikasaantorikkeet,
    tyontekijaId,
    jobTitleName,
    additionalTopText = null,
    item,
    colorId,
    isRecurring,
}) => {
    const filled = typeof tyontekijaId === 'number'

    const overridingBackgroundColor = !filled ? '#fff' : colorId ? undefined : filledBackgroundColor

    const _filledHoverColor = colorId ? getColor60ForColorId(colorId) : filledHoverColor
    const _unFilledHoverColor = colorId ? getColor30ForColorId(colorId) : unfilledHoverColor

    const hoverColor = filled ? _filledHoverColor : _unFilledHoverColor

    return (
        <TapahtumaCalendarNode
            backgroundColor={overridingBackgroundColor}
            borderColor={mainColor}
            endDatetime={endDatetime}
            hoverColor={hoverColor}
            isOnPlanningPeriod={item.IsOnPlanningPeriod}
            mainColor={mainColor}
            colorId={colorId}
            published={published}
            isRecurring={isRecurring}
            startDatetime={startDatetime}
            upperText={getUpperText(filled, ammattinimikeShorthand, jobTitleName, additionalTopText)}
            warnings={tyoaikasaantorikkeet}
        />
    )
}

export default VuoroCalendarNode
