import * as React from 'react'

import { ListElementText } from '@planier/configurable-list-components'
import ITes from 'interfaces/ITes'

interface ITesProps {
    tes: ITes | null
}

const CollectiveAgreement: React.FunctionComponent<ITesProps> = ({ tes }) => {
    return tes ? <ListElementText displayValue={tes.Nimi} /> : null
}

export default CollectiveAgreement
