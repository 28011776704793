import PropTypes from 'prop-types'
import * as React from 'react'
import reactCss from 'reactcss'
import { isEqual } from 'lodash-es'

import AbstractAction, { IAbstractActionProps } from 'components/atoms/AbstractAction'
import ISvgData from 'interfaces/ISvgData'
import IconCodepoints from 'constants/IconCodepoints'
import IconSvg from 'components/atoms/IconSvg'
import Styles from 'constants/Styles'
import Tooltip from '../../../generic-components/Tooltip'
import { Translation } from '@planier/localization'

interface IProps extends IAbstractActionProps {
    actionIcon: boolean
    align: string
    children: string | ISvgData
    color: string
    cursor?: string
    disabled?: boolean
    listIcon: boolean
    margin: string
    size: string
    testId?: string
    tooltip?: string
    translate: boolean
    greyOut: boolean
}

/**
 * Ikoni.
 * Anna halutun ikonin nimi elementin sisällä. Käytettävissä ovat Material Icons -setin ikonit:
 * https://www.google.com/design/icons/. Vaihtoehtoisesti voi käyttää itsetehtyä SVG-kuvaa, jolloin
 * komponentti käyttää IconSvg - komponenttia.
 *
 * Esimerkki peruskäytöstä:
 * <Icon>help_outline</Icon>
 *
 * Esimerkki kustomoidun SVG-kuvan käytöstä. Childina annettava svg-kuvan pathit
 * ja tyylit sisältävä olio. Katso esimerkki tiedostosta SvgKuvat.js.
 * <Icon>{ SvgKuvat.poistaTyontekijaNappi }</Icon>
 *
 * Jos ikoni on statusikonina, saadaan tarvittavat tyylit antamalla statusIcon - propsi.
 * Tämän jälkeen komponentti tunnistaa switch-case rakenteella ennaltamääritellyt statusikonit
 * tai antaa sinisen default-tyylin ei-määritellyille ikoneille.
 * StatusIkonille voi myös antaa oman svg-kuvan - tällöin tyylit on asetettava SvgKuvat.ts - tiedostossa.
 * Esimerkki statusikonin luomisesta:
 * <Icon statusIcon>check_circle</Icon>
 * <Icon statusIcon>{ SvgKuvat.erikoistaidotIkoni }</Icon>
 */
export default class Icon extends AbstractAction<IProps> {
    static readonly defaultProps = {
        ...AbstractAction.defaultProps,
        actionIcon: false,
        color: Styles.mainColor.darkGrey,
        cursor: 'default',
        greyOut: false,
        listIcon: false,
        margin: '0em',
        size: '1.4rem',
        statusIcon: false,
        translate: true,
        align: 'middle',
    }

    static readonly propTypes = {
        ...AbstractAction.propTypes,
        actionIcon: PropTypes.bool,
        align: PropTypes.string,
        children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
        color: PropTypes.string,
        cursor: PropTypes.string,
        greyOut: PropTypes.bool,
        listIcon: PropTypes.bool,
        margin: PropTypes.string,
        size: PropTypes.string,
        statusIcon: PropTypes.bool,
        tooltip: PropTypes.string,
        translate: PropTypes.bool,
    }

    constructor(props: IProps) {
        super(props)
    }

    shouldComponentUpdate(nextProps: IProps): boolean {
        return !isEqual(this.props, nextProps)
    }

    protected styles(): any {
        return reactCss(
            {
                default: {
                    atom: {
                        cursor: this.props.cursor,
                        color: this.props.color,
                        fontSize: this.props.size,
                        margin: this.props.margin,
                        verticalAlign: this.props.align,
                    },
                },
                greyOut: {
                    atom: {
                        opacity: 0.2,
                        filter: 'alpha(opacity=20)',
                        color: this.props.color,
                        fontSize: this.props.size,
                        verticalAlign: 'middle',
                    },
                },
                actionIcon: {
                    atom: {
                        cursor: 'inherit',
                        transition: 'all 0.2s ease-out',
                    },
                },
                statusIcon: {
                    atom: {
                        color: this.getStatusIconColor(),
                    },
                },
            } as any,
            this.props
        )
    }

    private getStatusIconColor(): string {
        if (this.props.disabled) {
            return Styles.supplementaryColor.concreteGrey
        }

        const children = this.props.children
        const icon = typeof children === 'object' ? (children as ISvgData).name : children

        switch (icon) {
            case 'check_circle':
            case 'home':
                return '#8dbd33'
            case 'work':
            case 'hourglass_empty':
                return '#dc8f65'
            case 'block':
            case 'warning':
                return Styles.supplementaryColor.red
            case 'grade':
                return Styles.mainColor.white
            case 'account_circle':
            default:
                return Styles.supplementaryColor.brightBlue
        }
    }

    private createTooltip(): string {
        const { tooltip, translate } = this.props
        if (!tooltip) {
            return ''
        }

        return translate ? Translation.translateKey(tooltip) : tooltip
    }

    /*
     * Kaikki selaimet eivät välttämättä tue ns. ligatuureja jotka mahdollistavat
     * mm. sen että ikoneja voi käyttää pelkällä niiden tekstuaalisella nimellä.
     * Annetaan ne tämän takia numeerisina koodeina.
     */
    private getIconAsCharacterCode(): string {
        const iconCodepoint = (IconCodepoints as any)[this.props.children as string]
        if (iconCodepoint) {
            return `&#x${iconCodepoint};`
        }

        return this.props.children as string
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    renderWithTooltip(children: any): JSX.Element {
        const tooltip = this.createTooltip()

        return <Tooltip title={tooltip}>{children}</Tooltip>
    }

    render(): React.ReactNode {
        const { actionIcon, children, color, listIcon, testId } = this.props

        const needsHover = !!actionIcon && !listIcon

        if (typeof children === 'object') {
            return this.renderWithTooltip(
                <i className="material-icons" data-testid={testId} role="icon" style={this.styles().atom}>
                    <IconSvg color={color} hoverEffect={needsHover} svgData={children as ISvgData} />
                </i>
            )
        }

        /* eslint-disable react/no-danger */
        return this.renderWithTooltip(
            <i
                className="material-icons"
                dangerouslySetInnerHTML={{ __html: this.getIconAsCharacterCode() }}
                data-testid={testId}
                onMouseOut={needsHover ? this._removeHighlightWithColor : undefined}
                onMouseOver={needsHover ? this._highlightWithColor : undefined}
                role="icon"
                style={this.styles().atom}
            />
        )
        /* eslint-enable react/no-danger */
    }
}
