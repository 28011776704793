import {
    selectValuePickerValuesForQuery,
    fetchValuePickerConfigurationsThunk,
    selectValuePickerConfiguration,
    selectValuePickerValue,
} from '@planier/value-picker'
import { IThunkBaseAction } from '@planier/generic-state'
import { errorOnPromiseFailed } from 'action-creators/ErrorActions'
import {
    downloadTyovuorolistapakettiApi,
    newTyovuorolistaPakettiApi,
    getAllTyovuorolistapakettiApi,
} from '../webApi/TyovuorolistapakettiWebApi'
import { tyovuorolistapakettiValuePickerIds } from '../webApi/TyovuorolistapakettiConfiguration'
import { setTyovuorolistapaketti } from './TyovuorolistapakettiActions'
import {
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
} from '@planier/async-operation'

export const getAllTyovuorolistapaketti = (): IThunkBaseAction => async (dispatch) => {
    try {
        const tyovuorolistapaketit = await getAllTyovuorolistapakettiApi()

        dispatch(setTyovuorolistapaketti(tyovuorolistapaketit))
    } catch (error) {
        dispatch(errorOnPromiseFailed(error))
    }
}

export const getTyovuorolistapaketti = (fileId: number): IThunkBaseAction => async (dispatch) => {
    try {
        await downloadTyovuorolistapakettiApi(fileId)
    } catch (error) {
        dispatch(errorOnPromiseFailed(error))
    }
}

export const newTyovuorolistapaketti = (includeFreetime: boolean): IThunkBaseAction => async (dispatch, getState) => {
    const componentName = 'Tyovuorolistapaketti'
    dispatch(asyncOperationStartedAction(componentName))
    const storeState = getState()

    try {
        const dateRangeValuePickerId = 'Tyovuorolistapaketti.valuePicker.dateRange'
        const dateRangeConfiguration = selectValuePickerConfiguration(storeState, dateRangeValuePickerId)
        if (!dateRangeConfiguration) {
            throw Error(`Value picker ${dateRangeValuePickerId} does not exist`)
        }

        const dateParameters = selectValuePickerValuesForQuery(storeState, [dateRangeConfiguration])

        const tyovuorolistapakettiParameters = {
            ammattinimike: [
                ...(selectValuePickerValue(storeState, 'Tyovuorolistapaketti.valuePicker.ammattinimike') as ReadonlySet<
                    number
                >),
            ],
            tyosopimustyyppi: [
                ...(selectValuePickerValue(
                    storeState,
                    'Tyovuorolistapaketti.valuePicker.tyosopimustyyppi'
                ) as ReadonlySet<number>),
            ],
            customer: [
                ...(selectValuePickerValue(storeState, 'Tyovuorolistapaketti.valuePicker.customer') as ReadonlySet<
                    number
                >),
            ],
            area: [
                ...(selectValuePickerValue(storeState, 'Tyovuorolistapaketti.valuePicker.area') as ReadonlySet<number>),
            ],
            includeFrees: includeFreetime,
            dateRange: (dateParameters as any).dateRange,
            organization: [
                ...(selectValuePickerValue(storeState, 'Tyovuorolistapaketti.valuePicker.organization') as ReadonlySet<
                    number
                >),
            ],
            personsInCharge: [
                ...(selectValuePickerValue(
                    storeState,
                    'Tyovuorolistapaketti.valuePicker.personsInCharge'
                ) as ReadonlySet<number>),
            ],
        }

        await newTyovuorolistaPakettiApi(tyovuorolistapakettiParameters)
        const tyovuorolistapaketit = await getAllTyovuorolistapakettiApi()
        dispatch(setTyovuorolistapaketti(tyovuorolistapaketit))
        dispatch(asyncOperationSucceededAction(componentName))
    } catch (error) {
        dispatch(errorOnPromiseFailed(error))
        dispatch(asyncOperationFailedWithErrorDisplayThunk(error, componentName))
    }
}

export const initialiseTyovuorolistapakettiValuePickers = (): IThunkBaseAction => async (dispatch) => {
    try {
        await dispatch(fetchValuePickerConfigurationsThunk(tyovuorolistapakettiValuePickerIds))
    } catch (error) {
        dispatch(errorOnPromiseFailed(error))
    }
}
