import * as React from 'react'

import Styles from 'constants/Styles'
import TapahtumaAggregateCalendarNode from './TapahtumaNode/TapahtumaAggregateCalendarNode'
import { IEvent } from '@planier/event'

export interface IVuoroAggregateCalendarNodeProps {
    ammattinimikeShorthand: string
    endDatetime: string
    published: boolean
    startDatetime: string
    tyoaikasaantorikkeet: string[] | null
    tyontekijaId?: number | null
    henkilomaara: number
    puuttuvaHenkilomaara: number
    planningPeriodId: number | undefined
    item: IEvent
}

const filledBackgroundColor = '#D6F1F4'
const filledHoverColor = '#BBE0E5'
const unfilledHoverColor = '#F3F9FA'
const mainColor = Styles.planierColor.blueTurquoise.textColor

const VuoroCalendarNode: React.FunctionComponent<IVuoroAggregateCalendarNodeProps> = ({
    ammattinimikeShorthand,
    endDatetime,
    henkilomaara,
    item,
    published,
    puuttuvaHenkilomaara,
    startDatetime,
    tyoaikasaantorikkeet,
    tyontekijaId,
}) => {
    const filled = typeof tyontekijaId === 'number'

    return (
        <TapahtumaAggregateCalendarNode
            backgroundColor={filled ? filledBackgroundColor : Styles.mainColor.white}
            endDatetime={endDatetime}
            extraText={henkilomaara - puuttuvaHenkilomaara + ' / ' + henkilomaara}
            hoverColor={filled ? filledHoverColor : unfilledHoverColor}
            isOnPlanningPeriod={item.IsOnPlanningPeriod}
            mainColor={mainColor}
            published={published}
            startDatetime={startDatetime}
            upperText={ammattinimikeShorthand}
            warnings={tyoaikasaantorikkeet}
        />
    )
}

export default VuoroCalendarNode
