import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { ISelectorObject } from '@planier/data-source-types'
import { selectCalendarNodesBySelectorObjectThunk } from '../../../Thunks/ConfigurableCalendarThunks'
import { ContextMenu } from '@planier/generic-components'
import {
    selectCalendarDataSourceId,
    selectCalendarSelectorObjects,
    selectCalendarNodeActions,
} from '../../../State/ConfigurableCalendarSelectors'
import { Translation } from '@planier/localization'
import { getActionObjectsForMenu, getTemplateActionObjectsForMenu } from '../../../Utilities/ActionUtilities'

type TMenuData = Exclude<React.ComponentProps<typeof ContextMenu>['menuItemsData'], undefined>

export interface IGetContextMenuDynamicItems {
    (): TMenuData
}

const useContextMenuItemsForNodes = (
    calendarId: string
): {
    contextMenuStaticItems: TMenuData
    getContextMenuDynamicItems: IGetContextMenuDynamicItems
} => {
    const dispatch = useDispatch()
    const dataSourceId = useSelector((state: RootState) => selectCalendarDataSourceId(state, calendarId))
    const selectorObjects = useSelector((state: RootState) => selectCalendarSelectorObjects(state, calendarId))
    const nodeActions = useSelector((state: RootState) => selectCalendarNodeActions(state, calendarId))

    const handleMenuItemClick = (selectorObject: ISelectorObject) => {
        dispatch(selectCalendarNodesBySelectorObjectThunk(calendarId, selectorObject.SelectorProperties))
    }

    const selectorObjectItems: TMenuData = selectorObjects
        ? [
              {
                  label: Translation.translateKey('calendar.Node.ContextMenu.SelectorObjects.MainLabel'),
                  subMenu: selectorObjects.map((selectorObject) => ({
                      label: selectorObject.Title,
                      onClick: () => handleMenuItemClick(selectorObject),
                  })),
              },
          ]
        : []

    const getContextMenuDynamicItems: IGetContextMenuDynamicItems = () => {
        return [
            ...getTemplateActionObjectsForMenu(dispatch, calendarId, dataSourceId),
            ...getActionObjectsForMenu(dispatch, calendarId, nodeActions, dataSourceId),
        ]
    }

    return { contextMenuStaticItems: selectorObjectItems, getContextMenuDynamicItems }
}

export default useContextMenuItemsForNodes
