import { JSX, useEffect, useRef } from 'react'
import { FormModal } from '@planier/modal'
import styled from '@emotion/styled'
import { SummaryLabels } from '@planier/generic-components/SummaryLabel/SummaryLabels'
import NewCommentForm from './NewCommentForm'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectCommentThreads,
    selectIsGroupMessaging,
    selectSelectedEvents,
    selectShowNewCommentForm,
    selectSummaryLabelsForSelectedIds,
} from '../State/MessagingSelectors'
import { modalClosed, setShowNewCommentForm } from '../State/MessagingReducer'
import { closeMessageModal, commentingFormInitialized, markEventCommentsAsRead } from '../State/MessagingThunks'
import { IFormViewCustomFormComponentProps } from 'packages/form-view-types'
import CenteredLoadingIndicator from './CenteredLoadingIncicator'
import { IEvent } from 'packages/event'
import CommentThread from './CommentThread'
import { IMessagingDataSourceItem } from '../Types/IMessagingDataSourceItem'
import { isEmpty } from 'lodash-es'

type Props = IFormViewCustomFormComponentProps<IEvent & IMessagingDataSourceItem>

const classNameContentContainer = 'MuiDialogContent-root'
const FORM_ID = 'CommentingForm'

const CommentingModalComponent = styled(FormModal)`
    .${classNameContentContainer} {
        padding: 0;
    }
`

const WrappedSummaryLabels = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 24px;
    padding-left: 12px;
`

const Comments = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 24px 96px 24px 32px;
    gap: 6px;
    border-top: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
`

const CommentingPanel = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: hidden;
    position: relative;
`

const CommentingForm = ({ selectedItems }: Props): JSX.Element => {
    // We do not use formik form on this modal. But the base modal expects these props.
    // Instead we supply minimum required props for it to be satisfied
    const formikProps = {
        initialValues: {},
        onSubmit: () => {
            // ploi
        },
    }

    const dispatch = useDispatch()

    const handleOnClose = () => {
        dispatch(modalClosed())
        dispatch(closeMessageModal(FORM_ID, 'ThreadComment'))
    }

    const isGroupMessaging = useSelector(selectIsGroupMessaging)

    useEffect(() => {
        if (selectedItems) {
            dispatch(commentingFormInitialized(selectedItems))
        } else {
            /* eslint-disable-next-line no-console */
            console.error('form initialized without anything selected')
        }
    }, [dispatch, selectedItems])

    const containerRef = useRef(null)

    const showNewCommentForm = useSelector(selectShowNewCommentForm)
    const modalTitle = 'messaging.comments'
    const commentThreads = useSelector(selectCommentThreads)
    const events = useSelector(selectSelectedEvents)

    const summaryLabels = useSelector(selectSummaryLabelsForSelectedIds)

    const isLoading = commentThreads.isFetching

    const toggleNewCommentFormVisibility = () => {
        dispatch(setShowNewCommentForm(!showNewCommentForm))
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(markEventCommentsAsRead())
        }, 2000) // 2 seconds
        return () => clearTimeout(timer)
    }, [dispatch])

    return (
        <CommentingModalComponent
            formikProps={formikProps}
            open
            formId={FORM_ID}
            modalId={FORM_ID}
            title={modalTitle}
            onClose={handleOnClose}
            disableDefaultActionButtons
            muiDialogProps={{ maxWidth: 'sm', fullWidth: true }}
        >
            <CommentingPanel ref={containerRef}>
                <WrappedSummaryLabels>
                    <SummaryLabels summaryLabels={summaryLabels} />
                </WrappedSummaryLabels>
                <NewCommentForm
                    events={events}
                    isGroupMessage={false}
                    showForm={showNewCommentForm}
                    toggleShowForm={toggleNewCommentFormVisibility}
                    onClose={toggleNewCommentFormVisibility}
                />
                {!isGroupMessaging && (
                    <>
                        {isLoading && <CenteredLoadingIndicator />}
                        {!isEmpty(commentThreads.data) && (
                            <Comments>
                                {commentThreads.data.map((thread) => (
                                    <CommentThread key={thread.Id} thread={thread} />
                                ))}
                            </Comments>
                        )}
                    </>
                )}
            </CommentingPanel>
        </CommentingModalComponent>
    )
}

export default CommentingForm
