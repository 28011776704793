import * as React from 'react'
import styled from '@emotion/styled'
import MUITooltip, { TooltipProps as IMUITooltipProps } from '@material-ui/core/Tooltip'

import MarkDown from '../MarkDown'
import { Translation } from '@planier/localization'

const DefaultChildWrapper = styled.div`
    display: inline-flex;
    width: inherit;
`

interface ITooltipProps extends Omit<IMUITooltipProps, 'title'> {
    /**
     * Material UI's tooltip has a couple of limitations:
     *  1. It must be able to have a ref in the element it wraps
     *  2. In Safari it doesn't work on disabled elements unless at least
     *     one element it wraps has display block or is a flex item.
     *
     * That's why we wrap it by default in an inline-flex div element.
     * You can remove the wrapper completely if you give null, or alternatively
     * give a different wrapper (component or element) if you wish. Be sure
     * to know the consequences then however (that it might not work in Safari
     * if your node is disabled or not work at all if you're using a component
     * to which MUI's tooltip can't pass a ref property)!
     */
    childWrapperElement?: React.ComponentType | React.ElementType | null
    title: IMUITooltipProps['title'] | null | undefined
    wrapperElementClassName?: string
}

const isArrayOfStrings = (rawTitle: unknown): rawTitle is string[] => {
    if (!Array.isArray(rawTitle) || rawTitle.length === 0) {
        return false
    }

    return rawTitle.every((element) => typeof element === 'string')
}

const ListContainer = styled.ul`
    color: black;
    margin-left: -20px;
`

const getTooltipTitle = (rawTitle: ITooltipProps['title']): React.ReactNode => {
    if (rawTitle === '') {
        return rawTitle
    }

    switch (typeof rawTitle) {
        case 'number': {
            return <MarkDown>{rawTitle.toString()}</MarkDown>
        }
        case 'string': {
            return <MarkDown>{Translation.has(rawTitle) ? Translation.translateKey(rawTitle) : rawTitle}</MarkDown>
        }

        case 'object': {
            if (!isArrayOfStrings(rawTitle)) {
                return rawTitle
            }

            if (rawTitle.length === 1) {
                return getTooltipTitle(rawTitle[0])
            }

            return (
                <ListContainer>
                    {rawTitle.map((element) => (
                        <li key={element}>
                            <MarkDown>{element}</MarkDown>
                        </li>
                    ))}
                </ListContainer>
            )
        }

        default:
            return rawTitle
    }
}

const OwnTooltip = styled<React.FC<IMUITooltipProps>>((props) => (
    <MUITooltip classes={{ popper: props.className, tooltip: 'tooltip' }} {...props} />
))`
    display: flex;
    & .tooltip {
        background-color: ${({ theme }) => theme.colors.primaryTeal120};
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
        max-width: 400px;

        p {
            margin-bottom: 0;
        }
    }
`

/**
 * Tooltip component.
 *
 * If the tooltip title (i.e. the content within the tooltip) is a string or number, we
 * render it with markdown so that if the tooltip for example comes from the server,
 * we can format it however we like.
 */
const Tooltip: React.FunctionComponent<ITooltipProps> = ({
    childWrapperElement = DefaultChildWrapper,
    children,
    title,
    className,
    wrapperElementClassName,
    leaveDelay = 400,
    ...muiProps
}) => {
    if (title === null || title === undefined) {
        return children
    }

    const WrapperElement = childWrapperElement

    const titleToUse = getTooltipTitle(title)

    const tooltippedContent = WrapperElement ? (
        <WrapperElement className={wrapperElementClassName}>{children}</WrapperElement>
    ) : (
        children
    )

    return (
        <OwnTooltip
            className={className}
            enterDelay={200}
            enterNextDelay={200}
            enterTouchDelay={200}
            leaveDelay={leaveDelay}
            title={titleToUse as NonNullable<React.ReactNode>}
            disableInteractive
            {...muiProps}
        >
            {tooltippedContent}
        </OwnTooltip>
    )
}

export default Tooltip
