import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash-es'
import { FilterChip, Identifier, IFilterChipOption } from '@planier/generic-components'
import { useDebounce, useQuery } from '@planier/generic-utilities'
import {
    fetchDataForDependentValuePickerThunk,
    fetchValuePickerDataThunk,
    IDropdownV3ValuePickerOwnProps,
} from '@planier/value-picker'
import { selectIsStoredOptionsComponentFetching, selectStoredOptionsComponentAllOptions } from '@planier/stored-options'
import { RootState } from 'typesafe-actions'
import { createCancelToken } from '@planier/rest-api'
import { CancelTokenSource } from 'axios'

let cancelTokenSource: CancelTokenSource | null = null

const DropdownPicker: React.FC<IDropdownV3ValuePickerOwnProps> = ({
    label,
    value,
    onChange,
    identifierUrl,
    UseDynamicApiSearch,
    valuePickerId,
    itemOptionLabelFields,
}) => {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const dispatch = useDispatch()
    const { data, isLoading } = useQuery<{ ListData: IFilterChipOption[] }>(identifierUrl)

    const storedOptions = useSelector((state: RootState) =>
        selectStoredOptionsComponentAllOptions(state, valuePickerId)
    )

    const isSearching = useSelector((state: RootState) => selectIsStoredOptionsComponentFetching(state, valuePickerId))

    const getOptions = (): IFilterChipOption[] => {
        if (isLoading || !data) {
            return []
        }

        return !isEmpty(storedOptions) ? (storedOptions as IFilterChipOption[]) : data?.ListData
    }

    const fetchOptionsBasedOnSearchTerm = useDebounce(() => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel()
        }

        cancelTokenSource = createCancelToken()

        dispatch(
            fetchValuePickerDataThunk(
                valuePickerId,
                { useCachedValues: false },
                { Vapaatekstihaku: searchTerm },
                cancelTokenSource.token
            )
        )
    })

    const handleSearchInput = (userInput: string) => {
        setSearchTerm(userInput)
        fetchOptionsBasedOnSearchTerm()
    }

    const handleOnChange = (input: Identifier[]) => {
        onChange(input)
        dispatch(fetchDataForDependentValuePickerThunk(valuePickerId))
    }

    return (
        <FilterChip
            isLoading={isLoading || isSearching}
            label={label ?? ''}
            options={getOptions()}
            onChange={handleOnChange}
            value={value}
            onSearchTermChange={handleSearchInput}
            searchTermValue={searchTerm}
            useSearch={UseDynamicApiSearch}
            itemOptionLabelFields={itemOptionLabelFields}
            useFilter
            autoFocusFilter
            isMultiselect
        />
    )
}

export default DropdownPicker
