import * as React from 'react'
import { FieldProps } from 'formik'

import TimeRangeInput, { ITimeRangeInputProps } from './TimeRangeInput'

interface IFormikTimeRangeInputOwnProps extends FieldProps {}

type IFormikTimeRangeInputProps = IFormikTimeRangeInputOwnProps & {
    disabled?: boolean
}

interface IState {
    startTimeTouched: boolean
    endTimeTouched: boolean
    lengthTouched: boolean
}

interface IOnBlurObject {
    field: keyof ITimeRangeInputProps['value']
}

/**
 * @deprecated Use `FormikTimeRangeInputNew` instead.
 */
const FormikTimeRangeInput: React.FC<IFormikTimeRangeInputProps> = ({ disabled = false, ...props }) => {
    const [{ startTimeTouched, endTimeTouched, lengthTouched }, setTouchData] = React.useState<IState>({
        startTimeTouched: false,
        endTimeTouched: false,
        lengthTouched: false,
    })

    /**
     * Marks the field touched for formik if startTime and either endTime or length
     * is touched.
     */
    const handleBlur = ({ field: blurField }: IOnBlurObject) => {
        const {
            field: { name },
            form: { setFieldTouched, validateOnBlur },
        } = props

        setTouchData({
            startTimeTouched: startTimeTouched ? startTimeTouched : blurField === 'startTime',
            endTimeTouched: endTimeTouched ? endTimeTouched : blurField === 'endTime',
            lengthTouched: lengthTouched ? lengthTouched : blurField === 'timeLength',
        })

        if (startTimeTouched && (endTimeTouched || lengthTouched)) {
            const isTouched = true
            setFieldTouched(name, isTouched, validateOnBlur)
        }
    }

    const handleChange = (newTimeRangeValue: ITimeRangeInputProps['value']) => {
        const {
            form: { setFieldValue },
            field: { name },
        } = props
        setFieldValue(name, newTimeRangeValue)
    }

    const { field } = props

    return (
        <TimeRangeInput
            disabled={disabled}
            handleChange={handleChange}
            isSpaceAlwaysReservedForError
            onBlur={handleBlur}
            value={field.value}
        />
    )
}

export default FormikTimeRangeInput
