import * as React from 'react'

import EChangeOperation from '../Constants/EChangeOperation'
import { ListElementText } from '@planier/configurable-list-components'

export interface IListElementTextProps {
    operatorId: number
}

const ChangeOperatorElement: React.FunctionComponent<IListElementTextProps> = ({ operatorId }) => (
    <ListElementText displayValue={EChangeOperation[operatorId]} />
)

export default ChangeOperatorElement
