import { Moment, MomentInput } from 'moment'
import * as React from 'react'
import { uniqueId } from 'lodash-es'

import { ErrorableComponent } from '@planier/generic-components'
import DateUtility from 'common/DateUtility'
import Dropdown from 'components/molecules/Dropdown'
import TimeDropdownMenu from './TimeDropdownMenu'
import TimeInputUtility, { INPUT_TARKOITUS } from '../TimeInputUtility'
import _Log from 'common/Log'

const Log = new _Log('DesktopTimeInput')

function formStringFromTime(momentObject: Moment) {
    return DateUtility.asFullHoursMinutes(momentObject)
}

function formUserinputFromValueGivenAsProps(rawTimeValue: any) {
    if (!rawTimeValue) {
        return ''
    }

    return rawTimeValue && DateUtility.isMoment(rawTimeValue) ? formStringFromTime(rawTimeValue) : rawTimeValue
}

const LUVUN_INDEKSI_ENNEN_KAKSOISPISTETTA = 2
const CARET_INCREASE_NORMAL = 0
const CARET_INCREASE_JUMP_OVER_NEXT_CHARACTER = 1

interface IOwnProps {
    changeAction: any
    description: string
    disabled: boolean
    rawTimeValue: MomentInput
    translate?: boolean
    width: string
    onBlur?: (e: React.SyntheticEvent) => void
    validationError: string | null
}

interface IInputChangeData {
    changed?: false
    caretIncrease?: 0 | 1
    newValue?: string
}

/**
 * Ottaa vastaan ja lähettää joko Momentteja tai merkkijonoja jos
 * input-kenttään kirjoitettu aika ei ole validi momentti.
 *
 * Huom. Koodissa on aika paljon karetin sijaintiin liittyviä juttuja sen takia,
 * että kun tehdään muokkauksia uudelle input-arvolle, React ei tietysti osaa
 * arvata missä karetin pitäisi olla muokkauksen jälkeen. Tämän takia React
 * automaattisesti siirtää karetin uuden arvon loppuun. Kierretään tämä ongelma
 * siis pitämällä karetin oikea paikka muistissa ja asettamalla se aina komponentin
 * päivittymisen jälkeen.
 */
class DesktopTimeInput extends React.Component<IOwnProps> {
    static readonly displayName = 'DesktopTimeInput'

    static readonly defaultProps = {
        disabled: false,
        translate: true,
        width: '110px',
    }

    private _caretPosition: undefined | number
    private _textFieldId = uniqueId('ctr-component')
    /**
     * We save the original time value when the component mounts.
     * This is because the time also might contain relevant date in itself.
     * However, if we edit the time so that it's not a valid moment object
     * anymore, we save simply save it as a time string, thus losing the date.
     * Then when we get a valid moment object again, we wouldn't have that
     * original date anymore. But since we have that in this field, we
     * can simply restore the date from it.
     */
    private originalRawTimeValue: MomentInput

    constructor(props: IOwnProps) {
        super(props)

        this.onTimeSelectionChange = this.onTimeSelectionChange.bind(this)

        this.textInputChange = this.textInputChange.bind(this)
        this.setCaretPositionToMemory = this.setCaretPositionToMemory.bind(this)
        this.onClickForMainContainer = this.onClickForMainContainer.bind(this)
        this.renderDropdownMenuForTimes = this.renderDropdownMenuForTimes.bind(this)

        this._caretPosition = undefined
    }

    componentDidMount(): void {
        this.originalRawTimeValue = this.props.rawTimeValue
    }

    componentDidUpdate(): void {
        if (this._caretPosition !== undefined) {
            this.setCaretPositionToText()
            this.setCaretPositionToMemoryFromCurrentLocationInText()
        }
    }

    onTimeSelectionChange(time: string | Moment): void {
        const { changeAction } = this.props

        if (!changeAction) {
            Log.error('No changeAction given')
            return
        }

        changeAction(time)
    }

    onClickForMainContainer(): void {
        const caretPositionInText = this.getCurrentCaretPositionInText()

        const karettiAivanLopussa = caretPositionInText === 5

        if (karettiAivanLopussa) {
            this._caretPosition = 0
            this.setCaretPositionToText()
        }
    }

    renderDropdownMenuForTimes(): JSX.Element {
        const valueObject = this.props.rawTimeValue

        return (
            <TimeDropdownMenu
                currentUserInput={formUserinputFromValueGivenAsProps(valueObject)}
                onChange={this.onTimeSelectionChange}
                originalRawTimeValue={this.originalRawTimeValue}
                rawValueObject={valueObject}
            />
        )
    }

    /*
     * TimeInputissa aika syötetään muodossa HH:mm. Jos se on kuitenkin
     * jo siinä muodossa, numeroita voi ylikirjoittaa vapaasti. Tätä varten
     * tässä metodissa parsitaan syötettä sen mukaan mihin käyttäjä kirjoittaa
     * luvun - esim. jos syötteessä oli 23:33, ja käyttäjä yrittää kirjoittaa
     * luvun 4 ekan kolmosen paikalle, tulee siitä 24:33 (huom. tämä ei ole tietysti
     * validi aika, mutta ainakaan tällä hetkellä se ei ole tämän metodin ongelma).
     * Metodi myös ottaa huomioon kaksoispisteen oikean sijainnin jatkuvasti -
     * jos yritetään kirjoittaa sen paikalle, ylikirjoitetaan kaksoispistettä seuraava
     * numero sen sijaan.
     */
    textInputChange(newInput: string): void {
        this.setCaretPositionToMemoryFromCurrentLocationInText()
        const rawValueObject = this.props.rawTimeValue

        const currentValue = formUserinputFromValueGivenAsProps(rawValueObject)

        if (newInput && !this.containsOnlyNumbersAndAtMostOneColonInRightPlace(newInput)) {
            const caretChange = this.yritettiinPoistaaLuku(newInput, currentValue) ? 1 : -1 // ei anneta karetin siirtyä
            this.forceUpdateWithCaretChange((this._caretPosition as number) + caretChange)
            return
        }

        const { changed, caretIncrease, newValue } = this.ylikirjoitaLisaaTaiPoistaLukuTarpeenMukaan(
            newInput,
            currentValue
        )

        if (changed === false) {
            if (caretIncrease !== undefined) {
                this.forceUpdateWithCaretChange((this._caretPosition as number) + caretIncrease)
            }
            return
        }

        const valueToDispatch = TimeInputUtility.createMomentAndAddDateInfoIfPossible(
            newValue,
            rawValueObject,
            this.originalRawTimeValue
        )

        this.setCaretPositionToMemory((this._caretPosition as number) + (caretIncrease as number))
        this.onTimeSelectionChange(valueToDispatch)
    }

    private removeCharacterAtIndex(index: number | undefined, word: string): string {
        if (index === undefined) {
            Log.warn('index was undefined when removing character at index')
            return word
        }

        return word.slice(0, index) + word.slice(index + 1)
    }

    /*
     * Tarkistetaan että on joko tyhjä tai sisältää pelkästään lukuja ja yhden kaksoispisteen.
     * Jos kaksoispiste on olemassa, se saa olla vasta kahden tai kolmen luvun jälkeen.
     * Kolmen sen takia, koska jos inputissa on tunnit ja minuutit jo valmiina, ja yritetään
     * lisätä luku kohtaan juuri ennen kaksoispistettä, on siinä vaiheessa ennen kaksoispistettä
     * kirjaimia kolme kappaletta ennen kuin myöhemmin tämä tilanne korjataan (niin että
     * korvataankin kaksoispistettä seuraava luku).
     */
    private containsOnlyNumbersAndAtMostOneColonInRightPlace(input: string) {
        return /(^(([0-9]*)|[0-9]{2,3}:{1})[0-9]*$)/.test(input)
    }

    private siirraKaksoispistettaEnnenOlevaLukuKaksoispisteenJalkeen(newValue: string): string {
        const lukuEnnenKaksoispistetta = newValue[LUVUN_INDEKSI_ENNEN_KAKSOISPISTETTA]

        const lukuSiirrettynaKaksoispisteenJalkeen =
            newValue.slice(0, LUVUN_INDEKSI_ENNEN_KAKSOISPISTETTA) + ':' + lukuEnnenKaksoispistetta

        if (newValue.length > 4) {
            const vikanLuvunKanssa = lukuSiirrettynaKaksoispisteenJalkeen + newValue[newValue.length - 1]
            return vikanLuvunKanssa
        }

        return lukuSiirrettynaKaksoispisteenJalkeen
    }

    private ylikirjoitaLisaaTaiPoistaLukuTarpeenMukaan(newValue: string, currentValue: string): IInputChangeData {
        if (newValue === currentValue) {
            return this.noChange()
        }

        let newStringValue = newValue
        if (!newValue) {
            newStringValue = ''
        }

        const inputTarkoitus = TimeInputUtility.selvitaInputTarkoitus(currentValue, this._caretPosition, newStringValue)

        switch (inputTarkoitus) {
            case INPUT_TARKOITUS.TAYSI_POISTO:
                return this.fullRemoval()
            case INPUT_TARKOITUS.LUVUN_POISTAMINEN:
                return this.noChangeButMoveCaretOverNextCharacter()
            case INPUT_TARKOITUS.NORMAALI_LISAYS:
                return this.normalAdd(newStringValue)
            case INPUT_TARKOITUS.NORMAALI_TUNNIN_LISAYS:
                return this.normalAddWithColon(newStringValue)
            case INPUT_TARKOITUS.LUVUN_LISAYS_JUURI_ENNEN_KAKSOISPISTETTA:
                return this.overwriteNumberAfterColon(newStringValue, currentValue)
            case INPUT_TARKOITUS.LUVUN_LISAYS_AIVAN_LOPPUUN:
                return this.noChange()
            case INPUT_TARKOITUS.LUVUN_YLIKIRJOITUS:
                return this.overWriteNumber(newStringValue, currentValue)
            default:
                Log.error(
                    'Did not have any matching inputTarkoitus with inputTarkoitus $0 and currentValue $1',
                    inputTarkoitus,
                    currentValue
                )
                throw new Error('No inputTarkoitus')
        }
    }

    private noChange(): IInputChangeData {
        return { changed: false }
    }

    private noChangeButMoveCaretNormally(): IInputChangeData {
        return { changed: false, caretIncrease: 0 }
    }

    private noChangeButMoveCaretOverNextCharacter(): IInputChangeData {
        return { changed: false, caretIncrease: 1 }
    }

    private normalAdd(newValue: any): IInputChangeData {
        return { newValue, caretIncrease: CARET_INCREASE_NORMAL }
    }

    private fullRemoval(): IInputChangeData {
        return { newValue: '', caretIncrease: CARET_INCREASE_NORMAL }
    }

    private normalAddWithColon(newValue: string): IInputChangeData {
        return { newValue: `${newValue}:`, caretIncrease: CARET_INCREASE_JUMP_OVER_NEXT_CHARACTER }
    }

    private overWriteNumber(newValue: string, currentValue: string): IInputChangeData {
        const arvoKunVikaksiLisattyKirjainSiirrettyVanhanSenPaikallaOlevanTilalle = this.removeCharacterAtIndex(
            this._caretPosition,
            newValue
        )

        const arvoEiMuuttunutVanhasta =
            arvoKunVikaksiLisattyKirjainSiirrettyVanhanSenPaikallaOlevanTilalle === currentValue
        return arvoEiMuuttunutVanhasta
            ? this.noChangeButMoveCaretNormally()
            : {
                  newValue: arvoKunVikaksiLisattyKirjainSiirrettyVanhanSenPaikallaOlevanTilalle,
                  caretIncrease: CARET_INCREASE_NORMAL,
              }
    }

    private overwriteNumberAfterColon(newValue: string, currentValue: string): IInputChangeData {
        const arvoVikaksiLisatyllaLuvullaKaksoispisteenJalkeen =
            this.siirraKaksoispistettaEnnenOlevaLukuKaksoispisteenJalkeen(newValue)

        const arvoEiMuuttunutVanhasta = arvoVikaksiLisatyllaLuvullaKaksoispisteenJalkeen === currentValue
        return arvoEiMuuttunutVanhasta
            ? this.noChangeButMoveCaretOverNextCharacter()
            : {
                  newValue: arvoVikaksiLisatyllaLuvullaKaksoispisteenJalkeen,
                  caretIncrease: CARET_INCREASE_JUMP_OVER_NEXT_CHARACTER,
              }
    }

    private yritettiinPoistaaLuku(newInput: string, formerInput: string): boolean {
        return newInput.length < formerInput.length
    }

    private forceUpdateWithCaretChange(caretPosition: number): void {
        this.setCaretPositionToMemory(caretPosition)
        this.forceUpdate()
    }

    private setCaretPositionToMemory(newPosition: number): void {
        this._caretPosition = newPosition
    }

    private setCaretPositionToMemoryFromCurrentLocationInText(): void {
        this._caretPosition = this.getCurrentCaretPositionInText()
    }

    private setCaretPositionToText(): void {
        const element = document.getElementById(this._textFieldId) as HTMLInputElement

        element.setSelectionRange(this._caretPosition as number, this._caretPosition as number)
    }

    private getCurrentCaretPositionInText(): number {
        return TimeInputUtility.getCurrentCaretPositionInText(this._textFieldId)
    }

    private handleBlur = (event: React.SyntheticEvent) => {
        if (this.props.onBlur) {
            this.props.onBlur(event)
        }

        this._caretPosition = undefined
    }

    render(): React.ReactNode {
        const { description, disabled, translate, rawTimeValue, width, validationError } = this.props
        const userInputValue = formUserinputFromValueGivenAsProps(rawTimeValue)

        return (
            <ErrorableComponent error={validationError}>
                <Dropdown
                    changeAction={this.textInputChange}
                    disabled={disabled}
                    label={description}
                    menuRenderer={this.renderDropdownMenuForTimes}
                    onBlur={this.handleBlur}
                    onMainContainerClick={this.onClickForMainContainer}
                    textFieldId={this._textFieldId}
                    translate={translate}
                    value={userInputValue}
                    width={width}
                />
            </ErrorableComponent>
        )
    }
}

export default DesktopTimeInput
