import * as React from 'react'
import { JSX } from 'react'

import INavigationSubsection from '../../../Types/INavigationSubsection'
import TabLabel from './TabLabel'

type Props = {
    children: string
    to: string
    currentPageSubsection: INavigationSubsection | null
    iconElement: React.ReactNode
    isCurrentPage: boolean
    sectionName: string
}

const SectionWithoutSubsectionsTab = ({
    to,
    children,
    iconElement,
    isCurrentPage,
    sectionName,
}: Props): JSX.Element => {
    return (
        <TabLabel isCurrentPage={isCurrentPage} to={to} variant="h4" sectionName={sectionName}>
            {iconElement}
            {children}
        </TabLabel>
    )
}

export default SectionWithoutSubsectionsTab
