import _Log from 'common/Log'
const Log = new _Log('URL')
import {
    getIsLegacyEnvironment,
    LEGACY_BASE_PATH,
    NEW_ENVIRONMENT_BASE_PATH,
} from '@planier/legacy-environment-adapter'

// IE:llä ei pääsyä origin-muuttujaan, joten tehdään se protokollan, hostnamen ja portin perusteella
function locationOriginForIE(): string {
    let baseUrlEnd = ''
    if (window.location.port) {
        baseUrlEnd = `:${window.location.port}`
    }

    return `${window.location.protocol}//${window.location.hostname}${baseUrlEnd}`
}

function baseURL(): string {
    return window.location.origin ? window.location.origin : locationOriginForIE()
}

const apiBaseUrl = (): string => {
    if (process.env.BACK_END_BASE_URL) {
        return process.env.BACK_END_BASE_URL
    }

    return baseURL()
}

const getBasePath = () => (getIsLegacyEnvironment() ? LEGACY_BASE_PATH : NEW_ENVIRONMENT_BASE_PATH)

const makeNavigationWorkFornewPagesInDevelopment = (url: string) => {
    // A small hack to make all the links to the new side work in development.
    if (url.includes('/PlanierUI/index.html')) {
        return url.replace('/PlanierUI/index.html', '')
    }

    // it's extremely rare that we'd want to navigate to the news page (which is usually the default
    // page for admin users) of the old side from the new side, so we'll have a small hack for that too.
    // This way when developing and logging in as an admin user, we'll just go to the new payroll management.
    if (url.includes('/BSP/news.aspx')) {
        return '/#/tyovuorosuunnittelu'
    }

    return url
}

interface IIdInfoObject {
    paramName: string
    paramValue: number
}

/**
 * Apuluokka URL:ien käsittelyyn.
 */
export default class URL {
    /**
     * @returns API:n URL:n annetulla pathilla.
     * Käytä apikutsuja varten.
     */
    static api(path: string): string {
        return apiBaseUrl() + '/api/' + path
    }

    /**
     * @returns UI:n URL:n annetulla pathilla.
     * Käytä siirtyäksesi paikasta toiseen uudessa käyttöliittymässä.
     */
    static client(path: string): string {
        return `${baseURL()}${getBasePath()}/${path}`
    }

    static clientWithoutBaseURL(path = ''): string {
        return getBasePath() + path
    }

    static getBaseURL(): string {
        return baseURL() + '/api/'
    }

    /**
     * @returns nykyisen URL:n.
     */
    static getCurrent(): string {
        return window.location.href
    }

    static goToLoginPage(): void {
        location.assign(window.location.origin)
    }

    /**
     * Siirtyy annettuun urliin.
     */
    static goto(url: string): void {
        let urlToUse = url

        if (process.env.NODE_ENV !== 'production') {
            urlToUse = makeNavigationWorkFornewPagesInDevelopment(url)
        }

        Log.debug('Changing location to $0.', urlToUse)

        location.assign(urlToUse)
    }

    /**
     * Siirtyy annettuun urliin.
     */
    static open(url: string, target = '_blank', windowParams?: string): void {
        Log.debug('Opening $0.', url)
        window.open(url, target, windowParams)
    }

    /**
     * @returns Vanhan UI:n URL:n annetulla pathilla.
     * Käytä siirtyäksesi paikkaan vanhassa käyttöliittymässä.
     */
    static oldClient(path: string): string {
        // eslint-disable-next-line eqeqeq
        if (path.startsWith('http')) {
            return path
        } else if (path[0] === '/') {
            return baseURL() + path
        } else {
            return baseURL() + '/' + path
        }
    }

    /**
     * Muodostaa urlin parametrit taulukosta, jossa on olioita, joilla on jokin id
     * sekä tieto siitä mikä id on kyseessä. Esim:
     * [
     *     { paramName: 'asiakasId': paramValue: 3 }
     *     { paramName: 'jokuToinenId': paramValue: 42 }
     * ]
     */
    static buildUrlParameters(idInfoArray: IIdInfoObject[] = []): string {
        const filteredIdInfoArray = idInfoArray.filter(
            (idObject) => idObject !== undefined && idObject.paramValue !== undefined
        )

        let pathParams = ''

        if (filteredIdInfoArray[0]) {
            pathParams += `?${filteredIdInfoArray[0].paramName}=${filteredIdInfoArray[0].paramValue}`
        }

        for (let i = 1; i < filteredIdInfoArray.length; i++) {
            pathParams += `&${filteredIdInfoArray[i].paramName}=${filteredIdInfoArray[i].paramValue}`
        }

        return pathParams
    }
}
