import { IThunkBaseAction } from '@planier/generic-state'
import { asyncOperationStartedAction, asyncOperationFailedWithErrorDisplayThunk } from '@planier/async-operation'
import { closeModalAction } from '@planier/modal'
import { changeLanguage } from '../WebApi/LanguageChangeApi'
import { LANGUAGE_CHANGE_IN_PROGRESS_OPERATION_ID, LANGUAGE_CHANGE_MODAL_ID } from '../LanguageChangeForm'
import { applicationInitialised } from 'action-creators'

export const changeLanguageThunk = (selectedLanguage: string): IThunkBaseAction => async (dispatch) => {
    dispatch(asyncOperationStartedAction(LANGUAGE_CHANGE_IN_PROGRESS_OPERATION_ID))

    try {
        await changeLanguage(selectedLanguage as string)

        dispatch(closeModalAction(LANGUAGE_CHANGE_MODAL_ID))
        const reInitialize = true
        dispatch(applicationInitialised(reInitialize))
    } catch (error) {
        await dispatch(
            asyncOperationFailedWithErrorDisplayThunk(
                error,
                LANGUAGE_CHANGE_IN_PROGRESS_OPERATION_ID,
                'language-change.ChangeFailed.Message'
            )
        )
    }
}
