import { MomentInput } from 'moment'
import * as React from 'react'

import DateUtility from 'common/DateUtility'
import { ListElementText } from '@planier/configurable-list-components'

interface IWeekdayProps {
    rawTimeValue: MomentInput
}

const WeekDay: React.FC<IWeekdayProps> = ({ rawTimeValue }) => {
    return rawTimeValue ? (
        <ListElementText displayValue={DateUtility.weekdayAbbreviation(rawTimeValue) as string} />
    ) : null
}

export default WeekDay
