import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { BoundThunk } from '@planier/generic-state'
import {
    closeModalAction,
    CONFIRMATION_DIALOG_ID,
    ConfirmationDialog,
    selectIsModalOpen,
    selectModalProps,
} from '@planier/modal'
import { executeListItemActionThunk, executeSingleListItemActionThunk } from '../../Thunks/ConfigurableListThunks'
import IListActionConfirmationDialogModalProps from '../../interfaces/IListActionConfirmationDialogModalProps'
import { isPlainObject } from 'lodash-es'

interface IStateProps {
    modalOpen: boolean
    modalProps: Record<string, unknown> | undefined
}

interface IDispatchProps {
    closeModalById: typeof closeModalAction

    executeListItemAction: BoundThunk<typeof executeListItemActionThunk>
    executeSingleListItemAction: BoundThunk<typeof executeSingleListItemActionThunk>
}

export interface IDialogProps extends IStateProps, IDispatchProps {}

const validModalProps = (modalProps: any): modalProps is IListActionConfirmationDialogModalProps => {
    if (!isPlainObject(modalProps)) {
        return false
    }

    if (!Array.isArray(modalProps.itemIds)) {
        return false
    }

    if (!(typeof modalProps.listId === 'string')) {
        return false
    }

    return true
}

export const ListActionConfirmationDialogUnconnected: React.FunctionComponent<IDialogProps> = ({
    closeModalById,
    modalOpen,
    modalProps,
    executeListItemAction,
    executeSingleListItemAction,
}) => {
    if (!validModalProps(modalProps)) {
        return null
    }

    const handleClose = () => {
        closeModalById(CONFIRMATION_DIALOG_ID)
    }

    const handleSubmit = async () => {
        const { actionConfiguration, listId, itemIds } = modalProps
        handleClose()

        try {
            if (itemIds.length === 1) {
                await executeSingleListItemAction(listId, itemIds[0], actionConfiguration.Id)
            } else {
                await executeListItemAction(listId, itemIds, actionConfiguration.Id)
            }
        } catch (error) {
            return
        }
    }

    const { ConfirmButtonText, ConfirmationText } = modalProps.confirmationDialogTexts

    return (
        <ConfirmationDialog
            confirmationText={ConfirmationText}
            confirmButtonText={ConfirmButtonText}
            modalId={CONFIRMATION_DIALOG_ID}
            modalOpen={modalOpen}
            onClose={handleClose}
            onSubmit={handleSubmit}
        />
    )
}

const mapStateToProps = (state: RootState): IStateProps => ({
    modalOpen: selectIsModalOpen(state, CONFIRMATION_DIALOG_ID),
    modalProps: selectModalProps(state, CONFIRMATION_DIALOG_ID),
})

const mapDispatchToProps = {
    closeModalById: closeModalAction,
    executeListItemAction: executeListItemActionThunk,
    executeSingleListItemAction: executeSingleListItemActionThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListActionConfirmationDialogUnconnected)
