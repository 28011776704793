import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { EventDeletionModal } from '@planier/event'
import { TDataSourceItemId } from '@planier/data-source-types'
import { selectSelectedCalendarNodes } from '@planier/calendar'
import {
    TARVENAKYMA_CALENDAR_ID,
    TYONTEKIJANAKYMA_CALENDAR_ID,
    TARVENAKYMA_DATA_SOURCE_ID,
    TYONTEKIJANAKYMA_DATA_SOURCE_ID,
} from '../../Constants/WorkforceSchedulingConstants'
import { IEvent } from '@planier/event'
import { resetCalendarNodeSelectionsAction } from '@planier/calendar'
import { EVENT_DELETION_CONFIRMATION_DIALOG_ID } from '../../Constants/WorkforceSchedulingConstants'

interface IStateProps {
    demandViewSelectedTapahtumaNodes: ReadonlySet<TDataSourceItemId>
    employeeViewSelectedTapahtumaNodes: ReadonlySet<TDataSourceItemId>
}

interface IDispatchProps {
    resetCalendarNodeSelections: typeof resetCalendarNodeSelectionsAction
}

interface IDeletionConfirmationDialogProps extends IStateProps, IDispatchProps {}

const WorkforceSchedulingDeletionConfirmationDialog: React.FC<IDeletionConfirmationDialogProps> = ({
    demandViewSelectedTapahtumaNodes,
    employeeViewSelectedTapahtumaNodes,
    resetCalendarNodeSelections,
}) => {
    if (demandViewSelectedTapahtumaNodes.size === 0 && employeeViewSelectedTapahtumaNodes.size === 0) {
        return null
    }

    const activeDataSourceId =
        demandViewSelectedTapahtumaNodes.size > 0 ? TARVENAKYMA_DATA_SOURCE_ID : TYONTEKIJANAKYMA_DATA_SOURCE_ID

    const activeCalendarId =
        activeDataSourceId === TARVENAKYMA_DATA_SOURCE_ID ? TARVENAKYMA_CALENDAR_ID : TYONTEKIJANAKYMA_CALENDAR_ID

    const activeNodes =
        demandViewSelectedTapahtumaNodes.size > 0
            ? demandViewSelectedTapahtumaNodes
            : employeeViewSelectedTapahtumaNodes

    const resetCalendarNodeSelectionsForActiveCalendar = () => resetCalendarNodeSelections(activeCalendarId)

    const getAreDeletionReasonsDisplayed = (events: IEvent[]) => events.some((event) => event.Julkaistu)

    return (
        <EventDeletionModal
            dataSourceId={activeDataSourceId}
            eventIds={[...activeNodes] as string[]}
            getAreDeletionReasonsDisplayed={getAreDeletionReasonsDisplayed}
            modalId={EVENT_DELETION_CONFIRMATION_DIALOG_ID}
            resetSelectedEventsAfterSubmit={resetCalendarNodeSelectionsForActiveCalendar}
        />
    )
}

const mapStateToProps = (state: RootState): IStateProps => ({
    demandViewSelectedTapahtumaNodes: selectSelectedCalendarNodes(state, TARVENAKYMA_CALENDAR_ID),
    employeeViewSelectedTapahtumaNodes: selectSelectedCalendarNodes(state, TYONTEKIJANAKYMA_CALENDAR_ID),
})

const mapDispatchToProps = {
    resetCalendarNodeSelections: resetCalendarNodeSelectionsAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkforceSchedulingDeletionConfirmationDialog)
