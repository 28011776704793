import * as React from 'react'
import { Icon } from '@planier/generic-components'
import styled from '@emotion/styled'

import SortType from 'constants/SortType'
import Styles from 'constants/Styles'
import { IDataSourceSortParameters, IDataSourceSortParameter } from '@planier/data-source-types'

export interface ISortIconProps {
    className?: string
    handleHeaderClick?: () => void
    sortOrder: IDataSourceSortParameter['Order'] | undefined
    sortByParameter: IDataSourceSortParameter['Property']
    sortBySettings: IDataSourceSortParameters
}

const getIcon = (sortOrder: ISortIconProps['sortOrder']): string => {
    switch (sortOrder) {
        case SortType.ASC:
            return 'arrow_downward'
        case SortType.DESC:
            return 'arrow_upward'
        default:
            return ''
    }
}

const getIconColor = (
    sortBySettings: ISortIconProps['sortBySettings'],
    sortByParameter: ISortIconProps['sortByParameter']
) => {
    let iconColor = `${Styles.supplementaryColor.basicGrey}!important`
    const latestSortedHeader = Array.from(sortBySettings.keys()).pop()

    if (latestSortedHeader === sortByParameter) {
        iconColor = `${Styles.mainColor.black}!important`
    }
    return iconColor
}

const StyledIcon = styled(Icon)`
    margin-left: 7px;
`

export const SortIcon: React.FC<ISortIconProps> = ({
    className,
    handleHeaderClick,
    sortOrder,
    sortByParameter,
    sortBySettings,
}) => {
    const icon = getIcon(sortOrder)

    if (!icon) {
        return null
    }

    const color = getIconColor(sortBySettings, sortByParameter)

    return (
        <StyledIcon className={className} color={color} onClick={handleHeaderClick} size={13}>
            {icon}
        </StyledIcon>
    )
}

export default SortIcon
