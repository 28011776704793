import * as React from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import Styles from 'constants/Styles'
import PageDescription from '../PageDescription'
import { Translation } from '@planier/localization'
import {
    INavigationSectionWithSubsections,
    selectCurrentSectionWithSubsections,
    APP_BAR_PAGE_TITLE_MAX_WIDTH_IN_PIXELS,
} from '@planier/navigation'

interface IStateProps {
    currentSection: INavigationSectionWithSubsections | null
}

interface IOwnProps {
    navItemsFitHorizontally: boolean
    pageTitle: string
    pageDescription: string | null
}

interface IApplicationBarTitleProps extends IOwnProps, IStateProps {}

const PageTitleContainer = styled.div`
    margin: auto;
    max-width: ${APP_BAR_PAGE_TITLE_MAX_WIDTH_IN_PIXELS}px;
`

const PageTitle = styled(Typography)`
    color: ${Styles.mainColor.white};
`

const StyledPageDescription = styled(PageDescription)`
    color: ${Styles.mainColor.white};
    margin-left: 10px;
`

const ApplicationBarTitle: React.FC<IApplicationBarTitleProps> = ({
    navItemsFitHorizontally,
    pageTitle,
    pageDescription,
    currentSection,
}) => {
    const sectionLabel = currentSection && currentSection.Subsections.length > 1 ? currentSection.Translation : null

    if (!pageTitle && !sectionLabel) {
        return null
    }

    if (!navItemsFitHorizontally && pageTitle) {
        return (
            <PageTitleContainer>
                <PageTitle variant="h1">
                    {Translation.has(pageTitle) ? Translation.translateKey(pageTitle) : pageTitle}
                </PageTitle>
                <StyledPageDescription descriptionText={pageDescription} />
            </PageTitleContainer>
        )
    }

    return (
        <PageTitleContainer>
            <PageTitle variant="h1">{sectionLabel}</PageTitle>
        </PageTitleContainer>
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    return {
        currentSection: selectCurrentSectionWithSubsections(state),
    }
}

export default connect(mapStateToProps)(ApplicationBarTitle)
