import {
    WorkUnitGroupNode,
    EstoCalendarNode,
    IEstoCalendarNodeProps,
    IMuuTapahtumaCalendarNodeProps,
    IEmployeeGroupNodeOwnProps,
    ITyrkkyCalendarNodeProps,
    IVuoroCalendarNodeProps,
    MuuTapahtumaCalendarNode,
    EmployeeGroupNode,
    IWorkUnitGroupNodeProps,
    TyrkkyCalendarNode,
    VuoroCalendarNode,
    TransferNode,
    ITransferNodeProps,
    VuoroAggregateCalendarNode,
    IVuoroAggregateCalendarNodeProps,
    IEmptyCalendarNodeProps,
    EmptyCalendarNode,
} from '@planier/workforce-scheduling-components'
import DateHeaderNode, { IDateHeaderNodeProps } from '../Components/CalendarBase/DateHeaderNode'
import BlankGroupNode from '../Components/CalendarBase/CalendarContent/BlankGroupNode'

export interface ICalendarNodeFunctionComponent
    extends React.FunctionComponent<
        | IDateHeaderNodeProps
        | IEstoCalendarNodeProps
        | IMuuTapahtumaCalendarNodeProps
        | IEmployeeGroupNodeOwnProps
        | ITyrkkyCalendarNodeProps
        | IVuoroCalendarNodeProps
        | IWorkUnitGroupNodeProps
        | IVuoroAggregateCalendarNodeProps
        | ITransferNodeProps
        | IEmptyCalendarNodeProps
    > {}

export const getNodeComponent = (componentId: string): ICalendarNodeFunctionComponent => {
    return nodeComponents[componentId]
}

// @@TODO This will probably be implemented using a component registry.
const nodeComponents: { [key: string]: ICalendarNodeFunctionComponent } = {
    BlankGroupNode,
    DateHeaderNode,
    EstoCalendarNode,
    MuuTapahtumaCalendarNode,
    EmployeeGroupNode,
    WorkUnitGroupNode,
    TyrkkyCalendarNode,
    VuoroCalendarNode,
    VuoroAggregateCalendarNode,
    TransferNode,
    EmptyCalendarNode,
}
