import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { IDataSourceAggregatedDataRow } from '@planier/data-source-types'

const DataItem = styled.div`
    margin: 5px 0;
    height: 18px;
`

const LabelText = styled(Typography)`
    font-size: 15px;
`

const ValueText = styled(Typography)`
    font-size: 15px;
    padding-left: 13px;
`

const renderDataAsVerticalSummary = (aggregatedData: IDataSourceAggregatedDataRow[]): JSX.Element => {
    return (
        <div>
            {aggregatedData.map(({ Label, Value, Unit }) => (
                <DataItem key={Label}>
                    <LabelText display="inline" variant="bodyXS">
                        {Label}:
                    </LabelText>
                    <ValueText display="inline" variant="bodyS">{`${Value}${Unit ? ' ' + Unit : ''}`}</ValueText>
                </DataItem>
            ))}
        </div>
    )
}

export default renderDataAsVerticalSummary
