import { useState } from 'react'
import * as React from 'react'
import { AccordionProps } from '@material-ui/core/Accordion'

import Expandable, { IExpandableProps } from './Expandable'
import { getLogger } from '@planier/log'
const Log = getLogger('generic-components.ExpandableGroup')

export interface IExpandableGroupProps {
    expandables: IExpandableProps[]
    defaultOpenInIndex?: number | false
    className?: string
}

const ExpandableGroup: React.FunctionComponent<IExpandableGroupProps> = ({
    expandables,
    defaultOpenInIndex = 0,
    className,
}) => {
    const [expanded, setExpanded] = useState(defaultOpenInIndex)

    if (defaultOpenInIndex > expandables.length - 1) {
        Log.error("defaultOpenInIndex prop did not match any expandable's index")
    }

    const handleChange = (expandableIndex: number): AccordionProps['onChange'] => (event, isExpanded) => {
        setExpanded(isExpanded ? expandableIndex : false)
    }

    return (
        <React.Fragment>
            {expandables.map((expandableProps, index) => (
                <Expandable
                    className={className}
                    key={index}
                    muiExpansionPanelProps={{
                        ...expandableProps.muiExpansionPanelProps,
                        expanded: expanded === index,
                        onChange: handleChange(index),
                    }}
                    {...expandableProps}
                />
            ))}
        </React.Fragment>
    )
}

export default ExpandableGroup
