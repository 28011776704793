import { FC } from 'react'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core'

import styled from '@emotion/styled'

import Styles, { dark_100 } from 'constants/Styles'
import { Icon, Tooltip } from '@planier/generic-components'
import { useUnseenReleaseNotesCount } from '../Utils/ReleaseNotesUtils'

interface IReleaseNotesDialogOpenerProps {
    className?: string
}

const createColoredBadge = (color: string) =>
    withStyles(() => ({ badge: { backgroundColor: color, color: dark_100 } }))(Badge)

const ColoredBadge = createColoredBadge('#FFCB05')

const StyledBadge = styled(ColoredBadge)`
    width: 22px;
    margin-right: 20px;
`

const allMessagesReadTooltip = 'release-notes.ReleaseNotesDialogOpener.AllMessagesRead'
const hasUnreadmessagesTooltip = 'release-notes.ReleaseNotesDialogOpener.HasUnreadMessages'

const ReleaseNotesDialogOpener: FC<IReleaseNotesDialogOpenerProps> = () => {
    const { count, displayReleaseNotes } = useUnseenReleaseNotesCount()

    const handleReleaseNotesOpen = () => {
        displayReleaseNotes()
    }

    return (
        <Tooltip title={count ? hasUnreadmessagesTooltip : allMessagesReadTooltip}>
            <StyledBadge badgeContent={count}>
                <button onClick={handleReleaseNotesOpen} data-testid="STC_HEADER_RELEASENOTE_BTN">
                    <Icon color={Styles.mainColor.white}>notifications</Icon>
                </button>
            </StyledBadge>
        </Tooltip>
    )
}

export default ReleaseNotesDialogOpener
