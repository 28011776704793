import * as React from 'react'
import moment from 'moment'
import { ReadOnlyText } from '@planier/generic-components'

export interface INodeDatetimeRowProps {
    color?: string
    endDatetime: string
    startDatetime: string
}

const timeDisplayFormat = 'H.mm'

const NodeDatetimeRow: React.FunctionComponent<INodeDatetimeRowProps> = ({ color, endDatetime, startDatetime }) => (
    <ReadOnlyText rawColor={color} usage="event_block">
        {`${moment(startDatetime).format(timeDisplayFormat)}–${moment(endDatetime).format(timeDisplayFormat)}`}
    </ReadOnlyText>
)

export default NodeDatetimeRow
