import { combineReducers } from 'redux'

import { configurableCalendarReducer } from '@planier/calendar'
import { configurableListReducer } from '@planier/configurable-list'
import { dataSourceReducer } from '@planier/data-source'
import { modalReducer } from '@planier/modal'
import { valuePickerReducer } from '@planier/value-picker'
import { calendarReducer, workforceSchedulingReducer } from '@planier/workforce-scheduling'
import { formViewReducer } from '@planier/form-view'
import { notificationReducer } from '@planier/notifications'
import { osiokohtaisetAsetuksetReducer } from '@planier/osiokohtaiset-asetukset'
import { tyovuorolistapakettiReducer } from '@planier/tyovuorolistapaketti'
import { asyncOperationReducer } from '@planier/async-operation'
import asetusReducer from 'reducers/AsetusReducer'
import { navigationReducer } from '@planier/navigation'
import ohjeinfoReducer from 'reducers/OhjetiedostoReducer'
import tyyliReducer from 'reducers/TyyliReducer'
import userReducer from 'reducers/UserReducer'
import { pageReducer } from '@planier/page'
import { storedOptionsReducer } from '@planier/stored-options'
import { palkkausReducer } from '@planier/yllapitokayttoliittymat'
import { messagingReducer } from '@planier/messaging'
import { EmployeeCalendarReducer } from '@planier/employee-calendar'

export default combineReducers({
    asetukset: asetusReducer,
    calendarv2: calendarReducer,
    calendar: configurableCalendarReducer,
    dataSource: dataSourceReducer,
    formView: formViewReducer,
    listData: configurableListReducer,
    loginUser: userReducer,
    modal: modalReducer,
    navigationItems: navigationReducer,
    notifications: notificationReducer,
    ohjeinfot: ohjeinfoReducer,
    osiokohtaisetAsetukset: osiokohtaisetAsetuksetReducer,
    pageData: pageReducer,
    tyovuorolistapaketit: tyovuorolistapakettiReducer,
    tyylit: tyyliReducer,
    asyncOperationState: asyncOperationReducer,
    storedOptions: storedOptionsReducer,
    valuePicker: valuePickerReducer,
    PalkkausPage: palkkausReducer,
    workforceScheduling: workforceSchedulingReducer,
    messaging: messagingReducer,
    employeeCalendar: EmployeeCalendarReducer,
})
