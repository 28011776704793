import * as React from 'react'
import { connect } from 'react-redux'

import { Icon } from '@planier/generic-components'
import { getTyovuorolistapaketti } from '../actions/TyovuorolistapakettiThunks'
import { BoundThunk } from '@planier/generic-state'

interface IStateProps {
    id: number
}

interface IDispatchProps {
    getTyovuorolistapaketti: BoundThunk<typeof getTyovuorolistapaketti>
}

interface IDownloadActionWrapper extends IStateProps, IDispatchProps {}

export class DownloadActionWrapperUnconnected extends React.Component<IDownloadActionWrapper> {
    handleClick = (): void => {
        this.props.getTyovuorolistapaketti(this.props.id)
    }

    render(): React.ReactNode {
        return (
            <Icon onClick={this.handleClick} tooltip="Tyovuorolistapaketti.download">
                file_download
            </Icon>
        )
    }
}

const mapDispatchToProps = {
    getTyovuorolistapaketti,
}

export default connect(null, mapDispatchToProps)(DownloadActionWrapperUnconnected)
