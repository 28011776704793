import { useEffect, useState } from 'react'
import { getIsLegacyEnvironment } from '@planier/legacy-environment-adapter'
import { setJwt, getJwt, getRefreshToken } from '@planier/authentication'

const useJwt = (isPageWithoutUserInitialization: boolean): { isGettingJwt: boolean } => {
    const [isGettingJwt, setIsGettingJwt] = useState<boolean | null>(null)

    useEffect(() => {
        if (isPageWithoutUserInitialization) {
            return
        }

        setIsGettingJwt(false)
        ;(async () => {
            if (getJwt() || getIsLegacyEnvironment()) {
                setIsGettingJwt(false)
                return
            }

            setIsGettingJwt(true)
            const jwt = await getRefreshToken()

            if (jwt) {
                setJwt(jwt)
            }

            setIsGettingJwt(false)
        })()
    }, [isPageWithoutUserInitialization])

    return {
        isGettingJwt: isGettingJwt === null,
    }
}

export default useJwt
