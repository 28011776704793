import * as React from 'react'

import { EActionFunctionalityType, handleViewEngineActionThunk } from '@planier/view-engine'
import { useDispatch } from 'react-redux'
import { ReadOnlyText } from '@planier/generic-components'

export interface IWorkunitLink {
    name: string
    id: number
    noLinkText?: string
}

export const OpenWorkunitEditForm = {
    FunctionalityType: EActionFunctionalityType.ConfigurableModal,
    DataSourceActionId: '',
    FormId: 'WorkunitModifyForm',
    AdditionalPropertyMapping: [],
    AdditionalInitialValueSourceProperties: [],
    AdditionalInitialValues: [],
    IgnoreInitialValueFields: [],
    PermissionId: 'EDIT',
}

const WorkunitLink: React.FunctionComponent<IWorkunitLink> = ({ name, id, noLinkText }) => {
    const dispatch = useDispatch()

    if (noLinkText) {
        return (
            <ReadOnlyText isLink={false} textOverflow="ellipsis" color="dark_100" usage="legacy_list_element">
                {noLinkText}
            </ReadOnlyText>
        )
    }

    const EDIT_PERMISSIONS = [{ Toiminto: 'EDIT', OnkoSallittu: true, SyyKieltoon: null }]

    const openWorkunitForm = () => {
        dispatch(
            handleViewEngineActionThunk({
                items: [{ Id: id, Kayttooikeudet: EDIT_PERMISSIONS }],
                functionalityAction: OpenWorkunitEditForm,
                dataSourceId: 'WorkUnit',
                additionalRequestData: {},
            })
        )
    }

    return (
        <ReadOnlyText
            isLink={true}
            linkOnClick={openWorkunitForm}
            textOverflow="ellipsis"
            color="dark_100"
            usage="legacy_list_element"
            tooltipEnabled
        >
            {name}
        </ReadOnlyText>
    )
}

export default WorkunitLink
