import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { selectListViewFilterIds } from '../../State/ConfigurableListSelectors'
import { fetchListDataThunk } from '../../Thunks/ConfigurableListThunks'
import TListViewId from '../../interfaces/TListViewId'
import TValuePickerId from '@planier/value-picker/types/TValuePickerId' // TODO Cyclic reference
import { BoundThunk } from '@planier/generic-state'
import { selectIsValuePickerInitialized, selectValuePickerValue } from '@planier/value-picker'
import { makeRequestTemplate } from '@planier/rest-api'
import { LIST_FILTER_TO_ITSELF_PREFIX } from '../../Constants/ListConstants'
import { isEqual } from 'lodash-es'

interface IOwnProps {
    listId: TListViewId
}

type TFilters = { [valuePickerId: string]: unknown }

interface IStateProps {
    filterIds: TValuePickerId[]
    pageFilters: TFilters
}

interface IDispatchProps {
    fetchListData: BoundThunk<typeof fetchListDataThunk>
}

export interface IListFilterListenerProps extends IOwnProps, IStateProps, IDispatchProps {}

const filterWasAddedDynamically = (previousFilters: TFilters, filterId: string) => {
    return !Object.prototype.hasOwnProperty.call(previousFilters, filterId)
}

interface IFiltersChanged {
    isAnyFilterValueChanged: boolean
    firstChangedFilter: string | undefined
}

const filterSelectionsChanged = (
    prevProps: IListFilterListenerProps,
    currentProps: IListFilterListenerProps,
    filterIds: TValuePickerId[]
): IFiltersChanged => {
    const { pageFilters: prevPageFilters } = prevProps
    const { pageFilters: currentPageFilters } = currentProps

    const changedFilter = filterIds
        .filter((id) => !filterWasAddedDynamically(prevPageFilters, id))
        .find((filterId) => {
            const prevSelections = prevPageFilters[filterId]
            const currentSelections = currentPageFilters[filterId]

            return !isEqual(prevSelections, currentSelections)
        })

    return {
        isAnyFilterValueChanged: Boolean(changedFilter),
        firstChangedFilter: changedFilter,
    }
}

// TODO: this whole thing could be done using useDataFetchAtValuePickerValueChange,
// though might need some minor refactoring.
/*
 * Nonvisible component that triggers a new data query for the list given
 * in the listId prop on any filter value change.
 */
export class ListFilterListener extends React.Component<IListFilterListenerProps> {
    private cleanupApiCall: null | (() => void) = null

    componentDidUpdate(prevProps: IListFilterListenerProps): void {
        const { filterIds, listId } = this.props

        const { isAnyFilterValueChanged, firstChangedFilter } = filterSelectionsChanged(
            prevProps,
            this.props,
            filterIds
        )

        if (!isAnyFilterValueChanged) {
            return
        }

        const listFilterToItselfWasOnlyToChange = `${LIST_FILTER_TO_ITSELF_PREFIX}${listId}` === firstChangedFilter

        const resetSelectedItems = !listFilterToItselfWasOnlyToChange

        this.cleanupApiCall && this.cleanupApiCall()

        const { cleanup } = makeRequestTemplate({
            execute: async (cancelToken) => {
                // Reset the offset every time the filters change.
                await this.props.fetchListData(listId, {
                    cancelToken,
                    resetSelected: resetSelectedItems,
                    resetOffset: true,
                })
            },
            after: () => {
                this.cleanupApiCall = null
            },
        })

        this.cleanupApiCall = cleanup
    }

    componentWillUnmount(): void {
        this.cleanupApiCall = null
    }

    render(): React.ReactNode {
        return null
    }
}

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => {
    const filterIds = [...selectListViewFilterIds(state, listId), `ListFilterToItself-${listId}`]

    const pageFilters = filterIds
        .filter((filterId) => selectIsValuePickerInitialized(state, filterId))
        .reduce((filters, filterId) => {
            filters[filterId] = selectValuePickerValue(state, filterId)
            return filters
        }, {} as TFilters)

    return {
        filterIds,
        pageFilters,
    }
}

const mapDispatchToProps = {
    fetchListData: fetchListDataThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListFilterListener)
