import { useState, useEffect } from 'react'
import { ContextMenu } from '@planier/generic-components'

type TMenuData = Exclude<React.ComponentProps<typeof ContextMenu>['menuItemsData'], undefined>

type TUseMenuItemsReturn = {
    menuItems: TMenuData
    addDynamicMenuItems: () => void
}

const useMenuItemsForNode = (
    contextMenuStaticItems: TMenuData,
    getContextMenuDynamicItems: () => TMenuData
): TUseMenuItemsReturn => {
    const [menuItems, setMenuItems] = useState<TMenuData>(contextMenuStaticItems)

    useEffect(() => {
        setMenuItems(contextMenuStaticItems)
    }, [contextMenuStaticItems])

    const addDynamicMenuItems = () => {
        const dynamicItems = getContextMenuDynamicItems()

        if (dynamicItems.length > 0) {
            setMenuItems([...dynamicItems, ...contextMenuStaticItems])
        }
    }

    return { menuItems, addDynamicMenuItems }
}

export default useMenuItemsForNode
