import { MomentInput } from 'moment'

import IAmmattinimike from 'interfaces/IAmmattinimike'
import { IPalkkatyyppitunniste, IPalkkalajitunniste } from '@planier/palkka'
import { ITyosuhdemuototunniste, ITyosuhdetyyppitunniste } from '@planier/tyosuhde'
import { IDataSourceItem } from '@planier/data-source-types'
import EEventClass from './EEventClass'

interface IEventIdentifier {
    TyoaikatapahtumanId: number
    Tyoaikatapahtumatyyppi: number
}

interface IEventType {
    Id: number
    Nimi: string
    Varikoodi: string | null
    Name: string
}

interface IIdentifierObject {
    Id: string | number
    Nimi: string
}

export interface IIdentifier {
    Id: string | number
    Name: string
}

export enum EEventTypeCategory {
    Shift = 1,
    WorktimePreference,
    OfftimePreference,
    ReservingEventGroup,
    NonReservingEventGroup,
    EventGroup,
    OtherWorktime,
    OtherNotWorktime,
    AnnualLeave,
    SickLeave,
}

// Transfer Id 6 was checked from the API. Maybe a full enum would be better here?
export enum EEventType {
    Transfer = 6,
}

export default interface IEvent extends IDataSourceItem {
    EventType: IEventType
    EventGroup: any
    JobTitle: IIdentifier
    EventTypeCategory: IIdentifier
    Ammattinimike: IAmmattinimike | null
    Alkamisaika: MomentInput
    Asiakas: null | IIdentifierObject
    Employee: null | IIdentifier
    EmployeeId: null | number
    WorkUnit: null | IIdentifier
    StartTime: MomentInput
    EndTime: MomentInput
    AsiakasHyvaksynyt: boolean
    Id: string
    Kokonaislaskutus: number
    Kokonaispalkka: number
    Lisatiedot: string | null
    LisatiedotKuitattu: boolean
    LisatiedotTyontekijalta: string | null
    Loppumisaika: MomentInput
    LounaanAlkuaika: string | null
    LounasId: number | null
    Lounasminuutit: number
    Palkkatyyppi: null | IPalkkatyyppitunniste
    Projektinumero: null | IIdentifierObject
    RuokaperintaId: number | null
    Ruokaperintahinta: number | null
    Tapahtumatunniste: IEventIdentifier
    Tapahtumatyyppi: IEventType
    TapahtumaluokkaID: EEventClass
    Tes: IIdentifierObject | null
    Tuntipalkka: number
    Tyontekija: IIdentifierObject | null
    TyontekijaHyvaksynyt: boolean
    Tyosuhdemuoto: null | ITyosuhdemuototunniste
    Tyosuhdetyyppi: null | ITyosuhdetyyppitunniste
    Urakkapalkkalaji: null | IPalkkalajitunniste
    Urakkapalkkamaara: number | null
    KasiteltyToteumaan: boolean
    KestoMinuutit: number
    Julkaistu: boolean
    YliajavaTuntipalkka: number | null
    OverridingProduct: number | null
    SuunnittelujaksoVersioId?: number
    IsOnPlanningPeriod: boolean
    CanComment: boolean
}
