import * as React from 'react'
import { useSelector } from 'react-redux'

import { selectCurrentSubsection, selectCurrentSectionWithSubsections } from '@planier/navigation'
import { getIsLegacyEnvironment } from '@planier/legacy-environment-adapter'
import SubPageNavigationTabs from './SubPageNavigationTabs'

const useSubPagesData = () => {
    const currentMainPage = useSelector(selectCurrentSectionWithSubsections)
    const currentSubPage = useSelector(selectCurrentSubsection)

    const subPagesForCurrentMainPage =
        currentMainPage && currentMainPage.subsectionObjects.length > 1 ? currentMainPage.subsectionObjects : []

    return {
        subPagesForCurrentMainPage,
        currentSubPageId: currentSubPage?.Id ?? null,
    }
}

type TSubPageNavigationTabs = React.ComponentProps<typeof SubPageNavigationTabs>

interface ISubPagesReturn {
    areNavigationTabsRendered: boolean
    SubPageNavigationTabs: React.FC<TSubPageNavigationTabs>
    subPageNavigationProps: TSubPageNavigationTabs
}

const useSubPages = (): ISubPagesReturn => {
    const subPagesData = useSubPagesData()

    return {
        areNavigationTabsRendered: !getIsLegacyEnvironment() && subPagesData.subPagesForCurrentMainPage.length > 0,
        SubPageNavigationTabs,
        subPageNavigationProps: subPagesData,
    }
}

export default useSubPages
