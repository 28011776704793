import { IThunkBaseAction } from '@planier/generic-state'
import { selectPageData } from './PageSelectors'
import { fetchPageData } from '../PageWebApi'
import { setPageData } from './PageActions'
import { Userpilot } from 'userpilot'
import {
    asyncOperationStartedAction,
    asyncOperationSucceededAction,
    asyncOperationFailedAction,
} from '@planier/async-operation'
import { PAGE_LOADING_ASYNC_OPERATION_ID } from '../PageConstants'
import { getLogger } from '@planier/log'
import { selectIsUserInstructionsEnabled } from 'reducers/AsetusSelectors'
const Log = getLogger('page.PageThunks')

export const fetchPageDataThunk = (pageId: string): IThunkBaseAction => async (dispatch, getState) => {
    if (selectPageData(getState(), pageId) !== null) {
        return
    }

    /** As a single page application we need to tell userPilot that the url has changed. */
    if (selectIsUserInstructionsEnabled(getState())) {
        Userpilot.reload(window.location.href)
    }

    try {
        dispatch(asyncOperationStartedAction(PAGE_LOADING_ASYNC_OPERATION_ID))
        const pageData = await fetchPageData(pageId)

        if (pageData === null) {
            Log.error('no data found for the page')
            return
        }

        dispatch(setPageData(pageData))

        dispatch(asyncOperationSucceededAction(PAGE_LOADING_ASYNC_OPERATION_ID))
    } catch (error) {
        dispatch(asyncOperationFailedAction(error, PAGE_LOADING_ASYNC_OPERATION_ID))
    }
}
