import { RootState } from 'typesafe-actions'
import { createSelector } from 'reselect'
import createCachedSelector from 're-reselect'
import IFormViewValuePickerField from '../Types/IFormViewValuePickerField'
import { selectOpenModals } from '@planier/modal'
import { IFormViewModalProps } from '@planier/form-view-types'
import IFormViewConfigurationV2 from '../Types/V2/IFormViewConfigurationV2'
import IFormViewFieldConfigurationV2 from '../Types/V2/IFormViewFieldConfigurationV2'
import { flatMap } from 'lodash-es'

type TFormViewId = string
type TValuePickerId = string

const defaultCacheKey = 'formViewNotInitialized'

const selectFormView = (state: RootState, formId: TFormViewId | null): undefined | IFormViewConfigurationV2 => {
    if (formId === null) {
        return undefined
    }

    return state.formView.get(formId)
}

export const selectFormViewConfiguration = (
    state: RootState,
    formId: TFormViewId | null
): IFormViewConfigurationV2 | undefined => {
    if (formId === null) {
        return undefined
    }

    const formView = selectFormView(state, formId)

    return formView
}

export const selectFormViewDataSourceId = (state: RootState, formId: TFormViewId | null): string | null => {
    const formView = selectFormViewConfiguration(state, formId)

    return formView ? formView.DataSourceId : null
}

export const selectIsLoading = (state: RootState, formId: TFormViewId): boolean => {
    const formView = selectFormViewConfiguration(state, formId)

    return formView ? formView.IsLoadingData : true
}

//selectIsLoading
export const selectFormViewFields = (state: RootState, formId: TFormViewId): IFormViewFieldConfigurationV2[] => {
    const formView = selectFormViewConfiguration(state, formId)

    const fields = flatMap(formView?.Sections, 'Fields') as IFormViewFieldConfigurationV2[]

    return fields
}

export const selectFormViewHasSaveAndContinue = (state: RootState, formId: TFormViewId): boolean => {
    const formView = selectFormViewConfiguration(state, formId)

    return formView?.HasSaveAndContinue ?? false
}

export const selectFormViewValuePickerIds = createCachedSelector(
    selectFormViewFields,
    (formViewFields): TValuePickerId[] => {
        const valuePickerIds = formViewFields
            .map(({ ValuePickerId }) => ValuePickerId)
            .filter((ValuePickerId): ValuePickerId is TValuePickerId => Boolean(ValuePickerId))

        return valuePickerIds
    }
)((state, formId) => formId ?? defaultCacheKey)

export const selectFormViewValuePickerFields = createCachedSelector(
    selectFormViewFields,
    (formViewFields): IFormViewValuePickerField[] => {
        const valuePickerFields = formViewFields
        return valuePickerFields
    }
)((state, formId) => formId ?? defaultCacheKey)

export const selectFormViewSubmitActionId = (state: RootState, formId: TFormViewId | null): string | null => {
    const formView = selectFormViewConfiguration(state, formId)

    return formView ? formView.SubmitActionId : null
}

const selectFormViewField = (
    state: RootState,
    formId: TFormViewId,
    fieldId: string
): IFormViewFieldConfigurationV2 | null => {
    const fields = selectFormViewFields(state, formId)

    if (!fields) {
        return null
    }

    return fields.find((field) => field.Id === fieldId) ?? null
}

export const selectFormViewFieldValuePickerId = (
    state: RootState,
    formId: TFormViewId,
    fieldId: string
): string | null => {
    const field = selectFormViewField(state, formId, fieldId)

    return field ? field.ValuePickerId : null
}

export const selectFormViewSubsequentFormViewId = (state: RootState, formId: TFormViewId): string | null => {
    const formView = selectFormViewConfiguration(state, formId)

    return formView ? formView.SubsequentFormId ?? null : null
}

export const selectOpenFormViewModalIds = createSelector(selectOpenModals, (openModal): string[] => {
    return [...openModal]
        .filter(([, modal]) => {
            const modalProps = modal.props as IFormViewModalProps
            const hasFormId = Boolean(modalProps?.formId)

            return hasFormId
        })
        .map(([modalId]) => modalId)
})
