import styled from '@emotion/styled'
import IconButton from '@material-ui/core/IconButton'

const ActionButton = styled(IconButton)`
    color: ${({ theme }) => theme.componentExtensions.icons.iconAction};

    &:disabled {
        color: ${({ theme }) => theme.componentExtensions.icons.iconDisabledLighter};
        pointer-events: auto;
    }
`

export default ActionButton
