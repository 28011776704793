import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'
import NodeDatetimeRow from '../NodeDatetimeRow'
import { Icon } from '@planier/generic-components'

import BasicCalendarNode from '../BasicCalendarNode'
import { getBackgroundColorForId, getTextColorForId, useAppTheme } from '@planier/theme'

type TBasicCalendarNodeProps = React.ComponentProps<typeof BasicCalendarNode>

const BottomRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

const EmptySpace = styled.div`
    width: 4px;
`

export interface ITapahtumaCalendarNodeProps extends Pick<TBasicCalendarNodeProps, 'borderColor'> {
    backgroundColor?: string
    endDatetime: string
    hoverColor: string
    published: boolean
    startDatetime: string
    upperText: string
    warnings: string[] | null
    mainColor: string
    isRecurring: boolean
    isOnPlanningPeriod: boolean
    colorId: string | null
}

const TapahtumaCalendarNode: React.FunctionComponent<ITapahtumaCalendarNodeProps> = ({
    backgroundColor,
    endDatetime,
    published,
    startDatetime,
    upperText,
    warnings,
    mainColor,
    isRecurring,
    isOnPlanningPeriod,
    colorId,
}) => {
    const theme = useAppTheme()

    const bgColor = backgroundColor ? backgroundColor : getBackgroundColorForId(colorId ?? '', theme)
    const textColor = colorId ? getTextColorForId(colorId, theme) : mainColor

    return (
        <BasicCalendarNode
            backgroundColor={bgColor}
            borderColor={textColor}
            extraTextNode={
                <BottomRow>
                    <NodeDatetimeRow color={textColor} endDatetime={endDatetime} startDatetime={startDatetime} />
                    <EmptySpace />
                    {isRecurring ? (
                        <Icon color={Styles.planierColor.blueTurquoise.textColor} size={12}>
                            {'repeat_variant'}
                        </Icon>
                    ) : null}
                </BottomRow>
            }
            hoverColor={theme.colors.neutralsGrey11}
            isBottomRightSignifierDisplayed={isOnPlanningPeriod}
            isCoveredOnLeft={published}
            mainColor={textColor}
            upperText={upperText}
            warnings={warnings}
        />
    )
}

export default TapahtumaCalendarNode
