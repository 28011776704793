import { createAction } from 'typesafe-actions'

import {
    EViewDataStatus,
    IDataSourceAccumulatedData,
    IDataSourceAllFetchRelatedData,
    IDataSourceDataRequestParametersOverride,
    IDataSourceGroupData,
    IDataSourceItem,
    IDataSourceSortParameter,
    TDataSourceItemId,
} from '@planier/data-source-types'
import IDataSourceData from '../Types/IDataSourceData'
import IDataSourceConfiguration from '../Types/IDataSourceConfiguration'
import { TDataSourceId } from '../Types/IDataSource'

export const dataSourceReplaceItemAction = createAction(
    'data-source.ReplaceItem',
    (dataSourceId: TDataSourceId, item: IDataSourceItem, previousId: TDataSourceItemId) => ({
        item,
        previousId,
    }),
    (dataSourceId: TDataSourceId) => dataSourceId
)()

export const dataSourceSetDataAction = createAction('data-source.DataFetched')<{
    dataSourceId: TDataSourceId
    data: IDataSourceData | null
    orderedItemIds: TDataSourceItemId[] | null
    accumulatedData: IDataSourceAccumulatedData[]
    groupData: IDataSourceGroupData[] | null
    dataStatus: EViewDataStatus
}>()

export const initialiseDataSourceAction = createAction('data-source.Initialized')<IDataSourceConfiguration>()

export const dataSourceSetValuePickerIdsAction = createAction(
    'data-source.SetValuePickerIds',
    (dataSourceId: TDataSourceId, valuePickerIds: string[]) => valuePickerIds,
    (dataSourceId: TDataSourceId) => dataSourceId
)()

/**
 * Sets all the given value pickers for all the given data sources
 */
export const dataSourcesSetValuePickerIdsAction = createAction(
    'data-source.SetValuePickerIdsForDataSources',
    (dataSourceIds: TDataSourceId[], valuePickerIds: string[]) => valuePickerIds,
    (dataSourceIds: TDataSourceId[]) => dataSourceIds
)()

/**
 * Sets any parameters that go in the `Filters` field of the body of the
 * dataSource's data requests.
 */
export const dataSourceSetFetchFiltersParametersAction = createAction(
    'data-source.SetFetchFiltersParameters',
    (dataSourceId: TDataSourceId, fetchParameters: Record<string, unknown>) => fetchParameters,
    (dataSourceId: TDataSourceId) => dataSourceId
)()

/**
 * Set any parameters for fetch request that will override other parameters.
 * Can be used for example if you want to temporarily in some context to ignore
 * any existing parameters (or just one existing parameter) and use some other value
 * for them.
 */
export const dataSourceOverrideFetchParametersSetAction = createAction(
    'data-source.SetFetchParametersOverride',
    (dataSourceId: TDataSourceId, fetchParameters: IDataSourceDataRequestParametersOverride) => fetchParameters,
    (dataSourceId: TDataSourceId) => dataSourceId
)()

/**
 * Remove any parameters that override others.
 */
export const dataSourceOverrideFetchParametersRemoveAction = createAction(
    'data-source.FetchFiltersParametersRemove',
    (dataSourceId: TDataSourceId) => dataSourceId
)()

/**
 * Sets any parameters that go in the root of the body of the dataSource's data requests
 */
export const dataSourceSetFetchParametersAction = createAction(
    'data-source.SetFetchParameters',
    (dataSourceId: TDataSourceId, fetchParameters: Record<string, unknown>) => fetchParameters,
    (dataSourceId: TDataSourceId) => dataSourceId
)()

export const dataSourceResetDataAction = createAction('data-source.ResetData')<TDataSourceId>()

export const dataSourceSetSortSettingsAction = createAction(
    'Data-source.ColumnsSorted',
    (
        dataSourceId: TDataSourceId,
        sortType: IDataSourceSortParameter['Order'],
        sortByParameter: IDataSourceSortParameter['Property']
    ) => ({
        dataSourceId,
        sortType,
        sortByParameter,
    }),
    (dataSourceId: TDataSourceId) => dataSourceId
)()

export const dataSourceSetAllFetchRequestRelatedDataAction = createAction(
    'data-source.SetAllFetchRequestRelatedData',
    (dataSourceId: TDataSourceId, allFetchRequestRelatedData: IDataSourceAllFetchRelatedData) => ({
        dataSourceId,
        allFetchRequestRelatedData,
    }),
    (dataSourceId: TDataSourceId) => dataSourceId
)()

export const dataSourceSetOffsetAction = createAction(
    'data-source.SetOffset',
    (dataSourceId: TDataSourceId | null, offset: number) => ({
        dataSourceId,
        offset,
    }),
    (dataSourceId: TDataSourceId) => dataSourceId
)()

export const dataSourceResetValuePickerIdsAction = createAction('data-source.ResetValuePickerIds')<TDataSourceId>()
