import * as React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { closeModalAction, selectIsModalOpen } from '@planier/modal'
import { deleteEventsThunk } from '../../State/Thunks/EventDeletionThunks'
import { selectDataSourceItemsByIds, TDataSourceId } from '@planier/data-source'
import SubHeader from './SubHeader'
import { Translation } from '@planier/localization'
import { BoundThunk } from '@planier/generic-state'
import { DeletionConfirmationDialog } from '@planier/modal-deletion-confirmation'
import IEvent from '../../Types/IEvent'
import { EVENT_DELETION_CONFIRMATION_DIALOG_ID } from '../../Constants/EventDeletionConstants'

interface IStateProps {
    open: boolean
    events: IEvent[]
}

interface IDispatchProps {
    deleteEvents: BoundThunk<typeof deleteEventsThunk>
    closeModal: typeof closeModalAction
}

interface IOwnProps {
    dataSourceId: TDataSourceId
    onClose?: () => void
    eventIds: string[]
    getAreDeletionReasonsDisplayed?: (events: IEvent[]) => boolean
    resetSelectedEventsAfterSubmit: () => void
    deletionUrl?: string
    modalId?: string
}

interface IEventDeletionModalState {
    isLoading: boolean
}

export interface IEventDeletionModalProps extends IOwnProps, IStateProps, IDispatchProps {}

interface IDeletionFormikState {
    reason: string
    additionalInfo: string
}

class EventDeletionModal extends React.Component<IEventDeletionModalProps, IEventDeletionModalState> {
    state = {
        isLoading: false,
    }

    static defaultProps = {
        modalId: EVENT_DELETION_CONFIRMATION_DIALOG_ID,
    }

    private handleFormSubmission = async (formState: IDeletionFormikState) => {
        const { deleteEvents, dataSourceId, eventIds, resetSelectedEventsAfterSubmit, deletionUrl } = this.props

        this.setState({ isLoading: true })
        try {
            await deleteEvents(dataSourceId, eventIds, formState.reason, formState.additionalInfo, deletionUrl)
            resetSelectedEventsAfterSubmit()

            this.handleOnClose()
        } catch (e) {
            // TODO: shoud have some error handling here
        }
        this.setState({ isLoading: false })
    }

    handleOnClose = () => {
        const { onClose, closeModal, modalId } = this.props

        onClose && onClose()
        closeModal(modalId as string)
    }

    render(): React.ReactNode {
        const { open, events, getAreDeletionReasonsDisplayed, modalId } = this.props

        if (events.length === 0) {
            return null
        }

        const { isLoading } = this.state

        const isSingleEvent = events.length === 1

        const submitLabel = Translation.translateKey(
            isSingleEvent
                ? 'Palkkahallinto.DeletionModal.DeleteTapahtuma'
                : 'Palkkahallinto.DeletionModal.SubmitButtonLabel.DeleteSeveral'
        )

        const title = isSingleEvent
            ? 'Palkkahallinto.DeletionModal.DeleteTapahtuma'
            : 'Palkkahallinto.DeletionModal.Title.DeleteSeveral'

        const subtitle = <SubHeader events={events} />

        const areDeletionReasonsDisplayed = getAreDeletionReasonsDisplayed
            ? getAreDeletionReasonsDisplayed(events)
            : true

        return (
            <DeletionConfirmationDialog
                areDeletionReasonsDisplayed={areDeletionReasonsDisplayed}
                confirmButtonText={submitLabel}
                deletionReasonsUrl={'/Poistosyy/Identifier'}
                dialogId={
                    /* TODO: refactor to function component, typing default props doesn't work properly in
                       class components so need to do the typecast here */
                    modalId as string
                }
                isLoading={isLoading}
                isOpen={open}
                onClose={this.handleOnClose}
                onSubmit={this.handleFormSubmission}
                subtitle={subtitle}
                title={title}
            />
        )
    }
}

const mapStateToProps = (
    state: RootState,
    { dataSourceId, eventIds, modalId = EVENT_DELETION_CONFIRMATION_DIALOG_ID }: IOwnProps
): IStateProps => ({
    open: selectIsModalOpen(state, modalId as string),
    events: selectDataSourceItemsByIds(state, dataSourceId, eventIds) as IEvent[],
})

const mapDispatchToProps = {
    deleteEvents: deleteEventsThunk,
    closeModal: closeModalAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(EventDeletionModal)
