import * as React from 'react'
import { JSX } from 'react'
import styled from '@emotion/styled'
import { ReadOnlyText } from '@planier/generic-components'

import NodeDatetimeRow from './NodeDatetimeRow'

type Props = {
    backgroundColor: string
    endDatetime: string
    hoverColor: string
    label: string
    mainColor?: 'primary' | 'error'
    startDatetime: string
    rawColor?: string
}

const NodeContainer = styled.div<{ backgroundColor: string; hoverColor: string; mainColor?: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    height: 100%;
    border: 2px solid ${({ theme }) => theme.colors.neutralsWhite100};
    background-color: ${({ backgroundColor }) => backgroundColor};

    :hover,
    :focus {
        outline: none;
        background-color: ${({ hoverColor }) => hoverColor};
    }
`

const CenterTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5px;
`

const SpecialCalendarNode = ({
    backgroundColor,
    endDatetime,
    hoverColor,
    label,
    mainColor,
    rawColor,
    startDatetime,
}: Props): JSX.Element => (
    <NodeContainer
        backgroundColor={backgroundColor}
        hoverColor={hoverColor}
        mainColor={rawColor || mainColor}
        tabIndex={0}
    >
        <CenterTextContainer>
            <ReadOnlyText rawColor={rawColor} color={!rawColor ? mainColor : undefined} usage="event_block">
                {label ? label.toUpperCase() : ''}
            </ReadOnlyText>
            <NodeDatetimeRow color={rawColor || mainColor} endDatetime={endDatetime} startDatetime={startDatetime} />
        </CenterTextContainer>
    </NodeContainer>
)

export default SpecialCalendarNode
