import * as React from 'react'

import IListViewColumnModel from '../../interfaces/IListViewColumnModel'
import ColumnSettingsRow from './ColumnSettingsRow/ColumnSettingsRow'

interface IColumnManagementFormProps {
    columnsSettings: IListViewColumnModel[]
}

const ColumnManagementForm: React.FunctionComponent<IColumnManagementFormProps> = ({ columnsSettings }) => {
    return (
        <React.Fragment>
            {columnsSettings.map((column, index) => (
                <ColumnSettingsRow
                    columnSettings={column}
                    key={column.Id}
                    lastRow={index === columnsSettings.length - 1}
                />
            ))}
        </React.Fragment>
    )
}

export default ColumnManagementForm
