import { Form, Formik, FormikConfig, FormikProps, FormikValues } from 'formik'
import { Fragment } from 'react'

import { SubmitButtonWithValidationErrors } from '@planier/generic-components'
import ModalBase, { IModalBaseOwnProps } from './ModalBase'
import { isFunction } from '@planier/generic-utilities'

interface IRenderWithFormikProps {
    (props: FormikProps<FormikValues>): React.ReactNode
}

export interface IFormModalOwnProps
    extends Pick<
        IModalBaseOwnProps,
        | 'handleClose'
        | 'muiDialogProps'
        | 'title'
        | 'onClose'
        | 'classNameTitleContainer'
        | 'classNameContentContainer'
        | 'classNameSubtitleContainer'
        | 'classNameTitle'
    > {
    children: IRenderWithFormikProps | React.ReactNode
    disableSubmit?: boolean
    disableDefaultActionButtons?: boolean
    disableSubmitWhenInvalid?: boolean
    extraButtons?: React.ReactNode | IRenderWithFormikProps
    extraButtonsBeforeSubmitButton?: React.ReactNode | IRenderWithFormikProps
    formId: string
    formikProps: FormikConfig<FormikValues>
    modalId: string
    open: boolean
    submitLabel?: string
    className?: string
    subtitle?: IModalBaseOwnProps['subtitle'] | ((props: FormikProps<FormikValues>) => React.ReactElement)
    bottomAlert?: IModalBaseOwnProps['bottomAlert'] | ((props: FormikProps<FormikValues>) => React.ReactElement)
}

interface IFormModalProps extends IFormModalOwnProps {}

const muiDialogPropsDefaultValue = { fullWidth: true }

const FormModal: React.FC<IFormModalProps> = ({
    open,
    children,
    disableSubmit = false,
    disableDefaultActionButtons = false,
    disableSubmitWhenInvalid = false,
    extraButtons,
    extraButtonsBeforeSubmitButton,
    formId,
    formikProps,
    modalId,
    muiDialogProps = muiDialogPropsDefaultValue,
    submitLabel,
    title,
    subtitle,
    handleClose,
    onClose,
    className,
    classNameTitleContainer,
    classNameContentContainer,
    classNameSubtitleContainer,
    classNameTitle,
    bottomAlert,
}) => {
    if (!open) {
        return null
    }

    return (
        <Formik {...formikProps}>
            {(formikOptions) => {
                const isDisabled = () => {
                    if (disableSubmit) {
                        return true
                    }

                    if (disableSubmitWhenInvalid) {
                        return !formikOptions.dirty || !formikOptions.isValid
                    }

                    return false
                }

                const renderSubmitButton = (extraFormButtons?: React.ReactNode) => (
                    <Fragment>
                        {isFunction(extraButtonsBeforeSubmitButton)
                            ? extraButtonsBeforeSubmitButton(formikOptions)
                            : extraButtonsBeforeSubmitButton}
                        {extraFormButtons}
                        <SubmitButtonWithValidationErrors
                            disabled={isDisabled()}
                            errors={formikOptions.errors}
                            form={formId}
                        >
                            {submitLabel}
                        </SubmitButtonWithValidationErrors>
                    </Fragment>
                )

                return (
                    <ModalBase
                        disableDefaultActionButtons={disableDefaultActionButtons}
                        actionButtons={
                            isFunction(extraButtons)
                                ? renderSubmitButton(extraButtons(formikOptions))
                                : renderSubmitButton(extraButtons)
                        }
                        bottomAlert={isFunction(bottomAlert) ? bottomAlert(formikOptions) : bottomAlert}
                        className={className}
                        classNameContentContainer={classNameContentContainer}
                        classNameSubtitleContainer={classNameSubtitleContainer}
                        classNameTitle={classNameTitle}
                        classNameTitleContainer={classNameTitleContainer}
                        handleClose={handleClose}
                        modalId={modalId}
                        muiDialogProps={muiDialogProps}
                        onClose={onClose}
                        subtitle={isFunction(subtitle) ? subtitle(formikOptions) : subtitle}
                        title={title}
                    >
                        <Form id={formId}>{isFunction(children) ? children(formikOptions) : children}</Form>
                    </ModalBase>
                )
            }}
        </Formik>
    )
}

FormModal.displayName = 'FormModal'

export default FormModal
