import { JSX } from 'react'
import styled from '@emotion/styled'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'

const LoadingIndicatorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 472px;
    flex: 1;
    z-index: 9999;
    position: absolute;
    top: 0;
`

const CenteredLoadingIndicator = (): JSX.Element => {
    return (
        <LoadingIndicatorContainer>
            <LoadingIndicatorInline isLoading />
        </LoadingIndicatorContainer>
    )
}

export default CenteredLoadingIndicator
