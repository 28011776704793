import * as React from 'react'

import { getLogger } from '@planier/log'
const Log = getLogger('configurable-list.ConfigurableListComponentsRegistry')

interface IConfigurableListRegistryComponents {
    [componentId: string]: React.ComponentType<unknown> | undefined
}

interface IConfigurableListComponentRegistry {
    getComponent: (componentId: string) => React.ComponentType<unknown> | null
}

const createRegistryState = (
    components: null | IConfigurableListRegistryComponents
): IConfigurableListComponentRegistry => {
    const getComponent = (componentId: string) => {
        if (!components) {
            Log.error('List components not registered')
            return null
        }

        const Component = components[componentId]

        if (!Component) {
            Log.error(`No list component with ID '${componentId}'`)
            return null
        }

        return Component
    }
    return { getComponent }
}

const emptyRegistry = createRegistryState(null)

const ConfigurableListComponentRegistry = React.createContext<IConfigurableListComponentRegistry>(emptyRegistry)

export default ConfigurableListComponentRegistry

interface IConfigurableListComponentRegistryProviderProps {
    readonly components: IConfigurableListRegistryComponents
}

export const ConfigurableListComponentRegistryProvider: React.FunctionComponent<IConfigurableListComponentRegistryProviderProps> = ({
    children,
    components,
}) => {
    const registry = createRegistryState(components)

    return (
        <ConfigurableListComponentRegistry.Provider value={registry}>
            {children}
        </ConfigurableListComponentRegistry.Provider>
    )
}
