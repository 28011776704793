import * as React from 'react'
import { Grid } from '@material-ui/core'
import moment, { Moment, MomentInput } from 'moment'
import styled from '@emotion/styled'

import TranslatedTypography from '../../TranslatedTypography'
import TimeLength from '../TimeLength'
import Checkbox from '../Checkbox'
import TimeInput from 'components/molecules/TimeInput'
import DateUtility from 'common/DateUtility'

const minutesInDay = 60 * 24

const StyledCheckbox = styled(Checkbox)`
    width: auto;
    padding: 0;
`

const GridWithMargin = styled(Grid)`
    margin-right: 65px;
`

const Over24HoursContainer = styled.div`
    margin-left: -8px;
`

const StyledContainerGrid = styled(Grid)`
    align-items: baseline;
`

export interface IValue {
    startTime: Moment | string | null
    endTime: Moment | string | null
    timeLength: number
    isOver24Hours: boolean
}

type TTimeInputProps = React.ComponentProps<typeof TimeInput>

export interface ITimeRangeInputProps extends Pick<TTimeInputProps, 'isSpaceAlwaysReservedForError'> {
    value: IValue
    onBlur?: ({ field }: { field: string }) => void
    handleChange: (value: IValue) => void
    disabled: boolean
    validationErrorEndTime?: string | null
    validationErrorStartTime?: string | null
}

const ensureSecondsAreZeroIfMoment = (value: string | Moment | null) => {
    return DateUtility.isMomentAsTime(value) ? moment(value as Moment).seconds(0) : value
}

/**
 * TODO: At the moment always assumes that the date of the start and end time
 * is in the current day. If they're not, things can get messy when the values
 * are changed here, because we need to be able to assume the end time is always
 * before the first time. We could fix this for example by setting the date of the
 * values to current here every time the props change (and use these edited values instead
 * of the direct prop values), but for now you might want to use for example DateUtility's
 * setDateToCurrent method when setting the values initially.
 */
const TimeRangeInput: React.FunctionComponent<ITimeRangeInputProps> = ({
    onBlur,
    handleChange,
    value,
    value: { startTime, endTime, timeLength, isOver24Hours },
    disabled,
    validationErrorEndTime = null,
    validationErrorStartTime = null,
    isSpaceAlwaysReservedForError,
}) => {
    /**
     * Gives the difference of the given time values in minutes. Takes into account:
     *   1. whether the start time is after the end time (i.e. the end time is supposed
     *      to be in the day after the start time)
     *      - if yes, then adds a day to the end time
     *   2. whether the isOver24Hours selection is true
     *      - if yes, adds (potentially another) day to the end time if the difference
     *        is not already over 24 hours.
     */
    const minuteDifference = (newStartTime: Moment, newEndTime: Moment) => {
        const isAfter = newStartTime.isAfter(newEndTime)

        let endTimeEnsuredToBeSameOrAfterStartTime = isAfter ? moment(newEndTime).add(1, 'days') : moment(newEndTime)

        const added24HoursAlready = moment(endTimeEnsuredToBeSameOrAfterStartTime).diff(newStartTime, 'hours') > 24

        if (isOver24Hours && !added24HoursAlready) {
            endTimeEnsuredToBeSameOrAfterStartTime = endTimeEnsuredToBeSameOrAfterStartTime.add(1, 'days')
        } else if (!isOver24Hours && added24HoursAlready) {
            const daysDiff = moment(endTimeEnsuredToBeSameOrAfterStartTime).diff(newStartTime, 'days')
            endTimeEnsuredToBeSameOrAfterStartTime = endTimeEnsuredToBeSameOrAfterStartTime.subtract(daysDiff, 'days')
        }

        const minuteDiff = moment(endTimeEnsuredToBeSameOrAfterStartTime).diff(newStartTime, 'minutes')

        return minuteDiff
    }

    /**
     * Handles change for start time. When the start time changes, if the end time is set,
     * adjusts the length.
     * When adjusting the length, takes into account:
     *
     *   1. whether the end the time is before or after the start time
     *      - if the end time is before, adds a day to the end time
     *   2. whether the isOver24Hours selection is true or false
     *      - if it's true, adds a day to the end time
     */
    const handleAlkuaikaSelection = (newStartTime: Moment | null) => {
        const newStartTimeWithSecondsZeroed = ensureSecondsAreZeroIfMoment(newStartTime)

        const adjustTimeLength =
            DateUtility.isMomentAsTime(newStartTimeWithSecondsZeroed) && DateUtility.isMomentAsTime(endTime)
        const resetTimeLength =
            !DateUtility.isMomentAsTime(newStartTimeWithSecondsZeroed) || !DateUtility.isMomentAsTime(endTime)

        let newTimeLength = timeLength
        let newEndTime = endTime

        if (resetTimeLength) {
            newTimeLength = 0
        } else if (adjustTimeLength) {
            newTimeLength = minuteDifference(
                newStartTimeWithSecondsZeroed as Moment,
                ensureSecondsAreZeroIfMoment(endTime) as Moment
            )
            newEndTime = moment(newStartTimeWithSecondsZeroed as Moment).add(newTimeLength, 'minutes')

            // newTimeLength will never be zero, if it is zero it means the user want event/ shift for 24 hrs
            if (newTimeLength === 0) {
                newTimeLength = minutesInDay
                newEndTime = moment(newStartTimeWithSecondsZeroed as Moment).add(1, 'days')
            }
        }

        handleChange({
            ...value,
            startTime: newStartTimeWithSecondsZeroed,
            timeLength: newTimeLength,
            endTime: newEndTime,
        })
    }

    /**
     * Handles change for end time. When the end time changes, if the start time is set,
     * adjusts the length.
     * When adjusting the length, takes into account:
     *
     *   1. whether the end the time is before or after the start time
     *      - if the end time is before, adds a day to the end time
     *   2. whether the isOver24Hours selection is true or false
     *      - if it's true, adds a day to the end time
     */
    const handleEndTimeSelect = (newEndTime: Moment | null) => {
        let newEndTimeWithSecondsZeroed = ensureSecondsAreZeroIfMoment(newEndTime)

        const adjustTimeLength =
            DateUtility.isMomentAsTime(startTime) && DateUtility.isMomentAsTime(newEndTimeWithSecondsZeroed)
        const resetTimeLength =
            !DateUtility.isMomentAsTime(startTime) || !DateUtility.isMomentAsTime(newEndTimeWithSecondsZeroed)

        let newTimeLength = timeLength

        if (resetTimeLength) {
            newTimeLength = 0
        } else if (adjustTimeLength) {
            newTimeLength = minuteDifference(
                ensureSecondsAreZeroIfMoment(startTime) as Moment,
                newEndTimeWithSecondsZeroed as Moment
            )
            newEndTimeWithSecondsZeroed = moment(startTime as Moment).add(newTimeLength, 'minutes')

            if (newTimeLength === 0) {
                newTimeLength = minutesInDay
                newEndTimeWithSecondsZeroed = moment(startTime as Moment).add(1, 'days')
            }
        }

        handleChange({
            ...value,
            endTime: newEndTimeWithSecondsZeroed,
            timeLength: newTimeLength,
        })
    }

    /**
     * Handles change for the length. When the length changes, if the start time is set,
     * adjusts the end time (regardless of whether it's set).
     * If the length is less than 24 hours, sets the isOver24Hours to false.
     */
    const handleTimeLengthChange = (newTimeLength: number) => {
        const adjustEndTime = DateUtility.isMomentAsTime(startTime)

        const newEndTime = adjustEndTime
            ? moment(startTime as Moment)
                  .seconds(0)
                  .add(newTimeLength, 'minutes')
            : endTime

        const timeLengthOver24Hours = newTimeLength / 60 > 24
        const newIsOver24Hours = timeLengthOver24Hours

        handleChange({
            ...value,
            endTime: newEndTime,
            timeLength: newTimeLength,
            isOver24Hours: newIsOver24Hours,
        })
    }

    /**
     * Handles change for the isOver24Hours selection. When the selection changes,
     * if both start time and end time are set, changes length as necessary:
     *   1. if isOver24Hours is true
     *      - increases the length by a day
     *   2. if isOver24Hours is false and length is over 24 hours
     *      - decreases the length by a day or multiple days if
     *        the current length is over 48 hours, because at that point
     *        simply decreasing it would still keep it above 24 hours.
     */
    const handleOver24HoursChange = (newIsOver24Hours: boolean) => {
        const startTimeWithSecondsZeroed = ensureSecondsAreZeroIfMoment(startTime)
        const adjustTimeLength =
            DateUtility.isMomentAsTime(startTimeWithSecondsZeroed) && DateUtility.isMomentAsTime(endTime)

        let newTimeLength = timeLength
        let newEndTime = ensureSecondsAreZeroIfMoment(endTime)

        if (adjustTimeLength) {
            const timeLengthIsCurrentlyOver24Hours = timeLength / 60 >= 24
            const timeLengthIsCurrentlyOver48Hours = timeLength / 60 >= 48

            if (!newIsOver24Hours && timeLengthIsCurrentlyOver48Hours) {
                // we can't simply set the newTimeLength to 24 hours here as that would also cause the end time
                // to change (and we don't want that) so we basically calculate the extra hours going over 24 hours
                // and then subtract those from the current time length. So for example if the timeLength currently is 49,
                // there are 2 whole days, i.e. 48 extra hours, so we get 1 hours as the result.
                const currentTimeLengthAsHours = timeLength / 60
                const extraHours = currentTimeLengthAsHours - (currentTimeLengthAsHours % 24)
                newTimeLength = timeLength - extraHours * 60
            } else if (!newIsOver24Hours && timeLengthIsCurrentlyOver24Hours) {
                newTimeLength = timeLength - 24 * 60
            } else if (newIsOver24Hours) {
                newTimeLength = timeLength + 24 * 60
            }

            newEndTime = moment(startTimeWithSecondsZeroed as Moment)
                .seconds(0)
                .add(newTimeLength, 'minutes')

            if (newTimeLength === 0) {
                newTimeLength = minutesInDay
                newEndTime = moment(startTimeWithSecondsZeroed as Moment).add(1, 'days')
            }
        }

        handleChange({
            ...value,
            endTime: newEndTime,
            timeLength: newTimeLength,
            isOver24Hours: newIsOver24Hours,
        })
    }

    const handleBlur = (fieldName: keyof IValue) => {
        if (onBlur) {
            onBlur({ field: fieldName })
        }
    }

    const handleStartTimeBlur = () => {
        handleBlur('startTime')
    }

    const handleEndTimeBlur = () => {
        handleBlur('endTime')
    }

    const handleTimeLengthBlur = () => {
        handleBlur('timeLength')
    }

    const timeLengthDisabled = !DateUtility.isMomentAsTime(startTime) && !DateUtility.isMomentAsTime(endTime)
    const over24HoursDisabled = !DateUtility.isMomentAsTime(startTime)

    return (
        <StyledContainerGrid container>
            <GridWithMargin item>
                <TimeInput
                    changeAction={handleAlkuaikaSelection}
                    description="alkuaika"
                    disabled={disabled}
                    isSpaceAlwaysReservedForError={isSpaceAlwaysReservedForError}
                    onBlur={handleStartTimeBlur}
                    rawTimeValue={startTime as MomentInput}
                    validationError={validationErrorStartTime}
                />
            </GridWithMargin>
            <GridWithMargin item>
                <TimeInput
                    changeAction={handleEndTimeSelect}
                    description="loppuaika"
                    disabled={disabled}
                    isSpaceAlwaysReservedForError={isSpaceAlwaysReservedForError}
                    onBlur={handleEndTimeBlur}
                    rawTimeValue={endTime as MomentInput}
                    validationError={validationErrorEndTime}
                />
                <Over24HoursContainer>
                    <StyledCheckbox
                        disabled={disabled || over24HoursDisabled}
                        onClick={handleOver24HoursChange}
                        value={isOver24Hours}
                    />
                    <TranslatedTypography variant="caption">Tapahtuma.TapahtumaOver24h</TranslatedTypography>
                </Over24HoursContainer>
            </GridWithMargin>
            <Grid item>
                <TimeLength
                    disabled={disabled || timeLengthDisabled}
                    handleChange={handleTimeLengthChange}
                    onBlur={handleTimeLengthBlur}
                    valueInMinutes={timeLength}
                />
            </Grid>
        </StyledContainerGrid>
    )
}

TimeRangeInput.defaultProps = {
    disabled: false,
}

export default TimeRangeInput
