import styled from '@emotion/styled'
import { JSX } from 'react'
import Tooltip from './Tooltip/Tooltip'
import { EUserType, User } from '@planier/user'
import {
    getPrimaryEventColor,
    getSecondaryEventColor,
    PlanierTheme,
    selectableColorIds,
    useAppTheme,
} from '@planier/theme'

type Props = {
    user: User | null
}

const getColorId = (userId: number) => {
    const offset = userId % 6

    return selectableColorIds[offset]
}

const getBadgeColors = (user: User | null, theme: PlanierTheme) => {
    if (!user) {
        return { backgroundColor: undefined, textColor: undefined, borderColor: undefined }
    }

    const colorId = getColorId(user.Id)
    const isAdmin = user.Type === EUserType.Admin

    const getBackgroundColor = () => {
        if (isAdmin) {
            return theme.colors.primaryTeal11
        }

        if (user.IsExternal) {
            return theme.eventTemplates.secondary.event3
        }

        return getPrimaryEventColor(colorId)
    }

    const getTextColor = () => {
        if (isAdmin || user.IsExternal) {
            return theme.componentExtensions.text.primary
        }

        return getSecondaryEventColor(colorId)
    }

    const getBorderColor = () => {
        if (isAdmin) {
            return theme.colors.primaryTeal100
        }

        if (user.IsExternal) {
            return theme.eventTemplates.preview.event3
        }

        return undefined
    }

    return { backgroundColor: getBackgroundColor(), textColor: getTextColor(), borderColor: getBorderColor() }
}

const getInitials = (name: string | null | undefined) => {
    if (!name || name === '' || name.length === 0) {
        return ''
    }

    const nameParts = name.split(/(\s+)/).filter((e) => {
        return e.trim().length > 0
    })

    if (nameParts.length > 1) {
        return `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`
    } else if (nameParts.length === 1) {
        return nameParts[0][0].toUpperCase()
    } else {
        return name[0].toUpperCase()
    }
}

const StyledBadge = styled.div<{
    backgroundColor?: string
    textColor?: string
    borderColor?: string
}>`
    display: flex;
    height: 24px;
    max-width: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    ${({ borderColor }) =>
        borderColor
            ? `
        border: 2px solid ${borderColor};
    `
            : null}
    background-color: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.primaryTeal60};
    color: ${({ textColor, theme }) => textColor ?? theme.colors.primaryTeal120};
`

const BadgeText = styled.span`
    font-family: Inter;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
`

const UserBadge = ({ user }: Props): JSX.Element => {
    const theme = useAppTheme()
    const { backgroundColor, textColor, borderColor } = getBadgeColors(user, theme)

    return (
        <Tooltip title={user?.Name}>
            <StyledBadge backgroundColor={backgroundColor} textColor={textColor} borderColor={borderColor}>
                <BadgeText>{getInitials(user?.Name)}</BadgeText>
            </StyledBadge>
        </Tooltip>
    )
}

export default UserBadge
