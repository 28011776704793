import * as React from 'react'
import styled from '@emotion/styled'

import Styles from 'constants/Styles'
import { ListElementText } from '@planier/configurable-list-components'

export interface ITapahtumatyyppiProps {
    name: string
    colour?: string | null
}

const Tag = styled.div`
    max-width: inherit;
    width: inherit;
    display: inline-flex;
    justify-content: center;
    background-color: ${({ colour }: { colour: string }) => colour};
    border-radius: 3px;
    padding: 6px;
`

const WhiteText = styled(ListElementText)`
    color: ${Styles.mainColor.white};
    font-weight: bold;
`

/**
 * Displays Tapahtumatyyppi as text. If a colour is supplied, a surrounding rectangle
 * with the given colour as background colour is rendered.
 */
const Tapahtumatyyppi: React.FunctionComponent<ITapahtumatyyppiProps> = ({ colour, name }) => {
    if (!colour) {
        return <ListElementText displayValue={name} />
    }

    return (
        <Tag colour={colour}>
            <WhiteText displayValue={name} />
        </Tag>
    )
}

export default Tapahtumatyyppi
