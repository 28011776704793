import Cookies from 'js-cookie'
import { getRefreshToken } from '../WebApi/JwtWebApi'
import JwtData from '../Types/IJwtData'
import { parseJwt } from '../Utilities/JwtUtilities'
import { getLogger } from '@planier/log'
const Log = getLogger('authentication.JwtState')

const jwtState: JwtData = {
    token: null,
    expiresAt: null,
}

let tokenRefreshTimeoutId: null | number = null

const refreshToken = async () => {
    const jwt = await getRefreshToken()

    if (jwt) {
        setJwt(jwt)
    } else {
        deleteJwt()
    }
}

const makeTokenToRefreshBeforeExpire = (expiresAt: number) => {
    const expiresAtInMilliseconds = expiresAt * 1000
    const expiresIn = expiresAtInMilliseconds - Date.now()

    const minuteLessThanExpiry = expiresIn - 60000

    tokenRefreshTimeoutId = window.setTimeout(() => {
        refreshToken()
    }, minuteLessThanExpiry)
}

export const setJwt = (token: string): void => {
    jwtState.token = token
    const { exp } = parseJwt(token) ?? {}

    if (!exp) {
        Log.error("Couldn't parse data from token")
        return
    }

    jwtState.expiresAt = exp

    localStorage.setItem('PAuthToken', token)

    if (tokenRefreshTimeoutId !== null) {
        clearTimeout(tokenRefreshTimeoutId)
    }
    makeTokenToRefreshBeforeExpire(exp)
}

export const getJwt = (): string | null => {
    let cookieJwt = Cookies.get('AuthToken')

    //Remove this
    const oldCookieJwt = Cookies.get('PAuthToken')
    const storageJwt = localStorage.getItem('PAuthToken')

    if (!cookieJwt && oldCookieJwt) {
        cookieJwt = oldCookieJwt
    }

    if (cookieJwt && storageJwt) {
        localStorage.removeItem('PAuthToken')
    }

    return cookieJwt || storageJwt || null
}

export const deleteJwt = (): void => {
    jwtState.token = null
    jwtState.expiresAt = null

    localStorage.removeItem('PAuthToken')
    Cookies.remove('PAuthToken')
    if (tokenRefreshTimeoutId !== null) {
        clearTimeout(tokenRefreshTimeoutId)
    }
}
