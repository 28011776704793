import { FieldProps } from 'formik'
import * as React from 'react'

import HoursInput from './HoursInput'
import ErrorableField from '../../Form/ErrorableField'

type THoursInputOwnProps = React.ComponentProps<typeof HoursInput>

export interface IFormikHoursInputProps extends FieldProps, Omit<THoursInputOwnProps, 'valueInMinutes' | 'onChange'> {}

const FormikHoursInput: React.FunctionComponent<IFormikHoursInputProps> = ({ field: { value, ...field }, form }) => {
    const handleChange = (newValueInMinutes: number) => {
        form.setFieldValue(field.name, newValueInMinutes)
    }

    const handleBlur = () => {
        const isTouched = true
        form.setFieldTouched(field.name, isTouched, form.validateOnBlur)
    }

    // TODO: should display the validation error separately for each
    // Also the blur should happen only when both of the fields have been touched
    return (
        <ErrorableField field={field} form={form}>
            <HoursInput onBlur={handleBlur} onChange={handleChange} valueInMinutes={value} />
        </ErrorableField>
    )
}

export default FormikHoursInput
