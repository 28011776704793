import * as React from 'react'
import { JSX, useCallback, useEffect, useRef, useState } from 'react'

import { IDataSourceItem } from '@planier/data-source-types'
import IListViewColumnModel from '../../interfaces/IListViewColumnModel'
import Row from './Row'
import { TListIconData } from '../../interfaces/IList'
import IListViewAction from '../../interfaces/IListViewAction'
import { IListViewIconAction } from './../../interfaces/TDisplayAction'
import TFunctionalityAction from '../../interfaces/TFunctionalityAction'
import { calculateRowWidth } from '../../Utilities/RowWidthCalculations'
import { IDataSourceAction, TDataSourceId } from '@planier/data-source'
import { TableVirtuoso } from 'react-virtuoso'
import { isEmpty } from 'lodash-es'
import RowHeaders from '../RowHeaders/RowHeaders'
import { useOnWindowResize } from '@planier/generic-utilities'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { selectFiltersHeight } from '../../State/ConfigurableListSelectors'
import { ROW_HEIGHT } from '../../Constants/RowConstants'
import { BOTTOM_GAP, MODAL_BOTTOM_GAP } from '../../Constants/ListConstants'
import ListEndReachedText from '../ListContent/ListEndReachedText'
import { selectOpenModals } from '@planier/modal'

type Props = {
    dataItemIds: (string | number)[]
    massSelectable: boolean
    metadataForColumns: IListViewColumnModel[]
    onRowSelect?: (item?: IDataSourceItem) => void
    iconData: TListIconData | undefined
    listId: string
    rowActions: IListViewAction<IListViewIconAction, TFunctionalityAction>[]
    rowsAreSelectable: boolean
    actionConfigurations: IDataSourceAction[]
    dataSourceId: TDataSourceId | null
    loadMoreData: () => Promise<void>
    isLoading: boolean
    isLoadingMore: boolean
    displayListEndReachedText: boolean
    itemsCount: number
}

const FooterContainer = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
`

const Footer = ({
    isLoading,
    displayListEndReachedText,
    itemsCount,
}: {
    isLoading: boolean
    displayListEndReachedText: boolean
    itemsCount: number
}): JSX.Element => {
    const content =
        !isLoading && displayListEndReachedText ? (
            <ListEndReachedText
                allItemsCount={itemsCount}
                isLoading={isLoading}
                maxResultsDisplayed={displayListEndReachedText}
            />
        ) : (
            <LoadingIndicatorInline isLoading={isLoading} />
        )

    return <FooterContainer>{content}</FooterContainer>
}

const VirtualizedRows = ({
    dataItemIds,
    metadataForColumns,
    massSelectable,
    rowActions,
    iconData,
    listId,
    onRowSelect,
    rowsAreSelectable,
    actionConfigurations,
    dataSourceId,
    loadMoreData,
    isLoading,
    isLoadingMore,
    displayListEndReachedText,
    itemsCount,
}: Props): JSX.Element => {
    const { current: originalRowWidth } = useRef(calculateRowWidth(metadataForColumns, rowActions, massSelectable))

    const openModals = useSelector(selectOpenModals)
    const isInsideModal = [...openModals.values()].some((modal) => modal.props?.listId === listId)

    const [rowWidth, setRowWidth] = useState(originalRowWidth)

    const ensureRowWidthIsAtLeastSameAsWindowWidth = useCallback(() => {
        const windowWidth = document.documentElement.offsetWidth
        const rowFitsWindow = windowWidth >= rowWidth

        if (rowFitsWindow && windowWidth !== rowWidth) {
            setRowWidth(windowWidth)
        } else if (!rowFitsWindow && originalRowWidth !== rowWidth) {
            setRowWidth(originalRowWidth)
        }
        /*
        Prevent the callback from being recreated every time the rowWidth changes. Currently
        every time the window is resized, we call this function. If the function was also recreated
        as the result of that, it would also cause the useEffect hook to be run. That would cause
        a rather weird effect when resizing the browser, as it could first set the rowWidth to equal
        the original width, and then right after it would run the useEffect and set the rowWidth to
        equal the window width.
        */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(ensureRowWidthIsAtLeastSameAsWindowWidth, [ensureRowWidthIsAtLeastSameAsWindowWidth])
    useOnWindowResize(ensureRowWidthIsAtLeastSameAsWindowWidth)

    const filtersHeight = useSelector((state: RootState) => selectFiltersHeight(state, listId))
    const [height, setHeight] = useState<string | number>('60vh')

    useEffect(() => {
        if (isEmpty(dataItemIds) || isLoading) {
            return
        }

        const bottomGap = !isInsideModal ? BOTTOM_GAP : MODAL_BOTTOM_GAP

        // setting the list height needs to be throttled to avoid a ResizeObserver bug
        setTimeout(() => {
            setHeight(`calc(100vh - ${filtersHeight + bottomGap}px)`)
        }, 0)
    }, [dataItemIds, filtersHeight, isInsideModal, isLoading])

    const [isScrolling, setIsScrolling] = useState<boolean>(false)

    const renderRow = useCallback(
        (index, item) => {
            return (
                <Row
                    actionConfigurations={actionConfigurations}
                    dataSourceId={dataSourceId}
                    firstRow={index === 0}
                    iconData={iconData}
                    isSelectable={rowsAreSelectable}
                    itemId={item}
                    key={item}
                    listId={listId}
                    massSelectable={massSelectable}
                    metadataForColumns={metadataForColumns}
                    onRowSelect={onRowSelect}
                    rowActions={rowActions}
                    isScrolling={isScrolling}
                    style={{ minWidth: rowWidth }}
                />
            )
        },
        [
            actionConfigurations,
            dataSourceId,
            iconData,
            isScrolling,
            listId,
            massSelectable,
            metadataForColumns,
            onRowSelect,
            rowActions,
            rowWidth,
            rowsAreSelectable,
        ]
    )

    return (
        <>
            <TableVirtuoso
                style={{ height }}
                data={dataItemIds}
                overscan={{ main: 400, reverse: 400 }}
                defaultItemHeight={ROW_HEIGHT}
                endReached={loadMoreData}
                isScrolling={setIsScrolling}
                fixedHeaderContent={() =>
                    !isEmpty(dataItemIds) && <RowHeaders listId={listId} isLoadingInitialData={false} />
                }
                itemContent={renderRow}
            />
            <Footer
                isLoading={isLoadingMore}
                displayListEndReachedText={displayListEndReachedText}
                itemsCount={itemsCount}
            />
        </>
    )
}

export default VirtualizedRows
