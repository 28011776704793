import { useDispatch, useSelector } from 'react-redux'
import * as React from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'

import ColumnManagementModalButton from './ColumnManagementModalButton'
import EConfigurableListModal from '../../Types/EConfigurableListModal'
import {
    selectColumnSortSettings,
    selectIsColumnManagementAllowed,
    selectIsListSelectable,
    selectListConfigurationForColumns,
} from '../../State/ConfigurableListSelectors'
import MassSelectionCheckbox from './MassSelectionCheckbox'
import { openModalAction } from '@planier/modal'
import RowHeaderElement from './RowHeaderElement'
import Styles from 'constants/Styles'
import { ROW_HEIGHT } from '../../Constants/RowConstants'

type IOwnProps = {
    isLoadingInitialData: boolean
    listId: string
}

const Container = styled.div`
    height: ${ROW_HEIGHT}px;
    padding-left: 2rem;
    display: inline-flex;
    top: 0;
    min-width: 100%;
    background-color: ${Styles.mainColor.white};
    z-index: 1;
`

const CheckboxContainer = styled.div`
    margin-right: 24px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
`
const CheckboxContainerOuter = styled.div`
    margin-left: -10px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
`

const RowHeadersContainer = styled.div`
    display: flex;
    flex: 1 1 100%;
`

/**
 * Renderöi listan sarakkeiden otsikot.
 */
const RowHeaders = ({ isLoadingInitialData, listId }: IOwnProps): JSX.Element => {
    const dispatch = useDispatch()

    const sortBy = useSelector((state: RootState) => selectColumnSortSettings(state, listId))
    const columnManagementAllowed = useSelector((state: RootState) => selectIsColumnManagementAllowed(state, listId))
    const columnMetadata = useSelector((state: RootState) => selectListConfigurationForColumns(state, listId))
    const massSelectable = useSelector((state: RootState) => selectIsListSelectable(state, listId))

    const handleColumnManagementButtonClick = (): void => {
        dispatch(openModalAction(`${EConfigurableListModal.ColumnManagement}-${listId}`))
    }

    const columnsRendered = columnMetadata.map((column) => {
        const { Id, Header, HeaderComponent, Visible, Width, SortByParameter, Tooltip } = column
        const sortOrderForColumn = sortBy.get(SortByParameter)

        if (!Visible) {
            return null
        }

        return (
            <RowHeaderElement
                headerComponent={HeaderComponent}
                key={Id}
                listId={listId}
                sortByParameter={SortByParameter}
                sortBySettings={sortBy}
                sortOrder={sortOrderForColumn}
                text={Header}
                tooltip={Tooltip}
                width={Width}
            />
        )
    })

    return (
        <Container>
            {massSelectable && (
                <CheckboxContainerOuter>
                    <CheckboxContainer>
                        <MassSelectionCheckbox isLoading={isLoadingInitialData} listId={listId} />
                    </CheckboxContainer>
                </CheckboxContainerOuter>
            )}

            <RowHeadersContainer>{columnsRendered}</RowHeadersContainer>

            {columnManagementAllowed && <ColumnManagementModalButton onClick={handleColumnManagementButtonClick} />}
        </Container>
    )
}

export default RowHeaders
