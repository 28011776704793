import MockAdapter from 'axios-mock-adapter/types'

import { WebApiMock } from '@planier/test'
import { getTestDataSourceConfiguration, getTestDataSourceId, getTestDataSourceData } from './DataSourceTestData'
import { IListViewDataContainer } from '@planier/data-source-types'
import { AxiosRequestConfig } from 'axios'

export default class DataSourceWebApiMock extends WebApiMock {
    constructor(axiosMock?: MockAdapter) {
        super(axiosMock)
    }

    mockDataSourceConfigurationFetchRequest(configuration = getTestDataSourceConfiguration(), statusCode = 200): void {
        this.axiosMock.onGet(`/ViewEngine/DataSource?id=${configuration.Id}`).reply(statusCode, configuration)
    }

    getHistoryForDataSourceConfigurationFetchRequest(dataSourceId = getTestDataSourceId()): AxiosRequestConfig | null {
        return this.getHistoryForRequest(`/ViewEngine/DataSource?id=${dataSourceId}`, 'get')
    }

    mockDataSourceDataFetchRequest(
        url = getTestDataSourceConfiguration().Url,
        data: IListViewDataContainer<any> = getTestDataSourceData(),
        statusCode = 200
    ): void {
        this.axiosMock.onPost(url).reply(statusCode, data)
    }

    getHistoryForDataSourceDataFetchRequest(dataUrl = getTestDataSourceConfiguration().Url): AxiosRequestConfig | null {
        return this.getHistoryForRequest(dataUrl, 'post')
    }

    mockDataSourceDataToExcelRequest(dataSourceId = getTestDataSourceId(), statusCode = 200): void {
        this.axiosMock.onPost(`/ViewEngine/DataSource/${dataSourceId}/Excel`).reply(statusCode, new Blob())
    }

    getHistoryForDataSourceDataToExcelRequest(dataSourceId = getTestDataSourceId()): AxiosRequestConfig | null {
        return this.getHistoryForRequest(`/ViewEngine/DataSource/${dataSourceId}/Excel`, 'post')
    }
}
