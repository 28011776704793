import * as React from 'react'
import styled from '@emotion/styled'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import Styles from 'constants/Styles'
import { INavigationSubsection } from '@planier/navigation'
import { sortBy } from 'lodash-es'
import { getTestId } from '@planier/test'

const StyledPaper = styled(Paper)`
    margin-bottom: 0.6rem;

    & .Mui-selected {
        color: ${Styles.planierColor.blueTurquoise.textColor};
    }
`

interface ISubPageNavigationTabs {
    subPagesForCurrentMainPage: INavigationSubsection[]
    currentSubPageId: null | number
}

const SubPageNavigationTabs: React.FC<ISubPageNavigationTabs> = ({ subPagesForCurrentMainPage, currentSubPageId }) => {
    const pagesOrdered = sortBy(subPagesForCurrentMainPage, (navItem) => navItem.OrderNumber)
    const createTestId = (linkName: string) => {
        return getTestId(['subpage', 'nav'], linkName)
    }

    return (
        <StyledPaper>
            <Tabs indicatorColor="primary" textColor="primary" value={currentSubPageId} variant="scrollable">
                {pagesOrdered.map(({ Url, Id, Translation: label, Name }) => (
                    <Tab component="a" href={Url} key={Id} label={label} value={Id} data-testid={createTestId(Name)} />
                ))}
            </Tabs>
        </StyledPaper>
    )
}

export default SubPageNavigationTabs
