import { createAction } from 'typesafe-actions'

import IListViewModel, { IListViewReport } from '../interfaces/IListViewModel'
import { TDataSourceItemId } from '@planier/data-source-types'
import IListViewUserSettingsForColumns from '../interfaces/IListViewUserSettingsForColumns'

type TListId = string
export const configurableListInitialiseAction = createAction('ConfigurableListInitialise')<TListId>()

export const configurableListSetConfigurationAction = createAction('ConfigurableListSetConfiguration')<
    IListViewModel,
    TListId
>()

export const reportSelectedForEditAction = createAction('reportSelectedForEditAction')<IListViewReport, TListId>()

export const listViewReportsFetchedAction = createAction('listViewReportsFetched')<IListViewReport[], TListId>()

export const toggleRowItemSelectionAction = createAction(
    'ToggledRowItemSelection',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (itemSelected: boolean, rowItemId: TDataSourceItemId, listId: string) => ({
        itemSelected,
        rowItemId,
    }),
    (itemSelected: boolean, rowItemId: TDataSourceItemId, listId: string) => listId
)()

export const toggleRowItemMassSelectionAction = createAction(
    'ToggledRowItemMassSelection',
    (selected: boolean, listId: string, orderedItemIds: TDataSourceItemId[]) => ({
        selected,
        orderedItemIds,
    }),
    (selected: boolean, listId: string) => listId
)()

export const toggleGroupSimilarRowsAction = createAction(
    'ToggledRowItemShowGrouped',
    (opened: boolean, rowItemId: TDataSourceItemId, listId: string, childIds?: TDataSourceItemId[]) => ({
        opened,
        rowItemId,
        listId,
        childIds,
    }),
    (opened: boolean, rowItemId: TDataSourceItemId, listId: string) => listId
)()

export const setSelectedItemsAction = createAction('ConfigurableList_setSelectedItemsAction')<
    ReadonlySet<TDataSourceItemId>,
    TListId
>()

export const resetAllSelectedListItemsAction = createAction(
    'ConfigurableList_ResetAllSelectedListItemsAction',
    (listId) => listId
)()

export const saveUserSettingsForColumnsAction = createAction(
    'ConfigurableListColumnSettingsChanged',
    (listId: string, settingsForColumns: IListViewUserSettingsForColumns) => ({ settingsForColumns }),
    (listId: string) => listId
)()

export const setMaximumRowActionsAction = createAction(
    'ConfigurableList_SetMaximumRowActionsAction',
    (maximumRowActions: number, listId: string) => ({ maximumRowActions, listId })
)()

export const setFiltersHeightAction = createAction(
    'ConfigurableList_SetFiltersHeightAction',
    (filtersHeight: number, listId: string) => ({ filtersHeight, listId })
)()

export const setDefaultOpenedItem = createAction(
    'ConfigurableList_SetDefaultOpenedItem',
    (rowItemId: TDataSourceItemId, listId: string) => ({ rowItemId, listId })
)()

export const resetOpenedRowsState = createAction('ConfigurableList_ResetOpenedRowsState', (listId: string) => listId)()
