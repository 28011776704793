import { closeAllModalsAction } from '@planier/modal'
import { displaySuccessToaster, displayErrorToaster } from '@planier/notifications'
import { IThunkBaseAction } from '@planier/generic-state'
import { fetchDataSourceDataThunk } from '@planier/data-source'
import { deleteEvents as deleteEventsQuery } from '../../WebApi/DeletingEvents'
import {
    asyncOperationFailedAction,
    asyncOperationSucceededAction,
    asyncOperationStartedAction,
} from '@planier/async-operation'

export interface IDeleteTapahtumas {
    (
        dataSourceId: string,
        eventIds: string[],
        deletionReasonId: string,
        additionalInfo: string,
        deletionUrl: undefined | string
    ): IThunkBaseAction
}

export const deleteEventsThunk: IDeleteTapahtumas = (
    dataSourceId,
    eventIds,
    deletionReasonId,
    additionalInfo,
    deletionUrl
) => async (dispatch) => {
    dispatch(asyncOperationStartedAction('global'))

    const isSingleEvent = eventIds.length === 1

    try {
        await deleteEventsQuery(eventIds, deletionReasonId, additionalInfo, deletionUrl)

        await dispatch(
            fetchDataSourceDataThunk(dataSourceId, {
                fetchDependentDataSourceData: true,
            })
        )

        dispatch(
            displaySuccessToaster(`Palkkahallinto.DeletionModal.DeletionSucceeded${isSingleEvent ? '' : '.Multi'}`)
        )
        dispatch(closeAllModalsAction())
        dispatch(asyncOperationSucceededAction('global'))
    } catch (e) {
        dispatch(displayErrorToaster(`Palkkahallinto.DeletionModal.DeletionFailed${isSingleEvent ? '' : '.Multi'}`))

        dispatch(asyncOperationFailedAction(e, 'global'))
    }
}
