import { MAX_LIST_ROWS } from '../components/ListContent/constants/ListConstants'
import { TDataSourceItemId } from '@planier/data-source-types'
import { memoize } from 'lodash-es'

export const getItemIdsForRender = memoize(
    (itemIds: TDataSourceItemId[], offset: number, maxRows: number = MAX_LIST_ROWS): TDataSourceItemId[] =>
        itemIds.slice(0, maxRows + offset)
)

export const containsHiddenRows = (itemIds: TDataSourceItemId[], maxRows = MAX_LIST_ROWS): boolean =>
    itemIds.length > maxRows
