import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { TooltipWithEllipsis } from '@planier/generic-components'
import { success_100, alert_100, orange_100, dark_100 } from 'constants/Styles'

export interface IListElementTextProps {
    displayValue: string | number
    className?: string
    color?: 'green' | 'red' | 'yellow'
    overridingTooltip?: string
    useBoldFont?: boolean
}

function getColor(color: string): string {
    switch (color) {
        case 'green':
            return success_100
        case 'red':
            return alert_100
        case 'yellow':
            return orange_100
        default:
            return dark_100
    }
}

/**
 * Display the given displayValue prop as text. Used as a generic list row element
 * in the configurable list.
 */
const ListElementText: React.FunctionComponent<IListElementTextProps> = ({
    className,
    displayValue,
    color,
    overridingTooltip,
    useBoldFont,
}) => (
    <TooltipWithEllipsis title={overridingTooltip ?? displayValue} enforceTooltip={!!overridingTooltip}>
        {(ref) => (
            <Typography
                className={className}
                display="inline"
                noWrap
                ref={ref}
                variant="listElement"
                color={color ? getColor(color) : dark_100}
                fontWeight={useBoldFont ? 'bold' : 'normal'}
            >
                {displayValue}
            </Typography>
        )}
    </TooltipWithEllipsis>
)

export default ListElementText
