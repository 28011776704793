import { RootState } from 'typesafe-actions'
import createCachedSelector from 're-reselect'

import { IConfigurableListState } from '../interfaces/IListState'
import IListViewColumnModel from '../interfaces/IListViewColumnModel'
import IListViewModel, { IListViewReport } from '../interfaces/IListViewModel'
import { IDataSourceItem, TDataSourceItemId } from '@planier/data-source-types'
import { TValuePickerId } from '@planier/value-picker'
import TListViewId from '../interfaces/TListViewId'
import {
    selectDataItemIds,
    selectDataSourceData,
    selectDataSourceItem,
    selectDataSourceSortSettings,
    selectIsDataSourceBeingDownloadedToExcel,
    TDataSourceId,
} from '@planier/data-source'
import IListViewAction from '../interfaces/IListViewAction'
import { IListViewButtonAction, IListViewIconAction } from '../interfaces/TDisplayAction'
import { LIST_FILTER_TO_ITSELF_PREFIX } from '../Constants/ListConstants'
import { getItemIdsForRender } from '../Utilities/ConfigurableListHelpers'
import { MAX_LIST_ROWS, MAX_WORKUNITGROUP_ROWS } from '../components/ListContent/constants/ListConstants'

const emptyArray: unknown[] = []

const selectListState = (state: RootState, listId: TListViewId): IConfigurableListState | undefined => {
    const lists = state.listData

    return lists[listId]
}

export const selectListViewConfiguration = (programState: RootState, listId: string): IListViewModel | null => {
    const listState = selectListState(programState, listId)

    if (!listState || !listState.configuration) {
        return null
    }

    return listState.configuration
}

export const selectSelectedItemIds = (programState: RootState, listId: string): ReadonlySet<TDataSourceItemId> => {
    const listState = selectListState(programState, listId)

    return listState ? (listState.selected as ReadonlySet<TDataSourceItemId>) : new Set()
}

export const selectOpenedGroupIds = (programState: RootState, listId: string): ReadonlySet<TDataSourceItemId> => {
    const listState = selectListState(programState, listId)

    return listState ? (listState.opened as ReadonlySet<TDataSourceItemId>) : new Set()
}

export const selectListDisplayName = (state: RootState, listId: TListViewId): string | null => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.DisplayName : null
}

export const selectMaximumRowActions = (state: RootState, listId: TListViewId): number => {
    const listState = selectListState(state, listId)

    return listState?.maximumRowActions || 0
}

export const selectFiltersHeight = (state: RootState, listId: TListViewId): number => {
    const listState = selectListState(state, listId)

    return listState?.filtersHeight || 0
}

export const selectListBulkActions = (
    programState: RootState,
    listId: string
): IListViewAction<IListViewButtonAction>[] => {
    const listConfiguration = selectListViewConfiguration(programState, listId)

    return listConfiguration ? listConfiguration.BulkActions : []
}

export const selectListRowActions = (
    programState: RootState,
    listId: string
): IListViewAction<IListViewIconAction>[] => {
    const listConfiguration = selectListViewConfiguration(programState, listId)

    return listConfiguration ? listConfiguration.RowActions : []
}

export const selectListStaticActions = (
    programState: RootState,
    listId: string
): IListViewAction<IListViewButtonAction>[] => {
    const listConfiguration = selectListViewConfiguration(programState, listId)

    return listConfiguration ? listConfiguration.StaticActions : []
}

export const selectAreAllRowsSelected = (
    storeState: RootState,
    listId: TListViewId,
    allItemIds: TDataSourceItemId[]
): boolean => {
    const totalItemCount = allItemIds.length

    if (totalItemCount === 0) {
        return false
    }

    const selectedRows = selectSelectedItemIds(storeState, listId).size
    return selectedRows === totalItemCount
}

export const selectDataSourceId = (state: RootState, listId: TListViewId): string | null => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.DataSourceId : null
}

export const selectListViewFilterIdsFromConfiguration = (state: RootState, listId: TListViewId): TValuePickerId[] => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration?.FilterIds ?? (emptyArray as TValuePickerId[])
}

export const selectFilterItselfDataRequestParametersName = (state: RootState, listId: TListViewId): string | null => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration?.FilterItselfDataRequestParameterName ?? null
}

export const selectListViewFilterIds = createCachedSelector(
    selectListViewFilterIdsFromConfiguration,
    selectFilterItselfDataRequestParametersName,
    selectListViewConfiguration,
    (filterIdsFromConfiguration, filterItselfDataRequestParameterName, listConfiguration) => {
        return filterItselfDataRequestParameterName && listConfiguration
            ? [...filterIdsFromConfiguration, `${LIST_FILTER_TO_ITSELF_PREFIX}${listConfiguration.Id}`]
            : filterIdsFromConfiguration
    }
)((state, listId) => listId)

export const selectListConfigurationForColumns = (state: RootState, listId: TListViewId): IListViewColumnModel[] => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.Columns : []
}

export const selectIsListSelectable = (state: RootState, listId: TListViewId): boolean => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.IsSelectable : false
}

export const selectIsItemSelected = (
    state: RootState,
    listId: TListViewId,
    itemId: TDataSourceItemId,
    dataSourceId: TDataSourceId | null
): boolean => {
    // if item is a parent, toggle if all subrows open or closed

    const item = selectDataSourceItem(state, dataSourceId, itemId)
    const selectedItemsIds = selectSelectedItemIds(state, listId)
    const childIds = selectChildIdsDeep(state, dataSourceId, itemId)
    const isParent = item?.MetaData?.IsParent && Boolean(childIds)

    return isParent ? childIds.every((childId) => selectedItemsIds.has(childId)) : selectedItemsIds.has(itemId)
}

export const selectIsGroupOpened = (state: RootState, listId: TListViewId, itemId: TDataSourceItemId): boolean => {
    return selectOpenedGroupIds(state, listId).has(itemId)
}

export const selectOpenedSubrows = (
    state: RootState,
    listId: TListViewId,
    dataSourceId: TDataSourceId | null
): string[] => {
    const opened = selectOpenedGroupIds(state, listId) as Set<TDataSourceItemId>

    return selectChildIds(state, dataSourceId, [...opened])
}

export const selectIsSubrowHidden = (
    state: RootState,
    listId: TListViewId,
    dataSourceId: TDataSourceId | null,
    itemId: TDataSourceItemId
): boolean => {
    const opened = selectOpenedSubrows(state, listId, dataSourceId)
    const item = selectDataSourceItem(state, dataSourceId, itemId) as IDataSourceItem
    const isSubrow = item.MetaData?.IsParent === false
    return isSubrow && opened.includes(itemId as string)
}

export const selectIsListAutoFetchDataEnabled = (state: RootState, listId: TListViewId): boolean => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.AutoFetchData : false
}

export const selectIsColumnManagementAllowed = (state: RootState, listId: TListViewId): boolean => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.AllowColumnManagement : false
}

export const selectIsRowSelectable = (state: RootState, listId: TListViewId): boolean => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.RowSelectable : false
}

export const selectListAdditionalDataFetchParameters = (
    state: RootState,
    listId: TListViewId
): IListViewModel['AdditionalDataFetchParameters'] | null => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration?.AdditionalDataFetchParameters ?? null
}

export const selectColumnSortSettings = (
    state: RootState,
    listId: TListViewId
): ReturnType<typeof selectDataSourceSortSettings> => {
    const dataSourceId = selectDataSourceId(state, listId)

    return selectDataSourceSortSettings(state, dataSourceId)
}

export const selectIsDataBeingLoadedToExcel = (state: RootState, listId: TListViewId): boolean => {
    const dataSourceId = selectDataSourceId(state, listId)

    return selectIsDataSourceBeingDownloadedToExcel(state, dataSourceId)
}

export const selectListReports = (state: RootState, listId: TListViewId): IListViewReport[] => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.Reports : []
}

export const selectCanUserCreateReports = (state: RootState, listId: TListViewId): boolean => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration ? listConfiguration.CanCreateReports : false
}

export const selectListViewReport = (
    state: RootState,
    listId: string,
    reportId: number | null
): IListViewReport | null => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration?.Reports?.find((x) => x.Id === reportId) ?? null
}

export const selectReportSelectedForEdit = (state: RootState, listId: string): IListViewReport | null => {
    const listConfiguration = selectListViewConfiguration(state, listId)

    return listConfiguration?.ReportSelectedForEdit ?? null
}

export const selectChildIds = (
    state: RootState,
    dataSourceId: TDataSourceId | null,
    useIds: TDataSourceItemId[] | null = null
): string[] => {
    const idsToFilter = useIds ? useIds : selectDataItemIds(state, dataSourceId)
    const childIds = idsToFilter
        .map((id) => {
            const itemData = selectDataSourceItem(state, dataSourceId, id)
            return itemData?.MetaData?.ChildIds
        })
        .filter(Boolean)
        .flat(1)

    return childIds ? (childIds as string[]) : []
}

export const selectChildIdsDeep = (
    state: RootState,
    dataSourceId: TDataSourceId | null,
    itemId: TDataSourceItemId
): string[] => {
    const children: string[] = []

    const getChildIds = (id: TDataSourceItemId) => {
        const itemData = selectDataSourceItem(state, dataSourceId, id)

        const childIds = itemData?.MetaData?.ChildIds

        if (childIds) {
            children.push(...(childIds as string[]))

            childIds.forEach((childId) => {
                getChildIds(childId)
            })
        }

        return children
    }

    return getChildIds(itemId)
}

export const selectVisibleIds = (
    state: RootState,
    listId: TListViewId,
    dataSourceId: TDataSourceId | null,
    offset: number
): string[] => {
    const allDataIds = selectDataItemIds(state, dataSourceId) as string[]
    const renderDataIds = getItemIdsForRender(
        allDataIds,
        offset,
        dataSourceId === 'WorkUnitGroup' ? MAX_WORKUNITGROUP_ROWS : MAX_LIST_ROWS
    ) as string[]
    const allSubrows = selectChildIds(state, dataSourceId)
    const openedSubrows = selectOpenedSubrows(state, listId, dataSourceId)
    const closedSubrows = allSubrows.filter((item) => !openedSubrows.includes(item))
    const visibleRows = renderDataIds.filter((item) => !closedSubrows.includes(item))

    return visibleRows
}

export const selectGroupDepth = (
    state: RootState,
    dataSourceId: TDataSourceId | null,
    itemId: TDataSourceItemId
): number => {
    let level = 0

    const data = selectDataSourceData(state, dataSourceId)
    const itemData = data.get(itemId)

    if (!itemData || !itemData.MetaData?.ParentId) {
        return 0
    }

    const calculateLevel = (item: IDataSourceItem) => {
        const parent = data.get(<string | number>item?.MetaData?.ParentId)

        if (parent) {
            level += 1
            calculateLevel(parent)
        }

        return level
    }

    return calculateLevel(itemData)
}

export const selectDefaultOpenedItems = (
    state: RootState,
    listId: TListViewId
): ReadonlySet<TDataSourceItemId> | undefined => {
    const listState = selectListState(state, listId)

    return listState?.defaultOpened
}
