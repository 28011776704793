import { createReducer } from 'typesafe-actions'

import { initializeApplicationAction } from 'action-creators'

export const INITIAL_STATE = new Map<string, string | number | boolean>()

const asetusReducer = createReducer(INITIAL_STATE).handleAction([initializeApplicationAction], (state, action) => {
    return new Map([
        ...Array.from(state),
        ...Array.from(action.payload.asetukset),
        ['logoutUrl', action.payload.logoutUrl],
    ])
})

export default asetusReducer
