import asyncOperationReducer from '../State/AsyncOperationsReducer'
import IAsyncOperationReducerCombined from '../Types/IAsyncOperationReducerCombined'
import IAsyncOperationState from '../Types/IAsyncOperationState'

export const getTestAsyncOperationId = (): string => 'asyncOperationTestId'

export const getTestAsyncOperation = (asyncOperation?: Partial<IAsyncOperationState>): IAsyncOperationState => ({
    id: getTestAsyncOperationId(),
    error: null,
    ready: false,
    isInProgress: true,
    other: null,
    ...asyncOperation,
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getAsyncOperationReducerMap = () => {
    return {
        asyncOperationState: asyncOperationReducer,
    }
}

export const getAsyncOperationReducerState = (): IAsyncOperationReducerCombined => {
    return {
        asyncOperationState: new Map(),
    }
}
