import { IDataSourceConfiguration } from '@planier/data-source'
import { IDataSourceItem } from '@planier/data-source-types'
import IFormViewFieldModel from '../../Types/IFormViewFieldModel'
import { getInputPropertyName } from './InternalUtils'
import { TUniqueValuesCountForFields } from '@planier/form-view-types'
import { getLogger } from '@planier/log'
import { get, set } from 'lodash-es'

const Log = getLogger('form-view.getAmountOfUniqueValueForProperty')

type TValueCountsForScalarFields = Record<string, number>
type TValueCountsForFieldsWithObjectValues = Record<string, TValueCountsForScalarFields>

const getAmountOfUniqueValueForProperty = (
    field: IFormViewFieldModel,
    items: IDataSourceItem[],
    dataSourcePropertyId: string | null,
    dataSource: IDataSourceConfiguration
) => {
    let propertyName: string | null
    try {
        propertyName = getInputPropertyName(field.Id, dataSourcePropertyId, dataSource)
    } catch (error) {
        Log.warn(error.message)
        return null
    }

    const distinctValues = new Set<unknown>(items.map((item) => (propertyName ? get(item, propertyName) : null)))

    return distinctValues
}

const isScalarField = (field: IFormViewFieldModel) => {
    const { DataSourcePropertyMapping: mapping } = field
    return mapping.length === 1 && mapping[0].ComponentParameterName === null
}

const getUniqueCountsForScalarFields = (
    items: IDataSourceItem[],
    fields: IFormViewFieldModel[],
    dataSource: IDataSourceConfiguration
) => {
    return fields.filter(isScalarField).reduce((valueCounts, field) => {
        const { DataSourcePropertyId } = field.DataSourcePropertyMapping[0]

        const distinctValues = getAmountOfUniqueValueForProperty(field, items, DataSourcePropertyId, dataSource)

        if (distinctValues === null) {
            return valueCounts
        }

        set(valueCounts, field.Id, distinctValues.size)
        return valueCounts
    }, {} as TValueCountsForScalarFields)
}

const getUniqueCountsForObjectFields = (
    items: IDataSourceItem[],
    fields: IFormViewFieldModel[],
    dataSource: IDataSourceConfiguration
) => {
    return fields
        .filter((field) => !isScalarField(field))
        .reduce((valueCounts, field) => {
            const mapping = field.DataSourcePropertyMapping

            const valueCountsForEachProperty = mapping.reduce(
                (valueCountsForProperties, { ComponentParameterName, DataSourcePropertyId }) => {
                    if (!ComponentParameterName) {
                        Log.warn(
                            'Form field $0: ComponentParameterName should be non-null when' +
                                ' there are multiple data source properties mapped to a form view input.',
                            field.Id
                        )
                        return valueCountsForProperties
                    }

                    const distinctValues = getAmountOfUniqueValueForProperty(
                        field,
                        items,
                        DataSourcePropertyId,
                        dataSource
                    )

                    if (distinctValues === null) {
                        return valueCountsForProperties
                    }

                    set(valueCountsForProperties, ComponentParameterName, distinctValues.size)
                    return valueCountsForProperties
                },
                {} as TValueCountsForScalarFields
            )

            set(valueCounts, field.Id, valueCountsForEachProperty)
            return valueCounts
        }, {} as TValueCountsForFieldsWithObjectValues)
}

const getAmountOfUniqueValuesForFields = (
    items: IDataSourceItem[],
    fields: IFormViewFieldModel[],
    dataSource: IDataSourceConfiguration
): TUniqueValuesCountForFields => {
    const uniqueValueCountsForScalarFields = getUniqueCountsForScalarFields(items, fields, dataSource)

    const uniqueValueCountsForFieldsWithObjectsAsValues = getUniqueCountsForObjectFields(items, fields, dataSource)

    return { ...uniqueValueCountsForScalarFields, ...uniqueValueCountsForFieldsWithObjectsAsValues }
}

export default getAmountOfUniqueValuesForFields
