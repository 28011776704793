import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import { Icon } from '@planier/generic-components'
import Styles from 'constants/Styles'
import { Translation } from '@planier/localization'

export interface INodeWarningProps {
    warnings: string[] | null
}

const WarningsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ListItem = styled.li`
    color: ${Styles.mainColor.darkGrey};
    font-size: 10px;
`

const WarningsList: React.FC<{ warnings: string[] }> = ({ warnings }) => (
    <WarningsContainer>
        <Typography variant="caption">{Translation.translateKey('calendar.TyoaikasaantoViolation')}:</Typography>
        <ol>
            {warnings.map((warning) => (
                <ListItem key={warning}>
                    <Typography variant="caption">{warning}</Typography>
                </ListItem>
            ))}
        </ol>
    </WarningsContainer>
)

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
`

const NodeWarning: React.FunctionComponent<INodeWarningProps> = ({ warnings }) => {
    if (!warnings || warnings.length === 0) {
        return null
    }

    return (
        <Container>
            <Icon
                color={Styles.planierColor.blueTurquoise.textColor}
                size={12}
                tooltip={<WarningsList warnings={warnings} />}
            >
                warning
            </Icon>
        </Container>
    )
}

export default NodeWarning
