import { Dropdown } from '@planier/generic-components-dropdown'
import { IValuePickerCommonOwnProps } from 'packages/value-picker'

interface IDDLItem {
    Id: number
    Label: string
}
interface IDropDownPicker extends IValuePickerCommonOwnProps<number> {
    options: IDDLItem[]
    width?: number
}

const DropdownPicker: React.FC<IDropDownPicker> = ({ required, label, value, onChange, options, width }) => {
    const _onChange = (newValues: Set<number>) => {
        if (newValues.size !== 0) {
            const valueToSelect = newValues.values().next().value
            onChange(valueToSelect)
        }
    }

    return (
        <Dropdown<IDDLItem>
            defaultText={label ?? ''}
            itemOptionLabelFields={['Label']}
            itemValueLabelFields={['Label']}
            textForMultipleSelected={''}
            itemIdField={'Id'}
            isLoading={false}
            multiselect={false}
            disabled={false}
            width={width}
            required={required}
            selectAction={_onChange}
            items={options}
            values={new Set<number>([value])}
            valueFieldType={'form'}
        />
    )
}

export default DropdownPicker
