import { RootState } from 'typesafe-actions'

import {
    IDataSourceDataRequestGroupBy,
    IDataSourceDataRequestParameters,
    IDataSourceDataRequestParametersOverride,
    TDataSourceItemId,
} from '@planier/data-source-types'
import { TDataSourceId } from '../Types/IDataSource'
import { DEFAULT_OVERRIDE_REQUEST_PARAMETERS } from '../Constants/DataSourceQueryConstants'
import {
    selectDataSourceFetchFiltersParameters,
    selectDataSourceFetchParameters,
    selectDataSourceOffset,
    selectDataSourceOverrideFetchParameters,
    selectDataSourceSortSettingsAsArray,
    selectDataSourceValuePickerIds,
} from '../State/DataSourceSelectors'
import { selectValuePickerConfigurations, selectValuePickerValuesForQuery } from '@planier/value-picker'
import IFetchDataSourceDataOptions from '../Types/IFetchDataSourceDataOptions'
import { getLogger } from '@planier/log'

const Log = getLogger('data-source.getDataFetchParameters')

const getValuePickerDataFetchParameters = (state: RootState, dataSourceId: TDataSourceId) => {
    const valuePickerIds = selectDataSourceValuePickerIds(state, dataSourceId)
    const valuePickerConfigurations = selectValuePickerConfigurations(state, valuePickerIds)
    const parametersFromValuePickers = selectValuePickerValuesForQuery(state, valuePickerConfigurations)

    return parametersFromValuePickers
}

type TDynamicRequestParametersWithoutUndefined = Exclude<
    IFetchDataSourceDataOptions['dynamicRequestParameters'],
    undefined
>

const getSortSettings = (
    state: RootState,
    dataSourceId: TDataSourceId,
    dynamicSortSettings: TDynamicRequestParametersWithoutUndefined['parameters']['SortBy'],
    overrideStoredWithDynamic: boolean
) => {
    if (overrideStoredWithDynamic && dynamicSortSettings) {
        return dynamicSortSettings
    }

    const storedSortSettings = selectDataSourceSortSettingsAsArray(state, dataSourceId)

    if (!dynamicSortSettings) {
        return storedSortSettings
    }

    return [...dynamicSortSettings, ...storedSortSettings]
}

const getOffSet = (
    state: RootState,
    dataSourceId: TDataSourceId,
    dynamicOffset: TDynamicRequestParametersWithoutUndefined['parameters']['Offset']
) => {
    return dynamicOffset ?? selectDataSourceOffset(state, dataSourceId)
}

const getLimit = (
    state: RootState,
    dataSourceId: TDataSourceId,
    dynamicLimit: TDynamicRequestParametersWithoutUndefined['parameters']['Limit']
) => {
    return dynamicLimit ?? 250
}

const getExtraRows = (
    state: RootState,
    dataSourceId: TDataSourceId,
    dynamicExtraRows: TDynamicRequestParametersWithoutUndefined['parameters']['ExtraRows']
) => {
    return dynamicExtraRows ?? 'Truncate'
}

const getFilters = (
    state: RootState,
    dataSourceId: TDataSourceId,
    dynamicFilters: TDynamicRequestParametersWithoutUndefined['parameters']['Filters'],
    overrideStoredWithDynamic: boolean,
    itemIds?: TDataSourceItemId[]
) => {
    if (overrideStoredWithDynamic && dynamicFilters) {
        return dynamicFilters
    }

    const parametersFromValuePickers = getValuePickerDataFetchParameters(state, dataSourceId)
    const dataSourceFetchParametersForFilters = selectDataSourceFetchFiltersParameters(state, dataSourceId)

    const { Filters: possibleFiltersObject } = selectDataSourceFetchParameters(state, dataSourceId) as {
        Filters?: unknown
    }

    const filters = {
        ...parametersFromValuePickers,
        ...(possibleFiltersObject as Record<string, unknown>),
        ...dataSourceFetchParametersForFilters,
        ...(itemIds && { Ids: itemIds }),
        ...dynamicFilters,
    }

    return filters
}

const getDataSourceFetchParametersForRootOfTheBody = (state: RootState, dataSourceId: TDataSourceId) => {
    // In case Filters and GroupBy are defined in the fetchParameters for data source for some reason, we strip them out
    // so that they don't override the ones defined elsewhere.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { Filters, GroupBy, ...dataSourceFetchParametersForRootOfTheBody } = selectDataSourceFetchParameters(
        state,
        dataSourceId
    ) as { Filters?: unknown; GroupBy?: unknown }

    return dataSourceFetchParametersForRootOfTheBody
}

const getGroupBy = (state: RootState, dataSourceId: TDataSourceId) => {
    const { GroupBy } = selectDataSourceFetchParameters(state, dataSourceId) as {
        GroupBy?: IDataSourceDataRequestGroupBy[]
    }

    return GroupBy
}

const areBothFieldsGiven = (field1: unknown, field2: unknown) => {
    return field2 !== undefined && field2 !== undefined
}

type TOverrideParams = IDataSourceDataRequestParametersOverride['parameters']

const isAnyFetchParametersOverriddenTwice = (fetchParameters1: TOverrideParams, fetchParameters2: TOverrideParams) => {
    const diff1 = Object.keys(fetchParameters1).some((param: keyof TOverrideParams) =>
        areBothFieldsGiven(fetchParameters1[param], fetchParameters2[param])
    )
    if (diff1) {
        return true
    }

    const diff2 = Object.keys(fetchParameters2).some((param: keyof TOverrideParams) =>
        areBothFieldsGiven(fetchParameters2[param], fetchParameters1[param])
    )
    return diff2
}

const getOverrideDataFetchParameters = (
    state: RootState,
    dataSourceId: TDataSourceId,
    dynamicRequestParameters: IDataSourceDataRequestParametersOverride
): IDataSourceDataRequestParametersOverride => {
    const {
        overridePerField,
        overrideEverything,
        parameters: {
            Filters: dynamicFilters,
            GroupBy: dynamicGroupBy,
            Limit: dynamicLimit,
            Offset: dynamicOffset,
            SortBy: dynamicSortBy,
            ExtraRows: dynamicExtraRows,
            GetUpdateData: dynamicGetUpdateData,
        },
    } = dynamicRequestParameters

    const overrideRequestParametersInStore = selectDataSourceOverrideFetchParameters(state, dataSourceId)

    const overrideRequestParametersInStoreAsObject = overrideRequestParametersInStore ?? { parameters: {} }

    const {
        overridePerField: overridePerFieldInStore = false,
        overrideEverything: overrideEverythingInStore = false,
        parameters: {
            Filters: overrideFiltersInStore,
            GroupBy: overrideGroupByInStore,
            Limit: overrideLimitInStore,
            Offset: overrideOffsetInStore,
            SortBy: overrideSortByInStore,
            ExtraRows: overrideExtraRowsInStore,
            GetUpdateData: overrideGetUpdateData,
        },
    } = overrideRequestParametersInStoreAsObject

    if (overridePerField !== false && overridePerFieldInStore !== false) {
        Log.warn('overridePerField was given dynamically and was also stored')
    }

    if (overrideEverything !== false && overrideEverythingInStore !== false) {
        Log.warn('overrideEverything was given dynamically and was also stored')
    }

    if (
        isAnyFetchParametersOverriddenTwice(
            dynamicRequestParameters.parameters,
            overrideRequestParametersInStoreAsObject.parameters
        )
    ) {
        Log.warn('Some of the parameters for overriding were given dynamically and were also stored')
    }

    return {
        overridePerField: overridePerField || overridePerFieldInStore,
        overrideEverything: overrideEverything || overrideEverythingInStore,
        parameters: {
            Filters: dynamicFilters ?? overrideFiltersInStore,
            GroupBy: dynamicGroupBy ?? overrideGroupByInStore,
            Limit: dynamicLimit ?? overrideLimitInStore,
            Offset: dynamicOffset ?? overrideOffsetInStore,
            SortBy: dynamicSortBy ?? overrideSortByInStore,
            ExtraRows: dynamicExtraRows ?? overrideExtraRowsInStore,
            GetUpdateData: dynamicGetUpdateData ?? overrideGetUpdateData,
        },
    }
}

const getDataFetchParameters = (
    state: RootState,
    dataSourceId: TDataSourceId,
    itemIds?: TDataSourceItemId[],
    dynamicRequestParameters = DEFAULT_OVERRIDE_REQUEST_PARAMETERS
): IDataSourceDataRequestParameters => {
    const overrideParams = getOverrideDataFetchParameters(state, dataSourceId, dynamicRequestParameters)
    const {
        overridePerField = false,
        overrideEverything = false,
        parameters: {
            Filters: overrideFilters,
            GroupBy: overrideGroupBy,
            Limit: overrideLimit,
            Offset: overrideOffset,
            SortBy: overrideSortBy,
            ExtraRows: overrideExtraRows,
            GetUpdateData: overrideGetUpdateData,
        },
    } = overrideParams

    if (overrideEverything) {
        return {
            Filters: overrideFilters ?? {},
            SortBy: overrideSortBy ?? [],
            Limit: overrideLimit ?? 250,
            Offset: overrideOffset ?? 0,
            ExtraRows: overrideExtraRows ?? 'Truncate',
            GroupBy: overrideGroupBy ?? [],
            GetUpdateData: overrideGetUpdateData ?? false,
        }
    }

    const sortSettings = getSortSettings(state, dataSourceId, overrideSortBy, overridePerField)
    const offset = getOffSet(state, dataSourceId, overrideOffset)
    const limit = getLimit(state, dataSourceId, overrideLimit)
    const extraRows = getExtraRows(state, dataSourceId, overrideExtraRows)
    const filters = getFilters(state, dataSourceId, overrideFilters, overridePerField, itemIds)
    const dataSourceFetchParametersForRootOfTheBody = getDataSourceFetchParametersForRootOfTheBody(state, dataSourceId)
    const groupBy = getGroupBy(state, dataSourceId)

    return {
        Filters: filters,
        SortBy: sortSettings,
        Limit: limit,
        Offset: offset,
        ExtraRows: extraRows,
        GroupBy: overrideGroupBy ?? groupBy ?? [],
        GetUpdateData: overrideGetUpdateData ?? false,
        ...dataSourceFetchParametersForRootOfTheBody,
    }
}

export default getDataFetchParameters
