import { TimerangeInputV2, ITimerangeValue, ITimeRangeValidationError } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from '@planier/value-picker'

interface ITimeRangePickerProps extends IValuePickerCommonOwnProps<ITimerangeValue> {}

const ITimeRangePicker: React.FC<ITimeRangePickerProps> = ({ required, value, onChange, errors, valuePickerId }) => {
    const typedError = errors as ITimeRangeValidationError
    return (
        <TimerangeInputV2
            required={required}
            value={value}
            errors={typedError}
            onChange={onChange}
            valuePickerId={valuePickerId}
        />
    )
}

export default ITimeRangePicker
