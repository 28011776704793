import * as React from 'react'
import { SystemInternalLink } from '@planier/generic-components'
import { ReadOnlyText } from '@planier/generic-components'

export interface IEmployeeLinkLegacy {
    name: string
    id: number
    domain: string
    noLinkText?: string
}

const EmployeeLinkLegacy: React.FunctionComponent<IEmployeeLinkLegacy> = ({ name, id, domain, noLinkText }) => {
    const valueLinkWithValue = 'https://' + domain + '/BSP/employeeInfo.aspx?employeeID=' + id

    if (noLinkText) {
        return (
            <ReadOnlyText isLink={false} textOverflow="ellipsis" color="dark_100" usage="legacy_list_element">
                {noLinkText}
            </ReadOnlyText>
        )
    }

    return (
        <SystemInternalLink
            openInNewTab={true}
            to={valueLinkWithValue}
            windowParams="toolbar=yes,scrollbars=yes,resizable=yes,left=300,width=1100,height=900"
        >
            <ReadOnlyText color="dark_100" usage="legacy_list_element">
                {name}
            </ReadOnlyText>
        </SystemInternalLink>
    )
}

export default EmployeeLinkLegacy
