import * as React from 'react'
import styled from '@emotion/styled'

import URL from 'common/URL'
import INavigationSectionWithSubsections from '../../../Types/INavigationSectionWithSubsections'
import { Icon, Tooltip } from '@planier/generic-components'
import INavigationSubsection from '../../../Types/INavigationSubsection'
import SectionWithoutSubsectionsTab from './SectionWithoutSubsectionsTab'
import SectionWithSubsectionsTab from './SectionWithSubsectionsTab'
import { useAppTheme } from '@planier/theme'

interface ISectionProps {
    item: INavigationSectionWithSubsections
    currentPageSection: INavigationSectionWithSubsections | null
    currentPageSubsection: INavigationSubsection | null
    isDrawerOpen: boolean
}

const getSectionUrl = (section: INavigationSectionWithSubsections) => {
    return section.subsectionObjects.length > 1 ? null : section.subsectionObjects[0].Url
}

const IconTooltipWrapper = styled.div`
    display: flex;
`

const Section: React.FC<ISectionProps> = ({ isDrawerOpen, item, currentPageSection, currentPageSubsection }) => {
    const { colors } = useAppTheme()

    const iconElement = item.Icon && (
        <Tooltip
            childWrapperElement={IconTooltipWrapper}
            placement="right"
            title={isDrawerOpen ? '' : item.Translation}
        >
            <Icon color={item.Id === currentPageSection?.Id ? colors.primaryTeal120 : colors.neutralsGrey100} size={22}>
                {item.Icon}
            </Icon>
        </Tooltip>
    )

    const sectionUrl = getSectionUrl(item)

    const isCurrentPage = item.Id === currentPageSection?.Id

    if (sectionUrl) {
        return (
            <SectionWithoutSubsectionsTab
                currentPageSubsection={currentPageSubsection}
                iconElement={iconElement}
                isCurrentPage={isCurrentPage}
                key={sectionUrl}
                to={URL.oldClient(sectionUrl)}
                sectionName={item.Name}
            >
                {item.Translation}
            </SectionWithoutSubsectionsTab>
        )
    }

    return (
        <SectionWithSubsectionsTab
            currentPageSubsection={currentPageSubsection}
            iconElement={iconElement}
            isCurrentPage={isCurrentPage}
            isDrawerOpen={isDrawerOpen}
            item={item}
        />
    )
}

export default Section
