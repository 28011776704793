import { RootState } from 'typesafe-actions'

import IDataSourceReducerCombined from '../Types/IDataSourceReducerCombined'
import {
    IDataSourceItem,
    IDataSourceGroupData,
    IListViewDataContainer,
    EViewDataStatus,
} from '@planier/data-source-types'
import IDataSource, { TDataSourceId } from '../Types/IDataSource'
import IDataSourceConfiguration from '../Types/IDataSourceConfiguration'
import EDataSourceActionMethods from '../Constants/EDataSourceActionMethods'
import IDataSourceAction from '../Types/IDataSourceAction'
import IDataSourcePropertyModel from '../Types/IDataSourcePropertyModel'
import { getAsyncOperationReducerState, getAsyncOperationReducerMap } from '@planier/async-operation'
import dataSourceReducer from '../State/DataSourceReducer'
import {
    getEventsTestDataSourceConfiguration,
    getEventsTestDataSourceData,
} from './ActualDataSourcesTestData/EventsDataSource'
import { DEFAULT_OVERRIDE_REQUEST_PARAMETERS } from '../Constants/DataSourceQueryConstants'
import {
    getEmployeeCalendarTestDataSourceConfiguration,
    getEmployeeCalendarTestDataSourceData,
} from './ActualDataSourcesTestData/EmployeeCalendarTestData'

export type TTestDataSourceItemId = string

export type TTestDataSourceReducerCombined = IDataSourceReducerCombined

export interface ITestDataSourceItem extends IDataSourceItem {
    TestProperty: string
}

export const getTestDataSourceId = (): string => 'TestDataSource'
export const getSecondDataSourceId = (): string => 'AnotherDataSourceId'
export const getThirdDataSourceId = (): string => 'ThirdDataSourceId'

export const getTestDataSourcePropertyId = (): string => 'TestDataSource_TestProperty'

export const getTestDataSourcePropertyName = (): string => 'TestProperty'

export const getTestDataSourceActionName = (): string => 'TestDataSource_TestAction'

export const getTestDataSourceAction = (): IDataSourceAction => ({
    Id: getTestDataSourceActionName(),
    DataPropertyParameters: [],
    EndpointUrl: '/test/action/url',
    Method: EDataSourceActionMethods.POST,
    StaticParameters: {},
    DataSourceId: getTestDataSourceId(),
})

export const getTestDataSourceActions = (): IDataSourceAction[] => [
    getTestDataSourceAction(),
    {
        DataPropertyParameters: [{ Key: 'Ids', PropertyPath: 'Id' }],
        DataSourceId: 'TransferBatch',
        EndpointUrl: 'Transfer/TransferBatch/GetFiles',
        Id: 'TransferBatches_GetFiles',
        Method: EDataSourceActionMethods.POST,
        RefetchAll: false,
        SendAllValues: false,
        SuccessMessage: 'Toiminto suoritettu',
    },
]

export const getDataSourceProperties = (): IDataSourcePropertyModel[] => [
    {
        Id: getTestDataSourcePropertyId(),
        Property: getTestDataSourcePropertyName(),
        DataType: 'string',
    },
]

export const getDataSourceDependencies = (): TDataSourceId[] => ['AnotherDataSourceId', 'ThirdDataSource']
export const getSecondDataSourceDependencies = (): TDataSourceId[] => [getTestDataSourceId()]
export const getThirdDataSourceDependencies = (): TDataSourceId[] => [getTestDataSourceId()]

export const getTestDataSourceConfiguration = (
    dataSourceId = getTestDataSourceId(),
    dependencies = getDataSourceDependencies()
): IDataSourceConfiguration => ({
    Id: dataSourceId,
    Actions: getTestDataSourceActions(),
    Url: `data-source/${dataSourceId}/url`,
    Properties: getDataSourceProperties(),
    DependsOn: dependencies,
})

export const getTestDataSourceItemId = (): TTestDataSourceItemId => 'TestDataItem'

export const getTestDataSourceItem = (additionalProperties?: Record<string, unknown>): ITestDataSourceItem => ({
    Id: getTestDataSourceItemId(),
    Kayttooikeudet: [
        {
            Toiminto: 'GET_TRANSFER_BATCH_FILES',
            OnkoSallittu: true,
            SyyKieltoon: null,
        },
    ],
    TestProperty: 'Foo',
    ...additionalProperties,
})

export const getTestFormDataSourceId = (): string => 'FormDataSource'

export const getTestFormDataSourceConfiguration = (): IDataSourceConfiguration => ({
    Id: getTestFormDataSourceId(),
    Actions: [getTestDataSourceAction()],
    Url: `data-source/FormDataSource/url`,
    Properties: [
        {
            Id: 'TestDataSource_StringProperty',
            Property: 'StringProperty',
            DataType: 'string',
        },
        {
            Id: 'TestDataSource_IntProperty',
            Property: 'IntProperty',
            DataType: 'int',
        },
        {
            Id: 'TestDataSource_BooleanProperty',
            Property: 'BooleanProperty',
            DataType: 'boolean',
        },
        {
            Id: 'TestDataSource_RequiredStringProperty',
            Property: 'RequiredStringProperty',
            DataType: 'string',
        },
        {
            Id: 'TestDataSource_ValuePickerProperty',
            Property: 'ValuePickerProperty',
            DataType: 'int',
        },
        {
            Id: 'TestDataSource_CustomComponentProperty',
            Property: 'CustomComponentProperty',
            DataType: 'int',
        },
        {
            Id: 'TestDataSource_HiddenProperty',
            Property: 'HiddenProperty',
            DataType: 'int',
        },
        {
            Id: 'TestDataSource_MultiMappedProperty_First',
            Property: 'MultiMappedProperty.First',
            DataType: 'int',
        },
        {
            Id: 'TestDataSource_MultiMappedProperty_Second',
            Property: 'MultiMappedProperty.Second',
            DataType: 'int',
        },
    ],
    DependsOn: [],
})

export interface ITestFormItem extends IDataSourceItem {
    StringProperty?: string
    IntProperty?: number
    BooleanProperty?: boolean
    RequiredStringProperty: string
    ValuePickerProperty?: number
    CustomComponentProperty?: string
    HiddenProperty: string
    MultiMappedProperty?: {
        First: number
        Second: number
    }
}

export const getTestFormDataSourceItems = (): ITestFormItem[] => [
    {
        Id: 'TestItem1',
        Kayttooikeudet: [],
        StringProperty: 'foo',
        IntProperty: 123,
        BooleanProperty: true,
        RequiredStringProperty: 'bar',
        ValuePickerProperty: 456,
        CustomComponentProperty: 'baz',
        HiddenProperty: 'boz',
        MultiMappedProperty: { First: 123, Second: 456 },
    },
    {
        Id: 'TestItem2',
        Kayttooikeudet: [],
        StringProperty: 'foofoo',
        IntProperty: 123,
        RequiredStringProperty: 'bar',
        ValuePickerProperty: 789,
        CustomComponentProperty: 'baz',
        HiddenProperty: 'bozboz',
        MultiMappedProperty: { First: 123, Second: 789 },
    },
]

export const getTestDataSourceGroupData = (): IDataSourceGroupData[] => [
    {
        CustomFunctionData: [],
        Data: {},
        DataItemIds: [],
        GroupByProperties: [{ Property: 'TestDataSource_StringProperty', Transformation: null, Value: 15720 }],
        WindowFunctionData: [],
    },
    {
        CustomFunctionData: [
            {
                CustomFunction: 'MinMaxDate(Aikaväli,Alkuaika,Loppuaika)',
                Label: 'Aikaväli',
                Value: '11.12.2019–11.12.2019',
                Unit: '',
            },
            {
                CustomFunction: 'SingleOrCount(Palkkatyypit,Palkkatyyppi.Id,Palkkatyyppi.Nimi)"',
                Label: 'Palkkatyypit',
                Value: 'Tuntipalkka',
                Unit: '$',
            },
        ],
        Data: {},
        DataItemIds: null,
        GroupByProperties: [],
        WindowFunctionData: [
            { Property: 'Id', Label: 'Tapahtumat', Value: '8', Unit: 'kpl' },
            { Property: 'KestoMinuutit', Label: 'Tunnit', Value: '139', Unit: 'h' },
        ],
    },
]

export const getTestDataSource = (
    dataSourceId = getTestDataSourceId(),
    dependencies = getDataSourceDependencies()
): IDataSource => ({
    accumulatedData: [
        {
            Label: 'Test Label',
            Unit: 'TestUnit',
            Value: '123',
        },
    ],
    configuration: getTestDataSourceConfiguration(dataSourceId, dependencies),
    data: new Map([[getTestDataSourceItemId(), getTestDataSourceItem()]]),
    orderedItemIds: [getTestDataSourceItemId()],
    valuePickerIds: [],
    fetchParameters: {},
    fetchFiltersParameters: {},
    groupData: getTestDataSourceGroupData(),
    isDataFetched: true,
    timestamp: 123,
    sortSettings: new Map(),
    offset: 0,
    overrideFetchParameters: DEFAULT_OVERRIDE_REQUEST_PARAMETERS,
    dataStatus: EViewDataStatus.OK,
})

const secondDataSource: IDataSource = getTestDataSource(getSecondDataSourceId(), getSecondDataSourceDependencies())

const thirdDataSource: IDataSource = getTestDataSource(getThirdDataSourceId(), getThirdDataSourceDependencies())

export const getTestDataSourceData = (): IListViewDataContainer<ITestDataSourceItem> => {
    return {
        ListData: [getTestDataSourceItem()],
        AccumulatedData: [],
        GroupData: [],
        Status: EViewDataStatus.OK,
    }
}

export const getTestDataSourceCombinedReducerState = (
    testDataSource = getTestDataSource()
): TTestDataSourceReducerCombined => ({
    dataSource: new Map([
        [getTestDataSourceId(), testDataSource],
        [getSecondDataSourceId(), secondDataSource],
        [getThirdDataSourceId(), thirdDataSource],
    ]),
})

export const getTestDataSourceConfigurationForActualDataSource = (
    dataSourceId: string,
    configuration?: Partial<IDataSourceConfiguration>
): IDataSourceConfiguration => {
    switch (dataSourceId) {
        case 'VuorotJaVapaatDataSource':
            return getEventsTestDataSourceConfiguration(configuration)
        case 'TyontekijanakymaDataSource':
            return getEmployeeCalendarTestDataSourceConfiguration(configuration)
        default:
            throw new Error('No configuration for the given data source')
    }
}

export const getTestDataForActualDataSource = (dataSourceId: TDataSourceId): IListViewDataContainer<unknown> => {
    switch (dataSourceId) {
        case 'VuorotJaVapaatDataSource':
            return getEventsTestDataSourceData()
        case 'TyontekijanakymaDataSource':
            return getEmployeeCalendarTestDataSourceData()
        default:
            throw new Error('No configuration for the given data source')
    }
}

export const getTestDataSourceDependentReducerState = (testDataSource = getTestDataSource()): Partial<RootState> => ({
    ...getTestDataSourceCombinedReducerState(testDataSource),
    ...getAsyncOperationReducerState(),
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTestDataSourceDependentReducerMap = () => ({
    dataSource: dataSourceReducer,
    ...getAsyncOperationReducerMap(),
})
