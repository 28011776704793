import { IThunkBaseAction } from '@planier/generic-state'

import { setNavigationSectionsAction, setNavigationSubsectionsAction } from './NavigationActions'
import { fetchNavigationSectionsFromServer, fetchNavigationSubsectionsFromServer } from '../WebApi/NavigationWebApi'
import {
    asyncOperationStartedAction,
    asyncOperationFailedWithErrorDisplayThunk,
    asyncOperationSucceededAction,
} from '@planier/async-operation'
import { NAVIGATION_ITEMS_ASYNC_OPERATION } from '../Constants/AsyncOperationConstants'

export const fetchNavigationItemsThunk = (): IThunkBaseAction => async (dispatch) => {
    dispatch(asyncOperationStartedAction(NAVIGATION_ITEMS_ASYNC_OPERATION))

    try {
        const [sections, subsections] = await Promise.all([
            fetchNavigationSectionsFromServer(),
            fetchNavigationSubsectionsFromServer(),
        ])

        dispatch(setNavigationSectionsAction(sections))
        dispatch(setNavigationSubsectionsAction(subsections))

        dispatch(asyncOperationSucceededAction(NAVIGATION_ITEMS_ASYNC_OPERATION))
    } catch (error) {
        dispatch(asyncOperationFailedWithErrorDisplayThunk(error, NAVIGATION_ITEMS_ASYNC_OPERATION))
    }
}
