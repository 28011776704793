import styled from '@emotion/styled'
import { primary_100 } from 'constants/Styles'
import { FC } from 'react'

interface IEventDotProps {
    size?: number
    fillStyle?: string
    color?: string
    invisible?: boolean
}

const ColoredDot = styled.div<{ size: number; color: string; invisible: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: ${({ size }) => size}px;
    border: 1px solid white;
    background-color: ${({ color }) => color};

    ${({ invisible }) =>
        invisible
            ? `
        border: 0;
        background-color: transparent;
    `
            : ''}
`

const HollowDot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: white;
`

const CrossDot = styled.div`
    width: 4px;
    height: 4px;
    display: inline-grid;
    place-content: center;
    &:before {
        content: '\\d7';
        color: #fff;
    }
`

const EventDot: FC<IEventDotProps> = ({ size, fillStyle, color = primary_100, invisible = false }) => {
    return (
        <ColoredDot color={color} size={size ?? 10} invisible={invisible}>
            {!invisible && fillStyle === 'hollow' ? <HollowDot /> : ''}
            {!invisible && fillStyle === 'cross' ? <CrossDot /> : ''}
        </ColoredDot>
    )
}

export default EventDot
