import * as React from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import MaterialUiMenu from '@material-ui/core/Menu'

type TOnMenuOpen = (event: React.MouseEvent) => void
type TAnchorPosition = 'element' | 'mouse'

interface IMenuProps {
    children: (onClick: TOnMenuOpen) => React.ReactNode
    renderMenuContent: (handleMenuClose: () => void) => React.ReactNode
    anchorOrigin?: React.ComponentProps<typeof MaterialUiMenu>['anchorOrigin']
    className?: string

    /**
     * Tells how the menu anchors itself to its child when it's open.
     * By default anchors itself to the element (so for example the
     * menu opens below the element) but you can make it open
     * where the user clicked.
     */
    anchorPosition?: TAnchorPosition
    onOpen?: () => void
}

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
`

interface IInitialState {
    mouseX: null | number
    mouseY: null | number
    isOpen: boolean
    anchorEl: null | Element
}

const initialState = {
    mouseX: null,
    mouseY: null,
    isOpen: false,
    anchorEl: null,
}

/**
 * Menu component that you can easily render for any component.
 * Renders any given content.
 */
const Menu: React.FC<IMenuProps> = ({
    children,
    anchorOrigin = { vertical: 'bottom', horizontal: 'right' },
    className,
    anchorPosition = 'element',
    onOpen,
    renderMenuContent,
}) => {
    const [state, setState] = useState<IInitialState>(initialState)

    const handleMenuOpen = (event: React.MouseEvent) => {
        event.preventDefault()

        onOpen && onOpen()

        if (anchorPosition === 'element') {
            setState({ ...state, anchorEl: event.currentTarget, isOpen: true })
        } else {
            setState({
                ...state,
                mouseX: event.clientX - 2,
                mouseY: event.clientY - 4,
                isOpen: true,
            })
        }
    }

    const handleMenuClose = () => {
        setState({ ...state, anchorEl: null, isOpen: false })
    }

    return (
        <React.Fragment>
            {children(handleMenuOpen)}

            {state.isOpen && (
                <MaterialUiMenu
                    anchorEl={state.anchorEl}
                    anchorOrigin={anchorOrigin}
                    anchorPosition={
                        state.mouseY !== null && state.mouseX !== null
                            ? { top: state.mouseY, left: state.mouseX }
                            : undefined
                    }
                    anchorReference={anchorPosition === 'mouse' ? 'anchorPosition' : 'anchorEl'}
                    className={className}
                    getContentAnchorEl={null}
                    onClose={handleMenuClose}
                    open={state.isOpen}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    variant="menu"
                >
                    <ContentContainer>{renderMenuContent(handleMenuClose)}</ContentContainer>
                </MaterialUiMenu>
            )}
        </React.Fragment>
    )
}

export default Menu
