import makeRequest, { ERequestMethod } from '@planier/rest-api'
import { CancelToken } from 'axios'

export const CREATE_KASITTELYERA_URL = '/Tapahtuma/Kasittelyera'

export const createKasittelyera = async (tapahtumaIds: string[], cancelToken: CancelToken): Promise<number> =>
    makeRequest<number>({
        method: ERequestMethod.POST,
        url: CREATE_KASITTELYERA_URL,
        data: {
            tapahtumaIdt: tapahtumaIds,
        },
        cancelToken,
    })

export const PROCESS_EVENTS_URL = '/Tapahtuma/Kasittelyera/Kasittele'
export const processEventsForProcessingBatch = async (
    processingBatchIds: number[],
    cancelToken?: CancelToken
): Promise<void> =>
    makeRequest<void>({
        method: ERequestMethod.POST,
        url: PROCESS_EVENTS_URL,
        data: {
            ToteumankasittelyeraIds: processingBatchIds,
        },
        cancelToken,
    })
