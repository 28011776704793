import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'

import { NonInitialNodesContainer } from './HeaderNodesContainer'
import CloseButton from './CloseButton'
import { BoundThunk } from '@planier/generic-state'
import { resetToCalendarInitialEndDateThunk } from '../../../../Thunks/ConfigurableCalendarThunks'

interface IOwnProps {
    children: JSX.Element[]
    totalHeaderNodesCount: number
}

interface IDispatchProps {
    resetToCalendarInitialEndDate: BoundThunk<typeof resetToCalendarInitialEndDateThunk>
}

interface INodesAfterInitialNodesProps extends IOwnProps, IDispatchProps {}

const PositionedCloseButton = styled(CloseButton)`
    position: absolute;
    left: 0;
`

const NodesAfterInitialNodes: React.FC<INodesAfterInitialNodesProps> = ({
    children,
    resetToCalendarInitialEndDate,
    totalHeaderNodesCount,
}) => {
    return (
        <NonInitialNodesContainer
            childHeadersCount={children.length}
            hasContent={children.length > 0}
            totalHeaderNodesCount={totalHeaderNodesCount}
        >
            {children.length > 0 && <PositionedCloseButton onClick={() => resetToCalendarInitialEndDate()} />}
            {children}
        </NonInitialNodesContainer>
    )
}

const mapStateToProps = null
const mapDispatchToProps = {
    resetToCalendarInitialEndDate: resetToCalendarInitialEndDateThunk,
}

export default connect(mapStateToProps, mapDispatchToProps)(NodesAfterInitialNodes)
