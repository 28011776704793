import { FC } from 'react'
import styled from '@emotion/styled'
import { IDropdownListOption } from './Dropdown'
import { dark_100, dark_40, light_grey_100, primary_100, primary_120 } from 'constants/Styles'
import Checkbox from '../Checkbox'
import TextInput from '../TextInput/TextInputV2'
import Icon from '../../Icon'
import TranslatedTypography from '../../TranslatedTypography'
import { isEmpty } from 'lodash-es'
import Progress from 'components/molecules/Progress'

interface ISelectorOptionsProps {
    isMultiselect?: boolean
    onClick: (option: IDropdownListOption | []) => void
    onMultipleOptionsSelected: (selectedOptions: number[]) => void
    options: IDropdownListOption[]
    onClose: () => void
    selectedOptions: number[]
    onClearSelection: () => void
    useFilter: boolean
    onChangeFilter: (filterText: string) => void
    filterText: string
    showClearSelection?: boolean
    itemOptionLabelFields?: string[]
    autoFocusFilter?: boolean
    isLoading?: boolean
}

interface IOptionProps {
    selected: boolean
}

interface IOptionsFilterProps {
    onChange?: (filterText: string) => void
    value: string
    isLoading?: boolean
}

interface IClearSelectionButtonProps {
    isMultiselect?: boolean
    onClick: () => void
    selectedOptions: number[]
}

const Container = styled.div`
    border: 0px;
    box-sizing: border-box;
    border-radius: 4px;
    max-height: 328px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
`

const Option = styled.div<IOptionProps>`
    display: flex;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 12px;
    flex: 1;
    width: 100%;
    flex-direction: row;
    align-items: center;
    &:hover {
        cursor: pointer;
        background-color: ${light_grey_100};
    }
    ${({ selected }) => (selected ? `background-color: ${light_grey_100};` : '')}
`

const Text = styled.div`
    font-family: 'Inter';
    font-size: 14px;
    color: ${dark_100};
`

const OptionsFilterContainer = styled.div`
    padding-left: 6px;
    padding-right: 0;
    position: relative;
`

const ClearSelectionButtonContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5px;
    margin-bottom: 4px;
`

const ClearSelectionButtonText = styled.button`
    color: ${primary_120};
    font-family: 'Inter';
    font-size: 12px;
    height: 12px;
    &:hover {
        cursor: pointer;
    }
`

const SelectAllContainer = styled.div`
    width: 100%;
    border-bottom: 1px solid ${primary_100};
`

const NoResultsText = styled(TranslatedTypography)`
    margin-top: 10px;
    margin-bottom: 10px;
`

const LoadingIndicator = styled(Progress)`
    position: absolute;
    right: 40px;
    top: 25%;
`

const OptionsFilter: FC<IOptionsFilterProps> = ({ onChange, value, isLoading, autoFocus = false }) => {
    return (
        <OptionsFilterContainer>
            <TextInput
                overrideStyle={{ width: '256px' }}
                value={value}
                onChange={onChange}
                endAdorment={<Icon color={dark_40}>search</Icon>}
                autoFocus={autoFocus}
            />
            {isLoading && <LoadingIndicator size={20} />}
        </OptionsFilterContainer>
    )
}

const ClearSelectionButton: FC<IClearSelectionButtonProps> = ({ isMultiselect, onClick, selectedOptions }) => {
    const buttonText = !isEmpty(selectedOptions) ? (
        <ClearSelectionButtonText onClick={onClick}>
            {isMultiselect ? 'tyhjennä valinnat' : 'tyhjennä valinta'}
        </ClearSelectionButtonText>
    ) : (
        <ClearSelectionButtonText />
    )

    return <ClearSelectionButtonContainer>{buttonText}</ClearSelectionButtonContainer>
}

const SelectorOptions = ({
    isMultiselect,
    options,
    onClick,
    onMultipleOptionsSelected,
    selectedOptions,
    useFilter,
    onChangeFilter,
    onClearSelection,
    filterText,
    itemOptionLabelFields,
    autoFocusFilter,
    isLoading,
    showClearSelection = true,
}: ISelectorOptionsProps): JSX.Element => {
    const allOptionsSelected = selectedOptions.length === options.length

    const handleOnClick = (option: IDropdownListOption) => {
        onClick(option)
    }

    const handleSelectAll = () => {
        const newOptions = !allOptionsSelected
            ? (options.map((option: IDropdownListOption) => option.Id) as number[])
            : []

        onMultipleOptionsSelected(newOptions)
    }

    const optionsBlock = options.map((option) => {
        const selected = selectedOptions.some((selectedOption) => selectedOption === option.Id)

        const optionText = () => {
            if (!itemOptionLabelFields) {
                return option.Name
            }

            return itemOptionLabelFields.map((labelField) => (option as Record<string, any>)[labelField]).join(' ')
        }

        return (
            <Option key={option.Id} onClick={() => handleOnClick(option)} selected={selected}>
                {isMultiselect ? <Checkbox onClick={() => handleOnClick(option)} value={selected} /> : null}
                <Text>{optionText()}</Text>
            </Option>
        )
    })

    const selectAllOption = isMultiselect && !isEmpty(options) && (
        <SelectAllContainer>
            <Option selected={allOptionsSelected} onClick={handleSelectAll}>
                <Checkbox onClick={handleSelectAll} value={allOptionsSelected} />
                <Text>
                    <TranslatedTypography>valitse-kaikki</TranslatedTypography>
                </Text>
            </Option>
        </SelectAllContainer>
    )

    return (
        <Container>
            {showClearSelection && (
                <ClearSelectionButton
                    onClick={onClearSelection}
                    isMultiselect={isMultiselect}
                    selectedOptions={selectedOptions}
                />
            )}
            {useFilter && (
                <OptionsFilter
                    onChange={onChangeFilter}
                    value={filterText}
                    autoFocus={autoFocusFilter}
                    isLoading={isLoading}
                />
            )}
            {isEmpty(options) && <NoResultsText>no-results-found</NoResultsText>}
            {selectAllOption}
            {optionsBlock}
        </Container>
    )
}

export default SelectorOptions
