import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import Styles from 'constants/Styles'
import { useMoveHorizontallyThroughElements } from '@planier/generic-utilities'

interface ISideWaysMovingProps {
    children: React.ReactNode
    className?: string
    backButtonClassName?: string
    forwardButtonClassName?: string
    innerContainerClassName?: string
}

const Container = styled.div`
    display: flex;
    overflow: hidden;
    flex-grow: 1;
    align-items: center;
    position: relative;
`

const moveButtonBaseStyles = css`
    cursor: pointer;
    position: absolute;
    z-index: 1;
    font-size: 20px;
`

type TChevronIconProps = React.ComponentProps<typeof ChevronLeftIcon | typeof ChevronRightIcon>

interface IMoveIconProps extends TChevronIconProps {
    isEnabled: boolean
}

const moveButtonEnabledColor = '#6D6E71'

const getEnabledOrDisabledStyles = ({ isEnabled }: IMoveIconProps) => {
    return css`
        pointer-events: ${isEnabled ? 'auto' : 'none'};
        color: ${isEnabled ? moveButtonEnabledColor : Styles.supplementaryColor.buttonDisabled};
    `
}

const BackButton = styled<React.FC<IMoveIconProps>>(ChevronLeftIcon, {
    shouldForwardProp: (prop: keyof IMoveIconProps) => prop !== 'isEnabled',
})`
    ${moveButtonBaseStyles}

    ${getEnabledOrDisabledStyles}

    left: 0;
`

const ToNextButton = styled<React.FC<IMoveIconProps>>(ChevronRightIcon, {
    shouldForwardProp: (prop: keyof IMoveIconProps) => prop !== 'isEnabled',
})`
    ${moveButtonBaseStyles}

    ${getEnabledOrDisabledStyles}

    right: 0;
`

const InnerContainer = styled.div<{ areMoveButtonsNeededToBeDisplayed: boolean }>`
    display: flex;
    overflow: hidden;
    align-items: center;
    width: 100%;
    margin: auto;
    white-space: nowrap;

    ${({ areMoveButtonsNeededToBeDisplayed }) =>
        areMoveButtonsNeededToBeDisplayed &&
        `
        margin-right: 20px;
        margin-left: 20px;
    `}
`

/**
 * Renders the given component and if it doesn't fit its space completely,
 * on both of sides of it renders arrow buttons, from which the user can
 * move sideways to see the rest of the content.
 */
const SideWaysMoving: React.FC<ISideWaysMovingProps> = ({
    children,
    className,
    forwardButtonClassName,
    backButtonClassName,
    innerContainerClassName,
}) => {
    const {
        containerRef,
        handleBackButtonClick,
        handleForwardButtonClick,
        isForwardElementEnabled,
        isBackElementEnabled,
        areMoveButtonsNeededToBeDisplayed,
    } = useMoveHorizontallyThroughElements()

    return (
        <Container className={className}>
            {areMoveButtonsNeededToBeDisplayed && (
                <BackButton
                    className={backButtonClassName}
                    isEnabled={isBackElementEnabled}
                    onClick={handleBackButtonClick}
                />
            )}
            <InnerContainer
                areMoveButtonsNeededToBeDisplayed={areMoveButtonsNeededToBeDisplayed}
                className={innerContainerClassName}
                ref={containerRef}
            >
                {children}
            </InnerContainer>

            {areMoveButtonsNeededToBeDisplayed && (
                <ToNextButton
                    className={forwardButtonClassName}
                    isEnabled={isForwardElementEnabled}
                    onClick={handleForwardButtonClick}
                />
            )}
        </Container>
    )
}

export default SideWaysMoving
