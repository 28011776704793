import { IDataSourceItem, TDataSourceItemId } from '@planier/data-source-types'
import IDataSourceData from '../Types/IDataSourceData'

export interface IParsedDataSourceData {
    data: IDataSourceData | null
    orderedItemIds: TDataSourceItemId[] | null
}

const parseDataSourceData = (rawData: IDataSourceItem[] | null): IParsedDataSourceData => {
    if (rawData === null) {
        return { data: null, orderedItemIds: null }
    }

    const orderedItemIds: TDataSourceItemId[] = []
    const data = new Map([]) as Map<TDataSourceItemId, IDataSourceItem>

    rawData.forEach((dataItem) => {
        orderedItemIds.push(dataItem.Id)
        data.set(dataItem.Id, dataItem)
    })

    return {
        data,
        orderedItemIds,
    }
}

export default parseDataSourceData
