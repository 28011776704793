import styled from '@emotion/styled'
import moment, { Moment } from 'moment'
import * as React from 'react'

import ActionLink from 'components/atoms/ActionLink'
import DateUtility from 'common/DateUtility'
import DayPicker, { DayModifiers } from 'react-day-picker'
import { Translation } from '@planier/localization'
import 'react-day-picker/lib/style.css'

type TMonths = [string, string, string, string, string, string, string, string, string, string, string, string]
const MONTHS: TMonths = [
    Translation.translateKey('kuukausi-tammi'),
    Translation.translateKey('kuukausi-helmi'),
    Translation.translateKey('kuukausi-maalis'),
    Translation.translateKey('kuukausi-huhti'),
    Translation.translateKey('kuukausi-touko'),
    Translation.translateKey('kuukausi-kesa'),
    Translation.translateKey('kuukausi-heina'),
    Translation.translateKey('kuukausi-elo'),
    Translation.translateKey('kuukausi-syys'),
    Translation.translateKey('kuukausi-loka'),
    Translation.translateKey('kuukausi-marras'),
    Translation.translateKey('kuukausi-joulu'),
]

type TWeekdays = [string, string, string, string, string, string, string]
const WEEKDAYS_LONG: TWeekdays = [
    Translation.translateKey('viikonpaiva-Sunnuntai'),
    Translation.translateKey('viikonpaiva-Maanantai'),
    Translation.translateKey('viikonpaiva-Tiistai'),
    Translation.translateKey('viikonpaiva-Keskiviikko'),
    Translation.translateKey('viikonpaiva-Torstai'),
    Translation.translateKey('viikonpaiva-Perjantai'),
    Translation.translateKey('viikonpaiva-Lauantai'),
]

const WEEKDAYS_SHORT: TWeekdays = [
    Translation.translateKey('viikonpaiva-sunnuntai-lyhyt'),
    Translation.translateKey('viikonpaiva-maanantai-lyhyt'),
    Translation.translateKey('viikonpaiva-tiistai-lyhyt'),
    Translation.translateKey('viikonpaiva-keskiviikko-lyhyt'),
    Translation.translateKey('viikonpaiva-torstai-lyhyt'),
    Translation.translateKey('viikonpaiva-perjantai-lyhyt'),
    Translation.translateKey('viikonpaiva-lauantai-lyhyt'),
]

const isDateSelected = (selectedDays: Moment[]) => {
    if (!selectedDays || selectedDays.length === 0) {
        return () => false
    }
    return (day: Date) => selectedDays.some((selectedDay) => DateUtility.isSameDate(selectedDay, day))
}

const addToSelected = (selectedDays: Moment[], day: Date) => {
    return selectedDays.concat(moment(day))
}

const removeFromSelected = (selectedDays: Moment[], day: Date) => {
    return selectedDays.filter((selectedDay) => !DateUtility.isSameDate(selectedDay, day))
}

const Container = styled.div`
    text-align: center;
`

export interface IMultiDatepickerProps {
    onBlur?: () => void
    handleSelection: (selectedDays: Moment[]) => void
    initialMonth?: Moment
    maxDate?: Moment
    minDate?: Moment
    singleDay: boolean
    value: Moment[]
    disabled: boolean
}

export default class MultiDatepicker extends React.Component<IMultiDatepickerProps> {
    static readonly defaultProps = {
        disabled: false,
        singleDay: false,
        value: [],
    }

    isDateDisabled = (day: Date): boolean => {
        if (this.props.disabled) {
            return true
        }

        const dayFromStart = moment(day).startOf('day')

        if (this.props.maxDate && this.props.minDate) {
            return this.dayIsAfterMaxDate(dayFromStart) || this.dayIsBeforeMinDate(dayFromStart)
        } else if (this.props.maxDate) {
            return this.dayIsAfterMaxDate(dayFromStart)
        } else if (this.props.minDate) {
            return this.dayIsBeforeMinDate(dayFromStart)
        }

        return false
    }

    reset = (): void => {
        this.props.handleSelection([])
    }

    handleDayClick = (day: Date, modifiers: DayModifiers, e: React.MouseEvent<HTMLDivElement>): void => {
        if (modifiers.disabled && !modifiers.selected) {
            return
        }

        const currentValue = this.props.value

        let valueToDispatch
        if (this.props.singleDay) {
            valueToDispatch = modifiers.selected ? [] : [moment(day)]
        } else {
            valueToDispatch = modifiers.selected
                ? removeFromSelected(currentValue, day)
                : addToSelected(currentValue, day)
        }

        this.props.handleSelection(valueToDispatch)

        e.stopPropagation()
        e.preventDefault()
    }

    private dayIsAfterMaxDate(day: Moment) {
        return day.isAfter(this.props.maxDate, 'day')
    }

    private dayIsBeforeMinDate(day: Moment) {
        return day.isBefore(this.props.minDate, 'day')
    }

    private monthShown(value: Moment[] = []) {
        if (this.props.initialMonth) {
            return moment(this.props.initialMonth).toDate()
        }

        if (value.length === 0) {
            return new Date()
        }

        const lastChosenValue = value[value.length - 1]

        return lastChosenValue.toDate()
    }

    render(): React.ReactNode {
        const { singleDay, value, minDate, maxDate, onBlur, disabled } = this.props
        const monthShown = this.monthShown(value)

        return (
            <Container>
                <div>
                    <DayPicker
                        disabledDays={this.isDateDisabled}
                        firstDayOfWeek={1}
                        fixedWeeks
                        fromMonth={minDate ? minDate.toDate() : undefined}
                        initialMonth={monthShown}
                        locale="fi"
                        month={monthShown}
                        months={MONTHS}
                        onBlur={onBlur}
                        onDayClick={this.handleDayClick}
                        selectedDays={isDateSelected(value)}
                        toMonth={maxDate ? maxDate.toDate() : undefined}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                    />
                </div>
                {!singleDay && (
                    <ActionLink clickAction={this.reset} disabled={disabled}>
                        {Translation.translateKey('nollaa-paivamaaravalinnat')}
                    </ActionLink>
                )}
            </Container>
        )
    }
}
