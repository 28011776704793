import { useState, useEffect } from 'react'
import * as React from 'react'
import styled from '@emotion/styled'
import PickersDay from '@material-ui/lab/PickersDay'

import DayPicker, { formatForDataUsage } from '../DayPicker'
import DateUtility from 'common/DateUtility'
import { isWithinRange, TDateStringDataFormat, TDateObject, toDateObject } from '@planier/dates'

const classNameTextInput = 'pln-DayRange-TextInput'
const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .${classNameTextInput} {
        max-width: 130px;
    }
`

const Divider = styled.div`
    width: 45x;
`

const DayBetweenRange = styled(PickersDay)`
    opacity: 50%;
`

type TDayPickerProps = React.ComponentProps<typeof DayPicker>

interface IOnChangeParameters {
    start: TDateStringDataFormat | null
    end: TDateStringDataFormat | null
}

interface IDayRangePickerProps
    extends Pick<TDayPickerProps, 'shouldDateBeDisabled' | 'required' | 'onBlur' | 'validationError'> {
    onChange: (startAndEnd: IOnChangeParameters) => void
    startLabel?: string
    endLabel?: string
    endDay: TDateStringDataFormat | null
    startDay: TDateStringDataFormat | null
    isStartDayErrored?: boolean
    isEndDayErrored?: boolean
    validationErrorStartDay?: string | null
    validationErrorEndDay?: string | null
    isFieldErrored?: boolean
}

const DayRangePicker: React.FC<IDayRangePickerProps> = ({
    endDay,
    startDay,
    onChange,
    required,
    startLabel = 'generic-components.DayRangePicker.StartLabel.DefaultValue',
    endLabel = 'generic-components.DayRangePicker.EndLabel.DefaultValue',
    shouldDateBeDisabled,
    onBlur,
    isStartDayErrored: isStartDayErroredProp = false,
    isEndDayErrored: isEndDayErroredProp = false,
    validationErrorStartDay = null,
    validationErrorEndDay = null,
    isFieldErrored = false,
    validationError,
}) => {
    const [isStartOpen, setIsStartOpen] = useState(false)
    const [isEndOpen, setIsEndOpen] = useState(false)

    const [{ startValueInternal, endValueInternal }, setValuesInternal] = useState<{
        startValueInternal: null | TDateStringDataFormat
        endValueInternal: null | TDateStringDataFormat
    }>({ startValueInternal: startDay, endValueInternal: endDay })

    useEffect(() => {
        setValuesInternal({
            startValueInternal: startDay,
            endValueInternal: endDay,
        })
    }, [startDay, endDay])

    const getStartAndEndDate = (newSelectedDays: TDateStringDataFormat[], nowSelectedDay: TDateStringDataFormat) => {
        const allUniqueSelectedDays = [...new Set([...newSelectedDays, startDay, endDay])].filter(
            (day): day is TDateStringDataFormat => day !== null
        )

        const amountOfDaysSelected = allUniqueSelectedDays.length

        if (nowSelectedDay === endDay) {
            return { start: startDay, end: null }
        }

        if (nowSelectedDay === startDay) {
            return { start: null, end: endDay }
        }

        if (amountOfDaysSelected === 0) {
            return { start: null, end: null }
        }

        if (amountOfDaysSelected === 1 || amountOfDaysSelected === 3) {
            return { start: nowSelectedDay, end: null }
        }

        const daysAsDateObjects = allUniqueSelectedDays.map((day) => toDateObject(day))

        const firstDateSelected = DateUtility.getSmallestDate(daysAsDateObjects) as TDateObject
        const lastDateSelected = DateUtility.getLastDate(daysAsDateObjects) as TDateObject

        return { start: formatForDataUsage(firstDateSelected), end: formatForDataUsage(lastDateSelected) }
    }

    // TODO: don't need separate function for start and end
    const handleStartDayChange = (newSelectedDays: TDateStringDataFormat[], nowSelectedDay: TDateStringDataFormat) => {
        const newStartAndEnd = getStartAndEndDate(newSelectedDays, nowSelectedDay)

        setValuesInternal({ startValueInternal: newStartAndEnd.start, endValueInternal: newStartAndEnd.end })

        onChange(newStartAndEnd)
    }

    const handleEndDayChange = (newSelectedDays: TDateStringDataFormat[], nowSelectedDay: TDateStringDataFormat) => {
        const newStartAndEnd = getStartAndEndDate(newSelectedDays, nowSelectedDay)

        setValuesInternal({ startValueInternal: newStartAndEnd.start, endValueInternal: newStartAndEnd.end })

        onChange(newStartAndEnd)
    }

    const handleStartOpen = () => {
        if (isEndOpen) {
            setIsEndOpen(false)
        }
        setIsStartOpen(true)
    }

    const handleStartClose = () => {
        setIsStartOpen(false)
    }

    const handleEndOpen = () => {
        if (isStartOpen) {
            setIsStartOpen(false)
        }
        setIsEndOpen(true)
    }

    const handleEndClose = () => {
        setIsEndOpen(false)
    }

    const isDayDisplayedAsSelectedForStartDayPicker = (day: string) => {
        return day === endDay
    }

    const isDayDisplayedAsSelectedForEndDayPicker = (day: string) => {
        return day === startDay
    }

    const handleRenderingForDatesBetweenDateRange: TDayPickerProps['renderDay'] = (
        day,
        selectedDates,
        props,
        defaultDayRenderer
    ) => {
        const isStartOrEndDay = day === startDay || day === endDay

        if (isStartOrEndDay || startDay === null || endDay === null) {
            return defaultDayRenderer(day, selectedDates, { ...props, selected: isStartOrEndDay })
        }

        if (isWithinRange(day, startDay, endDay)) {
            return <DayBetweenRange {...props} selected />
        }

        return defaultDayRenderer(day, selectedDates, props)
    }

    const isStartDayErrored = isStartDayErroredProp || (isFieldErrored && startValueInternal === null)
    const isEndDayErrored = isEndDayErroredProp || (isFieldErrored && endValueInternal === null)

    return (
        <Container>
            <DayPicker
                classNameTextInput={classNameTextInput}
                isDayDisplayedAsSelectedFunc={isDayDisplayedAsSelectedForStartDayPicker}
                isFieldErrored={isStartDayErrored}
                isOpen={isStartOpen}
                label={startLabel}
                multiselect
                onBlur={onBlur}
                onChange={handleStartDayChange}
                onClose={handleStartClose}
                onOpen={handleStartOpen}
                renderDay={handleRenderingForDatesBetweenDateRange}
                required={required}
                shouldDateBeDisabled={shouldDateBeDisabled}
                validationError={validationErrorStartDay ?? validationError}
                value={startDay ? [startDay] : []}
            />
            <Divider />
            <DayPicker
                classNameTextInput={classNameTextInput}
                isDayDisplayedAsSelectedFunc={isDayDisplayedAsSelectedForEndDayPicker}
                isFieldErrored={isEndDayErrored}
                isOpen={isEndOpen}
                label={endLabel}
                multiselect
                onBlur={onBlur}
                onChange={handleEndDayChange}
                onClose={handleEndClose}
                onOpen={handleEndOpen}
                renderDay={handleRenderingForDatesBetweenDateRange}
                required={required}
                shouldDateBeDisabled={shouldDateBeDisabled}
                validationError={validationErrorEndDay ?? validationError}
                value={endDay ? [endDay] : []}
            />
        </Container>
    )
}

export default DayRangePicker
