import { connect } from 'react-redux'
import * as React from 'react'
import { RootState } from 'typesafe-actions'

import { selectModalProps } from '@planier/modal'
import { IDataSourceItem } from '@planier/data-source-types'
import { IFormViewCustomFormComponent } from '@planier/form-view-types'

interface IOwnProps {
    FormComponent: IFormViewCustomFormComponent
    formId: string
}

type TDataSourceItems = IDataSourceItem[]

interface IStateProps {
    selectedItems: TDataSourceItems
}
interface ICustomModalProps extends IOwnProps, IStateProps {}

export const FormViewCustomModalUnconnected: React.FunctionComponent<ICustomModalProps> = ({
    formId,
    FormComponent,
    selectedItems,
}) => <FormComponent formId={formId} selectedItems={selectedItems} />

interface IListModalProps extends Record<string, unknown> {
    items: TDataSourceItems
}

const mapStateToProps = (state: RootState, { formId }: IOwnProps): IStateProps => {
    const modalProps = selectModalProps<IListModalProps>(state, formId)

    return {
        selectedItems: modalProps ? modalProps.items : [],
    }
}

export default connect(mapStateToProps)(FormViewCustomModalUnconnected)
