import ReadOnlyText from '../../ReadOnlyText'
import styled from '@emotion/styled'
import { JSX } from 'react'
import { getPreviewEventColor, selectableColorIds } from '@planier/theme'

type Props = {
    label: string
    onChange?: (colorId: string | null) => void
    value: string
}

const DotsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
`

export const ColoredDot = styled.div<{ color: string; size: number; selected?: boolean }>`
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    cursor: pointer;
    transition: box-shadow ${({ theme }) => theme.tokens.transitionQuick};
    box-shadow: ${({ selected, theme }) => selected && `0 0 0 3px ${theme.colors.primaryTeal60}`};
`

const ColorPicker = ({ label, onChange, value }: Props): JSX.Element => {
    const handleOnClick = (colorId: string) => {
        if (!onChange) {
            return
        }

        onChange(colorId !== value ? colorId : null)
    }

    return (
        <>
            <ReadOnlyText usage="h5">{label}</ReadOnlyText>
            <DotsContainer>
                {selectableColorIds.map((colorId) => (
                    <ColoredDot
                        key={colorId}
                        size={18}
                        onClick={() => handleOnClick(colorId)}
                        color={getPreviewEventColor(colorId) ?? '#fff'}
                        selected={colorId === value}
                    />
                ))}
            </DotsContainer>
        </>
    )
}

export default ColorPicker
