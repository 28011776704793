import { MomentInput } from 'moment'
import * as React from 'react'

import DateUtility from 'common/DateUtility'
import { ListElementText } from '@planier/configurable-list-components'

interface ITimeRangeProps {
    alkuaika: MomentInput
    loppuaika: MomentInput
}

const TimeRange: React.FC<ITimeRangeProps> = ({ alkuaika, loppuaika }) => {
    if (!alkuaika && !loppuaika) {
        return null
    }

    return (
        <ListElementText
            displayValue={`${DateUtility.asHourMinutes(alkuaika)} - ${DateUtility.asHourMinutes(loppuaika)}`}
        />
    )
}

export default TimeRange
