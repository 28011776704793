import { JSX, useEffect, useLayoutEffect } from 'react'
import { FormModal } from '@planier/modal'
import styled from '@emotion/styled'
import { ISummaryLabel, SummaryLabels } from '@planier/generic-components/SummaryLabel/SummaryLabels'
import NewMessageForm from './NewMessageForm'
import { Button, ReadOnlyText, SubTabs } from '@planier/generic-components'
import MessageThread from './Thread'
import IMessageThread from '../Types/IMessageThread'
import { useDispatch, useSelector } from 'react-redux'
import {
    isNoEmployeeSelectedFromRow,
    selectIsGroupMessaging,
    selectMessageThreadsBasedOnSelectedTab,
    selectMessagingModalTitle,
    selectSelectedEmployeeName,
    selectSelectedTab,
    selectShowMessageThread,
    selectShowNewMessageForm,
    selectViewedMessageThread,
} from '../State/MessagingSelectors'
import {
    modalClosed,
    setSelectedIds,
    setSelectedRecipients,
    setShowMessageThread,
    setShowNewMessageForm,
    tabSelected,
} from '../State/MessagingReducer'
import { MessageThreadForm } from './MessageThreadForm'
import { useScreenDimensions } from '@planier/form-view/Components/FormView/utils'
import { closeMessageModal, getMessagesForThread, getMessageThreadsForEmployee } from '../State/MessagingThunks'
import { IFormViewCustomFormComponentProps } from 'packages/form-view-types'
import CenteredLoadingIndicator from './CenteredLoadingIncicator'
import { IEmployeeDataSourceItem } from '../Types/IEmployeeDataSourceItem'
import { IMessagingDataSourceItem } from '../Types/IMessagingDataSourceItem'
import { setValuePickerValueAction } from '../../value-picker'
import { selectCurrentPage } from '@planier/navigation/State/NavigationSelectors'

interface IMessagingFormProps
    extends IFormViewCustomFormComponentProps<IEmployeeDataSourceItem & IMessagingDataSourceItem> {}

const classNameContentContainer = 'MuiDialogContent-root'

const EMPLOYEE_VALUEPICKER = 'FormView_ValuePicker_Tyontekija_No_Dependencies'

const MessagingModalComponent = styled(FormModal)`
    .${classNameContentContainer} {
        padding: 0;
    }
`

export const MESSAGING_FORM_ID = 'MessagingForm'

const WrappedSummaryLabels = styled.div`
    padding-bottom: 24px;
    padding-left: 12px;
`

const MessagesContainer = styled.div<{ showNewMessageForm: boolean }>`
    display: flex;
    flex-direction: column;
    width: 600px;
    padding: 24px 56px 24px 32px;
    gap: 6px;
`

const SideScrollingContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
    overflow: hidden;
    max-height: calc(100vh - 200px);
`

const PanelsContainer = styled.div<{ showMessageThread: boolean; newMessageFormVisible: boolean }>`
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 0 0 1200px;
    transition: left 0.4s ease-in-out;
    left: ${({ showMessageThread }) => (showMessageThread ? '0' : '600px')};
    height: ${({ newMessageFormVisible }) => (!newMessageFormVisible ? 'auto' : '0')};
`

const MessagesPanel = styled.div<{ showMessageThread: boolean }>`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    width: 600px;
    pointer-events: ${({ showMessageThread }) => (showMessageThread ? 'none' : 'all')};
`

const MessageThreadPanel = styled.div<{ showMessageThread: boolean; containerHeight: number }>`
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    pointer-events: ${({ showMessageThread }) => (showMessageThread ? 'all' : 'none')};
    z-index: 1400;
    overflow-y: auto;
    width: 600px;
`

const OpenNewMessageButtonContainer = styled.div`
    margin-left: 32px;
`

const MessagingForm = (props: IMessagingFormProps): JSX.Element => {
    // We do not use formik form on this modal. But the base modal expects these props.
    // Instead we supply minimum required props for it to be satisfied
    const formikProps = {
        initialValues: {},
        onSubmit: () => {
            // ploi
        },
    }

    const dispatch = useDispatch()

    const showMessageThread = useSelector(selectShowMessageThread)
    const showNewMessageForm = useSelector(selectShowNewMessageForm)
    const modalTitle = useSelector(selectMessagingModalTitle)
    const messageThreads = useSelector(selectMessageThreadsBasedOnSelectedTab)
    const isGroupMessaging = useSelector(selectIsGroupMessaging)
    const selectedEmployeeName = useSelector(selectSelectedEmployeeName)
    const selectedTab = useSelector(selectSelectedTab)
    const displayOnlyNewMessageForm = useSelector(isNoEmployeeSelectedFromRow)
    const viewedThread = useSelector(selectViewedMessageThread)
    const currentPage = useSelector(selectCurrentPage)

    const handleOnClose = () => {
        dispatch(modalClosed())
        dispatch(closeMessageModal(MESSAGING_FORM_ID, currentPage === 'Messages' ? 'ThreadMessage' : 'Employee'))
    }

    useEffect(() => {
        if (props.selectedItems) {
            dispatch(setSelectedIds(props.selectedItems))

            dispatch(
                setSelectedRecipients(
                    props.selectedItems.map(({ EmployeeName, EmployeeId }) => ({
                        Id: EmployeeId as number,
                        Name: EmployeeName,
                    }))
                )
            )
        }
    }, [dispatch, props.selectedItems])

    const messageThreadClicked = (thread: IMessageThread | undefined) => {
        if (thread) {
            dispatch(getMessagesForThread(thread))
        }
    }

    useEffect(() => {
        if (!showMessageThread) {
            dispatch(getMessageThreadsForEmployee())
        }
    }, [dispatch, showMessageThread])

    useEffect(() => {
        dispatch(setValuePickerValueAction([], EMPLOYEE_VALUEPICKER))
    }, [dispatch])

    // ensures the thread is toggled after it's been rendered and the browser knows its actual width
    useLayoutEffect(() => {
        if (viewedThread) {
            setTimeout(() => {
                dispatch(setShowMessageThread(true))
            }, 0)
        }
    }, [dispatch, viewedThread])

    const toggleNewMessageFormVisibility = () => {
        dispatch(setShowNewMessageForm(!showNewMessageForm))
    }

    const handleTabSelected = (tab: string) => {
        dispatch(tabSelected(tab))
    }

    const { height: windowHeight } = useScreenDimensions()

    const summaryLabels: ISummaryLabel[] = [
        {
            Icon: 'account_circle',
            Text: selectedEmployeeName ?? '',
        },
    ]

    const newMessageFormVisible = isGroupMessaging || showNewMessageForm || displayOnlyNewMessageForm

    return (
        <MessagingModalComponent
            formikProps={formikProps}
            formId={MESSAGING_FORM_ID}
            modalId={MESSAGING_FORM_ID}
            title={modalTitle}
            onClose={handleOnClose}
            muiDialogProps={{ maxWidth: 'sm', fullWidth: true }}
            disableDefaultActionButtons
            open
        >
            {newMessageFormVisible && (
                <NewMessageForm
                    onClose={
                        displayOnlyNewMessageForm || isGroupMessaging ? handleOnClose : toggleNewMessageFormVisibility
                    }
                    cancelButtonText={showNewMessageForm ? 'button-cancel' : 'button-sulje'}
                />
            )}

            {!displayOnlyNewMessageForm && !isGroupMessaging && (
                <SideScrollingContainer>
                    {
                        <PanelsContainer
                            showMessageThread={showMessageThread}
                            newMessageFormVisible={newMessageFormVisible}
                        >
                            <MessagesPanel showMessageThread={showMessageThread}>
                                {
                                    //Dont display summary row if no employees selected from row
                                    //This can be removed once summary is generated from ddl selected employees
                                    !displayOnlyNewMessageForm && (
                                        <WrappedSummaryLabels>
                                            <SummaryLabels summaryLabels={summaryLabels} />
                                        </WrappedSummaryLabels>
                                    )
                                }

                                <OpenNewMessageButtonContainer>
                                    <Button onClick={toggleNewMessageFormVisibility} variant="text" startIcon="add">
                                        <ReadOnlyText translate usage="button" color="primary_120">
                                            messaging.showFormButton
                                        </ReadOnlyText>
                                    </Button>
                                </OpenNewMessageButtonContainer>

                                {!displayOnlyNewMessageForm && (
                                    <>
                                        <SubTabs
                                            subTabs={[
                                                'messaging.tabAll',
                                                'messaging.tabMobileMessages',
                                                'messaging.tabAnnouncements',
                                            ]}
                                            onSelectSubTab={handleTabSelected}
                                            selectedSubTab={selectedTab ?? 'messaging.tabAll'}
                                        />
                                        <MessagesContainer showNewMessageForm={showNewMessageForm}>
                                            {(messageThreads.isFetching || false) && <CenteredLoadingIndicator />}
                                            {messageThreads.data?.map((thread) => (
                                                <MessageThread
                                                    key={thread.Id}
                                                    thread={thread}
                                                    onClick={messageThreadClicked}
                                                />
                                            ))}
                                        </MessagesContainer>
                                    </>
                                )}
                            </MessagesPanel>

                            {!displayOnlyNewMessageForm && (
                                <MessageThreadPanel
                                    showMessageThread={showMessageThread}
                                    containerHeight={windowHeight}
                                >
                                    <MessageThreadForm />
                                </MessageThreadPanel>
                            )}
                        </PanelsContainer>
                    }
                </SideScrollingContainer>
            )}
        </MessagingModalComponent>
    )
}

export default MessagingForm
