import * as React from 'react'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'

import { selectDataSourceId } from '../../State/ConfigurableListSelectors'
import { TDataSourceId, AggregatedDataOnPageBottom } from '@planier/data-source'
import TListViewId from './../../interfaces/TListViewId'

interface IOwnProps {
    listId: TListViewId
}

interface IStateProps {
    dataSourceId: TDataSourceId | null
}

interface IListAggregatedDataProps extends IOwnProps, IStateProps {}

const ListAggregatedData: React.FC<IListAggregatedDataProps> = ({ dataSourceId }) => {
    if (!dataSourceId) {
        return null
    }

    return <AggregatedDataOnPageBottom dataSourceId={dataSourceId} />
}

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => ({
    dataSourceId: selectDataSourceId(state, listId),
})

export default connect(mapStateToProps)(ListAggregatedData)
