import moment from 'moment'
import { TLocale } from 'common/DateUtility'

type TDecimalSeparatorsPerLanguage = {
    [key in TLocale]: ',' | '.'
}

const decimalSeparatorsPerLanguage: TDecimalSeparatorsPerLanguage = {
    fi: ',',
    en: '.',
    sk: ',',
    sv: ',',
    pl: ',',
    ru: ',',
}

const isNumericAllowingDecimalsRegex = new RegExp('^-?([0-9])*(,|\\.)?([0-9])*$')

/**
 * Checks if a string is numeric.
 * Allows for empty string, decimals and negative or positive numbers.
 * Doesn't care about the decimal separator.
 *
 * Decimal separator is determined by the user's planier language.
 */
export const isNumeric = (input: string, areDecimalsAllowed = true): boolean => {
    return areDecimalsAllowed ? isNumericAllowingDecimalsRegex.test(input) : /^-?([0-9])*$/.test(input)
}

/**
 * Checks if a numeric string has the decimal separator matching to the user's
 * planier language setting.
 */
export const hasCorrectDecimalSeparatorOrNoSeparator = (input: string): boolean => {
    const locale = moment.locale() as TLocale

    if (input.includes('.')) {
        return decimalSeparatorsPerLanguage[locale] === '.'
    } else if (input.includes(',')) {
        return decimalSeparatorsPerLanguage[locale] === ','
    }

    return true
}

export const round = (number: number, decimalPlaces: number): number => {
    const factorOfTen = Math.pow(10, decimalPlaces)
    return Math.round(number * factorOfTen) / factorOfTen
}

export const endsInSeparator = (input: string): boolean => {
    return Boolean(input) && (input[input.length - 1] === '.' || input[input.length - 1] === ',')
}

/**
 * Apparently if we use a numeric string value that has a comma as the
 * decimal separator, it might not be parsed correctly. Thus we'll
 * switch it to dot.
 */
export const ensureDecimalSeparatorIsDot = (input: string): string => {
    if (!isNumeric) {
        return input
    }

    return input.replace(',', '.')
}

/**
 * Converts a string to a number regardless of whether the string input has
 * a comma or dot as a decimal separator.
 */
export const convertNumericStringToNumber = (input: string): number => {
    return Number(ensureDecimalSeparatorIsDot(input))
}
