import * as React from 'react'
import styled from '@emotion/styled'

import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import { Checkbox } from '@planier/generic-components'

interface IOwnProps {
    isLoading: boolean
    rowChecked: boolean
    onClick: () => void
    marginLeft: string
    marginRight: string
}

interface IListCheckboxProps extends IOwnProps {}

const StyledCheckbox = styled(Checkbox)<{ marginLeft: string; marginRight: string }>`
    margin-left: ${({ marginLeft }) => marginLeft};
    margin-right: ${({ marginRight }) => marginRight};
`

/**
 * Checkbox shown in the left side of the row.
 */
export const ListCheckboxUnconnected: React.FC<IListCheckboxProps> = ({
    rowChecked,
    onClick,
    isLoading,
    marginLeft = '0px',
    marginRight = '0px',
}) => {
    if (isLoading) {
        return <LoadingIndicatorInline isLoading paddingLeft="4px" size={34} />
    }

    return (
        <StyledCheckbox
            data-testid="List-Checkbox"
            onClick={onClick}
            value={rowChecked}
            marginLeft={marginLeft}
            marginRight={marginRight}
        />
    )
}

export default ListCheckboxUnconnected
