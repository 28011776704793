import { JSX } from 'react'
import TextBoxWithBackgroundColor from './TextBoxWithBackgroundColor'
import { Color } from 'constants/Styles'
import { PlanierTheme, useAppTheme } from '@planier/theme'

type Variant = 'visibility' | 'message' | 'announcement'

type Props = {
    variant: Variant
    text: string
    translate?: boolean
}

const getColors = (
    variant: Variant,
    theme: PlanierTheme
): { backgroundColor: Color | string; textColor: Color | string; borderColor: Color | string } => {
    const { colors, componentExtensions } = theme

    switch (variant) {
        case 'visibility':
            return {
                backgroundColor: colors.neutralsWhite100,
                textColor: colors.neutralsGrey100,
                borderColor: componentExtensions.border.primary,
            }

        case 'message':
            return {
                backgroundColor: colors.neutralsWhite100,
                textColor: colors.neutralsGrey100,
                borderColor: colors.primaryTeal100,
            }

        case 'announcement':
            return {
                backgroundColor: colors.neutralsWhite100,
                textColor: colors.primaryTeal120,
                borderColor: '#66C7D4',
            }

        default:
            return { backgroundColor: 'white', textColor: 'dark_100', borderColor: 'dark_60' }
    }
}

const StatusLabel = ({ variant, text, translate }: Props): JSX.Element => {
    const theme = useAppTheme()

    const { backgroundColor, borderColor, textColor } = getColors(variant, theme)

    return (
        <TextBoxWithBackgroundColor
            text={text}
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            textColor={textColor}
            translate={translate}
        />
    )
}

export default StatusLabel
