import * as React from 'react'
import { Fragment } from 'react'
import styled from '@emotion/styled'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import ReadOnlyText from '../../ReadOnlyText'
import { translate, Translation } from '@planier/localization'
import { useAppTheme } from '@planier/theme'
import RequiredIndicator from '../../RequiredIndicator'
import { getTestId } from '@planier/test'

interface ITextStyleProps {
    width?: string
    height?: string
}

export interface ITextInputProps {
    value: string | null
    multiline?: boolean
    disabled?: boolean
    onChange?: (input: string | null) => void
    onBlur?: (e: React.MouseEvent) => void
    onClick?: (e: React.MouseEvent) => void
    label?: string
    placeholder?: string
    type?: 'number' | 'text' | 'time' | 'password'
    overrideStyle?: ITextStyleProps
    errors?: string
    endAdorment?: string | React.ReactNode
    isRequiredField?: boolean
    name?: string
    autoFocus?: boolean
    resizable?: boolean
    maxRows?: number
    valueInputId?: string
    AppendComponent?: React.ReactNode
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
`

const LabelContainer = styled.div`
    margin-bottom: 5px;
`
const ErrorContainer = styled.div`
    margin-top: 5px;
    max-width: 100%;
`

const TextFieldContainer = styled.div`
    display: flex;
    align-items: center;
`

const StyledTextField = styled(TextField)<{ resizable?: boolean }>`
    .MuiOutlinedInput-input {
        padding: 0 12px;
    }

    .MuiOutlinedInput-multiline {
        padding: 12px 0 12px 0;
    }

    .MuiOutlinedInput-root {
        transition: background-color ${({ theme }) => theme.tokens.transitionQuick};
        background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};

        fieldset {
            border: 1px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputDefault};
        }

        :hover {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDropdownHover};
        }

        &.Mui-focused {
            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDefault};
        }

        &.Mui-focused fieldset {
            border: 2px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputFocus};
        }

        &.Mui-disabled fieldset {
            border-color: ${({ theme }) => theme.componentExtensions.inputs.borderInputDisabled};
            background-color: ${({ theme }) => theme.componentExtensions.inputs.bgInputDisabled};
        }

        &.Mui-error fieldset {
            border: 2px solid ${({ theme }) => theme.componentExtensions.inputs.borderInputError};
        }
    }

    .MuiInputBase-input.Mui-disabled {
        z-index: 1;
    }

    textarea {
        ${({ resizable }) => resizable && 'resize: vertical'};

        &::placeholder {
            opacity: 1;
        }
    }

    input {
        &::placeholder {
            opacity: 1;
        }
    }
`

const TextInput: React.FC<ITextInputProps> = ({
    name,
    value,
    disabled,
    onChange,
    onClick,
    onBlur,
    label,
    multiline,
    placeholder,
    type,
    overrideStyle,
    errors,
    endAdorment,
    isRequiredField,
    autoFocus,
    resizable,
    maxRows = 10,
    valueInputId,
    AppendComponent,
}) => {
    const { colors, typographyExtensions } = useAppTheme()
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        onChange && onChange(event.target.value)
    }

    const hasValue = Boolean(value)

    const labelContent = label || ''

    const _height = overrideStyle?.height ?? (multiline ? 'auto' : '40px')

    const testId = getTestId(['VALUE_PICKER'], valueInputId)

    const FieldContainer = AppendComponent ? TextFieldContainer : Fragment

    return (
        <Container>
            {label && (
                <LabelContainer>
                    <ReadOnlyText translate={Translation.has(labelContent)} usage={'dropdown_label'}>
                        {labelContent}
                    </ReadOnlyText>
                    {isRequiredField && <RequiredIndicator />}
                </LabelContainer>
            )}

            <FieldContainer>
                <StyledTextField
                    data-testid={testId}
                    name={name}
                    value={value}
                    disabled={disabled}
                    placeholder={
                        Translation.has(placeholder) ? translate(placeholder) : placeholder || translate('write')
                    }
                    multiline={multiline}
                    minRows={multiline ? 4 : undefined}
                    maxRows={multiline ? maxRows : undefined}
                    style={{ borderRadius: 4, width: overrideStyle?.width ?? 300, height: _height }}
                    onChange={handleChange}
                    onClick={onClick}
                    onBlur={onBlur}
                    type={type}
                    autoFocus={autoFocus}
                    resizable={resizable}
                    InputProps={{
                        endAdornment: endAdorment ? (
                            <InputAdornment position="end">{endAdorment}</InputAdornment>
                        ) : null,
                        style: {
                            ...typographyExtensions.dropdown_selection,
                            height: _height,
                            color: hasValue ? colors.neutralsGrey100 : colors.neutralsGrey60,
                        },
                    }}
                    error={!!errors}
                    InputLabelProps={{
                        style: {
                            ...typographyExtensions.dropdown_label,
                            color: hasValue ? '#000000' : '#8E8F91',
                        },
                    }}
                />

                {AppendComponent}
            </FieldContainer>

            {errors && (
                <ErrorContainer>
                    <ReadOnlyText usage="h6" color="error" textOverflow="ellipsis">
                        {errors || ''}
                    </ReadOnlyText>
                </ErrorContainer>
            )}
        </Container>
    )
}

export default TextInput
