import EValuePickerType from '../types/EValuePickerType'
import { getValueAsDateRange, getValueAsDateRangeV2, getValueAsSet, getValueInSet } from '@planier/generic-utilities'
import { IDateRange, IDateRangeV2 } from '@planier/generic-utilities/SetUtilities'

export const SAVED_PICKERS_PREFIX = 'SavedPickerValues'

export const getValuePickerValueInCorrectFormat = (
    valuePickerType: EValuePickerType,
    value: unknown,
    isFormValue?: boolean
): unknown => {
    if (value === null) {
        return null
    }

    switch (valuePickerType) {
        case EValuePickerType.TagList:
        case EValuePickerType.Dropdown: {
            if (isFormValue) {
                return getValueAsSet(value)
            } else {
                return getValueInSet(value)
            }
        }
        case EValuePickerType.DateRangeV2: {
            return getValueAsDateRangeV2(value as IDateRangeV2)
        }
        case EValuePickerType.DateRange: {
            return getValueAsDateRange(value as IDateRange)
        }

        default: {
            return value
        }
    }
}
