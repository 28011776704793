import FileSaver from 'file-saver'
import makeRequest, { ERequestMethod } from '@planier/rest-api'
import ITyovuorolistapaketti from '../types/ITyovuorolistapaketti'
import ITyovuorolistapakettiParameters from '../types/ITyovuorolistapakettiParameters'

const GET_ALL_URL = '/Tyovuorolista/Kaikki'
const NEW_PACKAGE_URL = '/Tyovuorolista/UusiPaketti'
const DOWNLOAD_URL = '/Tiedosto/Download'

export const getAllTyovuorolistapakettiApi = async (): Promise<ITyovuorolistapaketti[]> => {
    const tyovuorolistapaketit = await makeRequest<ITyovuorolistapaketti[]>({
        method: ERequestMethod.GET,
        url: `${GET_ALL_URL}`,
    })

    return tyovuorolistapaketit
}

export const newTyovuorolistaPakettiApi = async (
    parameters: ITyovuorolistapakettiParameters
): Promise<ITyovuorolistapaketti> => {
    const tyovuorolistapaketti = await makeRequest<ITyovuorolistapaketti>({
        method: ERequestMethod.POST,
        url: `${NEW_PACKAGE_URL}`,
        data: parameters,
    })

    return tyovuorolistapaketti
}

export const downloadTyovuorolistapakettiApi = async (fileId: number): Promise<void> => {
    const data = await makeRequest<Blob>({
        method: ERequestMethod.GET,
        url: `${DOWNLOAD_URL}/${fileId}`,
        responseType: 'blob',
    })
    FileSaver.saveAs(data, `tyovuorolista_${fileId}.zip`)
}
