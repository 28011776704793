import { MomentInput } from 'moment'
import * as React from 'react'

import DateUtility from 'common/DateUtility'
import Typography, { TypographyProps } from '@material-ui/core/Typography'
import Tooltip from './Tooltip'

interface IDateProps {
    children: MomentInput | null
    displayTimeInTooltip?: boolean
    showTooltip?: boolean
    dateTypographyProps?: TypographyProps
}

const Date: React.FC<IDateProps> = ({
    children,
    displayTimeInTooltip = false,
    showTooltip = false,
    dateTypographyProps,
}) => {
    if (!children) {
        return null
    }

    const fullDate = DateUtility.asFullDate(children)
    const renderedDate = (
        <Typography display="inline" noWrap variant="listElement" {...dateTypographyProps}>
            {fullDate}
        </Typography>
    )

    if (!showTooltip) {
        return renderedDate
    }

    const tooltipContent = displayTimeInTooltip
        ? DateUtility.asFullDateTimeWithDayAbbreviation(children)
        : DateUtility.asFullDateAndDayAbbreviation(children)

    return <Tooltip title={tooltipContent}>{renderedDate}</Tooltip>
}

export default Date
