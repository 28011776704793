import styled from '@emotion/styled'
import { JSX } from 'react'
import { ReadOnlyText, StatusLabel } from '@planier/generic-components'
import IMessageThread from '../Types/IMessageThread'
import { EUserGroup } from '../Types/IMessage'
import { getCommentThreadColor } from '../Utils/commentUtils'
import { formatDateTimeStringForDisplay } from '@planier/dates'
import { translate } from '@planier/localization'
import { useAppTheme } from '@planier/theme'

type CommentThreadProps = {
    thread: IMessageThread
}

const MessageContainer = styled.div<{ color: string }>`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 4px;
    background-color: ${({ color }) => color};
    gap: 12px;
`

const SpreadContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const VisibilityContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
    border-bottom: 1px solid ${({ theme }) => theme.componentExtensions.border.secondary};
    padding-bottom: 12px;
`

const MessageContent = styled.div``

const CommentVisibility = ({ thread }: CommentThreadProps): JSX.Element => {
    return (
        <VisibilityContainer>
            <ReadOnlyText usage="bodyS">{`${translate('messaging.commentVisibility')}:`}</ReadOnlyText>

            <StatusLabel variant="visibility" text="messaging.visibilityInternal" translate />

            {thread.IsVisibleForEmployee && (
                <StatusLabel variant="visibility" text="messaging.visibilityEmployee" translate />
            )}

            {thread.IsVisibleForExternalUser && (
                <StatusLabel variant="visibility" text="messaging.visibilityExternal" translate />
            )}
        </VisibilityContainer>
    )
}

const CommentThread = (props: CommentThreadProps): JSX.Element => {
    const theme = useAppTheme()
    const { thread } = props

    const isInternalComment = thread.LatestMessage.CreatedByUserGroup === EUserGroup.Internal
    const threadColor = getCommentThreadColor(thread, theme)

    return (
        <MessageContainer color={threadColor}>
            <SpreadContent>
                <ReadOnlyText usage="bodyS">{thread.LatestMessage.CreatedByLogin?.Name}</ReadOnlyText>
                <ReadOnlyText usage="bodyS">{formatDateTimeStringForDisplay(thread.CreatedTimestamp)}</ReadOnlyText>
            </SpreadContent>
            {isInternalComment && <CommentVisibility thread={thread} />}
            <MessageContent>
                <ReadOnlyText usage="bodyS" textOverflow="pre-line">
                    {thread.LatestMessage.Content}
                </ReadOnlyText>
            </MessageContent>
        </MessageContainer>
    )
}

export default CommentThread
