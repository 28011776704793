import * as React from 'react'
import styled from '@emotion/styled'

import { ExpandableGroup } from '@planier/generic-components'
import { ConfigurableListTemplate } from '@planier/configurable-list'
import { AggregatedDataSummary } from '@planier/data-source'
import { TRANSACTION_PREVIEW_DATA_SOURCE_ID } from '../../../../../Constants/PalkkahallintoConstants'

const classNameLabelContainer = 'pln-ProcessForm-Expandable-LabelContainer'

const StyledExpandableGroup = styled(ExpandableGroup)`
    & .${classNameLabelContainer} {
        text-transform: uppercase;
    }
`

const MainContentUnconnected: React.FunctionComponent = () => {
    return (
        <StyledExpandableGroup
            defaultOpenInIndex={1}
            expandables={[
                {
                    label: 'payroll-management.ProcessEventToTransactionForm.subTitle.summary',
                    children: <AggregatedDataSummary dataSourceId={TRANSACTION_PREVIEW_DATA_SOURCE_ID} />,
                    classNameLabelContainer,
                },
                {
                    label: 'payroll-management.tapahtuma.ProcessEventToTransactionForm.subTitle.preview',
                    children: <ConfigurableListTemplate listId="TapahtumaKasittelyToteumaksiPreview" />,
                    defaultExpanded: true,
                    classNameLabelContainer,
                },
            ]}
        />
    )
}

export default MainContentUnconnected
