import * as React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'
import { RootState } from 'typesafe-actions'

import { selectAccumulatedData, selectAggregatedData, TDataSourceId } from '@planier/data-source'
import { IDataSourceAccumulatedData, IDataSourceAggregatedDataRow } from '@planier/data-source-types'

interface IStateProps {
    oldAccumulatedData: IDataSourceAccumulatedData[]
    aggregatedData: IDataSourceAggregatedDataRow[]
}

interface IOwnProps {
    dataSourceId: TDataSourceId
}

export interface IAccumulatedDataRowProps extends IStateProps, IOwnProps {}

const FlexGrid = styled(Grid)`
    display: flex;
    align-items: flex-end;
`

const LabelText = styled(Typography)`
    color: ${({ theme }) => theme.colors.neutralsGrey60};
    font-size: ${({ theme }) => theme.typography.h5.fontSize};
    font-style: italic;
    font-family: ${({ theme }) => theme.typography.h5.fontFamily};
    margin-left: 12px;
    margin-right: 12px;
`

const ValueText = styled(Typography)`
    font-size: 2rem;
    font-style: italic;
    font-family: ${({ theme }) => theme.typography.h2.fontSize};
`

/**
 * Renders either the old accumulated data or the new aggregated data, but this
 * is only used in browsers that don't support css style 'position: sticky'.
 * When the browser does support that, then `AggregatedDataOnPageBottom` is used.
 */
export const AccumulatedDataRow: React.FunctionComponent<IAccumulatedDataRowProps> = ({
    oldAccumulatedData,
    aggregatedData,
}) => {
    if (aggregatedData.length === 0 && oldAccumulatedData.length === 0) {
        return null
    }

    const dataToUse = aggregatedData.length > 0 ? aggregatedData : oldAccumulatedData

    return (
        <FlexGrid item>
            <Grid alignItems="center" container justifyContent="center" spacing={2}>
                {dataToUse.map(({ Label, Unit, Value }) => (
                    <FlexGrid item key={Label}>
                        <LabelText variant="h3">{Label}</LabelText>
                        <ValueText color="primary" variant="h1">{`${Value} ${Unit}`}</ValueText>
                    </FlexGrid>
                ))}
            </Grid>
        </FlexGrid>
    )
}

const mapStateToProps = (state: RootState, { dataSourceId }: IOwnProps): IStateProps => ({
    oldAccumulatedData: selectAccumulatedData(state, dataSourceId),
    aggregatedData: selectAggregatedData(state, dataSourceId),
})

export default connect(mapStateToProps)(AccumulatedDataRow)
