import * as React from 'react'
import { ComponentProps, useCallback, useRef } from 'react'
import styled from '@emotion/styled'

import ConfigurableList, { IConfigurableListHandles } from '../../ConfigurableList'
import TValuePickerId from '@planier/value-picker/types/TValuePickerId' // TODO Cyclic dependency
import { IDataSourceItem } from '@planier/data-source-types'
import { TListIconData } from '../../interfaces/IList'
import ButtonsWithValuePickers from './ButtonsWithValuePickers'
import ListAggregatedData from './../ListAggregatedData'
import { IInitialValuePickersValues } from '@planier/value-picker'
import { ISummaryLabel, SummaryLabels } from '@planier/generic-components/SummaryLabel/SummaryLabels'
import useResizeObserver from '@planier/generic-utilities/Hooks/useResizeObserver'
import { useDispatch } from 'react-redux'
import { resetAllSelectedListItemsAction, setFiltersHeightAction } from '../../State/ConfigurableListActions'
import { TABS_HEIGHT } from '../../Constants/ListConstants'

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

type TConfigurableListProps = ComponentProps<typeof ConfigurableList>

export interface IOwnProps extends Pick<TConfigurableListProps, 'shouldEmptyDataWhenUnmounting'> {
    accumulatedData?: React.ReactNode
    additionalActions?: TListIconData
    additionalButtons?: React.ReactNode
    additionalDataSourceDataRequestFiltersParameters?: Record<string, unknown> | null
    isValuePickerGroupFullWidth?: boolean
    listId: string
    omitFilterIds?: TValuePickerId[]
    onRowSelect?: (item: IDataSourceItem) => void
    valuePickersVisibilityTogglable?: boolean
    renderAggregatedData?: boolean
    renderButtonsAndFiltersWithinPaper?: boolean
    showExcelButton?: boolean
    listIsVirtualized?: boolean
    shouldAddScrollListener?: boolean
    initialValuePickersValues?: IInitialValuePickersValues | null
    hideFilterHeader?: boolean
    resetValuePickerValues?: boolean
}

interface IConfigurableListPageTemplateProps extends IOwnProps {
    summaryLabels?: ISummaryLabel[]
}

const ConfigurableListTemplate: React.FunctionComponent<IConfigurableListPageTemplateProps> = ({
    accumulatedData,
    additionalActions,
    additionalButtons,
    additionalDataSourceDataRequestFiltersParameters = null,
    isValuePickerGroupFullWidth,
    listId,
    onRowSelect,
    valuePickersVisibilityTogglable = false,
    renderAggregatedData = false,
    renderButtonsAndFiltersWithinPaper = false,
    omitFilterIds = [],
    showExcelButton = false,
    listIsVirtualized = false,
    shouldAddScrollListener = false,
    initialValuePickersValues = null,
    shouldEmptyDataWhenUnmounting,
    hideFilterHeader = false,
    resetValuePickerValues = false,
    summaryLabels = [],
}) => {
    const dispatch = useDispatch()
    const listRef = useRef<IConfigurableListHandles>(null)
    const filtersRef = useRef<HTMLDivElement>(null)

    const { height } = useResizeObserver(filtersRef)
    dispatch(setFiltersHeightAction(height + TABS_HEIGHT, listId))

    // Whenever filters' visibility is toggled, recalculate the scroll position
    // for the list, as the WindowScroller and virtualizing causes the rows
    // to need their exact absolute position
    const handleFilterVisibilityToggle = useCallback(() => {
        listRef.current && listRef.current.recalculateScrollPosition()
    }, [])

    const getModalSubmitCallback = () => {
        switch (listId) {
            case 'VuorotJaVapaatList':
                return () => dispatch(resetAllSelectedListItemsAction('VuorotJaVapaatList'))

            default:
                return undefined
        }
    }

    return (
        <Container>
            {summaryLabels.length > 0 && <SummaryLabels summaryLabels={summaryLabels} />}
            {hideFilterHeader ? null : (
                <div ref={filtersRef}>
                    <ButtonsWithValuePickers
                        accumulatedData={accumulatedData}
                        additionalButtons={additionalButtons}
                        isValuePickerGroupFullWidth={isValuePickerGroupFullWidth}
                        listId={listId}
                        omitFilterIds={omitFilterIds}
                        onAfterVisibilityWasToggled={handleFilterVisibilityToggle}
                        renderWithinPaper={renderButtonsAndFiltersWithinPaper}
                        showExcelButton={showExcelButton}
                        valuePickersVisibilityTogglable={valuePickersVisibilityTogglable}
                        onModalSubmitCallback={getModalSubmitCallback()}
                    />
                </div>
            )}
            <ConfigurableList
                additionalDataSourceDataRequestFiltersParameters={additionalDataSourceDataRequestFiltersParameters}
                iconData={additionalActions}
                initialValuePickersValues={initialValuePickersValues}
                isVirtualized={listIsVirtualized}
                listId={listId}
                onRowSelect={onRowSelect}
                ref={listRef}
                shouldAddScrollListener={shouldAddScrollListener}
                shouldEmptyDataWhenUnmounting={shouldEmptyDataWhenUnmounting}
                resetValuePickerValues={resetValuePickerValues}
            />
            {renderAggregatedData && <ListAggregatedData listId={listId} />}
        </Container>
    )
}

export default ConfigurableListTemplate
