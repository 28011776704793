import makeRequest, { ERequestMethod } from '@planier/rest-api'

export const changePassword = async (currentPassword: string, newPassword: string): Promise<void> =>
    makeRequest<void>({
        method: ERequestMethod.POST,
        url: '/Kayttaja/salasana',
        data: {
            VanhaSalasana: currentPassword,
            UusiSalasana: newPassword,
        },
    })
