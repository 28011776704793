import { connect } from 'react-redux'
import * as React from 'react'
import { RootState } from 'typesafe-actions'

import { selectAreAllRowsSelected, selectDataSourceId } from '../../State/ConfigurableListSelectors'
import { selectDataItemIds } from '@planier/data-source'
import { TDataSourceItemId } from '@planier/data-source-types'
import LoadingIndicatorInline from 'components/molecules/LoadingIndicatorInline'
import { toggleRowItemMassSelectionAction } from '../../State/ConfigurableListActions'
import { Checkbox } from '@planier/generic-components'

interface IOwnProps {
    isLoading: boolean
    listId: string
}

interface IStateProps {
    allItemIds: TDataSourceItemId[]
    checked: boolean
}

interface IDispatchProps {
    toggleRowItemMassSelection: typeof toggleRowItemMassSelectionAction
}

interface IMassSelectionCheckboxProps extends IStateProps, IDispatchProps, IOwnProps {}

/**
 * Listan sarakeotsikkorivin alussa näytettävä checkbox,
 * jonka avulla voi massavalita kaikki näytettävän datan
 * rivit.
 */
export class MassSelectionCheckbox extends React.PureComponent<IMassSelectionCheckboxProps> {
    handleMassSelectionCheckboxClick = (checked: boolean): void => {
        const { allItemIds, listId, toggleRowItemMassSelection } = this.props

        toggleRowItemMassSelection(checked, listId, allItemIds)
    }

    render(): React.ReactNode {
        const { checked, isLoading } = this.props

        if (isLoading) {
            return <LoadingIndicatorInline isLoading paddingLeft="4px" />
        }

        return (
            <Checkbox
                data-testid="List-MassSelection-Checkbox"
                onClick={this.handleMassSelectionCheckboxClick}
                value={checked}
            />
        )
    }
}

const mapStateToProps = (state: RootState, { listId }: IOwnProps): IStateProps => {
    const dataSourceId = selectDataSourceId(state, listId)
    const allItemIds = selectDataItemIds(state, dataSourceId)

    return {
        allItemIds,
        checked: selectAreAllRowsSelected(state, listId, allItemIds),
    }
}

const mapDispatchToProps: IDispatchProps = {
    toggleRowItemMassSelection: toggleRowItemMassSelectionAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(MassSelectionCheckbox)
