import IConfigurableCalendarConfiguration from '../Types/IConfigurableCalendarConfiguration'
import ECrossAxisGranularityType from '../Constants/ECrossAxisGranularityType'
import ECalendarDisplayMode from '../Constants/ECalendarDisplayMode'
import { EActionFunctionalityType } from '@planier/view-engine'
import { Translation } from '@planier/localization'

const configuration: IConfigurableCalendarConfiguration = {
    DataSourceId: 'TarvenakymaDataSource',
    DisplayMode: ECalendarDisplayMode.FullDay,
    Granularity: { Type: ECrossAxisGranularityType.TIME, Unit: 'd', Value: '1' },
    Title: 'Työvuorot',
    NodeActions: [
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_Action_Edit',
                FormId: 'WorkforceSchedulingEventCreateFormView',
                AdditionalPropertyMapping: [],
                AdditionalInitialValueSourceProperties: [
                    { key: 'Asiakas.Id', PropertyPath: 'WorkUnitId' },
                    { key: 'Alkamisaika', PropertyPath: 'StartTime', liftToArray: true, format: 'toDate' },
                ],
                AdditionalInitialValues: [],
                IgnoreInitialValueFields: [],
                IgnoreOriginalItemIds: true,
                PermissionId: 'CREATE',
            },
            Display: {
                Icon: 'add',
                Label: Translation.translateKey('Luo uusi'),
            },
            IsPrimaryAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_Action_Edit',
                FormId: 'TarvenakymaTapahtumaEditFormView',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'edit',
                Label: Translation.translateKey('Muokkaa'),
            },
            IsPrimaryAction: true,
            IsDoubleClickAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'Tarvenakyma_Action_Copy_Tapahtuma',
                // TODO: currently there's no permission of its own for this so just use UPDATE for now
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'content_copy',
                Label: Translation.translateKey('Kopioi'),
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_CreateTemplateFromEvent',
                PermissionId: 'UPDATE',
                FormId: 'CreateTemplateFromEvent',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
                reloadTemporaryData: true,
            },
            Display: {
                Icon: 'backup_table',
                Label: Translation.translateKey('Luo tapahtumapohja'),
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ListModal,
                DataSourceActionId: 'Tarvenakyma_Action_Add_Tyontekija',
                PermissionId: 'SET_EMPLOYEE',
                HiddenValuePickerIds: [
                    'WorkforceScheduling_PlanningPeriods',
                    'WorkforceScheduling_Calendar_Filter_Employees',
                ],
                ValuePickerPropertyMapping: [],
                ListId: 'SoveltuvatTyontekijatList',
                ModalTitle: 'Soveltuvan työntekijän haku',
                SelectedItemsOutputProperty: 'EventIds',
                OnRowClickItemOutProperty: 'TyontekijaId',
            },
            Display: {
                Icon: 'person_add',
                Label: Translation.translateKey('Lisää työntekijä'),
            },
            IsPrimaryAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.CustomModal,
                DataSourceActionId: 'Publish_Events',
                FormId: 'PublishEventsConfirmationDialog',
                PermissionId: 'PUBLISH',
            },
            Display: {
                Icon: 'publish',
                Label: Translation.translateKey('Julkaise'),
            },
            IsPrimaryAction: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_Action_CreateEventTemplateGroup',
                FormId: 'EventTemplateGroupCreateView',
                AdditionalPropertyMapping: [],
                IgnoreInitialValueFields: [],
                // TODO: need to add a field to output the selected IDs
                // TODO: there's no permission of its own for this. Maybe this action
                // actually doesn't need a permission either? Maybe the PermissionId should
                // be nullable for cases like this?
                PermissionId: 'UPDATE',
                SelectedItemsOutputProperty: 'EventIds',
            },
            Display: {
                Icon: 'folder_open',
                Label: Translation.translateKey('Luo profiili'),
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.CustomModal,
                DataSourceActionId: 'Tarvenakyma_Action_Remove_Tapahtuma',
                FormId: 'WorkforceSchedulingDeletionConfirmationDialog',
                PermissionId: 'DELETE',
            },
            Display: {
                Icon: 'delete',
                Label: Translation.translateKey('Poista'),
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.CustomModal,
                DataSourceActionId: 'Event_Cancel_TarvenakymaDataSource',
                FormId: 'CancelEventForm_TarvenakymaDataSource',
                PermissionId: 'CANCEL_PUBLISHED_EVENT',
            },
            Display: {
                Icon: 'cancel',
                Label: Translation.translateKey('Peru vuoro'),
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.ConfigurableModal,
                DataSourceActionId: 'Tarvenakyma_Action_EventRecurrence_SetEndTime',
                FormId: 'RepetitiveEventSetEndDate',
                PermissionId: 'RECURRING_EVENT_SET_END_DATE',
            },
            Display: {
                Icon: 'delete',
                Label: Translation.translateKey('Päätä tapahtumasarja'),
            },
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'Tarvenakyma_CombineEvents',
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'call_merge',
                Label: Translation.translateKey('Yhdistä tapahtumat yhdessä tehtäväksi'),
            },
            RequiresMultipleItems: true,
        },
        {
            Functionality: {
                FunctionalityType: EActionFunctionalityType.Immediate,
                DataSourceActionId: 'Tarvenakyma_DetachEvents',
                PermissionId: 'UPDATE',
            },
            Display: {
                Icon: 'call_split',
                Label: Translation.translateKey('Irrota tapahtuma tapahtumaryhmästä'),
            },
        },
    ],
    ValuePickerIds: [
        'WorkforceScheduling_PlanningPeriods',
        'WorkforceScheduling_Calendar_Filter_Domain',
        'WorkforceScheduling_Calendar_Filter_WorkUnit',
        'WorkforceScheduling_Calendar_Filter_JobTitle',
        'WorkforceScheduling_Calendar_Filter_Cla',
        'WorkforceScheduling_Calendar_Filter_Pool',
        'WorkforceScheduling_Calendar_Filter_Employees',
    ],
    NodeTypes: [
        {
            Id: 1,
            Component: 'VuoroCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'henkilomaara', DataSourcePropertyId: 'TarvenakymaDataSource_TotalCount' },
                {
                    ComponentProperty: 'puuttuvaHenkilomaara',
                    DataSourcePropertyId: 'TarvenakymaDataSource_MissingCount',
                },
                { ComponentProperty: 'isRecurring', DataSourcePropertyId: 'TarvenakymaDataSource_IsRecurringEvent' },
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TarvenakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'ammattinimikeShorthand',
                    DataSourcePropertyId: 'TarvenakymaDataSource_AmmattinimikeAbbreviation',
                },
                {
                    ComponentProperty: 'jobTitleName',
                    DataSourcePropertyId: 'TarvenakymaDataSource_AmmattinimikeName',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TarvenakymaDataSource_WorkTimeRuleViolations',
                },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TarvenakymaDataSource_Tyontekija' },
                {
                    ComponentProperty: 'additionalTopText',
                    DataSourcePropertyId: 'TarvenakymaDataSource_TyontekijaName',
                },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TarvenakymaDataSource_EventClassId',
                MatchingValues: [1],
            },
        },
        {
            Id: 2,
            Component: 'VuoroAggregateCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'henkilomaara', DataSourcePropertyId: 'TarvenakymaDataSource_TotalCount' },
                {
                    ComponentProperty: 'puuttuvaHenkilomaara',
                    DataSourcePropertyId: 'TarvenakymaDataSource_MissingCount',
                },
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TarvenakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'ammattinimikeShorthand',
                    DataSourcePropertyId: 'TarvenakymaDataSource_AmmattinimikeAbbreviation',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TarvenakymaDataSource_WorkTimeRuleViolations',
                },
            ],
            InfoTextProperty: '',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TarvenakymaDataSource_EventClassId',
                MatchingValues: [-6],
            },
        },
        {
            Id: 4,
            Component: 'MuuTapahtumaCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TarvenakymaDataSource_Tyontekija' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TarvenakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'tapahtumatyyppiShorthand',
                    DataSourcePropertyId: 'TarvenakymaDataSource_EventTypeAbbreviation',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TarvenakymaDataSource_WorkTimeRuleViolations',
                },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TarvenakymaDataSource_EventClassId',
                MatchingValues: [3, 8, 10, 11],
            },
        },
        {
            Id: 5,
            Component: 'MuuTapahtumaCalendarNode',
            PropertyMapping: [
                { ComponentProperty: 'startDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Alkamisaika' },
                { ComponentProperty: 'endDatetime', DataSourcePropertyId: 'TarvenakymaDataSource_Loppumisaika' },
                { ComponentProperty: 'tyontekijaId', DataSourcePropertyId: 'TarvenakymaDataSource_Tyontekija' },
                { ComponentProperty: 'published', DataSourcePropertyId: 'TarvenakymaDataSource_IsPublished' },
                {
                    ComponentProperty: 'tapahtumatyyppiShorthand',
                    DataSourcePropertyId: 'TyontekijanakymaDataSource_EventTypeName',
                },
                {
                    ComponentProperty: 'tyoaikasaantorikkeet',
                    DataSourcePropertyId: 'TarvenakymaDataSource_WorkTimeRuleViolations',
                },
            ],
            InfoTextProperty: 'Info',
            StartProperty: 'Alkamisaika',
            EndProperty: 'Loppumisaika',
            MainAxisProperty: '',
            TypeDetermination: {
                DataSourcePropertyId: 'TarvenakymaDataSource_EventClassId',
                MatchingValues: [12],
            },
        },
    ],
    SelectorObjects: [
        {
            SelectorProperties: [{ Property: 'Asiakas.Id', Transformation: 'None' }],
            Title: Translation.translateKey('EventSelector.WorkUnit'), //'Asiakkaan tapahtumat'
        },
        {
            SelectorProperties: [{ Property: 'Alkamisaika', Transformation: 'Date' }],
            Title: Translation.translateKey('EventSelector.Date'), //'Päivän tapahtumat'
        },
        {
            SelectorProperties: [{ Property: 'Ammattinimike.Id', Transformation: 'None' }],
            Title: Translation.translateKey('EventSelector.JobTitle'), //'Ammattinimikkeen tapahtumat'
        },
        { SelectorProperties: [], Title: Translation.translateKey('Kaikki tapahtumat') },
    ],
    CrossAxis: {
        Component: 'WorkUnitGroupNode',
        PropertyMapping: [
            { ComponentProperty: 'workUnitId', GroupDataPropertyPath: 'WorkUnitId' },
            { ComponentProperty: 'name', GroupDataPropertyPath: 'Text' },
            { ComponentProperty: 'kpiData', GroupDataPropertyPath: 'CounterData' },
        ],
    },
    MainAxis: [
        {
            Level: 1,
            Component: 'ViikkoLabel',
            ParameterMapping: [{ Text: 'Name' }],
            StartProperty: 'StartTime',
            EndProperty: 'EndTime',
        },
    ],
    AdditionalDataFetchParameters: {
        Limit: 2000,
        ExtraRows: 'OmitAll',
        GroupBy: [
            /* Currently semi hard coded in the backend */
        ],
        UseCustomGroupBy: true,
    },
}

export default configuration
