import * as React from 'react'

import { Translation } from '@planier/localization'
import ListElementText from './ListElementText'

export interface IListElementBoolean {
    displayValue: boolean
}

const ListElementBoolean: React.FunctionComponent<IListElementBoolean> = ({ displayValue }) => (
    <ListElementText displayValue={Translation.translateKey(displayValue ? 'kylla' : 'ei')} />
)

export default ListElementBoolean
