import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './RootReducer'

const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__

const store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk), devToolsExtension ? devToolsExtension() : (f: any) => f)
)

export default store
