import * as React from 'react'

import AlertBox from './AlertBox'
import { Translate, TTranslatable } from '@planier/localization'

export interface ITranslatedAlertBoxProps extends React.ComponentProps<typeof AlertBox> {
    children: TTranslatable
}

const TranslatedAlertBox: React.FC<ITranslatedAlertBoxProps> = ({ children, ...props }) => (
    <Translate translate={{ children }}>
        {({ children: translatedText }) => <AlertBox {...props}>{translatedText}</AlertBox>}
    </Translate>
)

export default TranslatedAlertBox
