import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from '@emotion/styled'

import { TranslatedButton } from '@planier/generic-components'
import Progress from 'components/molecules/Progress'
import Icon from 'components/atoms/Icon'

const StyledButton = styled(TranslatedButton)`
    margin-right: 8px;
`

export interface ITyovuorolistapakettiButtons {
    disabled: boolean
    isLoading: boolean
    refresh: () => void
    createPackage: () => void
}

const TyovuorolistapakettiButtons: React.FunctionComponent<ITyovuorolistapakettiButtons> = ({
    disabled,
    createPackage,
    refresh,
    isLoading,
}) => (
    <Grid container>
        <StyledButton disabled={disabled} onClick={createPackage}>
            Tyovuorolistapaketti.createPackage
        </StyledButton>

        <TranslatedButton onClick={refresh}>Tyovuorolistapaketti.refresh</TranslatedButton>

        <Icon tooltip="Tyovuorolistapaketti.help">info</Icon>

        {isLoading && <Progress />}
    </Grid>
)

export default TyovuorolistapakettiButtons
