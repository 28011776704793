import { useSelector } from 'react-redux'
import { useMemo } from 'react'
import moment, { Moment } from 'moment'
import { RootState } from 'typesafe-actions'
import { selectValuePickerValue } from '@planier/value-picker'
import IPricingDependencyConfiguration from './IPricingDependencyConfiguration'
import { getValueAsArray } from '@planier/generic-utilities/ArrayUtils'

export interface ITransactionPricingDependencies {
    rowId?: number
    mainRowId?: number
    workunitId?: number
    employeeId?: number
    jobtitleId?: number
    claId?: number
    dates?: Moment[]
    productId?: number
    salaryPrice?: number
    salaryBasisPrice?: number
    salaryPersentage?: number
    startTime?: Moment | null
    salaryCategoryId: number
    endTime?: Moment | null
}

const OtherPricingBasisId = 3

const usePricingDependencies = (configuration: IPricingDependencyConfiguration): ITransactionPricingDependencies => {
    const mainRowIdValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.mainRowIdPicker, configuration.mainRowIdContextId)
    )
    const rowIdValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.rowIdPicker, configuration.rowIdContextId)
    )
    const productValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.productIdPicker, configuration.productIdContextId)
    )
    const workUnitValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.workunitIdPicker, configuration.workunitIdContextId)
    )
    const employeeValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.employeeIdPicker, configuration.employeeIdContextId)
    )
    const jobtitleValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.jobtitleIdPicker, configuration.jobtitleIdContextId)
    )
    const claValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.claIdPicker, configuration.claIdContextId)
    )
    const dateValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.datePicker, configuration.datePickerContextId)
    )
    const salaryCategoryValue = useSelector<RootState>((state) =>
        selectValuePickerValue(
            state,
            configuration.salaryCategoryIdPicker,
            configuration.salaryCategoryIdPickerContextId
        )
    )
    const timeRangeValue = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.timeRangePicker, configuration.timeRangePickerContextId)
    ) as { startTime: Moment; endTime: Moment }
    const salaryPrice = useSelector<RootState>((state) =>
        selectValuePickerValue(state, configuration.salaryPicker ?? '', configuration.salaryPickerContextId)
    ) as {
        totalPrice?: number
        pricingBasisId?: number
        basisPrice: number
        persentage: number
        salaryEnabled: boolean
    }

    const deps = {
        mainRowId: mainRowIdValue as number,
        rowId: rowIdValue as number,
        productId: productValue as number,
        workunitId: workUnitValue as number,
        employeeId: employeeValue as number,
        jobtitleId: jobtitleValue as number,
        claId: claValue as number,
        dates: getValueAsArray(dateValue) as Moment[],
        startTime: timeRangeValue?.startTime as Moment,
        endTime: timeRangeValue?.endTime as Moment,
        salaryCategoryId: salaryCategoryValue as number,
        salaryPrice:
            salaryPrice?.pricingBasisId === OtherPricingBasisId ? (salaryPrice?.totalPrice as number) : undefined,
        salaryBasisPrice:
            salaryPrice?.pricingBasisId === OtherPricingBasisId ? (salaryPrice?.basisPrice as number) : undefined,
        salaryPersentage:
            salaryPrice?.pricingBasisId === OtherPricingBasisId ? (salaryPrice?.persentage as number) : undefined,
    }

    return deps
}

const useSalaryDependencies = (configuration: IPricingDependencyConfiguration): ITransactionPricingDependencies => {
    const deps = usePricingDependencies(configuration)

    const dateValue = deps.dates

    const dependencyStartTime = deps.startTime ? moment(deps.startTime).format('HH:mm') : ''
    const dependencyEndTime = deps.endTime ? moment(deps.endTime).format('HH:mm') : ''

    const dependencyDateValue = (Array.isArray(dateValue) ? dateValue : dateValue ? [dateValue] : [])
        .map((x) => moment(x).format('YYYY-MM-dd'))
        .join(',')

    // the hook has been made to depend on unused variables so removing them could break something. Disabling linting for now
    /* eslint-disable react-hooks/exhaustive-deps */
    const memoizedDeps = useMemo(() => {
        return {
            mainRowId: deps.mainRowId,
            rowId: deps.rowId,
            productId: deps.productId,
            workunitId: deps.workunitId,
            employeeId: deps.employeeId,
            jobtitleId: deps.jobtitleId,
            claId: deps.claId,
            dates: deps.dates,
            startTime: deps.startTime,
            endTime: deps.endTime,
            salaryCategoryId: deps.salaryCategoryId,
            salaryPrice: undefined,
            salaryBasisPrice: undefined,
            salaryPersentage: undefined,
        }
    }, [
        dependencyStartTime,
        dependencyEndTime,
        deps.mainRowId,
        deps.rowId,
        deps.claId,
        deps.productId,
        deps.workunitId,
        deps.employeeId,
        deps.jobtitleId,
        dependencyDateValue,
        deps.salaryCategoryId,
    ])

    return memoizedDeps
}

const usePriceDependencies = (configuration: IPricingDependencyConfiguration): ITransactionPricingDependencies => {
    const deps = usePricingDependencies(configuration)

    const dateValue = deps.dates

    const dependencyDateValue = (Array.isArray(dateValue) ? dateValue : dateValue ? [dateValue] : [])
        .map((x) => moment(x).format('YYYY-MM-dd'))
        .join(',')

    const dependencyStartTime = deps.startTime ? moment(deps.startTime).format('HH:mm') : ''
    const dependencyEndTime = deps.endTime ? moment(deps.endTime).format('HH:mm') : ''

    const memoizedDeps = useMemo(() => {
        return {
            mainRowId: deps.mainRowId,
            rowId: deps.rowId,
            productId: deps.productId,
            workunitId: deps.workunitId,
            employeeId: deps.employeeId,
            jobtitleId: deps.jobtitleId,
            claId: deps.claId,
            dates: deps.dates,
            startTime: deps.startTime,
            endTime: deps.endTime,
            salaryCategoryId: deps.salaryCategoryId,
            salaryPrice: deps.salaryPrice,
            salaryBasisPrice: deps.salaryBasisPrice,
            salaryPersentage: deps.salaryPersentage,
        }
    }, [
        dependencyEndTime,
        dependencyStartTime,
        deps.mainRowId,
        deps.rowId,
        deps.claId,
        deps.salaryPrice,
        deps.productId,
        deps.workunitId,
        deps.employeeId,
        deps.jobtitleId,
        dependencyDateValue,
        deps.salaryCategoryId,
        deps.salaryBasisPrice,
        deps.salaryPersentage,
    ])
    /* eslint-enable */

    return memoizedDeps
}

export { useSalaryDependencies, usePriceDependencies }
