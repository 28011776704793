import * as React from 'react'
import styled from '@emotion/styled'
import { RootState } from 'typesafe-actions'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'

import Styles from 'constants/Styles'
import PageDescription from './PageDescription'
import { Translation } from '@planier/localization'
import { INavigationSectionWithSubsections, selectCurrentSectionWithSubsections } from '@planier/navigation'

interface IStateProps {
    currentSection: INavigationSectionWithSubsections | null
}

interface IOwnProps {
    pageTitle: string
    pageDescription: string | null
}

interface IApplicationBarTitleProps extends IOwnProps, IStateProps {}

const PageTitleContainer = styled.div`
    margin: auto auto auto 4px;
`

const PageTitle = styled(Typography)`
    color: ${Styles.mainColor.white};
`

const StyledPageDescription = styled(PageDescription)`
    color: ${Styles.mainColor.white};
    margin-left: 10px;
`

const ApplicationBarTitle: React.FC<IApplicationBarTitleProps> = ({ pageTitle, pageDescription, currentSection }) => {
    const sectionLabel = currentSection && currentSection.Subsections.length > 1 ? currentSection.Translation : null

    if (!pageTitle && !sectionLabel) {
        return null
    }

    const headerText = pageTitle ? pageTitle : sectionLabel

    return (
        <PageTitleContainer>
            <PageTitle variant="h1" data-testid="STC_HEADER_TITLE">
                {Translation.has(headerText) ? Translation.translateKey(headerText) : headerText}
            </PageTitle>
            <StyledPageDescription descriptionText={pageDescription} />
        </PageTitleContainer>
    )
}

const mapStateToProps = (state: RootState): IStateProps => {
    return {
        currentSection: selectCurrentSectionWithSubsections(state),
    }
}

export default connect(mapStateToProps)(ApplicationBarTitle)
