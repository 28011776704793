import * as React from 'react'

import { formatForDataUsage, toDateObject } from '@planier/dates'

import { getValueAsArray } from '@planier/generic-utilities'

import { DayPickerV2 } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from 'packages/value-picker'
import { Moment } from 'moment'

export interface IBaseDayPickerProps extends IValuePickerCommonOwnProps<Moment[]> {
    multiselect: boolean
    shouldDateBeDisabled?: (val: string) => boolean
}

const DayPickerBase: React.FC<IBaseDayPickerProps> = ({
    label,
    value,
    multiselect,
    onChange,
    errors,
    required,
}: IBaseDayPickerProps) => {
    const values = getValueAsArray(value) ?? []

    const formattedValues = values.map((x) => formatForDataUsage(x))

    const _onChange = (newValues: string[]) => {
        const newValueObjects = newValues.map((x) => toDateObject(x))

        onChange(newValueObjects)
    }

    return (
        <DayPickerV2
            label={label}
            multiselect={multiselect}
            onChange={_onChange}
            required={required}
            value={formattedValues}
            errors={errors}
        />
    )
}

export default DayPickerBase
