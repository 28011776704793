import * as React from 'react'
import { Fragment } from 'react'
import styled from '@emotion/styled'

import { ILoadingItemsData } from 'interfaces/actions/ILoadingAction'
import ActionLink from 'components/atoms/ActionLink'
import AdditionalInfoPopover from './AdditionalInfoPopover'
import { TextButtonWithIcon } from '@planier/generic-components'

export interface ITyoaikatapahtumaLisatiedotProps {
    generalInfo: string | null
    additionalInfo: string | null
    additionalInfoFromEmployee: string | null
    additionalInfoAcknowledged: boolean
    dataForLoading: ILoadingItemsData
    isDisabled: boolean
    dispatch: () => void
    id: string
    employeeName: string
    profession: string | null
    startDatetime: string
}

const LinkContainer = styled.div`
    padding-left: 8px;
`

/**
 * Komponentti työaikatapahtuman lisätietojen näyttämiseen käyttöliittymässä
 */
class TyoaikatapahtumaLisatiedot extends React.Component<ITyoaikatapahtumaLisatiedotProps> {
    static readonly displayName = 'TyoaikatapahtumaLisatiedot'

    state = {
        popoverDisplayed: false,
        anchorElement: null,
    }

    handleClick = (event: React.MouseEvent<unknown>): void => {
        // alt. use ReactDOM.findDOMNode https://stackoverflow.com/a/38094037/3771402
        this.setState({ anchorElement: event.currentTarget, popoverDisplayed: true })
    }

    handlePopoverClose = (): void => {
        this.setState({ anchorElement: null, popoverDisplayed: false })
    }

    render(): React.ReactNode {
        const { anchorElement, popoverDisplayed } = this.state
        const {
            generalInfo,
            additionalInfo,
            additionalInfoAcknowledged,
            additionalInfoFromEmployee,
            dataForLoading,
            id,
            employeeName,
            profession,
            startDatetime,
            isDisabled,
        } = this.props

        // Prefer notes from employee, then from customer
        const primaryAdditionalInfo = additionalInfoFromEmployee || additionalInfo || generalInfo

        if (!primaryAdditionalInfo) {
            return null
        }

        const isActionDisabled = additionalInfoFromEmployee || additionalInfo ? isDisabled : true

        return (
            <Fragment>
                {additionalInfoAcknowledged || isActionDisabled ? (
                    <LinkContainer>
                        <ActionLink clickAction={this.handleClick}>{primaryAdditionalInfo}</ActionLink>
                    </LinkContainer>
                ) : (
                    <TextButtonWithIcon
                        disabled={false}
                        doNotTranslate
                        icon="feedback"
                        label={primaryAdditionalInfo}
                        onClick={this.handleClick}
                    />
                )}

                {popoverDisplayed && (
                    <AdditionalInfoPopover
                        additionalInfoAcknowledged={additionalInfoAcknowledged}
                        anchorElement={anchorElement}
                        generalNotes={generalInfo}
                        customerNotes={additionalInfo}
                        dataForLoading={dataForLoading}
                        datetime={startDatetime}
                        disabled={isActionDisabled}
                        employeeName={employeeName}
                        employeeNotes={additionalInfoFromEmployee}
                        id={id}
                        onClose={this.handlePopoverClose}
                        open={popoverDisplayed}
                        profession={profession}
                    />
                )}
            </Fragment>
        )
    }
}

export default TyoaikatapahtumaLisatiedot
