// Side effect imports
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { useEffect } from 'react'

import AdapterMoment from '@material-ui/lab/AdapterMoment'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeProvider } from '@emotion/react'
import { Provider as ReduxProvider } from 'react-redux'
import ReactDOM from 'react-dom'
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider'
import { Integrations } from '@sentry/tracing'

import { reduxStore } from '@planier/redux'
import Routes from './Routes'
import { ValuePickerComponentRegistryProvider } from '@planier/value-picker'
import { getValuePickerComponents } from '@planier/value-picker-components'
import { getListComponents } from '@planier/configurable-list-components-registry'
import { ConfigurableListComponentRegistryProvider } from '@planier/configurable-list'
import { FormViewComponentRegistryProvider } from '@planier/form-view'
import { getCustomModalComponents, getFormFieldComponents } from '@planier/form-view-components'
import { getJwt, setJwt } from '@planier/authentication'
import * as Sentry from '@sentry/react'
import { AppTheme, GlobalStyles } from '@planier/theme'

// side effect imports
import 'react-datepicker/dist/react-datepicker.min.css'

if (process.env.NODE_ENV !== 'production') {
    // Display Maps and Sets correctly in Redux DevTools (and maybe in other places as well)
    // Taken from https://github.com/zalmoxisus/redux-devtools-extension/issues/124#issuecomment-221972997
    Map.prototype.toJSON = function () {
        const obj = {}
        this.forEach((value, key) => (obj[key] = value))
        return obj
    }
    Set.prototype.toJSON = function () {
        const obj = []
        this.forEach((value) => obj.push(value))
        return obj
    }
}

if (location.hostname !== 'localhost' && location.hostname !== '127.0.0.1') {
    Sentry.init({
        dsn: 'https://fe9374eced2e4843a94bde62e6a88f46@o564080.ingest.sentry.io/6147473',
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.NODE_ENV,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    })
}

const listComponents = getListComponents()
const valuePickerComponents = getValuePickerComponents()
const formFieldComponents = getFormFieldComponents()
const customModalComponents = getCustomModalComponents()

const App = () => {
    useEffect(() => {
        const token = getJwt()

        if (token && token !== '') {
            setJwt(token)
        }
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={AppTheme}>
                <ThemeProvider theme={AppTheme}>
                    <ReduxProvider store={reduxStore}>
                        <ConfigurableListComponentRegistryProvider components={listComponents}>
                            <ValuePickerComponentRegistryProvider components={valuePickerComponents}>
                                <FormViewComponentRegistryProvider
                                    customModalComponents={customModalComponents}
                                    fieldComponents={formFieldComponents}
                                >
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <GlobalStyles />
                                        <Routes />
                                    </LocalizationProvider>
                                </FormViewComponentRegistryProvider>
                            </ValuePickerComponentRegistryProvider>
                        </ConfigurableListComponentRegistryProvider>
                    </ReduxProvider>
                </ThemeProvider>
            </MuiThemeProvider>
        </StyledEngineProvider>
    )
}

ReactDOM.render(<App />, document.getElementById('Planier'))
