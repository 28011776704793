import styled from '@emotion/styled'
import { useQuery } from '@planier/generic-utilities'
import DropdownList, { IDropdownProps } from '@planier/generic-components/Inputs/Dropdown/Dropdown'
import IDynamicDataPickerProps, { IDynamicDataValue } from '@planier/value-picker/types/IDynamicDataPickerProps'
import { FC } from 'react'
import TextInput from '@planier/generic-components/Inputs/TextInput/TextInputV2'
import { NumberInput, Toggle } from '@planier/generic-components'
import SingleDayPicker from './SingleDayPicker'
import { Moment } from 'moment'
import { ERequestMethod } from '@planier/rest-api'

interface IRegisterIdentifiersDropDownProps extends IDropdownProps {
    registerId: string
    placeholder: string
}

export enum EDynamicDataObjectType {
    Employee = 1,
    Workunit = 2,
}

enum EDynamicDataPropertyType {
    String = 1,
    Integer = 2,
    Boolean = 4,
    DateTime = 5,
    Register = 6,
}
interface IRegister {
    Id: number
    Name: string
}

interface IDynamicDataProperties {
    Id: number
    Name: string
    PropertyType: EDynamicDataPropertyType
    ObjectType: EDynamicDataObjectType
    Register: IRegister
    RegisterId: string
}

const Container = styled.div`
    padding-bottom: 16px;
`

const RegisterIdentifiersDropDownList: FC<IRegisterIdentifiersDropDownProps> = (props) => {
    const { registerId } = props
    const { data, isLoading } = useQuery<{ ListData: IDynamicDataProperties[] }>(`Register/${registerId}/Identifier`)
    const options: IDynamicDataProperties[] = isLoading || !data ? [] : data.ListData
    return <DropdownList {...props} isMultiselect={false} options={options} />
}

const getPropertyValue = (values: IDynamicDataValue[], property: IDynamicDataProperties) => {
    return values && values instanceof Array
        ? values?.filter((prop) => prop.PropertyId === property.Id)[0]?.Value
        : null
}

const DynamicDataPicker: FC<IDynamicDataPickerProps> = ({ value, onChange, required, ObjectType }) => {
    const body = { Filters: { ObjectType } }
    const { data, isLoading } = useQuery<{ ListData: IDynamicDataProperties[] }>(
        'DynamicProperty/Search',
        ERequestMethod.POST,
        body
    )

    const options: IDynamicDataProperties[] = isLoading || !data ? [] : data.ListData

    return (
        <>
            {data?.ListData.map((property) => {
                if (ObjectType !== property.ObjectType) {return null} // just in case...
                switch (property.PropertyType) {
                    case EDynamicDataPropertyType.Register: {
                        const propertyValue = getPropertyValue(value, property) ?? []
                        return (
                            <Container key={property.Id}>
                                <RegisterIdentifiersDropDownList
                                    key={property.Id}
                                    isMultiselect={false}
                                    registerId={property.RegisterId}
                                    options={options}
                                    value={[propertyValue]}
                                    label={property.Register.Name}
                                    placeholder={property.Register.Name}
                                    required={required}
                                    onChange={(Value) => {
                                        onChange([{ Value: Value[0], PropertyId: property.Id }])
                                    }}
                                />
                            </Container>
                        )
                    }
                    case EDynamicDataPropertyType.String: {
                        const handleOnChange = (Value: string) => {
                            onChange([{ Value, PropertyId: property.Id }])
                        }

                        const propertyValue = getPropertyValue(value, property) ?? []
                        return (
                            <Container key={property.Id}>
                                <TextInput
                                    key={property.Id}
                                    label={property.Name}
                                    placeholder={property.Register.Name}
                                    value={propertyValue}
                                    onChange={handleOnChange}
                                />
                            </Container>
                        )
                    }
                    case EDynamicDataPropertyType.DateTime: {
                        const propertyValue = getPropertyValue(value, property) ?? ''
                        const handleOnChange = (Value: Moment) => {
                            onChange([{ Value, PropertyId: property.Id }])
                        }

                        return (
                            <Container key={property.Id}>
                                <SingleDayPicker
                                    value={propertyValue}
                                    onChange={handleOnChange}
                                    key={property.Id}
                                    label={property.Name}
                                />
                            </Container>
                        )
                    }
                    case EDynamicDataPropertyType.Integer: {
                        const propertyValue = getPropertyValue(value, property) ?? ''
                        const handleOnChange = (Value: number) => {
                            onChange([{ Value: Value ? Value.toString() : null, PropertyId: property.Id }])
                        }
                        return (
                            <Container key={property?.Id}>
                                <NumberInput
                                    value={propertyValue}
                                    onChange={handleOnChange}
                                    key={property?.Id}
                                    label={property?.Name}
                                />
                            </Container>
                        )
                    }
                    case EDynamicDataPropertyType.Boolean: {
                        const propertyValue = getPropertyValue(value, property) === true
                        const handleChange = () => {
                            const Value = !propertyValue ?? false
                            onChange([{ Value, PropertyId: property.Id }])
                        }

                        return (
                            <Container key={property.Id}>
                                <Toggle
                                    checked={!!propertyValue}
                                    onChange={handleChange}
                                    label={property?.Name}
                                    key={property.Id}
                                />
                            </Container>
                        )
                    }
                    default: {
                        return <></>
                    }
                }
            })}
        </>
    )
}

export default DynamicDataPicker
