import * as React from 'react'
import { ColoredDot } from '@planier/generic-components'
import { getPreviewEventColor } from '@planier/theme'

export interface IListElementColor {
    displayValue: string | null
}

const ListElementColor: React.FunctionComponent<IListElementColor> = ({ displayValue }) =>
    displayValue ? <ColoredDot color={getPreviewEventColor(displayValue.replace('_', ''))} size={18} /> : null

export default ListElementColor
