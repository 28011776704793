import PropTypes from 'prop-types'
import * as React from 'react'
import styled from '@emotion/styled'
import ButtonBase from '@material-ui/core/ButtonBase'

import AbstractAction, { IAbstractActionProps } from 'components/atoms/AbstractAction'
import Styles from 'constants/Styles'
import { Translation } from '@planier/localization'
// import UnstyledButton from 'components/atoms/UnstyledButton'

export interface IActionLinkProps extends IAbstractActionProps {
    children: string
    disabled?: boolean
    translated?: boolean
}

interface IState {
    hover: boolean
}

type TButtonBaseProps = React.ComponentProps<typeof ButtonBase>
interface ILinkProps extends Omit<TButtonBaseProps, 'children'>, IActionLinkProps, IState {}

export const Link = styled<React.FC<ILinkProps>>(({ hover, ...other }: IState) => <ButtonBase {...other} />)`
    font-family: ${Styles.font.text.family};
    font-size: ${Styles.typography.button.fontSize};
    color: ${({ disabled }) => (disabled ? Styles.supplementaryColor.lightBlue : Styles.supplementaryColor.darkBlue)};
    text-transform: none;
    text-decoration: ${({ disabled, hover }) => (!disabled && hover ? 'underline' : 'none')};
`

/**
 * Klikattava linkki, joka alleviivaa itsensä hoveroidessa.
 * Anna linkkiteksti elementin sisällä. Käytä translated-lippua tagissa jos linkkiteksti on käännöskoodi.
 *
 * Esimerkki:
 * <ActionLink clickAction={ myAction } translated>my-translation-code</ActionLink>
 */
class ActionLink extends AbstractAction<IActionLinkProps> {
    static readonly defaultProps = {
        ...AbstractAction.defaultProps,
        disabled: false,
        translated: false,
    }

    static readonly propTypes = {
        ...AbstractAction.propTypes,
        children: PropTypes.string.isRequired,
        translated: PropTypes.bool,
    }

    state = {
        hover: false,
    }

    handleMouseOut = (): void => this.setState({ hover: false })

    handleMouseOver = (): void => this.setState({ hover: true })

    render(): React.ReactNode {
        const { disabled } = this.props
        const { hover } = this.state

        const text = this.props.translated ? Translation.translateKey(this.props.children) : this.props.children

        return (
            <Link
                className="button"
                disabled={disabled}
                hover={hover}
                // @@TODO Move the rest of the event handlers away from AbstractAction
                onClick={this._onClicked}
                onMouseOut={this.handleMouseOut}
                onMouseOver={this.handleMouseOver}
            >
                {text}
            </Link>
        )
    }
}

export default ActionLink
