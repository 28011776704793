import styled from '@emotion/styled'
import { JSX } from 'react'
import EventDot from './EventDot'
import { IEvent } from 'packages/event'
import { Moment } from 'moment'
import { ReadOnlyText } from '@planier/generic-components'
import { loadingPlaceholder } from 'constants/Styles'
import { EEventTypeCategory } from '@planier/event/Types/IEvent'
import { dayColorLogic, dotProps, getEventColor, parseDayState } from './DayLogic'
import { useSelector } from 'react-redux'
import { selectIsLoadingEvents } from '../State/EmployeeCalendarSelectors'
import { useAppTheme } from '@planier/theme'

type Props = {
    events: IEvent[]
    day: Moment
    selected: boolean
    isFirstSelected: boolean
    isLastSelected: boolean
    isToday: boolean
}

const CalendarDayContainer = styled.div<{
    selected: boolean
    isFirstSelected: boolean
    isLastSelected: boolean
    isToday: boolean
    dayColor: string
    hasOtherNotWorktime: boolean
    isLoading: boolean
}>`
    width: 36px;
    height: 36px;
    border-radius: 6px;
    border: ${({ selected, theme }) => selected && `1px solid ${theme.colors.neutralsGrey100}`};
    box-shadow: ${({ hasOtherNotWorktime, isLoading }) =>
        (isLoading && 'none') || (hasOtherNotWorktime && 'inset 0px 0px 0px 4px #C3A7D9')};

    outline: ${({ isToday, theme }) => isToday && `2px solid ${theme.colors.primaryTeal60}`};

    background: ${({ dayColor }) => dayColor};
    transition: border ${({ theme }) => theme.tokens.transitionQuick};

    ${({ isLoading }) => isLoading && loadingPlaceholder({ width: 50, height: 50 })}
`

const DayNumber = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;
    z-index: 1000;
`

const DotContainer = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

const DotRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 1px;
`

const CalendarDay = ({ events, day, selected, isFirstSelected, isLastSelected, isToday }: Props): JSX.Element => {
    const theme = useAppTheme()

    const dayState = parseDayState(events)
    const dayColorEvent = dayColorLogic(dayState)
    const dayColor = getEventColor(dayColorEvent, theme)

    const isLoadingEvents = useSelector(selectIsLoadingEvents)

    // invisible if dayColor on sama kuin Eventti
    return (
        <CalendarDayContainer
            selected={selected}
            isFirstSelected={isFirstSelected}
            isLastSelected={isLastSelected}
            isToday={isToday}
            dayColor={dayColor}
            hasOtherNotWorktime={dayState.hasOtherNotWorktime}
            isLoading={isLoadingEvents}
        >
            <DayNumber>
                <ReadOnlyText usage="bodyM">{day.date().toString()}</ReadOnlyText>
            </DayNumber>

            {!isLoadingEvents && (
                <DotContainer>
                    <DotRow>
                        <EventDot
                            {...dotProps(EEventTypeCategory.SickLeave, theme)}
                            invisible={!dayState.hasSickLeave || EEventTypeCategory.SickLeave === dayColorEvent}
                        />
                        <EventDot
                            {...dotProps(EEventTypeCategory.ReservingEventGroup, theme)}
                            invisible={
                                !dayState.hasReservingGroup || EEventTypeCategory.ReservingEventGroup === dayColorEvent
                            }
                        />
                        <EventDot invisible={true} />
                    </DotRow>
                    <DotRow>
                        <EventDot
                            {...dotProps(EEventTypeCategory.OtherWorktime, theme)}
                            invisible={!dayState.hasOtherWorkTime || EEventTypeCategory.OtherWorktime === dayColorEvent}
                        />
                        <EventDot invisible={true} />
                        <EventDot
                            {...dotProps(EEventTypeCategory.OtherNotWorktime, theme)}
                            invisible={
                                !dayState.hasOtherNotWorktime || EEventTypeCategory.OtherNotWorktime === dayColorEvent
                            }
                        />
                    </DotRow>
                    <DotRow>
                        <EventDot
                            {...dotProps(EEventTypeCategory.OfftimePreference, theme)}
                            invisible={
                                !dayState.hasTimeoffPreference || EEventTypeCategory.OfftimePreference === dayColorEvent
                            }
                        />
                        <EventDot
                            {...dotProps(EEventTypeCategory.WorktimePreference, theme)}
                            invisible={
                                !dayState.hasWorktimePreference ||
                                EEventTypeCategory.WorktimePreference === dayColorEvent
                            }
                        />
                        <EventDot invisible={true} />
                    </DotRow>
                </DotContainer>
            )}
        </CalendarDayContainer>
    )
}

export default CalendarDay
