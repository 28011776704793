import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { Field, FieldProps } from 'formik'
import styled from '@emotion/styled'

import FormikTextInput from 'components/atoms/TextInput/FormikTextInput'
import { FormModal, selectIsModalOpen } from '@planier/modal'
import { RequirableField } from '@planier/generic-components'
import { changePasswordThunk } from '../State/Thunks/PasswordChangeThunks'
import { selectIsPasswordChangeInProgress } from '../State/Selectors/PasswordChangeSelectors'
import { PASSWORD_MODAL_ID, passwordResetFormValidationSchema } from '../Constants/PasswordChangeConstants'
import { TranslatedTypography } from '@planier/generic-components'

const StyledRequirableField = styled(RequirableField)`
    padding-bottom: 48px;
`

const PasswordChangeRequirementsInfo = styled.div`
    padding-bottom: 48px;
`

const initialValues = {
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
}

const PasswordChangeForm: React.FC = () => {
    const dispatch = useDispatch()

    const isOpen = useSelector((state: RootState) => selectIsModalOpen(state, PASSWORD_MODAL_ID))
    const isPasswordChangeInProgress = useSelector(selectIsPasswordChangeInProgress)

    const handleSubmit = ({ currentPassword, newPassword1 }: typeof initialValues) => {
        dispatch(changePasswordThunk(currentPassword, newPassword1))
    }

    const formikProps = {
        initialValues,
        onSubmit: handleSubmit,
        validationSchema: passwordResetFormValidationSchema,
    }

    return (
        <FormModal
            disableSubmit={isPasswordChangeInProgress}
            formId={PASSWORD_MODAL_ID}
            formikProps={formikProps}
            modalId={PASSWORD_MODAL_ID}
            open={isOpen}
            title="password-change"
        >
            <React.Fragment>
                <Field name="currentPassword">
                    {(passProps: FieldProps) => (
                        <StyledRequirableField>
                            <FormikTextInput
                                {...passProps}
                                id="pln-password-change-pwCurrent"
                                label="password-current"
                                placeholder="password-current"
                                type="password"
                                testId="STC_FORM_PASSWORD_CURRENT_FIELD"
                            />
                        </StyledRequirableField>
                    )}
                </Field>
                <Field name="newPassword1">
                    {(passProps: FieldProps) => (
                        <StyledRequirableField>
                            <FormikTextInput
                                {...passProps}
                                id="pln-password-change-newPw1"
                                label="password-new"
                                placeholder="password-new"
                                type="password"
                                testId="STC_FORM_PASSWORD_NEW_FIELD"
                            />
                        </StyledRequirableField>
                    )}
                </Field>
                <Field name="newPassword2">
                    {(passProps: FieldProps) => (
                        <StyledRequirableField>
                            <FormikTextInput
                                {...passProps}
                                id="pln-password-change-newPs2"
                                label="password-new-again"
                                placeholder="password-new-again"
                                type="password"
                                testId="STC_FORM_PASSWORD_NEW_AGAIN"
                            />
                        </StyledRequirableField>
                    )}
                </Field>

                <PasswordChangeRequirementsInfo>
                    <TranslatedTypography variant="bodyS">password-format-example</TranslatedTypography>
                </PasswordChangeRequirementsInfo>
            </React.Fragment>
        </FormModal>
    )
}

export default PasswordChangeForm
