import DayPickerBase from './DayPickerBase'
import { Moment } from 'moment'
import { IValuePickerCommonOwnProps } from 'packages/value-picker'

export interface IMultiDayPickerProps extends IValuePickerCommonOwnProps<Moment[] | undefined> {
    shouldDateBeDisabled?: (val: string) => boolean
}

const MultiDayPicker: React.FC<IMultiDayPickerProps> = (props) => {
    const value = props.value ? props.value : []

    const onChange = (val: Moment[]) => props.onChange(val)

    return <DayPickerBase {...props} value={value} onChange={onChange} multiselect={true} />
}

export default MultiDayPicker
