import { FileUpload, IFile } from '@planier/generic-components'
import { IValuePickerCommonOwnProps } from '@planier/value-picker'

interface IFileValuePickerProps extends IValuePickerCommonOwnProps<IFile> {
    displayType?: 'default' | 'image'
}

const FilePicker: React.FC<IFileValuePickerProps> = ({ label, value, onChange, displayType }) => {
    return <FileUpload displayStyle={displayType ?? 'default'} value={value} label={label ?? ''} onChange={onChange} />
}

export default FilePicker
