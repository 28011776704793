import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import Styles from 'constants/Styles'
import { Translation } from '@planier/localization'
import { PageDescription } from '../ApplicationBar'
import { getIsLegacyEnvironment } from '@planier/legacy-environment-adapter'
import useSubPages from './useSubPages'
import { get } from 'lodash-es'

interface IPageContainerProps {
    children: React.ReactNode
    header: JSX.Element
    navItemsFitHorizontally: boolean
    pageTitle: string
    pageDescription: string | null
}

const newRoutes = [
    '/tyovuorosuunnittelu/:planningPeriodId?',
    '/workforcescheduling/:planningPeriodId?',
    '/wfs/:planningPeriodId',
]

const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 14px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.primaryChampion11};
`

const HeaderContainer = styled.div`
    width: 100%;
    z-index: 100;
`

// TODO: get rid of this
const getIsWorkforceSchedulingPage = (pageComponent: React.ReactNode) => {
    const path: string = get(pageComponent, 'props.match.path')
    const isWorkforceSchedulingPage = newRoutes.includes(path)

    return isWorkforceSchedulingPage
}

const BodyContainer = styled.div<{ areNavigationTabsRendered: boolean; pageComponent: React.ReactNode }>`
    display: inherit;
    flex-grow: 1;
    max-height: inherit;
    height: 100%;
    width: 100%;
    min-width: 320px;
    flex-direction: column;

    ${({ areNavigationTabsRendered }) =>
        !areNavigationTabsRendered &&
        `
        margin-top: 0.6rem;
    `}

    ${!getIsLegacyEnvironment() &&
    `
        padding-left: 40px;
    `}

    ${({ pageComponent }) =>
        getIsWorkforceSchedulingPage(pageComponent) &&
        `
            display: flex;
            max-height: calc(100% - ${Styles.layout.mainNavigationHeight});
            margin-top: 0;
        `}
`

const InnerBodyContainer = styled.div<{
    pageComponent: React.ReactNode
    pageTitle: string
    navItemsFitHorizontally: boolean
}>`
    display: flex;
    flex-direction: column;
    height: unset;
    width: 100%;

    ${({ pageComponent }) =>
        getIsWorkforceSchedulingPage(pageComponent) &&
        `
            height: 100%;
        `}

    ${({ navItemsFitHorizontally }) =>
        navItemsFitHorizontally &&
        getIsLegacyEnvironment() &&
        `
        padding-top: 33.04px;
    `}

    ${({ pageTitle }) =>
        !pageTitle &&
        `
        padding-top: 0;
    `}
`

const Title = styled(Typography)`
    padding-left: 1.25rem;
    margin-top: 1rem;
`

const PageContainer: React.FC<IPageContainerProps> = ({
    pageTitle,
    navItemsFitHorizontally,
    pageDescription,
    children,
    header,
}) => {
    const { areNavigationTabsRendered, SubPageNavigationTabs, subPageNavigationProps } = useSubPages()

    return (
        <Container>
            <HeaderContainer>{header}</HeaderContainer>

            <BodyContainer areNavigationTabsRendered={areNavigationTabsRendered} pageComponent={children}>
                {areNavigationTabsRendered && <SubPageNavigationTabs {...subPageNavigationProps} />}

                {pageTitle && getIsLegacyEnvironment() && navItemsFitHorizontally && (
                    <TitleContainer>
                        <Title variant="h1">
                            {Translation.has(pageTitle) ? Translation.translateKey(pageTitle) : pageTitle}
                        </Title>
                        <PageDescription descriptionText={pageDescription} />
                    </TitleContainer>
                )}

                <InnerBodyContainer
                    navItemsFitHorizontally={navItemsFitHorizontally}
                    pageComponent={children}
                    pageTitle={pageTitle}
                >
                    {children}
                </InnerBodyContainer>
            </BodyContainer>
        </Container>
    )
}

export default PageContainer
