import { JSX } from 'react'
import { css, Global } from '@emotion/react'
import FabrikRegular from '../../fonts/fabrik/Fabrik-Regular.woff2'
import FabrikBold from '../../fonts/fabrik/Fabrik-Bold.woff2'
import InterRegular from '../../fonts/inter/Inter-Regular.woff2'
import InterMedium from '../../fonts/inter/Inter-Medium.woff2'
import InterSemibold from '../../fonts/inter/Inter-Semibold.woff2'
import InterBold from '../../fonts/inter/Inter-Bold.woff2'

const GlobalStyles = (): JSX.Element => {
    const globalStyles = css`
        @font-face {
            font-family: 'Fabrik';
            src: url(${FabrikRegular});
            font-style: normal;
            font-weight: 400;
        }

        @font-face {
            font-family: 'Fabrik';
            font-style: bold;
            font-weight: 700;
            src: url(${FabrikBold});
        }

        @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            src: url(${InterRegular});
        }

        @font-face {
            font-family: 'Inter';
            font-weight: 500;
            src: url(${InterMedium});
        }

        @font-face {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            src: url(${InterSemibold});
        }

        @font-face {
            font-family: 'Inter';
            font-style: bold;
            font-weight: 700;
            src: url(${InterBold});
        }

        html {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 87.5%;
            height: 100%;
        }

        body {
            font-family: 'Source Sans Pro', sans-serif;
            margin: 0;
            padding: 0;
            height: 100%;
        }

        button {
            background-color: transparent;

            border-width: 0;
            font-family: inherit;
            font-size: inherit;
            font-style: inherit;
            font-weight: inherit;
            line-height: inherit;
            align-items: inherit;
            padding: 0;
            cursor: pointer;
        }

        *,
        *:before,
        *:after {
            -moz-box-sizing: inherit;
            -webkit-box-sizing: inherit;
            box-sizing: inherit;
        }

        ::-webkit-input-placeholder {
            /* WebKit, Blink, Edge */
            color: #939398;
        }

        :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #939398;
            opacity: 1;
        }

        ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #939398;
            opacity: 1;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #939398;
        }

        #Planier {
            height: 100%;
        }

        .react-datepicker-popper {
            z-index: 10000 !important;
        }
    `

    return <Global styles={globalStyles} />
}

export default GlobalStyles
