import * as React from 'react'
import { JSX } from 'react'
import styled from '@emotion/styled'

import { ReadOnlyText } from '@planier/generic-components'
import {
    IOnInputChangeFunction,
    ISelectRowsFunction,
    TAllValues,
    TNewlySelected,
    TRowTextComponent,
    TSelectedValues,
} from './../../Interfaces'
import { PADDING, REMOVE_ALL_BUTTON_HEIGHT, SEARCH_FIELD_HEIGHT } from './../../Constants'
import MenuContentList from './MenuContentList'
import SearchField from './SearchField'

type Props<T> = {
    hideSearch: boolean
    fullWidth: boolean
    inputFilterFunction: IOnInputChangeFunction
    itemOptionLabelFields: (keyof T)[]
    itemIdField: keyof T
    items: T[]
    textInputId: string
    menuHeight: number
    selectAction: ISelectRowsFunction<T>
    selectedNewItems: TNewlySelected<T>
    selectAllAction: ISelectRowsFunction<boolean>
    values: TAllValues<T>
    selectedValueItems: TSelectedValues<T>
    selectValueRowAction: ISelectRowsFunction<T>
    userInput: string
    RowTextComponent: TRowTextComponent<T>
    multiselect: boolean
    componentWidth: number
    isLoading: boolean
}

type IContainerProps = Pick<Props<any>, 'menuHeight' | 'hideSearch'>

const calculateMenuHeight = ({ menuHeight, hideSearch }: IContainerProps) => {
    const defaultHeight = menuHeight + REMOVE_ALL_BUTTON_HEIGHT + PADDING * 2
    return hideSearch ? defaultHeight : defaultHeight + SEARCH_FIELD_HEIGHT
}

const Container = styled.div`
    height: ${(props: IContainerProps) => calculateMenuHeight(props)}px;
`

const TopContainer = styled.div`
    padding: ${PADDING}px ${PADDING}px 0px;
`

const RemoveAllButton = styled.button`
    display: flex;
    margin-left: auto;
    height: ${REMOVE_ALL_BUTTON_HEIGHT}px;
`

const Menu = <T, >({
    componentWidth,
    hideSearch,
    itemIdField,
    itemOptionLabelFields,
    items,
    menuHeight,
    multiselect,
    inputFilterFunction,
    RowTextComponent,
    selectAction,
    selectAllAction,
    selectedNewItems,
    selectedValueItems,
    selectValueRowAction,
    textInputId,
    userInput,
    values,
    fullWidth,
    isLoading,
}: Props<T>): JSX.Element => {
    const handleRemoveAllButtonClick = () => {
        const selected = false
        selectAllAction(selected)
    }

    return (
        <Container hideSearch={hideSearch} menuHeight={menuHeight}>
            <TopContainer>
                <RemoveAllButton className="ctr-dropdown-remove-all-button" onClick={handleRemoveAllButtonClick}>
                    <ReadOnlyText translate usage="h6" color="primary_120" fontSize="14px">
                        tyhjenna-valinnat
                    </ReadOnlyText>
                </RemoveAllButton>
                {!hideSearch && (
                    <SearchField
                        inputChangeAction={inputFilterFunction}
                        textInputId={textInputId}
                        userInput={userInput}
                    />
                )}
            </TopContainer>
            <MenuContentList<T>
                componentWidth={componentWidth}
                fullWidth={fullWidth}
                isLoading={isLoading}
                itemIdField={itemIdField}
                itemOptionLabelFields={itemOptionLabelFields}
                items={items}
                listHeight={menuHeight}
                multiselect={multiselect}
                RowTextComponent={RowTextComponent}
                selectAction={selectAction}
                selectAllAction={selectAllAction}
                selectedNewItems={selectedNewItems}
                selectedValueItems={selectedValueItems}
                selectValueRowAction={selectValueRowAction}
                values={values}
            />
        </Container>
    )
}

export default Menu
