import * as React from 'react'
import { TagList } from '@planier/generic-components'

interface IListViewTag {
    MainColor: string
    SupportColor: string
    Label: string
    Id: number
}

interface IIListViewTagListProps {
    tags: IListViewTag[]
}

const mainColorField: keyof IListViewTag = 'MainColor'
const itemIdField: keyof IListViewTag = 'Id'
const supportColorField: keyof IListViewTag = 'SupportColor'
const labelFields: (keyof IListViewTag)[] = ['Label']

const ListElementTagList: React.FC<IIListViewTagListProps> = ({ tags }) => {
    return (
        <TagList
            itemIdField={itemIdField}
            itemLabelFields={labelFields}
            mainColorField={mainColorField}
            supportColorField={supportColorField}
            tagInfo={(tags as unknown) as React.ComponentProps<typeof TagList>['tagInfo']}
        />
    )
}

export default ListElementTagList
